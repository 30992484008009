import React from "react";
import * as Icon from "iconsax-react";
import { OverlayView } from "@react-google-maps/api";
import {
  Fade,
  Stack,
  Paper,
  Popper,
  Divider,
  useTheme,
  Typography,
} from "@mui/material";

export default function WarehousesMarker(props: any) {
  const { palette } = useTheme();
  const { warehouse } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <OverlayView
      key={warehouse.id}
      zIndex={open ? 11 : -1}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      bounds={
        {
          ne: { lat: warehouse.latitude, lng: warehouse.longitude },
          sw: { lat: warehouse.latitude, lng: warehouse.longitude },
        } as any
      }
    >
      <Stack
        onMouseEnter={handleClick()}
        onMouseLeave={() => setOpen(false)}
        sx={{
          zIndex: open ? 11 : -1,
          cursor: "pointer",
          width: 40,
          height: 40,
          position: "relative",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          animation: "effectBgWarehouse 1.5s linear infinite",
          "@keyframes effectBgWarehouse": {
            "0%": {
              bgcolor: `transparent`,
            },
            "50%": {
              bgcolor: palette.info.main,
            },
            "100%": {
              bgcolor: `transparent`,
            },
          },
        }}
      >
        <Stack
          sx={{
            width: 30,
            height: 30,
            textAlign: "center",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: palette.common.white,
            border: `2px solid ${palette.info.main}`,
          }}
        >
          <Icon.Building variant="Bulk" size={18} color={palette.info.main} />
          <Popper
            transition
            open={open}
            disablePortal
            placement="top"
            anchorEl={anchorEl}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ px: 2, py: 1, minWidth: 250 }}>
                  <Typography variant="subtitle2" flex={1} textAlign="left">
                    Depo Bilgisi
                  </Typography>
                  <Divider />
                  <Stack spacing={1} pt={1} textAlign="left">
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        Depo Adı:
                      </Typography>
                      <Typography variant="caption">
                        {warehouse.name}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        Firma Adı:
                      </Typography>
                      <Typography variant="caption">
                        {warehouse.firm_name}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        İl/İlçe:
                      </Typography>
                      <Typography variant="caption">
                        {warehouse.city}/{warehouse.county}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Stack>
      </Stack>
    </OverlayView>
  );
}
