import * as React from "react";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import DatePicker from "react-datepicker";
import dateFnsTr from "date-fns/locale/tr";
import { CalendarMonth } from "@mui/icons-material";
import {
  Box,
  Stack,
  Avatar,
  TextField,
  IconButton,
  Typography,
  AvatarGroup,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { BASE_URL } from "api/Env";
import { Services } from "api/Services";
import { IAutoPlaningListItem } from "../List/types";
import { usePlanner } from "contexts/PlannerProvider";

const service = new Services();

type IRowBox = {
  title: string;
  children: any;
};
const RowBox = ({ title, children }: IRowBox) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography flex={1} variant="body2" color="text.secondary">
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

interface Props {
  item: IAutoPlaningListItem;
  getData(): void;
  onEdit(): void;
}

export default function AutoPlanningListItem(props: Props) {
  const { item, getData, onEdit } = props;
  const {
    getRoutes,
    routeFilters,
    getOrders,
    filters,
    selectPointsOrders,
    onChangeAutoPlanning,
    deleteSelectPoints,
  } = usePlanner();
  const { enqueueSnackbar } = useSnackbar();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [delLoading, setDelLoading] = React.useState(false);
  const [date, setDate] = React.useState<Date | null>(null);

  const onSubmit = React.useCallback(async () => {
    setLoading(true);
    try {
      await service.automaticRoutingCreate({
        ...item,
        deliveryDate: date,
        orders: selectPointsOrders.map((o) => o.id),
      });
      setLoading(false);
      getRoutes(routeFilters);
      getOrders(filters);
      onChangeAutoPlanning();
      deleteSelectPoints();
      enqueueSnackbar("Başarıyla Oluşturuldu.", { variant: "success" });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Oluşturulamadı.", { variant: "error" });
    }
  }, [date, item, routeFilters, selectPointsOrders, filters]);

  const onDelete = React.useCallback(async () => {
    setDelLoading(true);
    try {
      await service.automaticRoutingDelete(item.branch.id, item.plan_name);
      getData(); // Listeyi yeniden çekiyorum
      enqueueSnackbar("Başarıyla silindi.", { variant: "success" });
      setDelLoading(false);
    } catch (error) {
      setDelLoading(false);
      enqueueSnackbar("Silinemedi.", { variant: "error" });
    }
  }, [item]);

  return (
    <Stack
      sx={{
        borderRadius: 1,
        overflow: "visible",
        bgcolor: "background.default",
        boxShadow:
          "0 0 2px 0 rgba(145, 158, 171, 0.5), 0 12px 24px -4px rgba(145, 158, 171, 0.2)",
      }}
    >
      <Stack px={2} minHeight={52} direction="row" alignItems="center">
        <Typography flex={1} variant="subtitle2">
          {item.plan_name}
        </Typography>
        <Stack spacing={1} direction="row" alignItems="center">
          <IconButton onClick={onEdit}>
            <Icon.Edit2 variant="Bulk" size={20} />
          </IconButton>
          <IconButton color="error" onClick={onDelete} disabled={delLoading}>
            {delLoading ? (
              <CircularProgress color="error" size={20} />
            ) : (
              <Icon.Trash variant="Bulk" size={20} />
            )}
          </IconButton>
          <IconButton onClick={() => setVisible((v) => !v)}>
            {visible ? (
              <Icon.ArrowUp variant="Bulk" size={20} />
            ) : (
              <Icon.ArrowDown variant="Bulk" size={20} />
            )}
          </IconButton>
        </Stack>
      </Stack>
      {visible && (
        <Stack spacing={1} sx={{ p: 2, pt: 0 }}>
          <RowBox
            title="Şube"
            children={
              <Typography variant="subtitle2">{item.branch.name}</Typography>
            }
          />
          <RowBox
            title="Çalışma süresi"
            children={
              <Typography variant="subtitle2">{item.workTime}</Typography>
            }
          />
          <RowBox
            title="Öğle Yemeği Başlangıç"
            children={
              <Typography variant="subtitle2">
                {item.workingTimes.lunchStartTime}
              </Typography>
            }
          />
          <RowBox
            title="Öğle Yemeği Bitiş"
            children={
              <Typography variant="subtitle2">
                {item.workingTimes.lunchEndTime}
              </Typography>
            }
          />
          <RowBox
            title="Araç doluluk oranı"
            children={
              <Typography variant="subtitle2">
                {item.dispatchExtraOptions.minFullness}
              </Typography>
            }
          />
          <RowBox
            title="Rota Modu"
            children={
              <Typography variant="subtitle2">
                {item.routeMode === "SHORTEST" ? "Minimum KM" : "Minimum Süre"}
              </Typography>
            }
          />
          <RowBox
            title="Ücretli yollar kullanılsın mı?"
            children={
              <Typography variant="subtitle2">
                {item.dontUseTollRoads ? "Evet" : "Hayır"}
              </Typography>
            }
          />
          <RowBox
            title="Mola süresi eklensin mi?"
            children={
              <Typography variant="subtitle2">
                {item.dispatchExtraOptions.useRestTime ? "Evet" : "Hayır"}
              </Typography>
            }
          />
          <RowBox
            title="Depooya dönülecek mi?"
            children={
              <Typography variant="subtitle2">
                {item.depOption === "FIRST"
                  ? "Depodan çıksın tekrar depoya dönmesin"
                  : item.depOption === "LAST"
                  ? "İlk noktadan başlasın en son depoya dönsün"
                  : "Depodan çıksın depoya geri dönsün"}
              </Typography>
            }
          />
          <RowBox
            title="Kuryeler"
            children={
              <AvatarGroup
                total={item.vehicles.length}
                sx={{
                  "& .MuiAvatar-root": {
                    width: 40,
                    height: 40,
                    fontSize: 10,
                    fontWeight: "700",
                    "&:first-of-type": {
                      zIndex: item.vehicles.length,
                    },
                  },
                }}
              >
                {item.vehicles.map((el, i) => {
                  return (
                    <Avatar
                      key={`img-${i}`}
                      sx={{ width: 40, height: 40, zIndex: i }}
                      src={`${BASE_URL}api/public/document/${el.picture_id}`}
                    />
                  );
                })}
              </AvatarGroup>
            }
          />
          <Stack direction="row" pt={2} spacing={1}>
            <Box flex={1}>
              <DatePicker
                withPortal
                showTimeSelect
                selected={date}
                locale={dateFnsTr}
                timeCaption="Saat"
                dateFormat="dd/MM/yyyy HH:mm"
                placeholderText="DD.MM.YYYY HH:mm"
                customInput={
                  <TextField
                    fullWidth
                    size="small"
                    label="Plan Tarihi"
                    placeholder="Plan tarihi giriniz"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      contentEditable: false,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarMonth />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                onChange={(date) => {
                  // @ts-ignore
                  setDate(date);
                }}
              />
            </Box>
            <LoadingButton
              size="small"
              loading={loading}
              onClick={onSubmit}
              variant="contained"
              sx={{ minWidth: 100 }}
              disabled={!date || selectPointsOrders.length === 0}
            >
              Devam Et
            </LoadingButton>
          </Stack>
          {selectPointsOrders.length === 0 && (
            <Typography variant="caption" color="error.main" textAlign="center">
              Lütfen harita üzerinden sipariş seçiniz.
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
}
