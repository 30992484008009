import * as React from "react";
import moment from "moment";
import * as Icon from "iconsax-react";
import { Button, Chip, Stack } from "@mui/material";
//
import { usePlanner } from "contexts/PlannerProvider";

interface Props {}

export default function RouteFilterSelects(props: Props) {
  const { routeFilters, setVisibleRouteFilters } = usePlanner();

  const dates =
    routeFilters.dates[0] && routeFilters.dates[1]
      ? routeFilters.dates
          .map((d) => moment(d).format("DD.MM.YYYY"))
          .join(" / ")
      : null;
  const projects =
    routeFilters.selectProjects.length === 0
      ? null
      : routeFilters.selectProjects.length === 1
      ? routeFilters.selectProjects[0].value
      : routeFilters.selectProjects.length;
  const branches =
    routeFilters.selectBranches.length === 0
      ? null
      : routeFilters.selectBranches.length === 1
      ? routeFilters.selectBranches[0].value
      : routeFilters.selectBranches.length;

  return (
    <Stack direction="row" flex={1} justifyContent="flex-end" spacing={1}>
      {dates && (
        <Chip
          label={dates}
          variant="soft"
          icon={<Icon.Calendar variant="Bulk" />}
        />
      )}
      {projects && (
        <Chip
          label={projects}
          variant="soft"
          icon={<Icon.Document variant="Bulk" />}
        />
      )}
      {branches && (
        <Chip
          label={branches}
          variant="soft"
          icon={<Icon.Building variant="Bulk" />}
        />
      )}
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={() => setVisibleRouteFilters(true)}
        startIcon={<Icon.FilterTick variant="Bulk" />}
      >
        Rotaları Filtrele
      </Button>
    </Stack>
  );
}
