import React from "react";
import { GoogleMap, useJsApiLoader, Polyline } from "@react-google-maps/api";
import { Box, Stack, useTheme } from "@mui/material";
import Orders from "./Orders";
import MapOptions from "./MapOptions";
import MapMarker from "./MapMarker";
import DepoMarker from "./DepoMarker";
import DepoSonArac from "./DepoSonArac";
import GerceklesenMarker from "./GerceklesenMarker";
import _ from "lodash";

const containerStyle = {
  width: "100%",
  height: "45vh",
};

const center = {
  lat: 39.006959567426534,
  lng: 35.4689090160141,
};

function MyComponent({ orders, polylineArr }) {
  const { palette } = useTheme();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCd7VzhABeme4cXeWwtv8bY2AWXKleRCbk",
  });
  const [clickRow, setClickRow] = React.useState(null);
  const [map, setMap] = React.useState(null);

  const [mapOptions, setMapOptions] = React.useState({
    planSirasi: true,
    planlananNoktalar: true,
    gerceklesenNoktalar: true,
    gpsSonArac: true,
    color: "#FF0000",
    weight: 6,
    opacity: 80,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds();

      if (orders.length > 0) {
        for (const order of orders) {
          bounds.extend({ lat: order.latitude, lng: order.longitude });
        }
        map.fitBounds(bounds);
      } else {
        bounds.extend(center);
        map.fitBounds(bounds);
        setTimeout(() => map.setZoom(6), 1000);
      }
    }
  }, [map, orders]);

  function onRowClick(order) {
    const index = orders.findIndex((i) => i.order_id === order.order_id);

    if (index > -1) {
      setClickRow(order);

      const bounds = new window.google.maps.LatLngBounds({
        lat: order.latitude,
        lng: order.longitude,
      });
      map.fitBounds(bounds);
      map.setZoom(16);
    }
  }

  return isLoaded ? (
    <Stack width="60%" spacing={2} direction="column">
      <Box
        minHeight="45vh"
        borderRadius={1}
        overflow="hidden"
        position="relative"
      >
        <MapOptions mapOptions={mapOptions} setMapOptions={setMapOptions} />
        <GoogleMap
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={containerStyle}
          options={{
            clickableIcons: false,
            mapTypeControl: true,
            streetViewControl: true,
            fullscreenControl: true,
          }}
        >
          {orders.map((order, index) => {
            const isActive = clickRow?.order_id === order.order_id;
            if (!mapOptions.planSirasi) {
              return null;
            }

            return (
              <MapMarker
                key={order.order_id}
                isActive={isActive}
                order={order}
              />
            );
          })}

          {polylineArr.map((e, index) => {
            const firstPlanlananRotalar = e.planlananRotalar[0];
            const lastGerceklesenRotalar =
              e.gerceklesenRotalar[e.gerceklesenRotalar.length - 1];

            return (
              <Box key={`polyline-item-${index}`} zIndex={999}>
                <Polyline
                  path={e.planlananRotalar.map((p) => ({
                    lat: p.latitude,
                    lng: p.longitude,
                  }))}
                  options={{
                    strokeColor: mapOptions.color,
                    strokeOpacity: mapOptions.opacity / 100,
                    strokeWeight: mapOptions.weight,
                    fillColor: mapOptions.color,
                    fillOpacity: 0.35,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: mapOptions.planlananNoktalar,
                    radius: 30000,
                    zIndex: 1,
                  }}
                />

                <Polyline
                  path={e.gerceklesenRotalar.map((p) => ({
                    lat: p.latitude,
                    lng: p.longitude,
                  }))}
                  options={{
                    strokeColor: palette.primary.main,
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    fillColor: palette.primary.main,
                    fillOpacity: 1,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: mapOptions.gerceklesenNoktalar,
                    radius: 30000,
                    zIndex: 2,
                  }}
                />

                {mapOptions.gerceklesenNoktalar &&
                  e.gerceklesenRotalar.map((g, i) => {
                    return (
                      <GerceklesenMarker
                        key={`gerceklesen-marker-${i}`}
                        route={e.route}
                        item={g}
                        location={{
                          lat: g.latitude,
                          lng: g.longitude,
                        }}
                      />
                    );
                  })}

                {mapOptions.planSirasi && firstPlanlananRotalar && (
                  <DepoMarker data={firstPlanlananRotalar} zIndex={8} />
                )}
                {lastGerceklesenRotalar && mapOptions.gpsSonArac && (
                  <DepoSonArac
                    route={e.route}
                    data={lastGerceklesenRotalar}
                    zIndex={10}
                  />
                )}
              </Box>
            );
          })}
        </GoogleMap>
      </Box>
      <Box mb={3}>
        <Orders orders={orders} onRowClick={onRowClick} />
      </Box>
    </Stack>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
