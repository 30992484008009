import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { statusFilter } from "helper";
import tableTextLabels from "utils/tableTextLabels";
import {
  Close,
  ExpandLess,
  ExpandMore,
  LocalShipping,
  Visibility,
} from "@mui/icons-material";
import exportCsv from "utils/exportCsv";
import { toTurkishLowerCase } from "components/CustomTable/utils";

const DataGrid = styled(MUIDataTable)(({ theme }) => ({
  "& thead th": { backgroundColor: "#F4F6F8!important", whiteSpace: "nowrap" },
  "& tbody tr": { cursor: "pointer" },
}));

const columnsInitial = [
  {
    name: "Plan Sırası",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Sipariş kodu",
    options: { filterType: "textField", display: "true" },
  },
  { name: "Sipariş tarihi", options: { filter: false, display: "true" } },
  {
    name: "Alıcı ismi",
    options: { filterType: "textField", display: "true" },
  },
  { name: "Teslim durumu", options: { display: "true" } },
  {
    name: "Adres",
    options: {
      filterType: "textField",
      display: "true",
    },
    style: { width: "300px" },
  },
  { name: "Telefon", options: { display: "true" } },
  { name: "İlçe", options: { display: "true" } },
  { name: "Desi", options: { display: "true" } },
  { name: "Teslim tarihi", options: { filter: false, display: "true" } },
  { name: "Paket Adedi", options: { filter: false, display: "true" } },
  {
    name: "Gönderi Kodu",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Şube",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Barkod",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Plan.Baş.Saati",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Plan.Bitiş.Saati",
    options: { filterType: "textField", display: "true" },
  },

  {
    name: "",
    options: {
      filter: false,
      display: false,
      viewColumns: false,
      download: false,
    },
  },
];

const Allorders = (props) => {
  const { orders, onRowClick } = props;
  const [columns, setColumns] = React.useState(columnsInitial);
  const [packageAccordion, setPackageAccordion] = React.useState(false);
  const [packageAccordionIndex, setPackageAccordionIndex] = React.useState(-1);

  const sortedOrders = orders.sort((a, b) => {
    return a.plan_day_sequence - b.plan_day_sequence;
  });

  const newOrders = sortedOrders.map((order) => {
    return [
      order.plan_day_sequence,
      order.order_code,
      moment(order.order_date).format("DD.MM.YYYY HH:mm"),
      order.sign_name,
      statusFilter(order),
      order.address,
      order.phone_number ? order.phone_number : "-",
      order.county,
      order.deci,
      order.delivery_date
        ? moment(order.delivery_date).add(3, "hours").format("DD.MM.YYYY HH:mm")
        : "-",

      order.quantity,
      order.shipment_code,
      order.branch,
      order.barcode,
      moment.utc(order.planned_start_time * 1000).format("HH:mm"),
      moment.utc(order.planned_end_time * 1000).format("HH:mm"),
      order,
    ];
  });

  const isVisibleRow = (rowIndex) => {
    return columns[rowIndex].options.display === "true";
  };

  return (
    <DataGrid
      title={`Tüm Siparişler (${newOrders.length})`}
      data={newOrders}
      columns={columns}
      sx={{ mb: 3 }}
      options={{
        onDownload: (buildHead, buildBody, columns, data) => {
          exportCsv({ fileName: "canliTakipOrders", columns, data });
          return false;
        },
        setTableProps: () => ({ size: "small" }),
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filterType: "dropdown",
        tableBodyHeight: "calc(55vh - 166px)",
        responsive: "standard",
        pagination: false,
        selectableRowsHeader: false,
        selectableRows: "none",
        textLabels: tableTextLabels,
        onViewColumnsChange: (changedColumn, action) => {
          var newCols = columns.map((col) => {
            let newCol = Object.assign({}, col);
            if (changedColumn === newCol.name) {
              if (action === "add") {
                newCol.options.display = "true";
              } else {
                newCol.options.display = "false";
              }
            }
            return newCol;
          });
          setColumns(newCols);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
          return (
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(event) => {
                handleSearch(toTurkishLowerCase(event.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={hideSearch}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        },
        customRowRender: (data, dataIndex, rowIndex) => {
          const item = data[data.length - 1];

          const isCompleted = ["DELIVERED"].includes(item.delivery_status);
          const isNotDelivered = [
            "NOT_DELIVERED",
            "WILL_BE_RETURN",
            "DELIVERY_CANCELLED",
          ].includes(item.delivery_status);
          return (
            <>
              <TableRow
                key={rowIndex}
                hover
                onClick={() => {
                  onRowClick(
                    newOrders[dataIndex][newOrders[dataIndex].length - 1]
                  );
                  setPackageAccordion(!packageAccordion);
                  setPackageAccordionIndex(dataIndex);
                }}
              >
                {isVisibleRow(0) && (
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {packageAccordion &&
                      packageAccordionIndex === dataIndex ? (
                        <ExpandLess fontSize="small" />
                      ) : (
                        <ExpandMore fontSize="small" />
                      )}
                      <LocalShipping
                        fontSize="small"
                        sx={{
                          color: isCompleted
                            ? "success.main"
                            : isNotDelivered
                            ? "error.main"
                            : "warning.main",
                        }}
                      />
                      <Typography variant="caption">{data[0]}</Typography>
                    </Stack>
                  </TableCell>
                )}
                {isVisibleRow(1) && (
                  <TableCell>
                    <Link
                      href={`/distribution/detail/sub-detail?siparisKodu=${data[1]}`}
                      target="_blank"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "secondary.main",
                      }}
                    >
                      <Visibility fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="subtitle2">{data[1]}</Typography>
                    </Link>
                  </TableCell>
                )}
                {isVisibleRow(2) && (
                  <TableCell>
                    <Typography variant="caption">{data[2]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(3) && (
                  <TableCell>
                    <Typography variant="caption">{data[3]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(4) && (
                  <TableCell>
                    <Typography variant="caption">{data[4]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(5) && (
                  <TableCell>
                    <Typography variant="caption" display="block" width={120}>
                      {data[5]}
                    </Typography>
                  </TableCell>
                )}
                {isVisibleRow(6) && (
                  <TableCell>
                    <Typography variant="caption">{data[6]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(7) && (
                  <TableCell>
                    <Typography variant="caption">{data[7]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(8) && (
                  <TableCell>
                    <Typography variant="caption">{data[8]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(9) && (
                  <TableCell>
                    <Typography variant="caption">{data[9]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(10) && (
                  <TableCell>
                    <Typography variant="caption">{data[10]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(11) && (
                  <TableCell>
                    <Typography variant="caption">{data[11]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(12) && (
                  <TableCell>
                    <Typography variant="caption">{data[12]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(13) && (
                  <TableCell>
                    <Typography variant="caption">{data[13]}</Typography>
                  </TableCell>
                )}
                {isVisibleRow(14) && (
                  <TableCell>
                    <Typography variant="caption" noWrap>
                      {data[14]}
                    </Typography>
                  </TableCell>
                )}
                {isVisibleRow(15) && (
                  <TableCell>
                    <Typography variant="caption" noWrap>
                      {data[15]}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
              {packageAccordion &&
                packageAccordionIndex === dataIndex &&
                item.packages.length > 0 &&
                item.packages.map((pack, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        pack.delivery_status === "DEBIT_FAIL_WRONG_BRANCH"
                          ? "warning.main"
                          : "#f4f5f9",
                      transition: "all 0.3s ease",
                      color: "#ff0 !important",
                    }}
                  >
                    <TableCell>{index + 1}. Paket</TableCell>
                    {isVisibleRow(1) && (
                      <TableCell>
                        <Stack direction="row">
                          <Visibility fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography variant="subtitle2">
                            {pack.order.order_code}
                          </Typography>
                        </Stack>
                      </TableCell>
                    )}
                    {isVisibleRow(2) && (
                      <TableCell>
                        <Typography variant="caption">
                          {" "}
                          {moment(item.order_date).format("DD.MM.YYYY HH:mm")}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(3) && (
                      <TableCell>
                        <Typography variant="caption">
                          {item.sign_name}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(4) && (
                      <TableCell>
                        <Typography variant="caption">
                          {statusFilter(item)}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(5) && (
                      <TableCell>
                        <Typography variant="caption">
                          {item.address}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(6) && (
                      <TableCell>
                        <Typography variant="caption">
                          {item.gsm_number || "-"}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(7) && (
                      <TableCell>
                        <Typography variant="caption">{item.county}</Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(8) && (
                      <TableCell>
                        <Typography variant="caption">
                          {" "}
                          {(item.deci / item.packages.length).toFixed(2)}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(9) && (
                      <TableCell>
                        <Typography variant="caption">
                          {" "}
                          {moment(item.delivery_date).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(10) && <TableCell>-</TableCell>}
                    {isVisibleRow(11) && (
                      <TableCell>
                        <Typography variant="caption">
                          {item.shipment_code}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(12) && (
                      <TableCell>
                        <Typography variant="caption">{item.branch}</Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(13) && (
                      <TableCell>
                        <Typography variant="caption">
                          {item.barcode}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(14) && (
                      <TableCell>
                        <Typography variant="caption">
                          {moment
                            .utc(item.planned_start_time * 1000)
                            .format("HH:mm")}
                        </Typography>
                      </TableCell>
                    )}
                    {isVisibleRow(15) && (
                      <TableCell>
                        <Typography variant="caption">
                          {moment
                            .utc(item.planned_end_time * 1000)
                            .format("HH:mm")}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </>
          );
        },
      }}
    />
  );
};

export default Allorders;
/*
 order.plan_day_sequence,
      order.order_code,
      moment(order.order_date).format("DD.MM.YYYY HH:mm"),
      order.county,
      order.deci,
      moment(order.delivery_date).format("DD.MM.YYYY HH:mm"),
      statusFilter(order),
      order.quantity,
      order.shipment_code,
      order.branch,
      order.barcode,
      moment(order.planned_start_time).format("HH:mm"),
      moment(order.planned_end_time).format("HH:mm"),
      order.sign_name,
      order.address,
      order,
 */
