import React from "react";
import moment from "moment";
import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
//
import ItemDrag from "./ItemDrag";
import { BASE_URL } from "api/Env";
import Label from "components/Label";
import { statusFilter } from "helper";
import ItemPackage from "./ItemPackage";
import DataLocaleText from "../DataLocaleText";
import { usePlanner } from "contexts/PlannerProvider";
import { OrderProps } from "contexts/PlannerProvider/types";
import CardDragLayer from "screens/DispatcherPlannerNew/CardDragLayer";
import { convertTimezone } from "utils/convertTimezone";

export interface Props {
  orders?: OrderProps[];
}

export default function DispatcherNewOrderList(props: Props) {
  const { orders } = props;
  const { points, handleSelectPoints, selectPointsOrders } = usePlanner();
  const [shouldProcessEvents, setShouldProcessEvents] = React.useState(false);

  React.useEffect(() => {
    // Bu etkileşim ile olayları işlemek için izin veriyoruz.
    setShouldProcessEvents(true);
  }, []);

  const newArr = orders ? orders : selectPointsOrders;
  const rowSelectionModel = newArr.map((i) => i.id);

  const tableData = React.useMemo(() => {
    return newArr.map((order) => {
      return {
        ...order,
        siparis_kodu: order.order_code,
        alici: `${order.customer.first_name} ${order.customer.last_name || ""}`,
        siparis_tarihi: convertTimezone(order.address.created_at),
        durumu: statusFilter(order),
        desi: order.deci,
        paket_sayisi: order.packages.length,
        proje: order.customer.first_name,
        sender: order.sender,
        sube: order.assigned_branch?.name || "",
        il: order.address.city,
        ilce: order.address.county,
        address: order.address.address,
        depo: order.warehouse.name,
      };
    });
  }, [newArr]);

  const onRowSelectionModelChange = (ids: any[]) => {
    if (shouldProcessEvents) {
      const getPoints = points.filter((p) => ids.includes(p.order_id));
      handleSelectPoints(getPoints);
    }
  };

  return (
    <>
      <CardDragLayer />
      <Box height={1}>
        <DataGrid
          rows={tableData}
          checkboxSelection
          disableRowSelectionOnClick
          localeText={DataLocaleText}
          pageSizeOptions={[25, 50, 100]}
          rowSelectionModel={rowSelectionModel}
          sx={{ bgcolor: "background.neutral" }}
          getCellClassName={() => "theme--header"}
          onRowSelectionModelChange={onRowSelectionModelChange}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          columns={[
            {
              width: 200,
              field: "siparis_kodu",
              headerName: "Sipariş Kodu",
              renderCell: (params) => (
                <ItemDrag
                  order={params.row as any}
                  selects={selectPointsOrders}
                  clearItemSelection={() => handleSelectPoints([])}
                />
              ),
            },
            {
              width: 200,
              field: "alici",
              headerName: "Alıcı",
            },
            {
              width: 200,
              field: "siparis_tarihi",
              headerName: "Sipariş Tarihi",
              renderCell: ({ row }) => {
                return <Label>{row.siparis_tarihi}</Label>;
              },
            },
            {
              width: 200,
              field: "durumu",
              headerName: "Durumu",
              renderCell: ({ row }) => {
                return (
                  <Label
                    color={row.durumu === "DELIVERED" ? "success" : "warning"}
                  >
                    {row.durumu}
                  </Label>
                );
              },
            },
            {
              width: 120,
              field: "desi",
              headerName: "Desi",
              renderCell: (params) => {
                return <Label>{params.row.desi}</Label>;
              },
            },
            {
              width: 200,
              field: "paket_sayisi",
              headerName: "Paket Sayısı",
              renderCell: (params) => {
                return <ItemPackage order={params.row as any} />;
              },
            },
            {
              width: 200,
              field: "proje",
              headerName: "Proje",
              renderCell: (params) => {
                const photo = params.row.warehouse.firm_logo_id
                  ? `${BASE_URL}api/public/document/${params.row.warehouse.firm_logo_id}`
                  : undefined;
                return (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                      src={photo}
                      sx={{ width: 30, height: 30 }}
                      alt={params.row.warehouse.firm_name}
                    />
                    <Typography variant="caption">
                      {params.row.warehouse.firm_name}
                    </Typography>
                  </Stack>
                );
              },
            },
            {
              width: 200,
              field: "sender",
              headerName: "Gönderen",
              renderCell: (params) => {
                return (
                  <Chip variant="soft" color="info" label={params.row.sender} />
                );
              },
            },
            {
              width: 200,
              field: "sube",
              headerName: "Şube",
            },
            {
              width: 200,
              field: "depo",
              headerName: "Depo",
            },
            {
              width: 200,
              field: "il",
              headerName: "İl",
            },
            {
              width: 200,
              field: "ilce",
              headerName: "İlçe",
            },
            {
              width: 300,
              field: "address",
              headerName: "Adres",
              renderCell: (params) => {
                return (
                  <Typography variant="caption" whiteSpace="pre-line">
                    {params.row.address}
                  </Typography>
                );
              },
            },
          ]}
          slotProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
          components={{
            Toolbar: () => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box flex={1} pl={2}>
                    <GridToolbarQuickFilter sx={{ width: 1 }} />
                  </Box>
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarColumnsButton />
                    <GridToolbarExport
                      printOptions={{ disableToolbarButton: true }}
                    />
                  </GridToolbarContainer>
                </Stack>
              );
            },
          }}
        />
      </Box>
    </>
  );
}
