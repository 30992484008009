import React from "react";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import dateFnsTr from "date-fns/locale/tr";
import { CalendarMonth } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
//
import { Services } from "api/Services";
import { BranchProps, DriverProps } from "./@types";
import CourierContent from "./VehicleCourierModal/Courier";
import { LoadingButton } from "@mui/lab";
import { usePlanner } from "contexts/PlannerProvider";
import generateUniqueColors from "utils/randomColors";

const service = new Services();

type StateProps = {
  name: string;
  branch: BranchProps | null;
  startDate: Date | null;
};

export interface RutCreateModalProps {
  onClose(): void;
}

export default function RutCreateModal(props: RutCreateModalProps) {
  const { onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { addNewRoutes } = usePlanner();
  const [state, setState] = React.useState<StateProps>({
    name: "",
    branch: null,
    startDate: null,
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [branches, setBranches] = React.useState<BranchProps[]>([]);
  const [selectDrivers, setSelectDrivers] = React.useState<DriverProps[]>([]);

  const getData = async () => {
    try {
      const res = await service.getPlanningBranches();
      setBranches(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const onSubmit = async () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        enqueueSnackbar("Lütfen tüm alanları doldurunuz.", {
          variant: "error",
        });
        return;
      }
    }

    if (!state.branch) {
      enqueueSnackbar(`Lütfen şube seçiniz`, {
        variant: "error",
      });
      return;
    }

    if (selectDrivers.length === 0) {
      enqueueSnackbar(`Lütfen en az 1 tane kurye seçiniz`, {
        variant: "error",
      });
      return;
    }

    const selectVehicle = selectDrivers.find((d) =>
      d.vehicles.find((v) => v.select)
    );

    if (!selectVehicle) {
      enqueueSnackbar(`Lütfen seçilen kuryelerin araçlarını seçiniz`, {
        variant: "error",
      });
      return;
    }

    const createColors = generateUniqueColors(selectDrivers.length);

    const sendData = selectDrivers.map((el, index: number) => {
      console.log(el.full_name, el.vehicles);
      const item = {
        route_name: state.name,
        branch_id: state.branch?.id,
        route_date: state.startDate,
        route_color: createColors[index],
        //
        driver_id: el.id,
        vehicle_id: el.vehicles.find((v) => v.select)?.name,
      };

      return item;
    });

    setSubmitLoading(true);

    try {
      const res = await service.postPlanningRouteCreate({
        routes: [...sendData],
      });
      const getRoutes = await service.getPlanningRoutes(
        res.entity.data[0].created_routes.map((r: any) => r.route_id)
      );
      addNewRoutes(getRoutes);
      setSubmitLoading(false);
      enqueueSnackbar("Rut başarıyla oluşturuldu.", {
        variant: "success",
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", {
        variant: "error",
      });
      setSubmitLoading(false);
    }
  };

  return (
    <Dialog hideBackdrop fullWidth maxWidth="md" open>
      <DialogTitle variant="h3">Rota Oluştur</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={3}>
          <TextField
            label="Rota Adı"
            placeholder="Rota Adı gir"
            onChange={(e) => {
              setState((s) => ({ ...s, name: e.target.value }));
            }}
          />
          <Stack direction="row" alignItems="center" gap={1}>
            <Box width={1}>
              <DatePicker
                withPortal
                showTimeSelect
                locale={dateFnsTr}
                timeCaption="Saat"
                selected={state.startDate}
                dateFormat="dd/MM/yyyy HH:mm"
                placeholderText="DD.MM.YYYY HH:mm"
                customInput={
                  <TextField
                    fullWidth
                    label="Plan Tarihi"
                    InputProps={{
                      contentEditable: false,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarMonth />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                onChange={(date) => {
                  setState((s) => ({ ...s, startDate: date }));
                }}
              />
            </Box>
          </Stack>
          <Autocomplete
            fullWidth
            loading={loading}
            options={branches}
            value={state.branch}
            getOptionLabel={(option) => option.name}
            onChange={(a, branch) => {
              setState((s) => ({ ...s, branch }));
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  variant="soft"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} placeholder="Ara" label="Şube" />
            )}
          />
          <CourierContent
            branch={state.branch}
            setSelectDrivers={setSelectDrivers}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="outlined" color="error" onClick={onClose}>
          Vazgeç
        </Button>
        <LoadingButton
          fullWidth
          onClick={onSubmit}
          variant="contained"
          loading={submitLoading}
        >
          Ekle
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
