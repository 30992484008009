import React, { useCallback, useState } from "react";
import Tree from "react-d3-tree";
import { Services } from "api/Services";
import Layout from "components/Layout";
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteModal from "./DeleteModal";
import Iconify from "components/Iconify";
import InputField from "components/MultiStepForm/InputField";
import UpdateModal from "./UpdateModal";
import CreateModal from "./CreateModal";

const server = new Services();

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

function convertToNewStructure(node) {
  const newNode = {
    id: node.id,
    name: node.name,
    children: node.sub_groups?.map((i) => convertToNewStructure(i)),
  };

  return newNode;
}

export const CustomActions = ({ onClick, icon, bgColor }) => {
  return (
    <Box
      sx={{
        width: 30,
        height: 30,
        borderRadius: "50%",
        color: "#fff",
        fontSize: "14px",
        backgroundColor: bgColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <IconButton color="secondary" size="large" onClick={() => null}>
        <Iconify icon={icon} width={20} height={20} sx={{ color: "#fff" }} />
      </IconButton>
    </Box>
  );
};

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  openCreateModal,
  openUpdateModal,
  openDeleteModal,
  setSelectedNode,
  foreignObjectProps,
}) => (
  <g style={{ cursor: "pointer" }}>
    <circle
      r={20}
      fill={nodeDatum.children?.length ? "#00AB55" : "#00ab555c"}
      onClick={toggleNode}
    />
    <foreignObject {...foreignObjectProps}>
      <Box
        sx={{
          border: "1px solid black",
          borderRadius: "5px",
          p: 1,
          backgroundColor: "#f1f1f1",
          "&:hover": {
            ".actions-button": {
              display: "flex",
            },
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "500", fontSize: "1.1rem", textAlign: "center" }}
        >
          {nodeDatum.name}
        </Typography>
        <Stack
          className="actions-button"
          direction="row"
          spacing={2}
          sx={{ display: "none" }}
        >
          <CustomActions
            onClick={() => {
              setSelectedNode(nodeDatum);
              openCreateModal();
            }}
            icon={"eva:plus-fill"}
            bgColor={"#00AB55"}
          />
          <CustomActions
            onClick={() => {
              setSelectedNode(nodeDatum);
              openUpdateModal();
            }}
            icon={"eva:edit-fill"}
            bgColor={"#3366FF"}
          />
          <CustomActions
            onClick={() => {
              setSelectedNode(nodeDatum);
              openDeleteModal();
            }}
            icon={"eva:trash-2-outline"}
            bgColor={"#FF3366"}
          />
        </Stack>
      </Box>
    </foreignObject>
  </g>
);

export default function App() {
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 200, y: 200 };
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const foreignObjectProps = { width: 140, height: 150, x: 20 };
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedNode, setSelectedNode] = useState();

  const getData = () => {
    server
      .getGroupHyrarchy()
      .then((res) => {
        const newData = convertToNewStructure(res[0]);
        setData(newData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleDelete = async () => {
    try {
      await server.deleteGroupHyrarchy(selectedNode.id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async () => {
    try {
      await server.updateGroupHyrarchy({
        id: selectedNode.id,
        name: selectedNode.name,
        sub_groups: selectedNode.children,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreate = async () => {
    try {
      await server.createGroupHyrarchy({
        name: selectedNode.name,
        parentId: selectedNode.id,
        sub_groups: [],
      });
    } catch (err) {
      console.log(err);
    }
  };
  console.log("isOpenCreate", isOpenCreate);
  return (
    <Layout
      heading="Hiyerarşi"
      links={[{ name: "Genel", href: "/" }, { name: "Hiyerarşi" }]}
      action={null}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div style={containerStyles} ref={containerRef}>
            <Tree
              data={data}
              nodeSize={nodeSize}
              orientation="vertical"
              translate={{ x: 300, y: 150 }}
              separation={{ siblings: 1, nonSiblings: 1 }}
              renderCustomNodeElement={(rd3tProps) =>
                renderForeignObjectNode({
                  ...rd3tProps,
                  foreignObjectProps,
                  openCreateModal: () => setIsOpenCreate(true),
                  openUpdateModal: () => setIsOpenUpdate(true),
                  openDeleteModal: () => setIsOpenDelete(true),
                  setSelectedNode: (node) => setSelectedNode(node),
                })
              }
            />
          </div>

          <CreateModal
            open={isOpenCreate}
            onClose={() => setIsOpenCreate(false)}
            onChange={(e) => {
              setSelectedNode({ ...selectedNode, name: e.target.value });
            }}
            onSubmit={async () => {
              await handleCreate();
              setIsOpenCreate(false);
              getData();
            }}
          />

          <UpdateModal
            open={isOpenUpdate}
            onClose={() => setIsOpenUpdate(false)}
            onChange={(e) => {
              setSelectedNode({ ...selectedNode, name: e.target.value });
            }}
            selectedNode={selectedNode}
            onSubmit={async () => {
              await handleUpdate();
              setIsOpenUpdate(false);
              getData();
            }}
          />
          <DeleteModal
            open={isOpenDelete}
            handleClose={() => setIsOpenDelete(false)}
            onSubmit={async () => {
              await handleDelete();
              setIsOpenDelete(false);
              getData();
            }}
          />
        </>
      )}
    </Layout>
  );
}
