import React from "react";
import { Box, FormControl, Grid, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DeleteSweep } from "@mui/icons-material";
import { Services } from "api/Services";
import _ from "lodash";
import MultipleSelect from "components/MultipleSelect";
import { useDispatch } from "react-redux";

const server = new Services();

export default function Filters({ filter, loading, getDashboardCounts }) {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState({
    customers: [],
    branches: [],
    cities: [],
    counties: [],
    warehouses: [],
  });

  const getFilters = async () => {
    try {
      const res = await server.getCustomerManagementFilters();
      const data = res.entity.data[0];
      setOptions({
        customers: _.uniqBy(data.customers, (e) => e.key),
        branches: _.uniqBy(data.branches, (e) => e.key),
        cities: _.uniqBy(data.cities, (e) => e.key),
        counties: _.uniqBy(data.counties, (e) => e.key),
        warehouses: _.uniqBy(data.warehouses, (e) => e.key),
      });
    } catch (error) {
      //console.log(error);
    }
  };

  React.useEffect(() => {
    getFilters();
  }, []);

  const onChange = (name, value) => {
    let newValues = [...value];

    const duplicates = _.filter(
      newValues.map((i) => i.key),
      (val, i, iteratee) => _.includes(iteratee, val, i + 1)
    );

    if (duplicates.length > 0) {
      newValues = _.uniqBy(newValues, (i) => i.key);

      // if still
      const ifStillIndex = newValues.findIndex((i) =>
        duplicates.includes(i.key)
      );
      if (ifStillIndex > -1) {
        newValues.splice(ifStillIndex, 1);
      }
    }

    const newFilter = { ...filter, [name]: newValues };

    dispatch({ type: "ANALYICS_FILTER_SET", payload: newFilter });
  };

  const resetFilter = () => {
    dispatch({ type: "ANALYICS_FILTER_RESET" });
    getDashboardCounts(true);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Projeler</InputLabel>
            <MultipleSelect
              label="Projeler"
              name="customers"
              defaultValue={filter.customers}
              options={options.customers}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Şubeler</InputLabel>
            <MultipleSelect
              label="Şubeler"
              name="branches"
              defaultValue={filter.branches}
              options={options.branches}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Depolar</InputLabel>
            <MultipleSelect
              label="Depolar"
              name="warehouses"
              defaultValue={filter.warehouses}
              options={options.warehouses}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>İller</InputLabel>
            <MultipleSelect
              label="İller"
              name="cities"
              defaultValue={filter.cities}
              options={options.cities}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>İlçeler</InputLabel>
            <MultipleSelect
              label="İlçeler"
              name="counties"
              defaultValue={filter.counties}
              options={options.counties}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <LoadingButton
            fullWidth
            color="error"
            loading={loading}
            startIcon={<DeleteSweep />}
            onClick={resetFilter}
          >
            Temizle
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
