import React from "react";
//
import { usePlanner } from "contexts/PlannerProvider";
import MapLegendsBranchesMarker from "./marker";

export default function MapLegendsBranches() {
  const { mapLegendsBranches, mapLegends } = usePlanner();

  if (!mapLegends.subeler) {
    return null;
  }

  return (
    <>
      {mapLegendsBranches.map((branch) => {
        return <MapLegendsBranchesMarker key={branch.id} branch={branch} />;
      })}
    </>
  );
}
