import React from "react";
import ReactExport from "react-export-excel";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "components/Iconify";
import PropTypes from "prop-types";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const getMultiValue = (data) => {
  let values = "";

  for (const [key, value] of Object.entries(data)) {
    values += `${value} `;
  }
  return values;
};

const DownloadExcel = (props) => {
  const { name, tableHeadData, tableData } = props;

  return (
    <ExcelFile
      filename={name
        .replaceAll(" ", "")
        .toLowerCase()
        .removeTurkishCharacters()}
      element={
        <Tooltip title="Excel İndir">
          <IconButton>
            <Iconify icon={"file-icons:microsoft-excel"} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet data={tableData} name={name}>
        {tableHeadData.map((head, index) => {
          const { title, key } = tableHeadData[index];

          return (
            <ExcelColumn
              key={`excel-item-${index}`}
              label={title}
              value={(val) => {
                const nestedObjectKey1 = key.split(".")[1];
                const nestedObjectControl = key.indexOf(".") > -1;
                const nestedObjectKey0 = val[key.split(".")[0]];

                const value = nestedObjectControl
                  ? nestedObjectKey0 != undefined
                    ? nestedObjectKey0[nestedObjectKey1]
                    : ""
                  : val[key];
                if (!["string", "number"].includes(typeof value) && !!value) {
                  return getMultiValue(value);
                }
                return value;
              }}
            />
          );
        })}
      </ExcelSheet>
    </ExcelFile>
  );
};

DownloadExcel.defaultProps = {
  name: "Dosya Adi",
  tableHeadData: [],
  tableData: [],
};

DownloadExcel.propTypes = {
  name: PropTypes.string,
  tableHeadData: PropTypes.array,
  tableData: PropTypes.array,
};

export default DownloadExcel;
