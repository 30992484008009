import { Dialog, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { CustomActions } from ".";

const UpdateModal = ({ open, onClose, onChange, onSubmit, selectedNode }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Typography variant="h6" sx={{ textAlign: "center", p: 2 }}>
        Grup Düzenle
      </Typography>
      <Stack spacing={2} sx={{ p: 2 }}>
        <TextField
          label="Grup Adı"
          value={selectedNode?.name}
          onChange={onChange}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2, justifyContent: "flex-end", alignItems: "center" }}
        >
          <CustomActions
            onClick={onClose}
            icon={"eva:close-outline"}
            bgColor={"#000"}
          />
          <CustomActions
            icon={"eva:checkmark-outline"}
            bgColor={"#00AB55"}
            onClick={() => onSubmit()}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default UpdateModal;
