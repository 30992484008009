import React from "react";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import {
  Box,
  Stack,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
//
import { Services } from "api/Services";
import { usePlanner } from "contexts/PlannerProvider";
import { PointProps } from "contexts/PlannerProvider/types";

//
const services = new Services();

export interface Props {}

export default function DispatcherNewMapSearch(props: Props) {
  const { setPointSearchList } = usePlanner();
  const { enqueueSnackbar } = useSnackbar();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const searchTimeRef = React.useRef<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onChangeText = React.useCallback((val: string) => {
    setSearch(val);

    if (val === "") {
      setLoading(false);
      setPointSearchList([]);
      clearTimeout(searchTimeRef.current);
      return;
    }

    if (searchTimeRef.current) {
      clearTimeout(searchTimeRef.current);
    }

    setLoading(true);

    searchTimeRef.current = setTimeout(async () => {
      try {
        const res: PointProps[] = await services.getPlanningPointSearch(
          val.toLocaleLowerCase("tr")
        );
        setPointSearchList(res);
        if (res.length === 0) {
          enqueueSnackbar("Hiç sonuç bulunamadı.", {
            variant: "warning",
            autoHideDuration: 3000,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }, 1000);
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearch("");
    setPointSearchList([]);
  }, []);

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        top: 20,
        right: 20,
        zIndex: 2,
        height: 44,
        bgcolor: "grey.0",
        borderRadius: 1.2,
        position: "absolute",
      }}
    >
      {visible && (
        <Stack direction="row" alignItems="center" flex={1}>
          {search !== "" && (
            <IconButton
              size="small"
              color="error"
              sx={{ ml: 1 }}
              onClick={clearSearch}
            >
              <Icon.Trash variant="Bulk" />
            </IconButton>
          )}
          <TextField
            autoFocus
            size="small"
            value={search}
            focused={false}
            inputMode="search"
            placeholder="Harita üzerinde arama yap"
            onChange={(e) => onChangeText(e.target.value)}
            sx={{
              minWidth: 320,
              "& fieldset": { borderWidth: 0 },
            }}
          />
        </Stack>
      )}
      <Box
        sx={{ height: 24, cursor: "pointer" }}
        onClick={() => {
          setSearch("");
          setVisible(!visible);
        }}
      >
        {loading ? (
          <CircularProgress size={24} sx={{ mx: 1.25 }} />
        ) : (
          <Box
            component="img"
            src="/images/planner-new/map/search.svg"
            sx={{ mx: 1.25, width: 24, height: 24, objectFit: "contain" }}
          />
        )}
      </Box>
    </Stack>
  );
}
