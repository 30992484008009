import PropTypes from "prop-types";
// @mui
import { Card, Typography, CardContent, Box, Stack } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
// utils
import { fDateTime } from "utils/formatTime";

// ----------------------------------------------------------------------

export default function AnalyticsOrderTimeline({
  activeTimeline,
  data,
  setActiveTimeline,
}) {
  return (
    <Card
      sx={{
        p: 3,
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      <Stack direction="row" spacing={4} mb={2}>
        <Typography
          onClick={() => setActiveTimeline("order")}
          sx={{
            color:
              activeTimeline === "order" ? "primary.main" : "text.secondary",
            cursor: "pointer",
          }}
        >
          Sipariş Hareketleri
        </Typography>
        <Typography
          onClick={() => setActiveTimeline("package")}
          sx={{
            color:
              activeTimeline === "package" ? "primary.main" : "text.secondary",
            cursor: "pointer",
          }}
        >
          Paket Hareketleri
        </Typography>
      </Stack>
      <CardContent sx={{ px: 0, pt: 0 }}>
        <Timeline sx={{ px: 0 }}>
          {data.history.map((item, index) => {
            const isLast = data.history.length - 1 === index;
            return <OrderItem key={index} item={item} isLast={isLast} />;
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

const colors = {
  ORDER_RECEIVED: "success",
  ORDER_ACCEPTED: "primary",
  DELIVERY_PLANNED: "info",
  BRANCH_ACCEPTED: "warning",
  COURIER_DEBIT: "secondary",
  ON_THE_WAY_TO_CUSTOMER: "error",
};

function OrderItem({ item, isLast }) {
  const {
    status,
    username,
    date,
    fullName,
    courierName,
    updateduser,
    deliveryTo,
    description,
  } = item;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={colors[status]} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {status === "DELIVERED" && deliveryTo ? (
          <Box>
            <Typography variant="subtitle2">
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                <Box>{status}</Box>
                <Box fontStyle="italic">( {deliveryTo} )</Box>
              </Stack>
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle2">{status}</Typography>
        )}
        {status === "DELIVERED" ||
        status === "NOT_DELIVERED" ||
        status === "BRANCH_ACCEPTED" ? ( // latest statuses
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {updateduser} ( {username} ) - {fDateTime(date)}
          </Typography>
        ) : (
          <>
            {/* ???????????? */}
            {fullName && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {fullName} ( {username} ) - {fDateTime(date)}
              </Typography>
            )}
            {!fullName && courierName && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {courierName} ( {username} ) - {fDateTime(date)}
              </Typography>
            )}

            {!fullName && !courierName && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {username} - {fDateTime(date)}
              </Typography>
            )}
          </>
        )}
        {description && (
          <Typography
            component="div"
            variant="caption"
            sx={{ color: "text.secondary" }}
          >
            {description}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}
