import React from "react";
import { useDragLayer } from "react-dnd";
import { Box, Typography } from "@mui/material";
//
import { DndTypes } from "./dragable";
import { OrderProps } from "contexts/PlannerProvider/types";

const getItemStyles = (currentOffset: any) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
  };
};

export default function CardDragLayer() {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const renderItem = (type: any, item: any) => {
    const cards = item.cardsDragStack;
    switch (type) {
      case DndTypes.ORDER:
        return (
          <>
            {cards.slice(0, 10).map((item: OrderProps, i: number) => {
              const firstItem = i === 0;
              return (
                <Box
                  key={`card-drag-layer-${i}`}
                  sx={{
                    p: 2,
                    borderRadius: 1,

                    border: "1px solid",
                    position: "absolute",
                    borderColor: "grey.300",
                    zIndex: cards.length - i,
                    bgcolor: "background.neutral",
                    transform: firstItem ? "none" : `rotate(-${i * 2.5}deg)`,
                  }}
                >
                  <Typography variant="subtitle2">
                    {cards.length} Sipariş Seçildi
                  </Typography>
                </Box>
              );
            })}
          </>
        );
      default:
        return null;
    }
  };
  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        inset: 0,
        zIndex: 100,
        position: "fixed",
        pointerEvents: "none",
      }}
    >
      <Box style={getItemStyles(currentOffset)}>
        {renderItem(itemType, item)}
      </Box>
    </Box>
  );
}
