import React from "react";
import PropTypes from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import {
  Divider,
  Fade,
  Link,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { TextFirstLetter } from "utils";
import moment from "moment";
import { statusFilter } from "helper";
import { Visibility } from "@mui/icons-material";

MapMarker.propTypes = {
  isActive: PropTypes.bool,
  order: PropTypes.object,
};

export default function MapMarker(props) {
  const { isActive, order, isDashboard } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const lat = isDashboard ? order.address.latitude : order.latitude;
  const lng = isDashboard ? order.address.longitude : order.longitude;

  return (
    <OverlayView
      zIndex={open ? 12 : isActive ? 2 : 1}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      bounds={{
        ne: { lat: lat, lng: lng },
        sw: { lat: lat, lng: lng },
      }}
    >
      <Stack
        onMouseEnter={handleClick()}
        onMouseLeave={() => setOpen(false)}
        sx={{
          width: 30,
          height: 30,
          textAlign: "center",
          borderRadius: "50%",
          cursor: "pointer",
          position: "relative",
          zIndex: open ? 100 : isActive ? 2 : 99,
          justifyContent: "center",
          border: "2px solid white",
          bgcolor: order.routeColor,
          animation: isActive ? "effect 1s ease forwards" : "none",
          "@keyframes effect": {
            from: { transform: "scale(1.5)" },
            to: { transform: "scale(1)" },
          },
        }}
      >
        <Typography
          color="white"
          variant="inherit"
          sx={{ fontSize: 12, fontWeight: "bold" }}
        >
          {order.plan_day_sequence}
        </Typography>
        <Popper
          open={open}
          transition
          placement="top"
          anchorEl={anchorEl}
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ px: 2, py: 1, minWidth: 250 }}>
                <Typography variant="subtitle2" flex={1} textAlign="left">
                  {TextFirstLetter(order.order_code)}
                </Typography>
                <Divider />
                <Stack spacing={1} pt={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Plan Sırası:
                    </Typography>
                    <Typography variant="caption">
                      {order.plan_day_sequence}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Sipariş Kodu:
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      component={Link}
                      href={`/distribution/detail/sub-detail?siparisKodu=${order.order_code}`}
                      target="_blank"
                      sx={{ color: "secondary.main" }}
                    >
                      <Visibility fontSize="small" />
                      <Typography variant="caption">
                        {order.order_code}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Teslim Durumu:
                    </Typography>
                    <Typography variant="caption">
                      {statusFilter(order)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      İlçe:
                    </Typography>
                    <Typography variant="caption">
                      {isDashboard ? order.address.county : order.county}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Desi:
                    </Typography>
                    <Typography variant="caption">{order.deci}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Teslim Tarihi:
                    </Typography>
                    <Typography variant="caption">
                      {order.delivery_date
                        ? moment(order.delivery_date)
                            .add(3, "hours")
                            .format("DD.MM.YYYY HH:mm")
                        : "-"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Sipariş Tarihi:
                    </Typography>
                    <Typography variant="caption">
                      {moment(order.order_date).format("DD.MM.YYYY HH:mm")}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Stack>
    </OverlayView>
  );
}
