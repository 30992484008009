import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./item";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Distribution = ({
  loading,
  tableData,
  handleDetailItemEdit,
  handleDetailItemDelete,
}) => {
  const [filterSearch, filterSearchOnChange] = React.useState("");
  const [deleteVisible, setDeleteVisible] = React.useState(null);

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "created_at", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "company_name", title: "Şirket Adı", onSort },
    { key: "company_type", title: "Şirket Tipi", onSort },
    { key: "tax_number", title: "Vergi Numarası", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((item) => item.key)],
    //
    uniqBy: "id",
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData = tableDataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <Item
              key={item.id}
              item={item}
              handleDetailEdit={() => handleDetailItemEdit(item.detailData)}
              handleDelete={() => setDeleteVisible(item)}
            />
          )),
        }}
      />
      {deleteVisible && (
        <Dialog
          open
          onClose={() => setDeleteVisible(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            {deleteVisible.company_name}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Bu projeyi silmek istediğinize emin misiniz?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setDeleteVisible(null)}
            >
              Vazgeç
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setDeleteVisible(null);
                handleDetailItemDelete(deleteVisible.id);
              }}
            >
              Sil
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Distribution;
