import React, { useState, useEffect, useMemo, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import AutoComplete from "../GeocodeDialog/AutoComplete";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import TYPES from "../../store/types";
import { useSelector, useDispatch } from "react-redux";
//import { geocodeEditSave } from "../../store/actions/orderAction";
import { Table } from "semantic-ui-react";
import "./AddPopupStyle.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  addCustomerWarehouse,
  updateCustomerWarehouse,
} from "../../store/actions/customerWarehouseAction";
import { addBranch, branchDrivers } from "../../store/actions/branchAction";
import AddBranchUser from "../../screens/DialogPopup/AddBranchUser";
import { useSnackbar } from "notistack";
import { Services } from "api/Services";

const server = new Services();

const AddPopup = (props) => {
  const {
    toastCallback,
    dialogAddPopup,
    setDialogAddPopup,
    product,
    //setProduct,
    onInputChange,
    addPopupWhichComponent,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const updateBranch = (data, cb) => {
    //console.log("updateBranch : ", data)

    let obj = {
      address: {
        address: data.product.addressBranch
          ? data.product.addressBranch
          : data.product.address.address,
        address_code_ext: "",
        address_line: "",
        city: data.product.city ? data.product.city : data.product.address.city,
        code: data.product.code ? data.product.code : data.product.address.code,
        country: "",
        county: data.product.county
          ? data.product.county
          : data.product.address.county,
        district: "",
        email: "",
        first_name: "",
        gsm_number: "",
        last_name: "",
        latitude:
          data.address.newCoodinate.latitude || data.product.address.latitude,
        longitude:
          data.address.newCoodinate.longitude || data.product.address.longitude,
        post_code: "",
        state: "",
        street: "",
      },
      //"firm_id": typeof data.product.firm_id == "object" ? data.product.firm_id.id : data.product.firm_id,
      type: data.product.type ? data.product.type : data.product.address.type,
      groupId: data.product.groupId,
      users: data.selectedUser,
      name: data.product.name,
    };

    return async (dispatch) => {
      server
        .updateBranch(obj, data.product.id)
        .then((data) => {
          dispatch({
            type: TYPES.UPDATE_BRANCH,
            payload: data.entity,
          });
          cb(false);
        })
        .catch((error) => {
          enqueueSnackbar(
            "Bir hata oluştu! Tüm alanları doldurduğunuzdan emin olunuz.",
            {
              variant: "error",
              autoHideDuration: 2500,
            }
          );
        });
    };
  };

  // useEffect(() => {
  //     console.log("yakup-kaytaran : ", product)
  // }, [product])

  //AddbranchUser.js -> DialogPopup
  const [dialogAddBranchUser, setDialogAddBranchUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]); // selected drivers

  const dialogAddBranchUserOpen = () => {
    setDialogAddBranchUser(true);
    dispatch(branchDrivers());
  };

  useEffect(() => {
    dialogAddPopup == false && setSelectedUser([]); // branch user drivers state
  }, [dialogAddPopup]);

  useEffect(() => {
    if (Object.keys(product).length > 0 && product.users) {
      let prodDriversUsers = product.users.map((d) => d.id);
      prodDriversUsers.length > 0 && setSelectedUser(prodDriversUsers);
    }
  }, [product.users]);

  //AddbranchUser.js -> DialogPopup ============

  const firmID = useSelector((state) => state.customerWarehouseReducer);

  // firm list
  const firmList = useSelector((state) => state.customerReducer);

  // branch list
  //const getBranchList = useSelector((state) => state.branchReducer);

  //!!!!!!!
  //delete product.projects;

  const [selectedSuggestionAdress, setSelectedSuggestionAdress] =
    useState(null);
  const [geocodeCoordinate, setGeocodeCoordinate] = useState({});

  const dispatch = useDispatch();
  const markerRef = useRef(null);

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;

        if (marker != null) {
          var draggedPoint = {
            latitude: marker.getLatLng().lat,
            longitude: marker.getLatLng().lng,
          };
          setGeocodeCoordinate(draggedPoint);
        }
      },
    }),
    []
  );

  const hideDialog = () => {
    setDialogAddPopup(false);
    setGeocodeCoordinate({});
    dispatch({
      type: TYPES.DELETE_LIST_SUGGESTION,
      payload: [],
    });
  };

  const addDialogSave = () => {
    let address = {
      newAddress: selectedSuggestionAdress,
      newCoodinate: geocodeCoordinate,
    };

    // if(!product.address || !product.code || !product.name || !address.newCoodinate.latitude || !address.newCoodinate.longitude) {
    //     toastCallback("warn", "Eksik alanlar var!")
    //     return;
    // }

    if (product.id) {
      if (addPopupWhichComponent == "Warehouse") {
        dispatch(
          updateCustomerWarehouse(
            { address, product, firmID },
            setDialogAddPopup
          )
        );
      } else {
        // let productObject = getBranchList.data.filter(d => d.id == product.id)[0];
        // if(Object.keys(address.newCoodinate).length === 0 && typeof product.address != "object") productObject.address.address = product.address;

        // dispatch(
        //     updateBranch({ address, product }, setDialogAddPopup)
        //     //updateBranch({ address, productObject }, setDialogAddPopup)
        // )
        dispatch(
          updateBranch({ address, product, selectedUser }, setDialogAddPopup)
        );
      }
    } else {
      //check address
      if (addPopupWhichComponent == "Warehouse") {
        if (!product.address) {
          toastCallback("warn", "Eksik alanlar var");
          return;
        }
      } else {
        //Branch
        if (!product.addressBranch) {
          toastCallback("warn", "Eksik alanlar var");
          return;
        }
      }

      if (
        Object.values(address.newCoodinate).includes(0) ||
        !Object.values(address.newCoodinate).length ||
        !product.city ||
        !product.code ||
        !product.county ||
        !product.name
      ) {
        toastCallback("warn", "Eksik alanlar var");
        return;
      }

      addPopupWhichComponent == "Warehouse"
        ? dispatch(
            addCustomerWarehouse(
              { address, product, firmID },
              setDialogAddPopup
            )
          )
        : dispatch(
            addBranch({ address, product, selectedUser }, setDialogAddPopup)
          );
      //console.log({ address, product, selectedUser })
    }
    setGeocodeCoordinate({});
    //setSelectedUser([]);  branch user drivers state
  };

  const addDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="Kaydet"
          className="p-button-warning"
          onClick={addDialogSave}
        />
      </React.Fragment>
    );
  };

  // x coordinate (input)
  // y coordinate (input)
  // updates input values
  const onInputChangeCoordinate = (e, name) => {
    // console.log("e2: ", e);
    // console.log("e3: ", name);
    let val = (e.target && e.target.value) || "";
    //val.replace(/[^\d.-]/g, ''); // String remove characters
    setGeocodeCoordinate({
      latitude:
        name == "latitude"
          ? val.replace(/[^\d.-]/g, "")
          : geocodeCoordinate.latitude == undefined
          ? 0
          : geocodeCoordinate.latitude,
      longitude:
        name == "longitude"
          ? val.replace(/[^\d.-]/g, "")
          : geocodeCoordinate.longitude == undefined
          ? 0
          : geocodeCoordinate.longitude,
    });
  };

  // dropdowm options
  const branchTypeOptions = [
    { label: "Standart kargo", value: "STANDART_SHIPPING" },
    { label: "Aynı gün kargo", value: "SAME_DAY_SHIPPING" },
  ];

  // hiyerarşi için

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    server.getGroupHyrarchy().then((res) => {
      setGroups(res);
    });
  }, []);

  function flattenData(data) {
    let flattenedData = [];

    function flatten(groups) {
      for (let group of groups) {
        flattenedData.push({
          value: group.id,
          label: group.name,
        });

        if (group.sub_groups.length > 0) {
          flatten(group.sub_groups);
        }
      }
    }

    flatten(data);
    return flattenedData;
  }

  return (
    <>
      <Dialog
        visible={dialogAddPopup}
        style={{ width: "1000px" }}
        header={
          addPopupWhichComponent == "Warehouse" ? "Depo Ekle" : "Şube Ekle"
        }
        modal
        footer={addDialogFooter}
        onHide={hideDialog}
        // blockScroll={true}
        // resizable={false}
      >
        <div className="confirmation-content" style={{ display: "flex" }}>
          {/* <p>{selectedProduct1 && selectedProduct1.name}</p> */}
          <div style={{ width: "30%" }}>
            {/* <div className="sizes">
                        <b>Müşteri Adı : </b> <span>{selectedProduct1 && selectedProduct1.address.first_name} </span> <br />
                        <b>Müşteri Adresi : {selectedProduct1 && selectedProduct1.address.address || selectedDialogEdit && selectedDialogEdit.address.address}</b> <br /> */}

            {/* < AutoComplete setGeocodeCoordinate={setGeocodeCoordinate} onChange={(selectedAdress) => setSelectedSuggestionAdress(selectedAdress)} /> */}
            {/* <InputText type="text" className="p-d-block p-mb-2 w100" placeholder="X" />
                    <InputText type="text" className="p-d-block p-mb-2 w100" placeholder="Y" /> */}

            {/* </div> */}

            <Table basic style={{ width: "95%" }}>
              <Table.Body>
                {/* Müşteri** */}
                {/* {addPopupWhichComponent == "Branch" && <Table.Row>
                                <Table.Cell style={{ "padding": "6px" }}>
                                    <Dropdown
                                        style={{ "width": "100%" }}
                                        value={typeof product.firm_id == "object" ? product.firm_id.id : product.firm_id}
                                        //value={product.firm_id.id}
                                        options={firmList.data}
                                        optionValue="id"
                                        optionLabel="name"
                                        onChange={(e) => onInputChange(e, "firm_id")}
                                        placeholder="Müşteri"
                                    />
                                </Table.Cell>
                            </Table.Row>} */}
                {/* Müşteri ============== */}

                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > {addPopupWhichComponent == "Warehouse" ? "Depo Adı" : "Şube Adı"} </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                  {/* <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name}</Table.Cell> */}
                  <Table.Cell style={{ padding: "6px" }}>
                    <InputText
                      className="addPopupInput"
                      placeholder={
                        addPopupWhichComponent == "Warehouse"
                          ? "Depo Adı"
                          : "Şube Adı"
                      }
                      value={product.name}
                      onChange={(e) => onInputChange(e, "name")}
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >  {addPopupWhichComponent == "Warehouse" ? "Depo Kodu" : "Şube Kodu"}  </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                  {/* <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name}</Table.Cell> */}
                  <Table.Cell style={{ padding: "6px" }}>
                    {/* <InputText className="addPopupInput" placeholder={addPopupWhichComponent == "Warehouse" ? "Depo Kodu" : "Şube Kodu"} value={product.code || product.address && product.address.code} onChange={(e) => onInputChange(e, "code")} /> */}
                    <InputText
                      className="addPopupInput"
                      placeholder={
                        addPopupWhichComponent == "Warehouse"
                          ? "Depo Kodu"
                          : "Şube Kodu"
                      }
                      value={
                        product.hasOwnProperty("code") == true
                          ? product.code
                          : product.address && product.address.code
                      }
                      onChange={(e) => onInputChange(e, "code")}
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >Adres </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                  {/* <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name}</Table.Cell> */}
                  <Table.Cell style={{ padding: "6px" }}>
                    {/* <InputText className="addPopupInput" placeholder="Adres" value={addPopupWhichComponent == "Warehouse" ? product.address : product.addressBranch ? product.addressBranch : product.address && product.address.address} onChange={(e) => addPopupWhichComponent == "Warehouse" ? onInputChange(e, "address") : onInputChange(e, "addressBranch")} />  *onChange={(e) => onInputChange(e, "address")} */}
                    <InputText
                      className="addPopupInput"
                      placeholder="Adres"
                      value={
                        addPopupWhichComponent == "Warehouse"
                          ? product.address
                          : product.hasOwnProperty("addressBranch") == true
                          ? product.addressBranch
                          : product.address && product.address.address
                      }
                      onChange={(e) =>
                        addPopupWhichComponent == "Warehouse"
                          ? onInputChange(e, "address")
                          : onInputChange(e, "addressBranch")
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > İl </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                  {/* <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name}</Table.Cell> */}
                  <Table.Cell style={{ padding: "6px" }}>
                    <InputText
                      className="addPopupInput"
                      placeholder="İl"
                      value={
                        product.hasOwnProperty("city") == true
                          ? product.city
                          : product.address && product.address.city
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (/^[a-zA-ZğüşöçİĞÜŞÖÇ ]*$/.test(value)) {
                          console.log("value", value);
                          onInputChange(e, "city");
                        } else {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > İlçe </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                  {/* <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name}</Table.Cell> */}
                  <Table.Cell style={{ padding: "6px" }}>
                    <InputText
                      className="addPopupInput"
                      placeholder="İlçe"
                      datatype="text"
                      value={
                        product.hasOwnProperty("county") == true
                          ? product.county
                          : product.address && product.address.county
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (/^[a-zA-ZğüşöçİĞÜŞÖÇ ]*$/.test(value)) {
                          console.log("value", value);
                          onInputChange(e, "county");
                        } else {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > X Koordinatı </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  <Table.Cell style={{ padding: "6px" }}>
                    {/* <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.longitude || selectedDialogEdit && selectedDialogEdit.address.longitude} onChange={(e) => onInputChange(e, 'longitude')} /> */}
                    {/* <InputText className="addPopupInput" placeholder="X Koordinatı" value={geocodeCoordinate && geocodeCoordinate.longitude || product && product.longitude || product.address && product.address.longitude} onChange={(e) => onInputChangeCoordinate(e, 'longitude')} /> */}
                    <InputText
                      className="addPopupInput"
                      placeholder="Y Koordinatı"
                      value={
                        (geocodeCoordinate && geocodeCoordinate.latitude) ||
                        (product && product.latitude) ||
                        (product.address && product.address.latitude)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        const str = value.toString();
                        const regex = /^-?\d*(\.\d{0,6})?$/;
                        if (regex.test(str)) {
                          onInputChangeCoordinate(e, "latitude");
                        } else {
                          return;
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                                <Table.Cell className="addPopupDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > Y Koordinatı </b>  </Table.Cell>
                            </Table.Row> */}
                <Table.Row>
                  <Table.Cell style={{ padding: "6px" }}>
                    {/* <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.latitude || selectedDialogEdit && selectedDialogEdit.address.latitude} onChange={(e) => onInputChange(e, 'latitude')} /> */}
                    {/* <InputText className="addPopupInput" placeholder="Y Koordinatı" value={geocodeCoordinate && geocodeCoordinate.latitude || product && product.latitude || product.address && product.address.latitude} onChange={(e) => onInputChangeCoordinate(e, 'latitude')} /> */}
                    <InputText
                      className="addPopupInput"
                      placeholder="X Koordinatı"
                      value={
                        (geocodeCoordinate && geocodeCoordinate.longitude) ||
                        (product && product.longitude) ||
                        (product.address && product.address.longitude)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        const str = value.toString();
                        const regex = /^-?\d*(\.\d{0,6})?$/;
                        if (regex.test(str)) {
                          onInputChangeCoordinate(e, "longitude");
                        } else {
                          return;
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Dropdown
              options={flattenData(groups)}
              value={product && product.groupId}
              placeholder="Grup Seçiniz"
              onChange={(e) => onInputChange(e, "groupId")}
              style={{ width: "92%", marginTop: "10px" }}
            />
            {addPopupWhichComponent == "Branch" && (
              <>
                <Dropdown
                  options={branchTypeOptions}
                  value={product && product.type}
                  placeholder="Şube tipi"
                  onChange={(e) => onInputChange(e, "type")}
                  style={{ width: "92%", marginTop: "10px" }}
                />
                <Table basic style={{ width: "95%" }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ padding: "6px", cursor: "pointer" }}>
                        {/* <i class="truck icon"></i> */}
                        <p
                          onClick={() =>
                            dialogAddBranchUserOpen(
                              setDialogAddBranchUser(true)
                            )
                          }
                        >
                          Şube Sorumlusu
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </>
            )}
          </div>
          <div style={{ width: "70%" }}>
            <AutoComplete
              setGeocodeCoordinate={setGeocodeCoordinate}
              onChange={(selectedAdress) =>
                setSelectedSuggestionAdress(selectedAdress)
              }
            />

            <MapContainer
              style={{ width: "100%", height: "365px", marginTop: "10px" }}
              center={[41.015137, 28.97953]}
              scrollWheelZoom={false}
            >
              {/* <ChangeView center={geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0 ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude] : selectedDialogEdit && [selectedDialogEdit.address.latitude, selectedDialogEdit.address.longitude] || [0, 0]} zoom={18} /> */}
              <ChangeView
                center={
                  geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0
                    ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude]
                    : (product &&
                        Object.keys(product).length > 0 &&
                        product.latitude &&
                        product.longitude && [
                          product.latitude,
                          product.longitude,
                        ]) ||
                      (product.address &&
                        typeof product.address == "object" && [
                          product.address.latitude,
                          product.address.longitude,
                        ]) || [0, 0]
                }
                zoom={18}
              />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {/* <Marker ref={markerRef} eventHandlers={eventHandlers} position={geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0 ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude] : selectedDialogEdit && [selectedDialogEdit.address.latitude, selectedDialogEdit.address.longitude] || [0, 0]} draggable={true}> */}
              <Marker
                ref={markerRef}
                eventHandlers={eventHandlers}
                position={
                  geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0
                    ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude]
                    : (product &&
                        Object.keys(product).length > 0 &&
                        product.latitude &&
                        product.longitude && [
                          product.latitude,
                          product.longitude,
                        ]) ||
                      (product.address &&
                        typeof product.address == "object" && [
                          product.address.latitude,
                          product.address.longitude,
                        ]) || [0, 0]
                }
                draggable={true}
              >
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </Dialog>

      {/* addPopupWhichComponent = Branch comp. */}
      {/* Add Branch User Dialog Popup () */}
      <AddBranchUser
        dialogAddBranchUser={dialogAddBranchUser}
        setDialogAddBranchUser={setDialogAddBranchUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        product={product}
      />
    </>
  );
};

export default AddPopup;
