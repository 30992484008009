import React from "react";
import { Search } from "@mui/icons-material";
import {
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  Box,
  Divider,
  styled,
  Badge,
  Avatar,
  Alert,
  CircularProgress,
  Autocomplete,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
//
import { BASE_URL } from "api/Env";
import { Services } from "api/Services";
import { removeTurkishChars } from "utils";
import { BranchProps, DriverProps } from "../@types";
import { VehicleTypeIcon } from "utils/vehicleTypesIcon";

const service = new Services();

const StyledBadge = styled(Badge)(({ theme }: any) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      top: 0,
      left: 0,
      content: '""',
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      position: "absolute",
      border: "1px solid currentColor",
    },
  },
}));

type CouriersProps = {
  data: DriverProps[];
  setData: React.Dispatch<React.SetStateAction<DriverProps[]>>;
};

const Couriers = ({ data, setData }: CouriersProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Kurye</TableCell>
          <TableCell>Proje</TableCell>
          <TableCell>Araç</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => {
          const selectVehicle = item.vehicles.find((v) => v.select) || null;

          return (
            <TableRow key={item.id}>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={1} flex={2}>
                  <Checkbox
                    color="secondary"
                    checked={item.select}
                    onChange={(e) => {
                      setData((s: DriverProps[]) => {
                        const newData = [...s];

                        const getVehicleIndex = newData.findIndex(
                          (i) => i.id === item.id
                        );

                        if (getVehicleIndex > -1) {
                          newData[getVehicleIndex].select = e.target.checked;
                        }

                        return newData;
                      });
                    }}
                  />
                  <StyledBadge
                    variant="dot"
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Avatar
                      alt={item.full_name}
                      src={`${BASE_URL}api/public/document/${item.photo?.id}`}
                    />
                  </StyledBadge>
                  <Stack>
                    <Typography variant="subtitle2">
                      {item.full_name}
                    </Typography>
                    <Typography variant="caption">{item.phone}</Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{item.company.name}</Typography>
              </TableCell>
              <TableCell>
                <Stack
                  flex={1}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {selectVehicle && (
                    <Box
                      component="img"
                      // @ts-ignore
                      src={VehicleTypeIcon(selectVehicle.type).icon}
                      sx={{ width: 30, height: 30, objectFit: "contain" }}
                    />
                  )}
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={selectVehicle}
                    options={item.vehicles}
                    disabled={item.vehicles.length === 1}
                    getOptionLabel={(option) => option.name}
                    onChange={(a, vehicle) => {
                      if (vehicle) {
                        setData((s: DriverProps[]) => {
                          const newData = [...s];

                          newData[index].vehicles = newData[index].vehicles.map(
                            (v) => {
                              return { ...v, select: v.id === vehicle.id };
                            }
                          );

                          return newData;
                        });
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Araç"
                        placeholder="Araç seç"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export interface Props {
  branch: BranchProps | null;
  setSelectDrivers: React.Dispatch<React.SetStateAction<DriverProps[]>>;
}

export default function CourierContent(props: Props) {
  const { branch, setSelectDrivers } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DriverProps[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [searchData, setSearchData] = React.useState<DriverProps[]>([]);

  const onChangeBranch = async (branch: BranchProps) => {
    setLoading(true);
    try {
      const res = await service.getPlanningDrivers([branch.id]);
      setData(
        res
          .filter((r: DriverProps) => r.status === "ACTIVE")
          .map((i: any) => {
            return {
              ...i,
              select: false,
              vehicles: i.vehicles.map((v: any, index: number) => ({
                ...v,
                select: index === 0,
              })),
            };
          })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangeSearch = (val: string) => {
    setSearch(val);

    const results = data.filter((item) =>
      removeTurkishChars(item.full_name)
        .toLowerCase()
        .includes(removeTurkishChars(val).toLowerCase())
    );

    if (val === "") {
      setSearchData([]);
    } else {
      setSearchData(results);
    }

    return results;
  };

  React.useEffect(() => {
    if (branch) {
      onChangeBranch(branch);
    }
  }, [branch]);

  React.useEffect(() => {
    const newSelects = data.filter((d) => d.select);

    setSelectDrivers(newSelects);
  }, [data]);

  if (!branch) {
    return (
      <Alert severity="error">
        Araçları görüntülemek için lütfen şube seçiniz
      </Alert>
    );
  }

  if (loading) {
    return (
      <CircularProgress size={30} color="info" sx={{ alignSelf: "center" }} />
    );
  }

  const selectsCourier = data.filter((c) => c.select);

  return (
    <Stack
      component="fieldset"
      sx={{
        p: 2,
        height: "50vh",
        borderRadius: 1,
        overflow: "auto",
        border: "1px solid",
        borderColor: "grey.300",
      }}
    >
      <Typography component="legend" variant="caption">
        Kuryeler
      </Typography>
      <Stack spacing={2}>
        <OutlinedInput
          fullWidth
          value={search}
          onChange={(e) => onChangeSearch(e.target.value)}
          placeholder="Kurye Ara"
          sx={{
            bgcolor: "grey.200",
            fieldset: { borderColor: "transparent!important" },
          }}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {selectsCourier.map((item) => {
            return (
              <Chip
                key={item.id}
                variant="soft"
                color="primary"
                label={item.full_name}
                onDelete={() => {
                  setData((s: DriverProps[]) => {
                    const newData = [...s];

                    const getVehicleIndex = newData.findIndex(
                      (i) => i.id === item.id
                    );

                    if (getVehicleIndex > -1) {
                      newData[getVehicleIndex].select = false;
                    }

                    return newData;
                  });
                }}
              />
            );
          })}
        </Stack>
        <Couriers
          setData={setData}
          data={searchData.length > 0 ? searchData : data}
        />
      </Stack>
    </Stack>
  );
}
