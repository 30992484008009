import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/Tabs/Label";
import { convertTimezone } from "utils/convertTimezone";
import moment from "moment";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import qs from "query-string";

ListRow.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  goDetail: PropTypes.func,
  onSelectRow: PropTypes.func,
  handleBarcode: PropTypes.func,
  setUpdateOrder: PropTypes.func,
  setDeleteItem: PropTypes.func,
};

export default function ListRow(props) {
  const {
    item,
    selected,
    goDetail,
    onSelectRow,
    handleBarcode,
    setUpdateOrder,
    setDeleteItem,
  } = props;
  const [openMenu, setOpenMenuActions] = React.useState(null);
  const queryParam = qs.parse(window.location.search);
  const { routeKey } = queryParam;

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const CustomSlot = (slot) => {
    if (!slot.start || !slot.end) return "-";
    return (
      <Typography variant="caption">
        <span>{moment(slot.start).format("DD/MM/YYYY")}</span> <br />
        {moment(slot.start).add(3, "hours").format("HH:mm")} -
        {moment(slot.end).add(3, "hours").format("HH:mm")}
      </Typography>
    );
  };
  return (
    <TableRow
      hover
      sx={{ "& td": { border: 0, fontSize: 12 } }}
      selected={selected}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={() => onSelectRow(item.id)} />
      </TableCell>
      <TableCell
        sx={{ cursor: "pointer", color: "blue" }}
        onClick={() => goDetail(item.siparisNo)}
      >
        {item.musteriNo}
      </TableCell>
      <TableCell
        sx={{ cursor: "pointer" }}
        onClick={() => goDetail(item.siparisNo)}
      >
        {item.siparisNo}
      </TableCell>
      <TableCell
        sx={{ cursor: "pointer" }}
        onClick={() => goDetail(item.siparisNo)}
      >
        {item.packageCode}
      </TableCell>
      <TableCell fontSize={10}>{item.gonderenFirma}</TableCell>
      <TableCell fontSize={10}>{item.depo}</TableCell>
      <TableCell fontSize={10}>{item.aliciAdi}</TableCell>
      <TableCell fontSize={10}>{item.aliciAdresi}</TableCell>
      <TableCell>{item.il}</TableCell>
      <TableCell>{item.ilce}</TableCell>
      <TableCell>{item.skor}</TableCell>
      <TableCell>
        <Label
          sx={{ fontSize: 10 }}
          color={item.siparisDurumu === "DELIVERED" ? "success" : "warning"}
        >
          {item.siparisDurumu}
        </Label>
      </TableCell>
      {routeKey === "DELIVERED" && (
        <TableCell>
          <Typography variant="caption">{item.deliveryTo}</Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography variant="caption">{item.aciklama}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.enlem}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.boylam}</Typography>
      </TableCell>
      <TableCell>
        {/* {moment(item.siparisinBizeGelmeSaati).format("DD/MM/YYYY")} */}
        <Typography variant="caption">
          {moment(item.siparisinBizeGelmeSaati).format("DD/MM/YYYY")} -
          {convertTimezone(item.siparisinBizeGelmeSaati).substring(10, 16)}
        </Typography>
      </TableCell>
      <TableCell>
        {/* {moment(item.siparisinBizeGelmeSaati).format("DD/MM/YYYY")} */}
        <Typography variant="caption">
          {item.debitDate ? moment(item.debitDate).format("DD/MM/YYYY") : ""} -
          {item.debitDate
            ? convertTimezone(item.debitDate).substring(10, 16)
            : ""}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography fontSize={10}>
          {item.teslimTarihi
            ? moment(item.teslimTarihi).format("DD/MM/YYYY")
            : ""}
          -
          {item.teslimTarihi
            ? convertTimezone(item.teslimTarihi).substring(10, 16)
            : ""}
        </Typography>
      </TableCell>
      <TableCell>
        {/* {moment(item.siparisinBizeGelmeSaati).format("DD/MM/YYYY")} */}
        <Typography variant="caption">
          {item.deliveryTime || item.deliveryTime === 0
            ? item.deliveryTime + " " + "gün"
            : "-"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography fontSize={10}>{CustomSlot(item.slot)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.cepTelefon}</Typography>
      </TableCell>
      <TableCell variant="caption">{item.deci}</TableCell>
      <TableCell variant="caption">{item.paketSayisi}</TableCell>
      <TableCell>
        <Typography variant="caption">{item.dagitimSubesi}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.barkod}</Typography>
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                sx={{
                  color:
                    item.durum === "Basıldı"
                      ? "success.darker"
                      : "warning.darker",
                }}
                disabled={item.durum === "Basıldı"}
                onClick={() => handleBarcode(item.id)}
              >
                <Iconify
                  icon={
                    item.durum === "Basıldı"
                      ? "akar-icons:circle-check-fill"
                      : "ep:circle-close-filled"
                  }
                />
                {item.durum}
              </MenuItem>

              <MenuItem
                sx={{ color: "secondary.main" }}
                onClick={() => setUpdateOrder(item)}
              >
                <Iconify icon={"clarity:map-marker-solid"} />
                Adres
              </MenuItem>

              <MenuItem
                sx={{ color: "error.main" }}
                onClick={() => setDeleteItem(item)}
              >
                <Iconify icon={"ant-design:delete-filled"} />
                Sil
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
