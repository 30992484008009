import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tr } from "date-fns/locale";

// @mui
import {
  DatePicker,
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  TextField,
  Divider,
  Button,
  MenuItem,
} from "@mui/material";
// components
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from "../../../../components/hook-form";
import { fData } from "utils/formatNumber";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ChevronLeft } from "@mui/icons-material";
import moment from "moment";
import { Services } from "api/Services";
import { BASE_URL } from "api/Env";
import RHFPhoneInput from "components/hook-form/RHFPhoneInput";
import { underAgeValidate } from "utils";
import Iconify from "components/Iconify";

// ----------------------------------------------------------------------

const getDocumentID = (url = "") => {
  const arr = url.split("/");
  return arr.at(-1);
};

const server = new Services();

const avatarStyle = {
  width: {
    xs: "100%",
    md: 100,
  },
  height: 40,
  border: "1px solid #f2f2f2",
  borderRadius: 1,
  "& *": {
    borderRadius: 0.5,
  },
  "& svg": {
    display: "none!important",
  },
};

DriverCreateEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  activeStep: PropTypes.number,
  changeStep: PropTypes.func,
  handleClose: PropTypes.func,
  steps: PropTypes.array,
  prevButtonDisable: PropTypes.bool,
  dataList: PropTypes.object.isRequired,
  getDrivers: PropTypes.func,
};

export default function DriverCreateEditForm({
  isEdit,
  activeStep,
  changeStep,
  handleClose,
  prevButtonDisable,
  steps,
  currentData,
  dataList,
  getDrivers,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const NewSchema = Yup.object().shape({
    ad: Yup.string().required("İsim alanı zorunlu"),
    soyad: Yup.string().required("Soyisim alanı zorunlu"),
    tc: Yup.string().required("TC alanı zorunlu"),
    tel: Yup.string().required("Telefon alanı zorunlu"),
    malKabul: Yup.boolean(),
    kuryeZimmet: Yup.boolean(),
    subeyeIade: Yup.boolean(),
    subeKabul: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      avatarUrl: currentData?.photo
        ? `${BASE_URL}api/public/document/${currentData?.photo.id}`
        : "",
      ad: currentData?.first_name || "",
      soyad: currentData?.last_name || "",
      tc: currentData?.identity_number || "",
      dogumTarih:
        currentData?.birthday || moment().subtract(18, "years").toDate(),
      tel: currentData?.phone || "",
      email: currentData?.email || "",
      adres: currentData?.address_line || "",
      // Step 2
      firma: currentData?.company?.id || null,
      arac:
        currentData?.vehicles && currentData?.vehicles.length > 0
          ? currentData?.vehicles[0].id || null
          : null,
      ehliyetNo: currentData?.licence_number || "",
      malKabul: currentData?.roles.includes("ORDER_ACCEPT_USER") || false,
      kuryeZimmet: currentData?.roles.includes("BRANCH_USER") || false,
      subeyeIade:
        currentData?.roles.includes("BRANCH_RETURN_ACCEPT_USER") || false,
      subeKabul: currentData?.roles.includes("BRANCH_ACCEPT_USER") || false,
      // Step 3
      ehliyetTarih: currentData?.licence_doc?.doc_expiration || "",
      ehliyetFile: currentData?.licence_doc
        ? `${BASE_URL}api/public/document/${currentData?.licence_doc.id}`
        : "",
      srcTarih: currentData?.src_doc?.doc_expiration || "",
      srcTarihFile: currentData?.src_doc
        ? `${BASE_URL}api/public/document/${currentData?.src_doc.id}`
        : "",
      psiTarih: currentData?.psycho_doc?.doc_expiration || "",
      psiTarihFile: currentData?.psycho_doc
        ? `${BASE_URL}api/public/document/${currentData?.psycho_doc.id}`
        : "",
      sabikaTarihFile: currentData?.criminal_record
        ? `${BASE_URL}api/public/document/${currentData?.criminal_record.id}`
        : "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentData]);

  const onSubmit = async () => {
    let isError = false;
    let text = "";

    if (
      [
        values.avatarUrl,
        values.ad,
        values.soyad,
        values.tc,
        values.dogumTarih,
        values.tel,
        values.email,
        values.adres,
      ].includes("")
    ) {
      text = "Lütfen kişisel bilgileri boş bırakmayınız.";
      isError = true;
    } else if (values.tc.length !== 11) {
      text = "Lütfen TC alanını kontrol ediniz";
      isError = true;
    } else if (values.tc[values.tc.length - 1] % 2 !== 0) {
      text = "TC Kimlik numaranızın son hanesi çift olmalıdır.";
      isError = true;
    } else if (values.tel.length < 10) {
      text = "Lütfen telefon alanını kontrol ediniz";
      isError = true;
    }

    if (
      !values.malKabul &&
      !values.kuryeZimmet &&
      !values.subeyeIade &&
      !values.subeKabul
    ) {
      text = "Lütfen en az bir tane seçim yapınız";
      isError = true;
    }

    if (isError) {
      enqueueSnackbar(text, { variant: "warning" });
      return;
    }

    if (moment(values.ehliyetTarih).get("years") < 1900) {
      enqueueSnackbar("Ehliyet tarihi alanını kontrol ediniz.", {
        variant: "warning",
      });
      return;
    }
    if (moment(values.srcTarih).get("years") < 1900) {
      enqueueSnackbar("Src tarihi alanını kontrol ediniz.", {
        variant: "warning",
      });
      return;
    }
    if (moment(values.psiTarih).get("years") < 1900) {
      enqueueSnackbar("Psikoteknik tarihi alanını kontrol ediniz.", {
        variant: "warning",
      });
      return;
    }

    let res = null;
    let res1 = null;
    let res2 = null;
    let res3 = null;
    let res4 = null;

    try {
      if (isEdit) {
        if (values.avatarUrl && typeof values.avatarUrl !== "string") {
          const x = await server.uploadVehicleDocument(values.avatarUrl);
          res = x.entity.data[0].id;
        } else {
          res = getDocumentID(values.avatarUrl) || "";
        }
        if (values.ehliyetFile && typeof values.ehliyetFile !== "string") {
          const x = await server.uploadVehicleDocument(values.ehliyetFile);
          res1 = x.entity.data[0].id;
        } else {
          res1 = values.ehliyetFile || "";
        }
        if (values.srcTarihFile && typeof values.srcTarihFile !== "string") {
          const x = await server.uploadVehicleDocument(values.srcTarihFile);
          res2 = x.entity.data[0].id;
        } else {
          res2 = values.srcTarihFile || "";
        }
        if (values.psiTarihFile && typeof values.psiTarihFile !== "string") {
          const x = await server.uploadVehicleDocument(values.psiTarihFile);
          res3 = x.entity.data[0].id;
        } else {
          res3 = values.psiTarihFile || "";
        }
        if (
          values.sabikaTarihFile &&
          typeof values.sabikaTarihFile !== "string"
        ) {
          const x = await server.uploadVehicleDocument(values.sabikaTarihFile);
          res4 = x.entity.data[0].id;
        } else {
          res4 = values.sabikaTarihFile || "";
        }
      } else {
        const resX = await server.uploadVehicleDocument(values.avatarUrl);
        res = resX.entity.data[0].id;

        const res1X = await server.uploadVehicleDocument(values.ehliyetFile);
        res1 = res1X.entity.data[0].id;

        const res2X = await server.uploadVehicleDocument(values.srcTarihFile);
        res2 = res2X.entity.data[0].id;

        const res3X = await server.uploadVehicleDocument(values.psiTarihFile);
        res3 = res3X.entity.data[0].id;

        const res4X = await server.uploadVehicleDocument(
          values.sabikaTarihFile
        );
        res4 = res4X.entity.data[0].id;
      }

      const sendData = {
        // Step 1
        photo_url: res,
        first_name: values.ad,
        last_name: values.soyad,
        identity_number: values.tc,
        birthday: moment(values.dogumTarih).format("YYYY-MM-DD"),
        phone: values.tel.replaceAll(" ", "").replace("+90", ""),
        email: values.email,
        address_line: values.adres,
        // Step 2
        company: values.company,
        vehicles: [values.arac].filter(Boolean),
        roles: [],
        // Step 3
        licence_doc: res1,
        licence_expiration_date: moment(values.ehliyetTarih).format(
          "YYYY-MM-DD"
        ),

        src_doc: res2,
        src_expiration_date: moment(values.srcTarih).format("YYYY-MM-DD"),
        // src_date: "2022-10-13T21:26:03.029Z",

        psycho_doc: res3,
        psycho_expiration_date: moment(values.psiTarih).format("YYYY-MM-DD"),
        // psycho_date: "2022-10-13T21:26:03.029Z",

        licence_number: values.ehliyetNo,
        criminal_record: res4,
        status: "ACTIVE",
        user_type: "DRIVER",

        // education_status: "string",
        employement_date: moment().format("YYYY-MM-DD"),
        // previous_experience: "string",
      };

      if (values.malKabul) {
        sendData.roles.push("ORDER_ACCEPT_USER");
      }
      if (values.kuryeZimmet) {
        sendData.roles.push("BRANCH_USER");
      }
      if (values.subeyeIade) {
        sendData.roles.push("BRANCH_RETURN_ACCEPT_USER");
      }
      if (values.subeKabul) {
        sendData.roles.push("BRANCH_ACCEPT_USER");
      }

      if (isEdit) {
        sendData.id = currentData.id;
      }

      await server.addDriver(sendData);
      enqueueSnackbar(
        !isEdit ? "Kurye başarıyla kaydedildi!" : "Kurye başarıyla düzenlendi!",
        {
          variant: "success",
        }
      );
      if (getDrivers) {
        getDrivers();
      }
      handleClose();
    } catch (error) {
      let msg = "Bir sorun oluştu";
      if (error && error.message) {
        msg = error.message;
      }
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, name) => {
      const file = acceptedFiles[0];

      if (!file.type.match(/image\/*/)) {
        enqueueSnackbar("Dosya türü geçerli değil", { variant: "error" });
        return;
      }

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onRemove = (name) => {
    values[name] = null;
    setValue(name, null);
  };

  // const companiesList = dataList.companies;
  // const vehiclesList = dataList.vehicles.map((i) => ({
  //   key: i.id,
  //   value: i.name,
  // }));

  React.useEffect(() => {
    // malKabul
    // kuryeZimmet
    // subeyeIade
    // subeKabul

    if (values.malKabul || values.kuryeZimmet) {
      setValue("subeyeIade", false);
      setValue("subeKabul", false);
    }

    if (values.subeyeIade || values.subeKabul) {
      setValue("malKabul", false);
      setValue("kuryeZimmet", false);
    }
  }, [
    values.malKabul,
    values.kuryeZimmet,
    values.subeyeIade,
    values.subeKabul,
  ]);

  const companiesList = dataList.companies;
  const vehiclesList = dataList.vehicles.map((i) => ({
    key: i.id,
    value: i.name,
  }));

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const uploadProfilePhotoHelpers = [
    "İzin verilen formatlar: *.jpeg, *.jpg, *.png",
    `Maks dosya boyutu: ${fData(3145728)}`,
  ];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} gap={3}>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={2} gap={3}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                boxShadow:
                  "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
              }}
            >
              <RHFUploadAvatar
                name="avatarUrl"
                accept="image/*"
                maxSize={3145728}
                onDrop={(e) => handleDrop(e, "avatarUrl")}
                onRemove={() => onRemove("avatarUrl")}
                helperText={
                  <Stack>
                    {uploadProfilePhotoHelpers.map((text) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        mt={2}
                        spacing={1}
                      >
                        <Iconify
                          icon="ic:info"
                          color="#FFD666"
                          sx={{ fontSize: 20 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            color: "text.secondary",
                          }}
                        >
                          {text}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                boxShadow:
                  "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
              }}
            >
              <Stack p={2}>
                <Box mb={1}>
                  <Typography variant="subtitle2" sx={{ my: 0.5 }}>
                    Yetkilendirme
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Kurye görev tanımlarını oluşturmaktadır. Özelliklere göre
                    aynı yetkiye sahip olmama durumu olabilmektedir.
                  </Typography>
                </Box>
                <RHFSwitch
                  name="malKabul"
                  labelPlacement="end"
                  label={
                    <Typography variant="body2">Mal kabul yapabilir</Typography>
                  }
                />
                <RHFSwitch
                  name="kuryeZimmet"
                  labelPlacement="end"
                  label={
                    <Typography variant="body2">
                      Kurye zimmeti yapabilir
                    </Typography>
                  }
                />
                <RHFSwitch
                  name="subeyeIade"
                  labelPlacement="end"
                  label={
                    <Typography variant="body2">
                      Şubeye iade kabul yapabilir
                    </Typography>
                  }
                />
                <RHFSwitch
                  name="subeKabul"
                  labelPlacement="end"
                  label={
                    <Typography variant="body2">
                      Şube kabul yapabilir
                    </Typography>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={7.7}
          sx={{
            backgroundColor: "#fff",
            padding: 2,
            borderRadius: 2,
            boxShadow:
              "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
          }}
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            sx={{
              gap: 2,
              "& > div": {
                width: { sm: "calc(50% - 16px)" },
                flexGrow: 1,
              },
            }}
          >
            <RHFTextField size="small" name="ad" label="Ad" />
            <RHFTextField size="small" name="soyad" label="Soyad" />
            <RHFTextField
              size="small"
              name="tc"
              label="T.C. Kimlik No"
              inputProps={{ maxLength: 11, type: "number" }}
              type="number"
            />
            <Controller
              name="dogumTarih"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider locale={tr} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    mask="__.__.____"
                    inputFormat="dd.MM.yyyy"
                    label="Doğum Tarihi"
                    minDate={moment("01.01.1900").toDate()}
                    maxDate={moment().subtract(18, "years").toDate()}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                        sx={{
                          "& .MuiInputBase-root": {
                            pointerEvents: "none !important",
                          },
                          "& .MuiButtonBase-root": {
                            pointerEvents: "all !important",
                            cursor: "pointer !important",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            <RHFPhoneInput size="small" name="tel" label="Telefon No" />
            <RHFTextField
              size="small"
              name="email"
              label="E-posta Adresi"
              type="email"
            />
            <Box flex={1}>
              <RHFSelect
                size="small"
                label="Firma"
                name="company"
                SelectProps={{ native: false }}
              >
              {companiesList.map((option, i) => (
                <MenuItem key={`${option.key}-${i}`} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </RHFSelect>
            </Box>
            <Box flex={1}>
              <RHFTextField
                size="small"
                name="adres"
                label="Adres"
                multiline
                minRows={3}
                type="address"
              />
            </Box>
          </Stack>
          <Stack mt={2} spacing={2}>
            <Typography variant="subtitle2" sx={{ my: 0.5 }}>
              Dosya Ekleri
            </Typography>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="ehliyetTarih"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider
                        locale={tr}
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          mask="__.__.____"
                          inputFormat="dd.MM.yyyy"
                          minDate={moment("01.01.1900").toDate()}
                          maxDate={moment("12.31.2050").toDate()}
                          label="Ehliyet Geçerlilik Tarihi"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <RHFUploadAvatar
                    sx={avatarStyle}
                    name="ehliyetFile"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={(e) => handleDrop(e, "ehliyetFile")}
                    onRemove={() => onRemove("ehliyetFile")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="srcTarih"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={tr}
                      >
                        <DatePicker
                          mask="__.__.____"
                          inputFormat="dd.MM.yyyy"
                          label="SRC Geçerlilik Tarihi"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <RHFUploadAvatar
                    sx={avatarStyle}
                    name="srcTarihFile"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={(e) => handleDrop(e, "srcTarihFile")}
                    onRemove={() => onRemove("srcTarihFile")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="psiTarih"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={tr}
                      >
                        <DatePicker
                          mask="__.__.____"
                          inputFormat="dd.MM.yyyy"
                          label="Psikoteknik Geçerlilik Tarihi"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFUploadAvatar
                    sx={avatarStyle}
                    name="psiTarihFile"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={(e) => handleDrop(e, "psiTarihFile")}
                    onRemove={() => onRemove("psiTarihFile")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Box
                    width={1}
                    border={1}
                    borderRadius={1}
                    pl={0.5}
                    borderColor={"grey.300"}
                  >
                    <Typography variant="body1" sx={{ p: 1, opacity: 0.6 }}>
                      Sabıka Kaydı Yükle *
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFUploadAvatar
                    sx={avatarStyle}
                    name="sabikaTarihFile"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={(e) => handleDrop(e, "sabikaTarihFile")}
                    onRemove={() => onRemove("sabikaTarihFile")}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Box width={1} mt={2}>
        <Divider />
        <Stack
          pt={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button color="error" variant="outlined" onClick={handleClose}>
              Geri Dön
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isEdit ? "Düzenle" : "Kurye Oluştur"}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}
