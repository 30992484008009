import { Box, Dialog, Stack, Typography } from "@mui/material";
import { CustomActions } from ".";

const DeleteModal = ({ open, handleClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Grup Silme</Typography>
        <Typography variant="body1">
          Bu işlemi geri alamayacaksınız. Emin misiniz?
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2, justifyContent: "flex-end", alignItems: "center" }}
        >
          <CustomActions
            onClick={() => handleClose()}
            icon={"eva:close-outline"}
            bgColor={"#000"}
          />
          <CustomActions
            icon={"eva:checkmark-outline"}
            bgColor={"#00AB55"}
            onClick={() => onSubmit()}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;
