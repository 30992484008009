import React from "react";
import PropTypes from "prop-types";
import { Stack, Box } from "@mui/material";
import Allorders from "./Allorders";
import AllPackages from "./AllPackages";

Orders.propTypes = {
  orders: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default function Orders(props) {
  const { orders, onRowClick } = props;
  return (
    <>
      <Allorders orders={orders} onRowClick={onRowClick} />
    </>
  );
}
