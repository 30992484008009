import TYPES from "../types";
import { Services } from "../../api/Services";
import { loadingActive } from "helper";

const server = new Services();

export const branchList = (data) => {
  return async (dispatch) => {
    loadingActive(dispatch, true);
    server
      .getBranchList()
      .then((data) => {
        dispatch({
          type: TYPES.GET_LIST_BRANCH,
          payload: data.entity,
        });
        loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const branchAssignmnetList = (data) => {
  return async (dispatch) => {
    server
      .getBranchList()
      .then((data) => {
        dispatch({
          type: TYPES.GET_LIST_BRANCH_ASSIGNMENT,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const warehouseListToBeAssigned = (data) => {
  return async (dispatch) => {
    server
      .warehouseListToBeAssigned()
      .then((data) => {
        dispatch({
          type: TYPES.GET_LIST_BRANCH_ASSIGNMENT,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const branchAssignmnetAppointed = (id) => {
  return async (dispatch) => {
    server
      .branchAssignmnetAppointed(id)
      .then((data) => {
        //console.log("zdata: ", data)
        dispatch({
          type: TYPES.SELECTED_BRANCH_OPTIONS_ID,
          payload: data.entity,
        });
        //cb(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const branchDrivers = () => {
  return async (dispatch) => {
    server
      .branchDrivers()
      .then((data) => {
        //console.log("zdata: ", data)
        dispatch({
          type: TYPES.BRANCH_DRIVERS,
          payload: data.entity,
        });
        //cb(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// export const branchAssignmnetSave = (firmID, data, cb) => {
//     return async (dispatch) => {
//         server.
//             branchSave(firmID, data).then((data) => {
//                 // dispatch({
//                 //     type: TYPES.GET_LIST_BRANCH_ASSIGNMENT,
//                 //     payload: data.entity
//                 // });
//                 //console.log("1a1a1a:", data)
//                 cb(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }
// };

export const assignWarehouseSave = (firmID, data, cb) => {
  return async (dispatch) => {
    server
      .assignWarehouseSave(firmID, data)
      .then((data) => {
        //console.log("tiptiptitp: ", data)
        dispatch({
          type: TYPES.WAREHOUSE_ASSIGMENT,
          payload: data.entity,
        });
        cb(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addBranch = (data, cb) => {
  let obj = {
    address: {
      //"address": data.product.address,
      address: data.product.addressBranch
        ? data.product.addressBranch
        : data.product.address.address,
      address_code_ext: "",
      address_line: "",
      city: data.product.city,
      code: data.product.code,
      country: "",
      county: data.product.county,
      district: "",
      email: "",
      first_name: "",
      gsm_number: "",
      last_name: "",
      latitude: data.address.newCoodinate.latitude,
      longitude: data.address.newCoodinate.longitude,
      post_code: "",
      state: "",
      street: "",
    },
    //"firm_id": data.product.firm_id,
    type: data.product.type,
    groupId: data.product.groupId,
    name: data.product.name,
    users: data.selectedUser,
  };

  return async (dispatch) => {
    server
      .addbranch(obj)
      .then((data) => {
        dispatch({
          type: TYPES.ADD_BRANCH,
          payload: data.entity,
        });
        cb(false);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};

export const deleteBranch = (id, callback, onError) => {
  let wId = id;
  return async (dispatch) => {
    server
      .deleteBranch(wId)
      .then((data) => {
        dispatch({
          type: TYPES.DELETE_BRANCH,
          //payload: data.entity, -> object does not return
          payload: wId,
        });
        callback(data.status);
      })
      .catch((error) => onError(error));
  };
};

export const branchDrawing = (id, data) => {
  return async (dispatch) => {
    server
      .branchDrawing(id, data)
      .then((data) => {
        //console.log("zdata: ", data)
        // dispatch({
        //     type: TYPES.BRANCH_DRIVERS,
        //     payload: data.entity
        // });
        //cb(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const branchDrawingGet = (id) => {
  return async (dispatch) => {
    server
      .branchDrawingGet(id)
      .then((data) => {
        // console.log("zdata: ", data)
        dispatch({
          type: TYPES.GET_BRANCH_DRAWING,
          payload: data.entity,
        });
        //cb(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
