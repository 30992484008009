import React from "react";
import PropTypes from "prop-types";
import MultiStepForm, { FormStep } from "components/MultiStepForm";
import * as Yup from "yup";
import InputField from "components/MultiStepForm/InputField";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SwitchField from "components/MultiStepForm/SwitchField";
import SelectField from "components/MultiStepForm/SelectField";
import { Services } from "api/Services";
import { useSnackbar } from "notistack";
import UploadForm from "components/MultiStepForm/UploadForm";
import { BASE_URL } from "api/Env";
import OnlyLetterInput from "components/MultiStepForm/OnlyLetterInput";
import TelInput from "components/MultiStepForm/TelInput";
import { RHFSelect, RHFTextField } from "components/hook-form";
import CustomSelect from "components/CustomSelect";
import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import {
  DatePicker,
  DateTimePicker,
  LoadingButton,
  LocalizationProvider,
  TimePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  COMPANY_TYPE_LIST,
  COUNTRYID_TURKIYE,
  DRIVER_TYPE_LIST,
  SLOT_DAY_LIST,
} from "helper";
import { tr } from "date-fns/locale";

const SPACING = 2;

const server = new Services();

CustomForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  getList: PropTypes.func,
  handleCloseDetail: PropTypes.func,
  vehicleFilterOptions: PropTypes.array,
};

export default function CustomForm({
  isEdit,
  currentData,
  getList,
  handleCloseDetail,
  vehicleFilterOptions,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [countyList, setCountyList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [vehicleModelList, setVehicleModelList] = React.useState([]);
  const [companyTypeId, setCompanyTypeId] = React.useState(null);
  const [driverTypeId, setDriverTypeId] = React.useState(null);
  const [cityId, setCityId] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [countyId, setCountyId] = React.useState(null);
  const [selectedCounty, setSelectedCounty] = React.useState(null);
  const [selectedSlots, setSelectedSlots] = React.useState([]);
  const [selectedCitiesAndCounties, setSelectedCitiesAndCounties] =
    React.useState([]);
  const [currentStartAsInt, setCurrentStartAsInt] = React.useState(0);
  const [currentStart, setCurrentStart] = React.useState(null);
  const [currentEndAsInt, setCurrentEndAsInt] = React.useState(0);
  const [currentEnd, setCurrentEnd] = React.useState(null);
  const [currentDayNumber, setCurrentDayNumber] = React.useState(null);
  const [currentDay, setCurrentDay] = React.useState(null);

  React.useState(() => {
    if (isEdit) {
      if (currentData && currentData.company_type >= 0) {
        setCompanyTypeId(currentData.company_type);
      }
    }
  }, [currentData]);

  const getUserContacts = async () => {
    try {
      const res = await server.getUserContacts();
      setUsers(res.entity.data);
    } catch (error) {}
  };

  const getCityList = async () => {
    try {
      const city = await server.getCityList(COUNTRYID_TURKIYE);
      setCityList(city);
    } catch (error) {}
  };

  const getCountyList = async (cityId) => {
    const county = await server.getCountyList(cityId);
    setCountyList(county);
  };

  React.useState(() => {
    // getUserContacts();
    getCityList();
    console.log("currentData", currentData);
    if (
      vehicleFilterOptions &&
      vehicleFilterOptions.vehicle_models &&
      vehicleFilterOptions.vehicle_models.length
    ) {
      const vehicleModels = [];
      vehicleFilterOptions.vehicle_models.forEach((element) => {
        const data = {
          value: element.key,
          label: element.value,
        };
        vehicleModels.push(data);
      });

      setVehicleModelList(vehicleModels);
    }
  }, []);

  const getSlotListForAPI = () => {
    const list = [];
    selectedSlots.forEach((item) => {
      console.log("daynumber", item);
      const model = {
        day_number: item.day_number,
        start: item.startAsInt,
        end: item.endAsInt,
      };
      list.push(model);
    });
    return list;
  };

  const initialValues = {
    /* logo: currentData?.customer_logo_id
      ? `${BASE_URL}/api/public/document/${currentData?.customer_logo_id}`
      : "", */
    company_name: currentData?.company_name || "", // currentData?.company_name || "",
    company_type: currentData?.company_type || "",
    tax_id_number: currentData?.tax_number || "",

    first_name: currentData?.first_name || "",
    last_name: currentData?.last_name || "",
    identity_number: currentData?.identity_number || "",
    email: currentData?.email || "",
    password: currentData?.password || "",
    phone: currentData?.phone || "",
    vehicle_name: currentData?.vehicle_name || "",
    vehicle_model_id: currentData?.vehicle_model_id || "",

    driver_type: currentData?.driver_type || "",
    city_oid: currentData?.city?.id || "",
    county_oid: currentData?.county?.id || "",
    vehicle_slots: currentData?.slots || [],
  };

  const onSubmit = async (values) => {
    const sendData = {
      company_name: values.company_name,
      company_type: companyTypeId,
      tax_id_number: values.tax_id_number,
      identity_number: values.identity_number,

      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      phone: values.phone,
      vehicle_name: values.vehicle_name,
      vehicle_model_id: values.vehicle_model_id,

      driver_type: driverTypeId - 1,
      /* city_oid: cityId,
      county_oid: countyId, */
      counties: handleCountyIdListForApi(),
      vehicle_slots: getSlotListForAPI(),
    };

    if (isEdit) {
      sendData.id = currentData?.id;
    }

    setLoading(true);

    // const isNewLogo = typeof values.logo === "object";

    try {
      /* if (isNewLogo) {
        const x = await server.uploadVehicleDocument(values.logo.file);
        const photoId = x.entity.data[0].id;
        sendData.customer_logo_id = photoId;
      } */

      if (isEdit) {
        const updateData = {
          id: currentData?.id,
          company_name: values.company_name,
          company_type: companyTypeId,
          tax_id_number: values.tax_id_number,
        };
        await server.updateDriverCompany(updateData);
      } else {
        await server.addDriverCompany(sendData);
      }
      enqueueSnackbar(`Başarıyla ${isEdit ? "güncellendi" : "kaydedildi"}!`, {
        variant: "success",
      });
      getList();
      handleCloseAndReset();
    } catch (error) {
      enqueueSnackbar(
        "Bir sorun oluştu! Lütfen girilen alanları tekrar kontrol edin",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

  const filterUsers = users.map((i) => ({
    value: i.id,
    label: i.username,
  }));

  const onChangeCompanyType = (v) => {
    setCompanyTypeId(v.target.value);
  };

  const onChangeDriverType = (v) => {
    setDriverTypeId(v.target.value);
  };

  const onChangeCity = (v) => {
    setCountyId([]);
    getCountyList(v.target.value);
    setCityId(v.target.value);
    const city = cityList.find((item) => item.id === v.target.value);
    setSelectedCity({ id: v.target.value, name: city.name });
  };

  const onChangeCounty = (v) => {
    setCountyId(v.target.value);
    const county = countyList.find((item) => item.id === v.target.value);
    setSelectedCounty({ id: v.target.value, name: county.name });
  };

  const handleSlotDayChange = (event, value) => {
    if (value && value.props) {
      setCurrentDayNumber(value.props.value);
      setCurrentDay({ id: value.props.value, name: value.props.children });
      console.log(
        "test value ",
        SLOT_DAY_LIST.find((i) => i.id === value.props.value)
      );
    } else {
      setCurrentDayNumber(null);
      setCurrentDay({ id: "", name: "" });
    }
  };

  const getIntValueFromDate = (date) => {
    const dateValue = new Date(date);
    return dateValue.getHours() * 60 + dateValue.getMinutes();
  };

  const handleSlotStart = (event) => {
    if (event) {
      const date = new Date(event);
      setCurrentStart(date);
      setCurrentStartAsInt(getIntValueFromDate(event));
    }
  };

  const handleSlotEnd = (event) => {
    setCurrentEnd(new Date(event));
    setCurrentEndAsInt(getIntValueFromDate(event));
  };

  const handleClearCountyList = () => {
    setCountyList([]);
  };

  const handleClearSelectedSlotStateValue = () => {
    setCurrentDayNumber(null);
    setCurrentDay({ id: "", name: "" });
    setCurrentEnd(null);
    setCurrentEndAsInt(0);
    setCurrentStart(null);
    setCurrentStartAsInt(0);
    handleClearCountyList();
  };

  const handleClearSelectedCitiesAndCountiesStateValue = () => {
    setSelectedCity(null);
    setSelectedCounty(null);
    setCityId(null);
    setCountyId(null);
    handleClearCountyList();
  };

  const handleCloseAndReset = () => {
    handleCloseDetail();
    handleClearSelectedSlotStateValue();
    handleClearSelectedCitiesAndCountiesStateValue();
    setSelectedCitiesAndCounties([]);
    setSelectedSlots([]);
  };

  const handleCountyIdListForApi = () => {
    const countyIdList = [];
    selectedCitiesAndCounties.forEach((item) => {
      countyIdList.push(item.countyId);
    });
    return countyIdList;
  };

  const handleAddSlotInSlotList = () => {
    if (
      currentDayNumber &&
      currentStart &&
      currentStartAsInt &&
      currentEnd &&
      currentEndAsInt
    ) {
      const model = {
        day_number: currentDayNumber,
        dayName: SLOT_DAY_LIST.find((item) => item.id === currentDayNumber)
          .name,
        start: currentStart,
        startAsInt: currentStartAsInt,
        end: currentEnd,
        endAsInt: currentEndAsInt,
      };
      if (!checkIsDataInTheSelectedSlotslist()) {
        selectedSlots.push(model);
        setSelectedSlots(selectedSlots);
        handleClearSelectedSlotStateValue();
      } else {
        enqueueSnackbar("Eklenen slot bilgileri listede mevcut", {
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Lütfen slot bilgilerini seçiniz", {
        variant: "warning",
      });
    }
  };

  const handleAddCountiesInCityAndCountList = () => {
    if (
      cityId &&
      countyId &&
      selectedCity &&
      selectedCity.name &&
      selectedCity.id &&
      selectedCounty &&
      selectedCounty.name &&
      selectedCounty.id
    ) {
      const model = {
        cityId: selectedCity.id,
        cityName: selectedCity.name,
        countyId: selectedCounty.id,
        countyName: selectedCounty.name,
      };
      if (!checkIsDataInTheSelectedCitiesAnyCountieslist()) {
        selectedCitiesAndCounties.push(model);
        setSelectedCitiesAndCounties(selectedCitiesAndCounties);
        handleClearSelectedCitiesAndCountiesStateValue();
      } else {
        enqueueSnackbar("Eklenen adres bilgileri listede mevcut", {
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Lütfen adres bilgilerini seçiniz", {
        variant: "warning",
      });
    }
  };

  const checkIsDataInTheSelectedSlotslist = () => {
    const index = selectedSlots.findIndex(
      (item) =>
        item.day_number === currentDayNumber &&
        item.startAsInt === currentStartAsInt &&
        item.endAsInt === currentEndAsInt
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  const checkIsDataInTheSelectedCitiesAnyCountieslist = () => {
    const index = selectedCitiesAndCounties.findIndex(
      (item) =>
        item.cityId === selectedCity.id &&
        item.cityName === selectedCity.name &&
        item.countyId === selectedCounty.id &&
        item.countyName === selectedCounty.name
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  const handleHourFormatFromDateString = (date) => {
    return date.toLocaleTimeString().substring(0, 5);
  };

  const handleDeleteSlotFromList = (option, i) => {
    const result = selectedSlots.filter((item, index) => index !== i);

    setSelectedSlots(result);
  };

  const handleDeleteCityAndCountyFromList = (option, i) => {
    const result = selectedCitiesAndCounties.filter(
      (item, index) => index !== i
    );

    setSelectedCitiesAndCounties(result);
  };

  const validationSchemaStepOne = Yup.object({
    company_name: Yup.string().required("Şirket adı alanı zorunlu"),
    // company_type: Yup.string().required("Şirket tipi alanı zorunlu"),
    tax_id_number: Yup.string().required("Vergi numarası alanı zorunlu"),
    // city_oid: Yup.string().required("İl alanı zorunlu"),
    // county_oid: Yup.string().required("İlçe alanı zorunlu"),
  });

  const validationSchemaStepTwo = Yup.object({
    first_name: Yup.string().required("Ad alanı zorunlu"),
    last_name: Yup.string().required("Soyad alanı zorunlu"),
    identity_number: Yup.string()
      .required("T.C. kimlik no alanı zorunlu")
      .length(11, "T.C kimlik no 11 karekter olmalı"),
    email: Yup.string().required("E-Posta alanı zorunlu"),
    // driver_type: Yup.string().required("Şirket tipi alanı zorunlu"),
    phone: Yup.string().test(
      "required",
      "Telefon alanı zorunlu",
      (value) =>
        value &&
        value !== "+90" &&
        value !== "+9" &&
        value !== "+" &&
        value !== ""
    ),
  });
  const validationSchemaStepThree = Yup.object({
    vehicle_name: Yup.string().required("Araç adı alanı zorunlu"),
    vehicle_model_id: Yup.string().required("Araç adı alanı zorunlu"),
  });

  return (
    <MultiStepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      loading={loading}
    >
      <FormStep
        stepName="Şirket Bilgileri"
        onSubmit={(values) => {}}
        validationSchema={validationSchemaStepOne}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                flex: 1,
                display: "grid",
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <InputField
                required
                fullWidth
                size="small"
                name="company_name"
                label="Şirket Adı"
              />
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  size="small"
                  label="Şirket Tipi"
                  name="company_type"
                  style={{ width: "100%" }}
                  SelectProps={{ native: false }}
                  onChange={onChangeCompanyType}
                  value={companyTypeId}
                >
                  {COMPANY_TYPE_LIST.map((option, i) => (
                    <MenuItem key={`${option.value}-${i}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <InputField
                required
                fullWidth
                size="small"
                name="tax_id_number"
                label="Vergi Numarası"
              />
            </Box>
          </Grid>
        </Grid>
      </FormStep>
      {!isEdit && (
        <FormStep
          stepName="Kullanıcı Bilgileri"
          onSubmit={() => {}}
          validationSchema={validationSchemaStepTwo}
        >
          <Box
            sx={{
              display: "grid",
              rowGap: 2,
              columnGap: 2,
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            {/* <SelectField
            required
            fullWidth
            size="small"
            name="contact_user_oid"
            label="Bringo Sorumlusu"
            options={filterUsers}
          /> */}
            <OnlyLetterInput
              required
              fullWidth
              size="small"
              name="first_name"
              label="Ad"
            />
            <OnlyLetterInput
              required
              fullWidth
              size="small"
              name="last_name"
              label="Soyad"
            />

            <InputField
              required
              fullWidth
              size="small"
              name="identity_number"
              label="T.C. Kimlik No"
              inputProps={{ type: "number", maxLength: 11 }}
            />

            <FormControl fullWidth>
              <TextField
                select
                required
                size="small"
                label="Sürücü Tipi"
                name="driver_type"
                style={{ width: "100%" }}
                SelectProps={{ native: false }}
                onChange={onChangeDriverType}
                value={driverTypeId >= 0 && (driverTypeId || "")}
              >
                {DRIVER_TYPE_LIST.map((option, i) => (
                  <MenuItem key={`${option.value}-${i}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <InputField
              required
              fullWidth
              type="email"
              size="small"
              name="email"
              label="E-Posta"
            />
            <InputField
              required={!isEdit}
              size="small"
              label="Parola"
              name="password"
              type="password"
            />
            <TelInput required size="small" name="phone" label="Telefon" />
          </Box>
        </FormStep>
      )}
      {!isEdit && (
        <FormStep
          stepName="Araç Bilgileri"
          onSubmit={() => {}}
          validationSchema={validationSchemaStepThree}
        >
          <Box
            sx={{
              display: "grid",
              rowGap: 2,
              columnGap: 2,
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <OnlyLetterInput
              required
              fullWidth
              size="small"
              name="vehicle_name"
              label="Araç İsmi"
            />

            <SelectField
              required
              fullWidth
              type="number"
              size="small"
              label="Vehicle Model Id"
              name="vehicle_model_id"
              options={vehicleModelList}
            />
            {/*
              <CustomSelect
                label="İl"
                placeholder="Seçiniz"
                data={cityList}
                name="city_oid"
                value={cityId}
                onChange={onChangeInput}
              /> */}
            {/* 
              <CustomSelect
                size="small"
                label="İl"
                name="city_oid"
                value={cityId || ""}
                data={cityList}
                onChange={onChangeCity}
              />
             } */}
          </Box>
          <Grid mt={2}>
            <Divider />
          </Grid>
          <Stack spacing={2} mt={2}>
            <Grid container spacing={SPACING}>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            size="small"
                            label="İl"
                            name="city_oid"
                            style={{ width: "100%" }}
                            SelectProps={{ native: false }}
                            onChange={onChangeCity}
                            value={cityId || ""}
                          >
                            {cityList.map((option, i) => (
                              <MenuItem
                                key={`${option.id}-${i}`}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            size="small"
                            label="İlçe"
                            name="county_oid"
                            style={{ width: "100%" }}
                            SelectProps={{ native: false }}
                            onChange={onChangeCounty}
                            value={countyId || ""}
                          >
                            {countyList.map((option, i) => (
                              <MenuItem
                                key={`${option.id}-${i}`}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddCountiesInCityAndCountList}
                >
                  Listeye Ekle
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={SPACING} flex={1}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" mb={1}>
                          <Typography variant="subtitle1" flex={1}>
                            Eklenen İl ve İlçeler
                          </Typography>
                        </Stack>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>İl</TableCell>
                                <TableCell>İlçe</TableCell>
                                <TableCell>Sil</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedCitiesAndCounties.map((option, i) => (
                                <TableRow hover sx={{ "& td": { border: 0 } }}>
                                  <TableCell>{option.cityName}</TableCell>
                                  <TableCell>{option.countyName}</TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        handleDeleteCityAndCountyFromList(
                                          option,
                                          i
                                        )
                                      }
                                    >
                                      Sil
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {selectedCitiesAndCounties.length === 0 && (
                                <TableRow>
                                  <TableCell align="center" colSpan={6}>
                                    <Box sx={{ py: 1 }}>
                                      <Typography gutterBottom align="center">
                                        List Boş
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Grid mt={2}>
            <Divider />
          </Grid>
          <Stack spacing={2} mt={2}>
            <Grid container spacing={SPACING}>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            size="small"
                            label="Slot Gün"
                            style={{ width: "100%" }}
                            SelectProps={{ native: false }}
                            onChange={handleSlotDayChange}
                            value={currentDay?.id || ""}
                          >
                            {SLOT_DAY_LIST.map((option, i) => (
                              <MenuItem
                                key={`${option.id}-${i}`}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={tr}
                        >
                          <TimePicker
                            label="Slot Başlangıç Tarihi"
                            onChange={(newValue) => {
                              handleSlotStart(newValue);
                            }}
                            value={currentStart}
                            type="time"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                type="time"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={tr}
                        >
                          <TimePicker
                            label="Slot Başlangıç Tarihi"
                            onChange={(newValue) => {
                              handleSlotEnd(newValue);
                            }}
                            value={currentEnd}
                            type="time"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                type="time"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddSlotInSlotList}
                >
                  Listeye Ekle
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={SPACING} flex={1}>
                  {/* <Divider /> */}
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" mb={1}>
                          <Typography variant="subtitle1" flex={1}>
                            Eklenen Slotlar
                          </Typography>
                        </Stack>
                        {/* <Divider sx={{ my: SPACING }} /> */}
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Gün</TableCell>
                                <TableCell>Başlangıç Saati</TableCell>
                                <TableCell>Bitiş Saati</TableCell>
                                <TableCell>Sil</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedSlots.map((option, i) => (
                                <TableRow hover sx={{ "& td": { border: 0 } }}>
                                  <TableCell>{option.dayName}</TableCell>
                                  <TableCell>
                                    {handleHourFormatFromDateString(
                                      option.start
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {handleHourFormatFromDateString(option.end)}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        handleDeleteSlotFromList(option, i)
                                      }
                                    >
                                      Sil
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {selectedSlots.length === 0 && (
                                <TableRow>
                                  <TableCell align="center" colSpan={6}>
                                    <Box sx={{ py: 1 }}>
                                      <Typography gutterBottom align="center">
                                        List Boş
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </FormStep>
      )}
    </MultiStepForm>
  );
}
