import React from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import CustomTable from "components/CustomTable";
import useTable, { getComparator } from "hooks/useTable";
import { applySortFilter } from "components/CustomTable/utils";
import { Services } from "api/Services";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material";
import qs from "query-string";
import Label from "components/Label";
import { TextFirstLetter } from "utils";

const server = new Services();

RegionalList.propTypes = {
  style: PropTypes.array,
};

export default function RegionalList(props) {
  const dispatch = useDispatch();
  const filters = useSelector((s) => s.dashboardFilterReducer);
  const [loading, setLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  const queryParam = qs.parse(props.location.search);
  const isCity = queryParam.city === "true";

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "created_at", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadDataCity = [
    { key: "il", title: "İl", onSort },
    { key: "siparisSayisi", title: "Sipariş Sayısı", onSort },
    { key: "yogunluk", title: "Yoğunluk", onSort },
  ];

  // Table Head Data
  const tableHeadDataCounty = [
    { key: "il", title: "İl", onSort },
    { key: "ilce", title: "İlçe", onSort },
    { key: "siparisSayisi", title: "Sipariş Sayısı", onSort },
    { key: "yogunluk", title: "Yoğunluk", onSort },
  ];

  const tableHeadData = isCity ? tableHeadDataCity : tableHeadDataCounty;

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((i) => i.key)],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  const getData = async () => {
    const section = isCity ? "shipments-by-city" : "shipments-by-county";

    try {
      const res = await server.getFilterResult(section, {
        ...filters,
        cities: filters.cities.map((i) => i.key),
        counties: filters.counties.map((i) => i.key),
        max_count: null,
      });

      setTableData(
        res.entity.data.map((i) => {
          const item = {
            il: i.city,
            siparisSayisi: i.count,
            yogunluk: i.density,
          };

          if (!isCity) {
            item.ilce = i.county;
          }

          return item;
        })
      );
      setLoading(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Layout
      {...props}
      heading={isCity ? "İl Listesi" : "İlçe Listesi"}
      links={[
        { name: "Genel", href: "/" },
        { name: isCity ? "İl Listesi" : "İlçe Listesi" },
      ]}
    >
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => {
            const newFilter = {
              ...filters,
              cities: [...filters.cities, { value: item.il, key: item.il }],
            };

            if (!isCity) {
              newFilter.counties = [
                ...filters.counties,
                { value: item.ilce, key: item.ilce },
              ];
            }

            const pageTitle = isCity
              ? `${TextFirstLetter(item.city)} Siparişleri`
              : `${TextFirstLetter(
                  `${item.city} / ${item.county}`
                )} Siparişleri`;

            return (
              <TableRow
                hover
                sx={{ cursor: "pointer", "& td": { border: 0 } }}
                key={`list-item-${index}`}
                onClick={() => {
                  dispatch({
                    type: "DASHBOARD_FILTER_SET",
                    payload: newFilter,
                  });
                  props.history.push({
                    pathname: "/shipments-detail",
                    search: qs.stringify({ pageTitle }),
                  });
                }}
              >
                <TableCell>
                  <Typography variant="caption">{item.il}</Typography>
                </TableCell>
                {!isCity && (
                  <TableCell>
                    <Typography variant="caption">{item.ilce}</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Label color="primary">{`${item.siparisSayisi} adet`}</Label>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {item.yogunluk.toFixed(2)}%
                  </Typography>
                  <LinearProgress
                    value={item.yogunluk}
                    variant="determinate"
                    color="success"
                  />
                </TableCell>
              </TableRow>
            );
          }),
        }}
      />
    </Layout>
  );
}
