import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
//
import { Cursors } from "..";
import { usePlanner } from "contexts/PlannerProvider";

type IconProps = {
  label: string;
  active?: boolean;
  src: string;
  onClick(): void;
};

const IconButton = ({ label, active, src, onClick }: IconProps) => {
  return (
    <Tooltip title={label}>
      <Box
        sx={{ width: 24, height: 24, cursor: "pointer", position: "relative" }}
        onClick={onClick}
      >
        <Box
          src={src}
          component="img"
          sx={{ width: 1, height: 1, objectFit: "contain" }}
        />
        {active && (
          <Box
            sx={{
              left: 0,
              top: 28,
              right: 0,
              height: 3,
              borderRadius: 1,
              position: "absolute",
              transform: "scale(0)",
              bgcolor: "secondary.main",
              animation: "borderA .4s ease forwards",
              "@keyframes borderA": { to: { transform: "scale(1)" } },
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export interface Props {
  cursor: Cursors;
  setCursor(cursor: Cursors): void;
  //
  centeredMap(): void;
  allSelectsPoint(): void;
}

export default function DispatcherNewMapActions(props: Props) {
  const { cursor, setCursor, centeredMap, allSelectsPoint } = props;
  const { mapLegends, routeOrdersFilters, setRouteOrdersFilters } =
    usePlanner();

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        top: 20,
        left: 20,
        px: 1.25,
        zIndex: 2,
        height: 44,
        bgcolor: "grey.0",
        borderRadius: 1.2,
        position: "absolute",
      }}
    >
      <IconButton
        label="Haritayı Ortala"
        src="/images/planner-new/map/1.svg"
        onClick={() => {
          centeredMap();
          setCursor("centered");
        }}
      />
      <IconButton
        label="Freehand"
        active={cursor === "freeHand"}
        src="/images/planner-new/map/2.svg"
        onClick={() => setCursor("freeHand")}
      />
      <IconButton
        label="Kement"
        active={cursor === "lasso"}
        src="/images/planner-new/map/3.svg"
        onClick={() => setCursor("lasso")}
      />
      {mapLegends.siparisler ? (
        <>
          <IconButton
            label="Hepsini Seç"
            onClick={allSelectsPoint}
            active={false}
            src="/images/planner-new/map/4.svg"
          />
          <IconButton
            label="Cetvel"
            active={cursor === "measure"}
            src="/images/planner-new/map/5.svg"
            onClick={() => setCursor("measure")}
          />
        </>
      ) : (
        mapLegends.rotalar && (
          <>
            <IconButton
              label="Tamamlanmış Siparişler"
              active={routeOrdersFilters.completed}
              src="/images/planner-new/map/7.svg"
              onClick={() =>
                setRouteOrdersFilters((s) => ({
                  ...s,
                  waiting: false,
                  completed: !s.completed,
                }))
              }
            />
            <IconButton
              label="Tamamlanmamış Siparişler"
              active={routeOrdersFilters.waiting}
              src="/images/planner-new/map/8.svg"
              onClick={() =>
                setRouteOrdersFilters((s) => ({
                  ...s,
                  completed: false,
                  waiting: !s.waiting,
                }))
              }
            />
          </>
        )
      )}
    </Stack>
  );
}
