import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth } from "../../api/Auth";
import Table from "../../components/Table";
import Layout from "components/Layout";
import { Box } from "@mui/material";
const LiveTracking = (props) => {
  const getliveTracking = useSelector((state) => state.liveTrackingReducer);
  const path = props.location.pathname.split("/")[1];

  let auth = new Auth();
  let isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  return (
    <Layout
      {...props}
      heading="Dağıtım"
      links={[{ name: "Genel", href: "/" }, { name: "Dağıtım" }]}
    >
      <Box
        sx={{
          "table.p-datatable-scrollable-body-table": {
            tableLayout: "auto",
          },
        }}
      >
        <Table
          products={
            Object.keys(getliveTracking.filterData).length > 0
              ? getliveTracking.filterData
              : getliveTracking.data
          }
          type="LiveTracking"
          differentPage={path == "report6" ? "LiveTrackingAdmin" : null}
        />
      </Box>
    </Layout>
  );
};

LiveTracking.whyDidYouRender = false;

export default LiveTracking;
