import * as React from "react";
import moment from "moment";
import { tr } from "date-fns/locale";
import * as Icon from "iconsax-react";
import { Close } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  Chip,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
//
import { Services } from "api/Services";
import { FiltersListItem } from "./OrderFilters";
import { initialRouteFilters, usePlanner } from "contexts/PlannerProvider";

export type RouteFiltersState = {
  slots: FiltersListItem[];
  cities: FiltersListItem[];
  order_dates: FiltersListItem[];
  statuses: FiltersListItem[];
  customers: FiltersListItem[];
  counties: FiltersListItem[];
  branches: FiltersListItem[];
};

const services = new Services();

export default function RouteFilters() {
  const {
    routeFilters,
    getRoutes,
    setRouteFilters,
    visibleRouteFilters,
    setVisibleRouteFilters,
  } = usePlanner();
  const [selectData, setSelectData] = React.useState<RouteFiltersState>({
    slots: [],
    cities: [],
    order_dates: [],
    statuses: [],
    customers: [],
    counties: [],
    branches: [],
  });

  const getFilters = React.useCallback(async () => {
    try {
      const res = await services.getPlanningFilters({
        status: [],
        driver_ids: [],
        end_date: null,
        start_date: null,
        branches: routeFilters.selectBranches.map((b) => b.key),
        customers: routeFilters.selectProjects.map((b) => b.key),
      });
      setSelectData(res[0]);
    } catch (error) {}
  }, [routeFilters]);

  React.useEffect(() => {
    getFilters();
  }, []);

  React.useEffect(() => {
    getRoutes(routeFilters);
  }, [routeFilters]);

  const resetFilters = () => {
    setRouteFilters(initialRouteFilters);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={visibleRouteFilters}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography variant="h5" flex={1}>
            Rotaları Filtrele
          </Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Chip
              variant="soft"
              color="secondary"
              onClick={resetFilters}
              onDelete={resetFilters}
              label="Seçimleri Temizle"
              deleteIcon={<Icon.Refresh2 variant="Bulk" size={20} />}
            />
            <IconButton onClick={() => setVisibleRouteFilters(false)}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
            <Stack direction="row" spacing={3}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={routeFilters.dates[0]}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    label="Rota Başlangıç Tarihi"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setRouteFilters({
                      ...routeFilters,
                      dates: [moment(e).toDate(), routeFilters.dates[1]],
                    });
                  }
                }}
                onAccept={(e) => {
                  setRouteFilters({
                    ...routeFilters,
                    dates: [moment(e).toDate(), routeFilters.dates[1]],
                  });
                }}
              />
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={routeFilters.dates[1]}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    label="Rota Bitiş Tarihi"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setRouteFilters({
                      ...routeFilters,
                      dates: [routeFilters.dates[0], moment(e).toDate()],
                    });
                  }
                }}
                onAccept={(e) => {
                  setRouteFilters({
                    ...routeFilters,
                    dates: [routeFilters.dates[0], moment(e).toDate()],
                  });
                }}
              />
            </Stack>
          </LocalizationProvider>
          <Autocomplete
            multiple
            fullWidth
            size="small"
            options={selectData.customers}
            noOptionsText="Sonuç bulunamadı."
            value={routeFilters.selectProjects}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderInput={(params) => <TextField {...params} label="Projeler" />}
            onChange={(e, select: any) => {
              setRouteFilters({ ...routeFilters, selectProjects: select });
            }}
          />
          <Autocomplete
            multiple
            fullWidth
            size="small"
            options={selectData.branches}
            noOptionsText="Sonuç bulunamadı."
            value={routeFilters.selectBranches}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderInput={(params) => <TextField {...params} label="Şubeler" />}
            onChange={(e, select: any) => {
              setRouteFilters({ ...routeFilters, selectBranches: select });
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
