import { isPointInsidePolygon } from "./mapDrawing";
import { Cursors } from "screens/DispatcherPlannerNew";

type LocationProps = {
  latitude: number;
  longitude: number;
};
type Props = {
  map: google.maps.Map;
  points: LocationProps[];
  setCursor(cursor: Cursors): void;
  handleSelectPoints(points: any[]): void;
};

export function drawFreeHand(props: Props) {
  const { map, points, setCursor, handleSelectPoints } = props;

  //the polygon
  const poly = new google.maps.Polyline({
    map,
    strokeWeight: 2,
    clickable: false,
    strokeColor: "#1977F2",
  });

  google.maps.event.addListener(map, "mousemove", function (e: any) {
    poly.getPath().push(e.latLng);
  });

  google.maps.event.addListenerOnce(map, "mouseup", function () {
    const paths = poly.getPath();

    poly.setMap(null);

    const newPoly = new google.maps.Polygon({
      map,
      paths,
      strokeWeight: 2,
      fillOpacity: 0.5,
      fillColor: "#1977F2",
      strokeColor: "#1977F2",
    });

    const coords = poly.getPath().getArray();
    if (!Array.isArray(coords)) {
      return;
    }

    const results = points.filter((location) => {
      const point = new google.maps.LatLng(
        location.latitude,
        location.longitude
      );
      return isPointInsidePolygon(point, newPoly);
    });

    handleSelectPoints(results);

    newPoly.setMap(null);

    setCursor("centered");
  });
}

export function drawPolygonHand(props: Props) {
  const { map, points, setCursor, handleSelectPoints } = props;
  let lassoPolygon: any = null;
  let lassoPath: any = [];

  lassoPolygon = new google.maps.Polygon({
    map,
    strokeWeight: 2,
    clickable: false,
    strokeColor: "#1977F2",
    fillColor: "transparent",
  });

  google.maps.event.addListener(map, "mousemove", function (event: any) {
    if (lassoPolygon) {
      lassoPath.push(event.latLng);
      lassoPolygon.setPath(lassoPath);
    }
  });

  google.maps.event.addListener(map, "mouseup", function () {
    if (lassoPolygon) {
      const pointsInsideLasso = points.filter((location) => {
        const point = new google.maps.LatLng(
          location.latitude,
          location.longitude
        );
        return isPointInsidePolygon(point, lassoPolygon);
      });

      lassoPolygon.setMap(null);
      lassoPolygon = null;
      lassoPath = [];

      handleSelectPoints(pointsInsideLasso);
      setCursor("centered");
    }
  });
}

export function enable(map: google.maps.Map) {
  map.setOptions({
    draggable: true,
  });
}

export function disable(map: google.maps.Map) {
  map.setOptions({
    draggable: false,
  });
}
