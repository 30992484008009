import React from "react";
import * as Icon from "iconsax-react";
import {
  Box,
  Sms,
  Mobile,
  Export,
  ArrowUp2,
  ArrowDown2,
  MouseCircle,
  CalendarTick,
  DirectboxSend,
  TableDocument,
} from "iconsax-react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Button, Chip, Stack, Menu, MenuItem, Typography } from "@mui/material";
//
import HeaderTabButton from "./HeaderTabButton";
import { usePlanner } from "contexts/PlannerProvider";
import AllOrderPlanned from "../AllOrderPlanned";

export interface Props {
  activeTab: number;
  fullScreen: boolean;
  toggleFullScreen(): void;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

export default function DispatcherNewOrdersHeader(props: Props) {
  const { fullScreen, toggleFullScreen, activeTab, setActiveTab } = props;
  const { routes, ordersCount, selectPointsOrders, selectRoutes } =
    usePlanner();
  const [visibleAutoPlanned, setVisibleAutoPlanned] =
    React.useState<boolean>(false);

  const disableSelectRouteBtn = selectRoutes.length === 0;
  const disableRouteBtn = routes.length === 0;

  const selectRoutesOrdersCount = selectRoutes.reduce((total, route) => {
    return total + route.orders.length;
  }, 0);

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{ alignItems: "center", bgcolor: "grey.300", pr: 1.5 }}
    >
      <Stack direction="row" alignItems="center" sx={{ flex: 1, height: 55 }}>
        <HeaderTabButton
          tab={0}
          activeTab={activeTab}
          onClick={() => setActiveTab(0)}
          label={`Siparişler (${ordersCount})`}
        />
        <HeaderTabButton
          tab={1}
          activeTab={activeTab}
          onClick={() => setActiveTab(1)}
          label={`Seçilen Siparişler (${selectPointsOrders.length})`}
        />
        <HeaderTabButton
          tab={2}
          activeTab={activeTab}
          onClick={() => setActiveTab(2)}
          label={`Rotalar (${routes.length})`}
        />
        <HeaderTabButton
          tab={3}
          activeTab={activeTab}
          onClick={() => setActiveTab(3)}
          label={`Seçilen Rota Siparişleri (${selectRoutesOrdersCount})`}
        />
      </Stack>

      <PopupState variant="popper" popupId="rotalari-planla">
        {(popupState) => (
          <React.Fragment>
            <Button
              size="small"
              disableElevation
              color="secondary"
              variant="contained"
              disabled={disableSelectRouteBtn}
              endIcon={<ArrowDown2 size={20} />}
              startIcon={<CalendarTick variant="Bulk" size={20} />}
              {...bindTrigger(popupState)}
            >
              Rotaları Planla
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  setVisibleAutoPlanned(true);
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box variant="Bulk" size={16} />
                  <Typography variant="body2">
                    Tüm Siparişleri Planla
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem onClick={popupState.close}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <MouseCircle variant="Bulk" size={16} />
                  <Typography variant="body2">
                    Seçilen Siparişleri Planla
                  </Typography>
                </Stack>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <PopupState variant="popover" popupId="rotalari-paylas">
        {(popupState) => (
          <React.Fragment>
            <Button
              size="small"
              disableElevation
              color="secondary"
              variant="contained"
              disabled={disableRouteBtn}
              endIcon={<ArrowDown2 size={20} />}
              startIcon={<Export variant="Bulk" size={20} />}
              {...bindTrigger(popupState)}
            >
              Rotaları Paylaş
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={popupState.close}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Mobile variant="Bulk" size={16} />
                  <Typography variant="body2">
                    Sürücü Uygulamasına Gönder
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem onClick={popupState.close}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Sms variant="Bulk" size={16} />
                  <Typography variant="body2">SMS Yoluyla Gönder</Typography>
                </Stack>
              </MenuItem>
              <MenuItem onClick={popupState.close}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <DirectboxSend variant="Bulk" size={16} />
                  <Typography variant="body2">
                    E-Posta Yoluyla Gönder
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem onClick={popupState.close}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TableDocument variant="Bulk" size={16} />
                  <Typography variant="body2">
                    Excel ile Dışarı Aktar
                  </Typography>
                </Stack>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Chip
        color="default"
        variant="outlined"
        onClick={toggleFullScreen}
        onDelete={toggleFullScreen}
        label={fullScreen ? "Daralt" : "Genişlet"}
        deleteIcon={
          fullScreen ? (
            <ArrowDown2 variant="Linear" size={20} />
          ) : (
            <ArrowUp2 variant="Linear" size={20} />
          )
        }
      />
      {visibleAutoPlanned && (
        <AllOrderPlanned onClose={() => setVisibleAutoPlanned(false)} />
      )}
    </Stack>
  );
}
