import React from "react";
import { Add } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

export interface Props {
  openModal(): void;
}

export default function RoutesEmptyList(props: Props) {
  const { openModal } = props;
  return (
    <Stack
      sx={{
        p: 2,
        gap: 3,
        flex: 1,
        overflowY: "auto",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 486px)",
        bgcolor: "background.default",
      }}
    >
      <Box sx={{ px: 14 }}>
        <Box
          component="img"
          src="/images/planner-new/routes/empty-list.svg"
          sx={{ width: 1, height: 1, objectFit: "contain" }}
        />
      </Box>
      <Box sx={{ textAlign: "center", px: 10 }}>
        <Typography variant="subtitle1">Planlamaya Başla</Typography>
        <Typography variant="caption">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut
        </Typography>
      </Box>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        startIcon={<Add />}
        onClick={openModal}
      >
        Rota Oluştur
      </Button>
    </Stack>
  );
}
