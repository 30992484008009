type Props = {
  map: google.maps.Map;
  callback(points: any[]): void;
  locations: Array<{ lat: number; lng: number }>;
};

export const mapHandleDrawing = (props: Props) => {
  const { map, locations, callback } = props;

  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingControl: false,
    polygonOptions: {
      editable: false,
      strokeWeight: 4,
      clickable: false,
      fillColor: "#1977F2",
      strokeColor: "#1977F2",
    },
    drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
  });

  drawingManager.setMap(map);

  window.google.maps.event.addListener(
    drawingManager,
    "overlaycomplete",
    (event: any) => {
      if (event.type === "polygon") {
        const path = event.overlay.getPath();
        const bounds = new window.google.maps.LatLngBounds();

        path.forEach((point: any) => bounds.extend(point));

        const points: { lat: number; lng: number }[] = [];

        locations.forEach((position) => {
          const point = new window.google.maps.LatLng(
            position.lat,
            position.lng
          );
          if (bounds.contains(point)) {
            points.push(position);
          }
        });

        callback(points);
        event.overlay.setMap(null);
        drawingManager.setMap(null);
      }
    }
  );
};

export const mapHandleLassoDrawing = (props: Props) => {
  const { map, locations, callback } = props;
  let lassoPolygon: any = null;
  let lassoPath: any = [];

  lassoPolygon = new google.maps.Polygon({
    map,
    strokeWeight: 2,
    clickable: false,
    strokeColor: "#1977F2",
    fillColor: "transparent",
  });

  google.maps.event.addListener(map, "mousemove", function (event: any) {
    if (lassoPolygon) {
      lassoPath.push(event.latLng);
      lassoPolygon.setPath(lassoPath);
    }
  });

  google.maps.event.addListener(map, "mouseup", function () {
    if (lassoPolygon) {
      const pointsInsideLasso = locations.filter((location) => {
        const point = new google.maps.LatLng(location.lat, location.lng);
        return isPointInsidePolygon(point, lassoPolygon);
      });

      lassoPolygon.setMap(null);
      lassoPolygon = null;
      lassoPath = [];

      callback(pointsInsideLasso);
    }
  });
};

export function isPointInsidePolygon(point: any, polygon: any) {
  const path = polygon.getPath();
  const pathLength = path.getLength();
  let isInside = false;

  for (let i = 0, j = pathLength - 1; i < pathLength; j = i++) {
    const vertex1 = path.getAt(i);
    const vertex2 = path.getAt(j);

    if (
      vertex1.lat() > point.lat() !== vertex2.lat() > point.lat() &&
      point.lng() <
        ((vertex2.lng() - vertex1.lng()) * (point.lat() - vertex1.lat())) /
          (vertex2.lat() - vertex1.lat()) +
          vertex1.lng()
    ) {
      isInside = !isInside;
    }
  }

  return isInside;
}
