import * as React from "react";
import * as Icon from "iconsax-react";
import {
  Box,
  Chip,
  Stack,
  Avatar,
  Checkbox,
  Skeleton,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
//
import { BASE_URL } from "api/Env";
import { searchByName } from "utils";
import { Services } from "api/Services";
import { DriverProps } from "../@types";
import { IAutoPlaningListItem } from "./List/types";
import {
  IAutoPlanningSelectDriver,
  IAutoPlanningSelectDriverVehicle,
} from "./Modal";

const service = new Services();

interface Props {
  branch: { key: string; value: string };
  selectDrivers: IAutoPlanningSelectDriver[];
  setSelectDrivers: React.Dispatch<
    React.SetStateAction<IAutoPlanningSelectDriver[]>
  >;
  detail?: IAutoPlaningListItem | null;
}

export default function AutoPlanningDrivers(props: Props) {
  const { branch, selectDrivers, setSelectDrivers, detail } = props;
  const [loading, setLoading] = React.useState(true);
  const [drivers, setDrivers] = React.useState<DriverProps[]>([]);
  const [search, setSearch] = React.useState("");

  const getDrivers = React.useCallback(async () => {
    setLoading(true);

    try {
      const res = await service.getPlanningDrivers([branch.key]);
      const newArr = res.filter((d: any) => d.vehicles.length > 0);
      setDrivers(newArr);

      if (detail?.branch) {
        setSelectDrivers(
          detail.vehicles.map((v) => {
            let vehicle = null;
            newArr.forEach((a: any) => {
              const isVehicle = a.vehicles.find(
                (b: any) => b.name === v.vehicle_id
              );
              if (isVehicle) {
                vehicle = isVehicle;
              }
            });

            return {
              vehicle,
              checked: true,
              driver_id: v.driver_id,
              picture_id: v.picture_id || "",
            };
          })
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [branch, detail]);

  React.useEffect(() => {
    getDrivers();
  }, [branch]);

  const onCheckedDriver = React.useCallback(
    (driver: IAutoPlanningSelectDriver) => {
      const hasDriver = selectDrivers.find(
        (d) => d.driver_id === driver.driver_id
      );
      if (hasDriver) {
        setSelectDrivers((d) =>
          [...d].filter((d) => d.driver_id !== driver.driver_id)
        );
      } else {
        const getFirstVehicle = drivers.find((d) => d.id === driver.driver_id)
          ?.vehicles[0];
        setSelectDrivers((d) => [
          ...d,
          { ...driver, vehicle: getFirstVehicle || null },
        ]);
      }
    },
    [selectDrivers, drivers]
  );

  const onChangeVehicleSelect = React.useCallback(
    (driverId: string, vehicle: IAutoPlanningSelectDriverVehicle | null) => {
      const hasDriverIndex = selectDrivers.findIndex(
        (d) => d.driver_id === driverId
      );

      setSelectDrivers((prevDrivers) => {
        const newDrivers = [...prevDrivers];
        newDrivers[hasDriverIndex].vehicle = vehicle;
        return newDrivers;
      });
    },
    [selectDrivers]
  );

  const searchFilterDrivers: DriverProps[] = React.useMemo(
    () => searchByName(search, drivers, ["full_name", "phone"]),
    [search, drivers]
  );

  const vehicleWithDrivers = selectDrivers.filter((d) => d.vehicle);

  return (
    <Stack
      p={2}
      pt={0}
      spacing={1}
      height={300}
      overflow="auto"
      borderRadius={1}
      bgcolor="background.neutral"
    >
      <Box
        sx={{
          pt: 2,
          top: 0,
          zIndex: 2,
          position: "sticky",
          bgcolor: "background.neutral",
        }}
      >
        <TextField
          fullWidth
          size="small"
          value={search}
          label="Sürücü Ara"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch("")} color="error">
                  <Icon.CloseCircle size={20} variant="Bulk" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {vehicleWithDrivers.length > 0 && (
          <Stack spacing={1} direction="row" py={1} pb={0}>
            {vehicleWithDrivers.map((driver) => {
              return (
                <Chip
                  variant="soft"
                  color="secondary"
                  key={driver.driver_id}
                  label={driver.vehicle?.name}
                  onDelete={() => onCheckedDriver(driver)}
                />
              );
            })}
          </Stack>
        )}
      </Box>
      {loading ? (
        <Stack spacing={1}>
          {[...new Array(5)].map((e, i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              sx={{
                width: 1,
                height: 58,
                borderRadius: 1,
                bgcolor: "background.default",
              }}
            />
          ))}
        </Stack>
      ) : (
        <Stack spacing={1}>
          {searchFilterDrivers.map((driver) => {
            const hasDriver = selectDrivers.find(
              (d) => d.driver_id === driver.id
            );
            return (
              <Stack
                key={driver.id}
                spacing={{ xs: 2, md: 0 }}
                direction={{ xs: "column", md: "row" }}
                sx={{
                  p: 1,
                  borderRadius: 1,
                  border: "1px solid",
                  bgcolor: "background.default",
                  borderColor: "background.neutral",
                }}
              >
                <Stack flex={1} spacing={1} direction="row" alignItems="center">
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    src={`${BASE_URL}api/public/document/${driver.photo?.id}`}
                  />
                  <Stack>
                    <Typography variant="caption" fontWeight="600">
                      {driver.full_name}
                    </Typography>
                    <Typography variant="caption">{driver.phone}</Typography>
                  </Stack>
                </Stack>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <FormControlLabel
                    label={hasDriver ? "" : "Sürücüyü Seç"}
                    control={
                      <Checkbox
                        size="small"
                        checked={Boolean(hasDriver)}
                        onChange={(e) =>
                          onCheckedDriver({
                            vehicle: null,
                            driver_id: driver.id,
                            picture_id: driver.photo.id,
                            // @ts-ignore
                            checked: e.target.checked,
                          })
                        }
                      />
                    }
                  />

                  {hasDriver && (
                    <Autocomplete
                      size="small"
                      sx={{ width: 200 }}
                      options={driver.vehicles}
                      value={hasDriver.vehicle}
                      ChipProps={{ variant: "soft" }}
                      getOptionLabel={(option) => option.name}
                      onChange={(a, vehicle) => {
                        onChangeVehicleSelect(driver.id, vehicle);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Ara"
                          label="Araç"
                          helperText={
                            !hasDriver.vehicle &&
                            "Araç seçmezseniz bu sürücü eklenmeyecek."
                          }
                        />
                      )}
                    />
                  )}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}
