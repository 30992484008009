import React from "react";
import Layout from "components/Layout";
import Filters from "./Filters";
import { Alert, AlertTitle, Box, Button, Grid, Stack } from "@mui/material";
import List from "./List";
import Map from "./Map";
import CountyList from "./CountyList";
import Branches from "./Branches";
import { Services } from "api/Services";
import Loader from "react-loader-spinner";
import moment from "moment";
import AnalyticalNew from "./AnalyticalNew";
import Couriers from "./Couriers";
import { FilterList } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { InView } from "react-intersection-observer";
import { useEffect } from "react";
import NewMap from "./NewMap";

const server = new Services();
const currentDate1 = moment().format("YYYY-MM-DD");

const initialStateFilter = {
  start_date: currentDate1,
  branches: [],
  vehicle_types: [],
  cities: [],
  vehicles: [],
  drivers: [],
  counties: [],
  customers: [],
  tenants: [],

  shipment_number: "",
  package_code: "",
  max_count: 4,
};

export default function CustomerManagement(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [loadingContent, setLoadingContent] = React.useState(true);
  const [filterLoading, setFilterLoading] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [asyncFilters, setAsyncFilters] = React.useState({
    cities: [],
    customers: [],
    branches: [],
    vehicle_types: [],
    vehicles: [],
    drivers: [],
    counties: [],
    tenants: []
  });

  const data = useSelector((s) => s.dashboardFilterReducer);
  const [filter, setFilter] = React.useState({
    ...data,
  });
  const [userFilter, setUserFilter] = React.useState({
    ...initialStateFilter,
  });
  const [statsData, setStatsData] = React.useState({
    metrics: {
      avgcompleteddistancebyvehicle: 0,
      avgdecibyvehicle: 0,
      avgorderbyvehicle: 0,
      avgpackagebyvehicle: 0,
      avgroutedistancebyvehicle: 0,
      operatingbranchcount: 0,
      workingvehiclecount: 0,
    },
    types: [],
  });
  const [distributions, setDistributions] = React.useState({
    package_count: 0,
    distance: 0,
    delivered_count: 0,
    delivered_deci: 0,
    completed_distance: 0,
    on_board_count: 0,
    not_delivered_count: 0,
    deci: 0,
    projects: [],
  });
  const [fetchError, setFetchError] = React.useState(false);

  userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");

  console.log(
    "filters",
    userFilter.branches.map((i) => i)
  );
  useEffect(() => {
    const getAsyncFilters = async () => {
      try {
        const res = await server.getCustomerManagementAsyncFilters({
          start_date: userFilter.start_date,
          end_date: userFilter.end_date,
          customers: userFilter.customers,
          vehicle_types: userFilter.vehicle_types,
          vehicles: userFilter.vehicles,
          drivers: userFilter.drivers,
          branches: userFilter.branches,
          cities: userFilter.cities,
          counties: userFilter.counties,
          tenants: userFilter.tenants
        });
        setAsyncFilters(res.entity.data[0]);
      } catch (error) {
        //console.log(error);
      }
    };
    getAsyncFilters();
  }, [userFilter]);

  console.log("asyncFilters", asyncFilters);

  const handleChangeFilter = (key, newValue) => {
    if (key === "shipment_number") {
      setFilter((f) => {
        const newFilter = { ...f, shipment_number: newValue.target.value };
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: newFilter });
        return newFilter;
      });
    } else if (key === "package_code") {
      setFilter((f) => {
        const newFilter = { ...f, package_code: newValue.target.value };
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: newFilter });
        return newFilter;
      });
    } else {
      setFilter((f) => {
        let data = null;
        if (key === "start_date") {
          data = { ...f, start_date: moment(newValue).format("YYYY-MM-DD") };
        } else {
          data = { ...f, [key]: newValue };
        }
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: data });
        return data;
      });
    }
  };

  const getStatsData = async () => {
    try {
      const res = await server.getFilterResult("stats", userFilter);
      setStatsData(res.entity.data[0]);
      setLoadingContent(false);
    } catch (error) {
      setFetchError(true);
    }
  };

  const getDistributions = async () => {
    try {
      const res = await server.getFilterResult("distributions", userFilter);
      setDistributions(res.entity.data[0]);
    } catch (error) {
      setFetchError(true);
    }
  };

  const handleFilter = async ({ reset }) => {
    if (fetchError) {
      setFetchError(false);
    }

    if (reset) {
      setFilter(initialStateFilter);
      dispatch({ type: "DASHBOARD_FILTER_RESET" });
    } else {
      const filters = {
        ...filter,
        start_date: moment(filter.start_date).format("YYYY-MM-DD"),
        cities: filter.cities.map((i) => i.key),
        customers: filter.customers.map((i) => i.key),
        branches: filter.branches.map((i) => i.key),
        vehicle_types: filter.vehicle_types.map((i) => i.key),
        vehicles: filter.vehicles.map((i) => i.key),
        drivers: filter.drivers.map((i) => i.key),
        counties: filter.counties.map((i) => i.key),
        tenants: filter.tenants.map((i) => i.key)
      };
      setUserFilter(filters);
    }

    setFilterLoading(true);

    for (const [key, value] of Object.entries(userFilter)) {
      const newValue = Array.isArray(value) ? value[0] : value;

      if (newValue === "") {
        delete userFilter[key];
      }
    }

    try {
      setFilterLoading(false);
      setLoading(false);
    } catch (error) {
      console.log("ERROR =>", error);
      setFilterLoading(false);
      setFetchError(true);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getStatsData();
    getDistributions();
  }, [userFilter]);

  React.useEffect(() => {
    if (
      !_.isEqual(filter, initialStateFilter) ||
      filter.start_date === initialStateFilter.start_date
    ) {
      handleFilter({ reset: false });
    }
  }, [filter]);

  return (
    <Layout
      {...props}
      heading="Genel Durum"
      links={[{ name: "Genel", href: "/" }, { name: "Genel Durum" }]}
      action={
        <Button
          variant="contained"
          color={openFilter ? "primary" : "inherit"}
          startIcon={<FilterList />}
          onClick={() => setOpenFilter(!openFilter)}
        >
          Filtrele
        </Button>
      }
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Oval" color="tomato" height={40} width={40} />
        </Box>
      ) : fetchError ? (
        <Alert severity="error">
          <AlertTitle>Bir sorun oluştu.</AlertTitle>
          Lütfen sunucuyu kontrol — <strong>ediniz!</strong>
        </Alert>
      ) : (
        <Stack spacing={3}>
          {openFilter && (
            <Filters
              data={asyncFilters}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              filterLoading={filterLoading}
              handleFilterReset={() => handleFilter({ reset: true })}
            />
          )}
          {loadingContent ? (
            <Box
              sx={{
                width: "100%",
                height: "10vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader type="Oval" color="tomato" height={40} width={40} />
            </Box>
          ) : (
            <Stack spacing={2}>
              <AnalyticalNew
                distributions={distributions}
                statsData={statsData}
              />
              <InView triggerOnce={true}>
                {({ ref, inView }) => (
                  <Box ref={ref}>
                    {inView && <List userFilter={userFilter} />}
                  </Box>
                )}
              </InView>
              <InView triggerOnce={true}>
                {({ ref, inView }) => (
                  <Box ref={ref}>
                    {inView && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} >
                          <NewMap userFilter={userFilter} />
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}
              </InView>
              <InView triggerOnce={true}>
                {({ ref, inView }) => (
                  <Box ref={ref}>
                    {inView && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Branches userFilter={userFilter} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Couriers userFilter={userFilter} />
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}
              </InView>
            </Stack>
          )}
        </Stack>
      )}
    </Layout>
  );
}
