import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import List from "./List";
import Detail from "./Detail";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Services } from "api/Services";
import { useSnackbar } from "notistack";

function convertString(phrase) {
  var maxLength = 100;

  var returnString = phrase.toLowerCase();
  //Convert Characters
  returnString = returnString.replace(/ö/g, "o");
  returnString = returnString.replace(/ç/g, "c");
  returnString = returnString.replace(/ş/g, "s");
  returnString = returnString.replace(/ı/g, "i");
  returnString = returnString.replace(/ğ/g, "g");
  returnString = returnString.replace(/ü/g, "u");

  // if there are other invalid chars, convert them into blank spaces
  // returnString = returnString.replace(/[^a-z0-9\s-]/g, "");
  // convert multiple spaces and hyphens into one space
  returnString = returnString.replace(/[\s-]+/g, " ");
  // trims current string
  returnString = returnString.replace(/^\s+|\s+$/g, "");
  // cuts string (if too long)
  if (returnString.length > maxLength)
    returnString = returnString.substring(0, maxLength);
  // add hyphens
  returnString = returnString.replace(/\s/g, "-");

  return returnString.toLocaleUpperCase("tr-TR");
}

const listFilterData = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      created_at: item.created_at,
      name: convertString(item.name || ""),
      code: item.code,
      address: item.address?.address || "",
      city: item.address?.city || "",
      county: item.address?.county || "",
      first_name: item.address?.first_name || "",
      last_name: convertString(item.address?.last_name || ""),
      gsm_number: item.address?.gsm_number || "",
      username: item.contact?.username || "",
      //
      take_photo_on_delivery: item.take_photo_on_delivery,
      use_barcode_on_delivery: item.use_barcode_on_delivery,
      use_order_code_for_outlet: item.use_order_code_for_outlet,
      use_receiver_info_for_outlet: item.use_receiver_info_for_outlet,
      use_shipment_code_on_delivery: item.use_shipment_code_on_delivery,
      //
      detailData: item,
    };
  });
};

const server = new Services();

ProjectDefinition.propTypes = {
  style: PropTypes.array,
};

export default function ProjectDefinition(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [detail, setDetail] = React.useState(null);

  function handleDetailItemEdit(item) {
    setDetail({ detail: item, isEdit: true });
  }

  function handleDetailItemNew() {
    setDetail({ detail: null, isEdit: false });
  }

  function handleCloseDetail() {
    setDetail(null);
  }

  async function getList() {
    setLoading(true);
    try {
      const res = await server.getList();
      setTableData(listFilterData(res.entity.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleDetailItemDelete(id) {
    const index = tableData.findIndex((i) => i.id === id);
    if (index > -1) {
      try {
        await server.delCustomer(id);
        const newData = [...tableData];
        newData.splice(index, 1);
        setTableData(newData);
        enqueueSnackbar("Başarıyla silindi.", { variant: "success" });
      } catch (error) {}
    }
  }

  React.useEffect(() => {
    getList();
  }, []);

  const headerText = useMemo(() => {
    return detail
      ? `Proje ${detail.isEdit ? "Düzenle" : "Ekle"} `
      : "Proje Yönetimi";
  }, [detail]);

  return (
    <Layout
      {...props}
      heading={headerText}
      links={[{ name: "Genel", href: "/" }, { name: headerText }]}
      action={
        detail ? null : (
          <Button
            startIcon={<Add />}
            variant="contained"
            onClick={handleDetailItemNew}
          >
            Yeni Proje
          </Button>
        )
      }
    >
      {detail ? (
        <Detail
          item={detail}
          getList={getList}
          handleCloseDetail={handleCloseDetail}
        />
      ) : (
        <List
          handleDetailItemEdit={handleDetailItemEdit}
          handleDetailItemDelete={handleDetailItemDelete}
          loading={loading}
          tableData={tableData}
        />
      )}
    </Layout>
  );
}
