import React from "react";
import _ from "lodash";
import * as Icon from "iconsax-react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
//
import RoutesHeader from "./Header";
import Label from "components/Label";
import RoutesDrivers from "./Drivers";
import RoutesEmptyList from "./EmptyList";
import RutCreateModal from "../RutCreateModal";
import { usePlanner } from "contexts/PlannerProvider";
import RoutesList, { RoutesListItemProps } from "./List";

export interface DispatcherNewRoutesProps {
  props?: any;
}

export default function DispatcherNewRoutes(props: DispatcherNewRoutesProps) {
  const { routes } = usePlanner();
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
  const [openRoute, setOpenRoute] = React.useState<RoutesListItemProps | null>(
    null
  );
  const { palette } = useTheme();

  const openModal = () => {
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const openDetailRoute = React.useCallback((route: RoutesListItemProps) => {
    setOpenRoute(route);
  }, []);

  const closeDetailRoute = React.useCallback(() => {
    setOpenRoute(null);
  }, []);

  const groupedRoutes: any = _.mapValues(
    _.groupBy(routes, (item) => {
      return [item.name];
    })
  );

  // Kullanıcı aracı değiştirdiğinde, yeni gelen rutlar içinden açık olan rotayı yeniden güncelliyorum.
  React.useEffect(() => {
    if (routes?.length > 0 && openRoute) {
      const newRoutes = groupedRoutes[Object.keys(openRoute)[0]];
      setOpenRoute({ [Object.keys(openRoute)[0]]: newRoutes });
    }
  }, [routes]);

  if (openRoute) {
    const detailRoutes = openRoute[Object.keys(openRoute)[0]] || [];
    const detailTitle = Object.keys(openRoute)[0];

    return (
      <>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow
                sx={{
                  height: 44,
                  bgcolor: "background.neutral",
                  borderColor: "background.default",
                }}
              >
                <TableCell width={20} sx={{ paddingLeft: "12px!important" }}>
                  <Stack>
                    <Icon.ArrowLeft
                      size={20}
                      variant="Bulk"
                      cursor="pointer"
                      onClick={closeDetailRoute}
                      color={palette.text.secondary}
                    />
                  </Stack>
                </TableCell>
                <TableCell sx={{ paddingLeft: "0!important" }}>
                  <Stack spacing={1} alignItems="center" direction="row">
                    <Typography variant="subtitle2">Plan Adı:</Typography>
                    <Label>{detailTitle}</Label>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <RoutesDrivers detailRoutes={detailRoutes} />
      </>
    );
  }

  return (
    <>
      <RoutesHeader
        count={Object.entries(groupedRoutes).length}
        openModal={openModal}
      />
      {routes.length === 0 ? (
        <RoutesEmptyList openModal={openModal} />
      ) : (
        <RoutesList data={groupedRoutes} openDetailRoute={openDetailRoute} />
      )}
      {visibleModal && <RutCreateModal onClose={closeModal} />}
    </>
  );
}
