import * as React from "react";
import _ from "lodash";
import moment from "moment";
import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
//
import { BASE_URL } from "api/Env";
import { statusFilter } from "helper";
import ItemPackage from "../List/ItemPackage";
import DataLocaleText from "../DataLocaleText";
import { usePlanner } from "contexts/PlannerProvider";
import Label from "components/Label";

interface Props {}

export default function SelectRouteListOrders(props: Props) {
  const {
    selectRoutes,

    selectMapRouteOrders,
    setSelectMapRouteOrders,
  } = usePlanner();

  const orders = React.useMemo(() => {
    return selectRoutes.flatMap((route) => {
      return route.orders.map((order) => ({
        ...order,
        routeDetail: route.route,
      }));
    });
  }, [selectRoutes]);

  const selectIds = React.useMemo(
    () => selectMapRouteOrders.map((s) => s.id),
    [selectMapRouteOrders]
  );

  const selectableNewOrders = React.useMemo(
    () =>
      orders.map((o) => ({
        ...o,
        routeId: o.routeDetail.route_id,
        route_color: o.routeDetail.route_color,
      })),
    [orders]
  );

  return (
    <DataGrid
      rows={_.orderBy(orders, "plan_day_sequence")}
      checkboxSelection
      disableRowSelectionOnClick
      localeText={DataLocaleText}
      rowSelectionModel={selectIds}
      pageSizeOptions={[25, 50, 100]}
      getCellClassName={() => "theme--header"}
      sx={{ height: 1, bgcolor: "background.neutral" }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setSelectMapRouteOrders(
          selectableNewOrders.filter((i) => newRowSelectionModel.includes(i.id))
        );
      }}
      components={{
        Toolbar: () => {
          return (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box sx={{ width: 1, maxWidth: 320 }} pl={2}>
                <GridToolbarQuickFilter sx={{ width: 1 }} />
              </Box>
              <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                />
              </GridToolbarContainer>
            </Stack>
          );
        },
      }}
      columns={[
        {
          width: 60,
          headerName: "Sıra",
          disableColumnMenu: true,
          field: "plan_day_sequence",
          renderCell: ({ row }) => {
            const bgcolor = row.routeDetail.route_color;
            return (
              <Label sx={{ bgcolor, color: "white" }}>
                {row.plan_day_sequence}
              </Label>
            );
          },
        },
        {
          flex: 1,
          field: "routeDetail_name",
          headerName: "Rota Adı",
          valueGetter: ({ row: { routeDetail } }) => routeDetail.name,
        },
        {
          flex: 1,
          field: "routeDetail_date",
          headerName: "Rota Tarihi",
          valueGetter: ({ row: { routeDetail } }) => {
            return moment(routeDetail.date).format("DD.MM.YYYY HH:mm");
          },
        },
        {
          flex: 1,
          field: "routeDetail_vehicle",
          headerName: "Rota Araç",
          valueGetter: ({ row: { routeDetail } }) => {
            return routeDetail.vehicle.name;
          },
        },
        {
          flex: 1,
          field: "routeDetail_driver",
          headerName: "Rota Sürücü",
          valueGetter: ({ row: { routeDetail } }) => {
            const { driver } = routeDetail;
            const isFullName = driver.first_name && driver.last_name;
            return isFullName
              ? `${driver.first_name} ${driver.last_name}`
              : driver.username;
          },
        },
        {
          flex: 1,
          field: "order_code",
          headerName: "Sipariş Kodu",
          valueGetter: ({ row }) => row.order_code,
        },
        {
          flex: 1,
          field: "order_customer",
          headerName: "Alıcı",
          valueGetter: ({ row }) => {
            return `${row.customer.first_name} ${row.customer.last_name || ""}`;
          },
        },
        {
          flex: 1,
          field: "order_date",
          headerName: "Tarihi",
          valueGetter: ({ row }) => {
            return moment(row.order_date).format("DD.MM.YYYY HH:mm");
          },
        },
        {
          flex: 1,
          field: "order_status",
          headerName: "Durumu",
          valueGetter: ({ row }) => statusFilter(row),
        },
        {
          field: "order_deci",
          headerName: "Desi",
          valueGetter: ({ row }) => row.deci,
        },
        {
          field: "order_packages",
          headerName: "Paket",
          valueGetter: ({ row }) => row.packages.length,
          renderCell: (params) => {
            return <ItemPackage order={params.row} />;
          },
        },
        {
          flex: 1,
          field: "order_project",
          headerName: "Proje",
          renderCell: (params) => {
            const photo = params.row.warehouse.firm_logo_id
              ? `${BASE_URL}api/public/document/${params.row.warehouse.firm_logo_id}`
              : undefined;
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                  src={photo}
                  sx={{ width: 30, height: 30 }}
                  alt={params.row.warehouse.firm_name}
                />
                <Typography variant="caption">
                  {params.row.warehouse.firm_name}
                </Typography>
              </Stack>
            );
          },
        },
        {
          width: 140,
          field: "sender",
          headerName: "Gönderen",
          renderCell: (params) => {
            return (
              <Chip variant="soft" color="info" label={params.row.sender} />
            );
          },
        },
        {
          flex: 1,
          field: "order_branch",
          headerName: "Şube",
          valueGetter: ({ row }) => row.assigned_branch.name,
        },
        {
          flex: 1,
          field: "order_warehouse",
          headerName: "Depo",
          valueGetter: ({ row }) => row.warehouse.name,
        },
        {
          flex: 1,
          field: "order_city",
          headerName: "İl",
          valueGetter: ({ row }) => row.address.city,
        },
        {
          flex: 1,
          field: "order_county",
          headerName: "İlçe",
          valueGetter: ({ row }) => row.address.county,
        },
        {
          width: 300,
          field: "order_address",
          headerName: "Adres",
          renderCell: (params) => {
            return (
              <Typography variant="caption" whiteSpace="pre-line">
                {params.row.address.address}
              </Typography>
            );
          },
        },
      ]}
    />
  );
}
