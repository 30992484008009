import React from "react";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
//
import Label from "components/Label";
import { statusFilter } from "helper";
import { Services } from "api/Services";
import { OrderProps, RouteProps } from "contexts/PlannerProvider/types";

const services = new Services();

export interface Props {
  order: OrderProps;
  route: RouteProps;
  handleDeleteOrder(orderId: string): void;
}

export default function RoutesListOrdersItem(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { order, route, handleDeleteOrder } = props;
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Emin misiniz?")) {
      return;
    }
    const sendData = {
      order_ids: [order.id],
      route_id: route.route_id,
    };
    setLoading(true);
    try {
      await services.deletePlanningRouteOrder(sendData);
      enqueueSnackbar("Sipariş başarıyla havuza taşındı.", {
        variant: "success",
      });
      setLoading(false);
      handleDeleteOrder(order.id);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Bir sorun oluştu.", {
        variant: "error",
      });
    }
  };

  const delivered = order.delivery_status === "DELIVERED";
  const notDelivered = order.delivery_status === "NOT_DELIVERED";
  const status = statusFilter(order);

  return (
    <TableRow sx={{ position: "relative" }}>
      <TableCell>
        <Stack
          sx={{
            width: 22,
            height: 26,
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: "49% 51% 51% 49% / 39% 39% 61% 61%",
            bgcolor: delivered
              ? "primary.main"
              : notDelivered
              ? "error.main"
              : "secondary.main",
          }}
        >
          <Typography variant="caption" fontWeight="bold" fontSize={10}>
            {order.plan_day_sequence}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack>
          <Typography fontWeight="bold" variant="caption">
            {order.address.first_name} {order.address.last_name}
          </Typography>
          <Typography variant="caption">{order.order_code}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Label
          variant="ghost"
          color={delivered ? "primary" : notDelivered ? "error" : "secondary"}
        >
          {status}
        </Label>
      </TableCell>
      <TableCell>
        <Label variant="ghost" color="info">
          {order.packages.length}
        </Label>
      </TableCell>
      <TableCell align="right" sx={{ paddingRight: "4px!important" }}>
        <IconButton onClick={handleDelete} color="error">
          <Icon.Trash variant="Bulk" size={18} />
        </IconButton>
      </TableCell>
      {loading && (
        <Stack
          sx={{
            inset: 0,
            zIndex: 3,
            borderRadius: 1,
            alignItems: "center",
            position: "absolute",
            bgcolor: "grey.50056",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={24} color="error" />
        </Stack>
      )}
    </TableRow>
  );
}
