import * as XLSX from "xlsx";

export default function exportCsv({ fileName, columns, data }) {
  const fields = [...columns.slice(0, -1)].map((column) => column.label);

  const newArray = data.map((item) => {
    const newArr = [...item.data];
    newArr.pop();
    return newArr;
  });

  const newRows = newArray.map((item, index) => {
    const newRow = {};
    fields.forEach((field, fieldIndex) => {
      newRow[field] = item[fieldIndex];
    });

    return newRow;
  });

  if (newRows.length === 0) {
    return;
  }

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(newRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  XLSX.utils.sheet_add_aoa(worksheet, [fields], {
    origin: "A1",
  });

  /* calculate column width */
  worksheet["!cols"] = fields.map((field) => {
    const fieldLength = field.length;
    const rowLength = `${newRows[0][field]}`.length;

    const wch = fieldLength > rowLength ? fieldLength : rowLength;

    return { wch };
  });

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `${fileName || "downloadTable"}.xlsx`, {
    compression: true,
  });
}
