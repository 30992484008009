import React from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
//
import { Services } from "api/Services";
import { usePlanner } from "contexts/PlannerProvider";
import { RouteProps } from "contexts/PlannerProvider/types";
import { VehicleProps } from "screens/DispatcherPlannerNew/@types";

const service = new Services();

export interface Props {
  route: RouteProps;
  onClose(): void;
}

export default function ChangeVehicleModal(props: Props) {
  const { route, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { getRoutes, routeFilters } = usePlanner();
  const [vehiclesLoading, setVehiclesLoading] = React.useState<boolean>(true);
  const [vehicles, setVehicles] = React.useState<VehicleProps[]>([]);
  const [selectVehicle, setSelectVehicle] = React.useState<VehicleProps | null>(
    null
  );
  const [selectDriver, setSelectDriver] = React.useState<any>(null);
  //
  const [loading, setLoading] = React.useState<boolean>(false);

  const initialVehicle = (res: VehicleProps[]) => {
    if (route.vehicle) {
      const getVehicle = res.find((v) => v.id === route.vehicle.id);
      if (getVehicle) {
        setSelectVehicle(getVehicle);
      }
    }
  };

  const getVehicles = async () => {
    try {
      const res: VehicleProps[] = await service.getPlanningVehicles();
      setVehicles(res);
      setVehiclesLoading(false);
      initialVehicle(res);
    } catch (error) {
      setVehiclesLoading(false);
    }
  };

  const onChangeVehicle = async () => {
    if (!selectVehicle || !selectDriver) {
      enqueueSnackbar("Lütfen tüm alanları doldurunuz.", { variant: "error" });
      return;
    }

    setLoading(true);

    try {
      await service.changePlanningRouteVehicle(
        route.route_id,
        selectDriver.id,
        selectVehicle.vehicle_id
      );
      await getRoutes(routeFilters);
      enqueueSnackbar("Başarıyla değiştirildi.", { variant: "success" });
      setLoading(false);
      onClose();
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Dialog hideBackdrop fullWidth maxWidth="md" open>
      <DialogTitle>Araç Değiştir</DialogTitle>
      <DialogContent dividers>
        {vehiclesLoading ? (
          <Stack spacing={1} alignItems="center" justifyContent="center">
            <CircularProgress size={30} />
            <Typography variant="body1" color="text.secondary">
              Araçlar çekiliyor...
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Autocomplete
              fullWidth
              options={vehicles}
              value={selectVehicle}
              onChange={(a, select) => {
                setSelectVehicle(select);
                setSelectDriver(null);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Araç"
                  placeholder="Araç Ara"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              value={selectDriver}
              options={selectVehicle?.drivers || []}
              getOptionLabel={(option) => option.name}
              onChange={(a, select) => setSelectDriver(select)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kurye"
                  placeholder="Kurye Ara"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ position: "relative", zIndex: 3 }}>
        <Button fullWidth variant="outlined" color="error" onClick={onClose}>
          Vazgeç
        </Button>
        <LoadingButton
          fullWidth
          color="primary"
          loading={loading}
          variant="outlined"
          onClick={onChangeVehicle}
        >
          Onayla
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
