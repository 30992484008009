type DndCardTypes = {
  ORDER: string;
  CARD: string;
  CHANGE_LIST_CARD: string;
};

export const DndTypes: DndCardTypes = {
  ORDER: "ORDER",
  CARD: "CONTAINER",
  CHANGE_LIST_CARD: "CHANGE_LIST_CARD",
};
