import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";

const TelInput = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <MuiTelInput
      fullWidth
      defaultCountry="TR"
      disableDropdown={true}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      onChange={(value) => {
        if (value.split(" ").join("").length < 16) {
          helpers.setValue(value.split(" ").join(""));
        } else {
          return;
        }
      }}
    />
  );
};

export default TelInput;
