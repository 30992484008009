import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./errorpage.css";

const ErrorPage = () => {
  const userdata = useSelector((state) => state.userReducer);
  const urlHistory = useHistory();
  const returnToHome = () => {
    if (userdata.user != "") {
      if (userdata.user.user_type == "ADMIN") {
        urlHistory.push("/customer-management");
      } else if (userdata.user.user_type == "SUPERADMIN") {
        urlHistory.push("/bringo-tenant/tenant-definition");
      } else {
        urlHistory.push("/bringo-customer/dashboard");
      }
    }
  };
  return (
    <div className="errorMain">
      <div className="errorOops">
        <span>Oops!</span>
      </div>
      <div className="errorPNF">
        <span>404 - Page Not Found1</span>
      </div>
      <div className="homePage" onClick={returnToHome}>
        GO TO HOMEPAGE
      </div>
    </div>
  );
};

ErrorPage.whyDidYouRender = false;

export default ErrorPage;
