import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MenuItem } from "@mui/material";
import { getStatus } from "helper";
import { tr } from "date-fns/locale";

const Filter = (props) => {
  const {
    filterHidden,
    // Option
    filterOption,
    filterOptions,
    filterOptionsOnChange,
    filterOptionsPlaceholder,
    // Date
    filterDate,
    filterDateDisable,
    filterDateOnChange,
    // Search
    filterSearch,
    filterSearchOnChange,
    filterSearchPlaceholder,
  } = props;

  if (filterHidden) {
    if (filterSearchOnChange) {
      return (
        <TextField
          fullWidth
          size="small"
          sx={{ mb: 2 }}
          label={filterSearchPlaceholder}
          value={filterSearch}
          onChange={(val) => filterSearchOnChange(val.target.value)}
        />
      );
    }
    return null;
  }

  return (
    <Grid container sx={{ mb: 2 }} spacing={2}>
      <Grid item xs={12} md={4} lg={2}>
        <TextField
          fullWidth
          select
          label={filterOptionsPlaceholder}
          value={filterOption}
          onChange={(e) => filterOptionsOnChange(e.target.value)}
          SelectProps={{
            MenuProps: {
              sx: { "& .MuiPaper-root": { maxHeight: 260 } },
            },
          }}
          sx={{
            textTransform: "capitalize",
          }}
        >
          {filterOptions.map((o) => (
            <MenuItem
              key={o}
              value={o}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: "body2",
                textTransform: "capitalize",
              }}
            >
              {getStatus(o)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {filterDate && !filterDateDisable && (
        <Grid item xs={12} md={8} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
            <DateRangePicker
              inputFormat="dd/MM/yyyy"
              disableMaskedInput
              startText="Başlangıç Tarihi"
              endText="Bitiş Tarihi"
              value={filterDate}
              onChange={filterDateOnChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField fullWidth {...startProps} />
                  <Box sx={{ mx: 1 }}></Box>
                  <TextField fullWidth {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={filterDate ? (filterDateDisable ? 8 : 12) : 8}
        lg={6}
      >
        <TextField
          fullWidth
          label={filterSearchPlaceholder}
          value={filterSearch}
          onChange={(val) => filterSearchOnChange(val.target.value)}
        />
      </Grid>
    </Grid>
  );
};

Filter.propTypes = {
  filterHidden: PropTypes.bool,
  // Option
  filterOption: PropTypes.string,
  filterOptions: PropTypes.array,
  filterOnChange: PropTypes.func,
  // Date
  filterDate: PropTypes.array,
  filterDateDisable: PropTypes.bool,
  filterDateOnChange: PropTypes.func,
  // Search
  filterSearch: PropTypes.string,
  filterSearchOnChange: PropTypes.func,
  filterSearchPlaceholder: PropTypes.string,
};

export default Filter;
