import * as React from "react";
import { Box, useTheme } from "@mui/material";
//
import AutoPlanningListItem from "../ListItem";
import { IAutoPlaningListItem } from "./types";

interface Props {
  data: IAutoPlaningListItem[];
  getData(): void;
  openDetail(item: IAutoPlaningListItem): void;
}

export default function AutoPlanningList(props: Props) {
  const { palette } = useTheme();
  const { data, getData, openDetail } = props;

  return (
    <Box
      sx={{
        p: 2,
        gap: 1,
        display: "flex",
        overflowY: "auto",
        flexDirection: "column",
        height: "calc(100vh - 486px)",
        backgroundColor: palette.background.default,
      }}
    >
      {data.map((item, index) => {
        return (
          <AutoPlanningListItem
            item={item}
            getData={getData}
            onEdit={() => openDetail(item)}
            key={`auto-plannig-item-${index}`}
          />
        );
      })}
    </Box>
  );
}
