import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import InputField from "components/MultiStepForm/InputField";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SwitchField from "components/MultiStepForm/SwitchField";
import SelectField from "components/MultiStepForm/SelectField";
import { Services } from "api/Services";
import { useSnackbar } from "notistack";
import UploadForm from "components/MultiStepForm/UploadForm";
import { BASE_URL } from "api/Env";
import OnlyLetterInput from "components/MultiStepForm/OnlyLetterInput";
import TelInput from "components/MultiStepForm/TelInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from "components/hook-form";
import Iconify from "components/Iconify";
import { fData } from "utils/formatNumber";
import { LoadingButton } from "@mui/lab";
import RHFPhoneInput from "components/hook-form/RHFPhoneInput";

const server = new Services();

CustomForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  getList: PropTypes.func,
  handleCloseDetail: PropTypes.func,
};

export default function CustomForm({
  isEdit,
  currentData,
  getList,
  handleCloseDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getUserContacts = async () => {
    try {
      const res = await server.getUserContacts();
      setUsers(res.entity.data);
    } catch (error) {}
  };

  const NewSchema = Yup.object().shape({
    logo: Yup.string().required("Bu alan zorunludur"),
    code: Yup.string().required("Bu alan zorunludur"),
  });

  console.log("currentData", currentData);

  const initialValues = useMemo(
    () => ({
      logo: currentData?.customer_logo_id
        ? `${BASE_URL}api/public/document/${currentData?.customer_logo_id}`
        : "",
      name: currentData?.name || "",
      code: currentData?.code || "",
      contact_user_oid: currentData?.contact?.id || "",
      is_follow: currentData?.is_follow || false,
      take_photo_on_delivery: currentData?.take_photo_on_delivery || false,
      use_barcode_on_delivery: currentData?.use_barcode_on_delivery || false,
      use_order_code_for_outlet:
        currentData?.use_order_code_for_outlet || false,
      use_receiver_info_for_outlet:
        currentData?.use_receiver_info_for_outlet || false,
      use_shipment_code_on_delivery:
        currentData?.use_shipment_code_on_delivery || false,
      enable_receiver_page_live_button:
        currentData?.enable_receiver_page_live_button || false,
      // Address
      addressCode: currentData?.address.code || "",
      addressText: currentData?.address.address || "",
      addressCity: currentData?.address.city || "",
      addressCounty: currentData?.address.county || "",
      addressFirstName: currentData?.address.first_name || "",
      addressLastName: currentData?.address.last_name || "",
      addressEmail: currentData?.address.email || "",
      addressGsmNumber: currentData?.address.gsm_number || "",
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    initialValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  React.useState(() => {
    getUserContacts();
  }, []);
  const values = watch();

  React.useEffect(() => {
    if (isEdit && currentData) {
      reset(initialValues);
    }
    if (!isEdit) {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentData]);

  const onSubmit = async (values) => {
    const sendData = {
      address: {
        address: values.addressText,
        address_code_ext: "",
        address_line: "",
        city: values.addressCity,
        code: values.addressCode,
        country3: "",
        county: values.addressCounty,
        district: "",
        email: values.addressEmail,
        first_name: values.addressFirstName,
        gsm_number: values.addressGsmNumber,
        last_name: values.addressLastName,
        latitude: 0,
        longitude: 0,
        post_code: "",
        state: "",
        street: "",
      },
      barcode: "",
      name: values.name,
      code: values.code,
      is_follow: values.is_follow,
      contact_user_oid: values.contact_user_oid,
      take_photo_on_delivery: values.take_photo_on_delivery,
      use_barcode_on_delivery: values.use_barcode_on_delivery,
      use_order_code_for_outlet: values.use_order_code_for_outlet,
      use_receiver_info_for_outlet: values.use_receiver_info_for_outlet,
      use_shipment_code_on_delivery: values.use_shipment_code_on_delivery,
      enable_receiver_page_live_button: values.enable_receiver_page_live_button,
    };

    if (isEdit) {
      sendData.id = currentData?.id;
    }

    setLoading(true);

    const isNewLogo = typeof values.logo === "object";

    try {
      if (isNewLogo) {
        console.log("values.logo", values.logo);
        const x = await server.uploadVehicleDocument(values.logo.file);
        const photoId = x.entity.data[0].id;
        sendData.customer_logo_id = photoId;
      }

      if (isEdit) {
        await server.updateCustomer(sendData);
      } else {
        await server.addCustomer(sendData);
      }
      enqueueSnackbar(`Başarıyla ${isEdit ? "güncellendi" : "kaydedildi"}!`, {
        variant: "success",
      });
      getList();
      handleCloseDetail();
    } catch (error) {
      enqueueSnackbar(
        "Bir sorun oluştu! Lütfen girilen alanları tekrar kontrol edin",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

  const filterUsers = users.map((i) => ({
    value: i.id,
    label: i.username,
  }));
  const onRemove = (name) => {
    values[name] = null;
    setValue(name, null);
  };
  const handleDrop = useCallback(
    (acceptedFiles, name) => {
      const file = acceptedFiles[0];

      if (!file.type.match(/image\/*/)) {
        enqueueSnackbar("Dosya türü geçerli değil", { variant: "error" });
        return;
      }

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  const uploadProfilePhotoHelpers = [
    "İzin verilen formatlar: *.jpeg, *.jpg, *.png",
    `Maks dosya boyutu: ${fData(3145728)}`,
  ];
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} gap={1}>
        <Grid item xs={12} sm={3.5}>
          <Grid container spacing={2} gap={1}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                boxShadow:
                  "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
              }}
            >
              <RHFUploadAvatar
                name="logo"
                accept="image/*"
                maxSize={3145728}
                onDrop={(e) => handleDrop(e, "logo")}
                onRemove={() => onRemove("logo")}
                helperText={
                  <Stack>
                    {uploadProfilePhotoHelpers.map((text) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        mt={2}
                        spacing={1}
                      >
                        <Iconify
                          icon="ic:info"
                          color="#FFD666"
                          sx={{ fontSize: 20 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            color: "text.secondary",
                          }}
                        >
                          {text}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                boxShadow:
                  "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
              }}
            >
              <Stack p={2}>
                <Box mb={1}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Yetkilendirme
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Proje görev tanımlarını oluşturmaktadır.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 2,
                  }}
                >
                  <RHFSwitch
                    label={
                      <Typography variant="body2">
                        Canlı takip butonu görünsün mü?
                      </Typography>
                    }
                    labelPlacement="end"
                    name="is_follow"
                  />
                  <RHFSwitch
                    label={
                      <Typography variant="body2">
                        Teslimatta fotoğraf çekilsin mi?{" "}
                      </Typography>
                    }
                    labelPlacement="end"
                    name="take_photo_on_delivery"
                  />
                  <RHFSwitch
                    label={
                      <Typography variant="body2">
                        Teslimatta barkod kullanılsın mı?
                      </Typography>
                    }
                    labelPlacement="end"
                    name="use_barcode_on_delivery"
                  />
                  {/* <RHFSwitch
                  label={
                    <Typography variant="body2">Depo sipariş kodu</Typography>
                  }
                  labelPlacement="end"
                  name="use_order_code_for_outlet"
                />
                <RHFSwitch
                  label={
                    <Typography variant="body2">
                      Outlet için alıcı bilgilerini kullan
                    </Typography>
                  }
                  labelPlacement="end"
                  name="use_receiver_info_for_outlet"
                /> */}
                  <RHFSwitch
                    label={
                      <Typography variant="body2">
                        Teslimatta gönderi kodunu kullan
                      </Typography>
                    }
                    labelPlacement="end"
                    name="use_shipment_code_on_delivery"
                  />
                  {/* <RHFSwitch
                  label={
                    <Typography variant="body2">
                      Alıcı ekranında canlı takibi aktifleştir
                    </Typography>
                  }
                  labelPlacement="end"
                  name="enable_receiver_page_live_button"
                /> */}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8.4}
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow:
              "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#637381",
              fontWeight: 700,
              paddingX: 4,
            }}
          >
            Proje Bilgileri
          </Typography>
          <Box
            sx={{
              flex: 1,
              display: "grid",
              rowGap: 4,
              columnGap: 4,
              gridTemplateColumns: "repeat(2, 1fr)",
              paddingX: 4,
              marginTop: 2,
            }}
          >
            <RHFTextField
              fullWidth
              size="small"
              name="name"
              label="Proje Adı"
              type="name"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="code"
              label="Proje Kısa Kodu"
              type="address"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="addressFirstName"
              label="Ad"
              type="name"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="addressLastName"
              label="Soyad"
              type="name"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="addressText"
              label="Adres"
              type="address"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="addressCode"
              label="Adres Kodu"
              type="number"
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#637381",
              fontWeight: 700,
              fontSize: 18,
              paddingX: 4,
              paddingY: 2,
            }}
          >
            Sorumlu Bilgileri
          </Typography>
          <Box sx={{ flex: 1, display: "grid", paddingX: 4, rowGap: 4 }}>
            <RHFSelect
              fullWidth
              size="small"
              label="Bringo Sorumlusu"
              name="contact_user_oid"
              SelectProps={{ native: false }}
            >
              {filterUsers.map((option, i) => (
                <MenuItem key={`${option.value}-${i}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "grid",
              padding: 4,
              rowGap: 4,
              columnGap: 4,
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <RHFTextField
              fullWidth
              size="small"
              name="addressCity"
              label="İl"
            />
            <RHFTextField
              fullWidth
              size="small"
              name="addressCounty"
              label="İlçe"
            />
            <RHFTextField
              fullWidth
              type="email"
              size="small"
              name="addressEmail"
              label="E-Posta"
            />
            <RHFPhoneInput
              size="small"
              name="addressGsmNumber"
              label="Telefon No"
            />
          </Box>
        </Grid>
      </Grid>
      <Box width={1} mt={2}>
        <Divider />
        <Stack
          pt={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              color="error"
              variant="outlined"
              onClick={handleCloseDetail}
            >
              Geri Dön
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isEdit ? "Proje Düzenle" : "Proje Oluştur"}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}
