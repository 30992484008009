import React from "react";
import PropTypes from "prop-types";
import MultiStepForm, { FormStep } from "components/MultiStepForm";
import * as Yup from "yup";
import InputField from "components/MultiStepForm/InputField";
import { Box, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SwitchField from "components/MultiStepForm/SwitchField";
import SelectField from "components/MultiStepForm/SelectField";
import { Services } from "api/Services";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import UploadForm from "components/MultiStepForm/UploadForm";
import { BASE_URL } from "api/Env";
import OnlyLetterInput from "components/MultiStepForm/OnlyLetterInput";
import TelInput from "components/MultiStepForm/TelInput";

const server = new Services();

CustomForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  getList: PropTypes.func,
  handleCloseDetail: PropTypes.func,
};

export default function CustomForm({
  isEdit,
  currentData,
  getList,
  handleCloseDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    logo: currentData?.customer_logo_id
      ? `${BASE_URL}api/public/document/${currentData?.customer_logo_id}`
      : "",
    name: currentData?.name || "",
    code: currentData?.code || "",
    contact_user_oid: currentData?.contact?.id || "",
    is_follow: currentData?.is_follow || false,
    take_photo_on_delivery: currentData?.take_photo_on_delivery || false,
    use_barcode_on_delivery: currentData?.use_barcode_on_delivery || false,
    use_order_code_for_outlet: currentData?.use_order_code_for_outlet || false,
    use_receiver_info_for_outlet:
      currentData?.use_receiver_info_for_outlet || false,
    use_shipment_code_on_delivery:
      currentData?.use_shipment_code_on_delivery || false,
    enable_receiver_page_live_button:
      currentData?.enable_receiver_page_live_button || false,
    // Address
    addressCode: currentData?.address.code || "",
    addressText: currentData?.address.address || "",
    addressCity: currentData?.address.city || "",
    addressCounty: currentData?.address.county || "",
    addressFirstName: currentData?.address.first_name || "",
    addressLastName: currentData?.address.last_name || "",
    addressEmail: currentData?.address.email || "",
    addressGsmNumber: currentData?.address.gsm_number || "",
  };

  const onSubmit = async (values) => {
    const sendData = {
      address: {
        address: values.addressText,
        address_code_ext: "",
        address_line: "",
        city: values.addressCity,
        code: values.addressCode,
        country3: "",
        county: values.addressCounty,
        district: "",
        email: values.addressEmail,
        first_name: values.addressFirstName,
        gsm_number: values.addressGsmNumber,
        last_name: values.addressLastName,
        latitude: 0,
        longitude: 0,
        post_code: "",
        state: "",
        street: "",
      },
      barcode: "",
      name: values.name,
      code: values.code,
      is_follow: values.is_follow,
      contact_user_oid: values.contact_user_oid,
      take_photo_on_delivery: values.take_photo_on_delivery,
      use_barcode_on_delivery: values.use_barcode_on_delivery,
      use_order_code_for_outlet: values.use_order_code_for_outlet,
      use_receiver_info_for_outlet: values.use_receiver_info_for_outlet,
      use_shipment_code_on_delivery: values.use_shipment_code_on_delivery,
      enable_receiver_page_live_button: values.enable_receiver_page_live_button,
    };

    if (isEdit) {
      sendData.id = currentData?.id;
    }

    setLoading(true);

    const isNewLogo = typeof values.logo === "object";

    try {
      if (isNewLogo) {
        const x = await server.uploadVehicleDocument(values.logo.file);
        const photoId = x.entity.data[0].id;
        sendData.customer_logo_id = photoId;
      }

      if (isEdit) {
        await server.updateTenant(sendData);
      } else {
        await server.addTenant(sendData);
      }
      enqueueSnackbar(`Başarıyla ${isEdit ? "güncellendi" : "kaydedildi"}!`, {
        variant: "success",
      });
      getList();
      handleCloseDetail();
    } catch (error) {
      enqueueSnackbar(
        "Bir sorun oluştu! Lütfen girilen alanları tekrar kontrol edin",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            logo: Yup.mixed().test(
              "required",
              "Resim alanı zorunlu",
              (value) => value !== ""
            ),
            name: Yup.string().required("Kiracı adı alanı zorunlu"),
            addressCode: Yup.string().required("Kiracı kodu alanı zorunlu"),
            addressText: Yup.string().required("Adress alanı zorunlu"),
            addressCity: Yup.string().required("İl alanı zorunlu"),
            addressCounty: Yup.string().required("İlçe alanı zorunlu"),
            addressGsmNumber: Yup.string().test(
              "required",
              "Telefon alanı zorunlu",
              (value) =>
                value &&
                value !== "+90" &&
                value !== "+9" &&
                value !== "+" &&
                value !== ""
            ),
          })}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <UploadForm
                    required={!isEdit}
                    formField={{ image: { name: "logo", label: "Logo" } }}
                    sx={{ width: 200, mb: 4 }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      flex: 1,
                      display: "grid",
                      rowGap: 2,
                      columnGap: 2,
                      gridTemplateColumns: {
                        xs: `repeat(2, 1fr)`,
                        lg: `repeat(2, 1fr)`,
                      },
                    }}
                  >
                    <InputField
                      required
                      fullWidth
                      size="small"
                      name="name"
                      label="Kiracı Adı"
                    />
                    <OnlyLetterInput
                      required
                      fullWidth
                      size="small"
                      name="addressCity"
                      label="İl"
                    />
                    <OnlyLetterInput
                      required
                      fullWidth
                      size="small"
                      name="addressCounty"
                      label="İlçe"
                    />
                    <InputField
                      required
                      fullWidth
                      size="small"
                      name="addressText"
                      label="Adres"
                    />
                    <InputField
                      required
                      fullWidth
                      size="small"
                      name="addressCode"
                      label="Adres Kodu"
                    />
                    <TelInput
                      required
                      size="small"
                      name="addressGsmNumber"
                      label="Telefon"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  Kaydet
                  {/* userData ? "Düzenle" : "Kaydet" */}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
