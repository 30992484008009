import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Stack, Typography } from "@mui/material";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { LoadingButton } from "@mui/lab";
import { Refresh, Search } from "@mui/icons-material";
import CustomSwitch from "components/CustomSwitch";

Filter.propTypes = {
  style: PropTypes.array,
};

export default function Filter(props) {
  const { options, setFilters, filters, setGetFiltersData } = props;

  const handleSwitchChange = (e) => {
    setFilters({ ...filters, active: e.target.checked });
  };

  const onChangeInput = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilterReset = () => {
    setFilters({
      branchId: "",
      companyId: "",
      name: "",
      active: "",
    });

    setGetFiltersData(true);
  };

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Filtre
      </Typography>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        {/* <CustomSelect
          label="Kullanıcı Firma"
          placeholder="Seçiniz"
          value={filters.companyId}
          data={options.companies}
          name="companyId"
          onChange={onChangeInput}
        /> */}
        <CustomSelect
          label="Bağlı Birim / Şube"
          placeholder="Seçiniz"
          value={filters.branchId}
          data={options.branches}
          name="branchId"
          onChange={onChangeInput}
        />
        <CustomInput
          label="Kurye Adı"
          placeholder="Kurye adı giriniz"
          name="name"
          value={filters.name}
          onChange={onChangeInput}
        />
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ mb: 1 }}>
            <CustomSwitch
              checked={filters.active === "" ? false : filters.active}
              onChange={handleSwitchChange}
              label={
                filters.active === ""
                  ? "Durum"
                  : filters.active
                  ? "Aktif"
                  : "Pasif"
              }
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: filters.active === "" ? "orange" : "",
                },
                "& .MuiSwitch-switchBase": {
                  color: filters.active === "" ? "#fff" : "",
                  marginLeft: filters.active === "" ? "10px" : "",
                },
              }}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              color="secondary"
              variant="contained"
              onClick={() => setGetFiltersData(true)}
              endIcon={<Search />}
              sx={{ py: "9px!important", minWidth: 100 }}
            >
              Filtrele
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleFilterReset}
            >
              <Refresh />
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
