import TYPES from "../types";

const initialState = [];

export const suggestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_SUGGESTION:
      return { ...state, data: action.payload.data };
    case TYPES.DELETE_LIST_SUGGESTION:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
