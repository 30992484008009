type Props =
  | "Kapalı Kasa Frigolu"
  | "Motorsiklet"
  | "Panelvan"
  | "Kapalı Kasa"
  | "Binek"
  | any;

export const VehicleTypeIcon = (type: Props) => {
  if (type === "Kapalı Kasa Frigolu") {
    return {
      icon: "/images/planner-new/vehicle-types/kapaliKasaFrigolu.svg",
      color: "warning",
    };
  }

  if (type === "Motorsiklet") {
    return {
      icon: "/images/planner-new/vehicle-types/motosiklet.svg",
      color: "success",
    };
  }
  if (type === "Panelvan") {
    return {
      icon: "/images/planner-new/vehicle-types/panelvan.svg",
      color: "success",
    };
  }
  if (type === "Kapalı Kasa") {
    return {
      icon: "/images/planner-new/vehicle-types/kapaliKasa.svg",
      color: "success",
    };
  }
  if (type === "Binek") {
    return {
      icon: "/images/planner-new/vehicle-types/binekArac.svg",
      color: "success",
    };
  }

  return {
    icon: "/images/planner-new/vehicle-types/diger.svg",
    color: "success",
  };
};
