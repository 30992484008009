import React from "react";
import { DialogTitle, IconButton, Dialog, DialogContent } from "@mui/material";
import Form from "./Form";
import { Close } from "@mui/icons-material";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, getList, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Detail({
  item,
  getList,
  handleCloseDetail,
  vehicleFilterOptions,
}) {
  const { isEdit, detail } = item;

  return (
    <Dialog open fullWidth maxWidth="md">
      <BootstrapDialogTitle onClose={handleCloseDetail}>
        Kurye Firması {isEdit ? "Düzenle" : "Oluştur"}
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Form
          isEdit={isEdit}
          currentData={detail}
          getList={getList}
          handleCloseDetail={handleCloseDetail}
          vehicleFilterOptions={vehicleFilterOptions}
        />
      </DialogContent>
    </Dialog>
  );
}
