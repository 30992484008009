import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Services } from "api/Services";
import { Form, Formik } from "formik";
import InputField from "components/MultiStepForm/InputField";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import SelectField from "components/MultiStepForm/SelectField";

const service = new Services();

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddWarehouse.propTypes = {
  firmaId: PropTypes.string,
  label: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function AddWarehouse(props) {
  const { firmaId, data, label, handleClose, getList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [firmaList, setFirmaList] = React.useState([]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const sendData = { ...values };

    if (data) {
      sendData.id = data.id;
    }

    try {
      await service
        .addWarehouse(sendData, data ? "post" : "put")
        .then(() => {
          enqueueSnackbar(`Başarıyla ${data ? "güncellendi" : "kaydedildi"}!`, {
            variant: "success",
          });
        })
        .catch((error) => {
          if (error?.code === "E0000") {
            enqueueSnackbar("Depo Zaten Mevcut!", {
              variant: "error",
            });
          } else {
            const msg = error?.message || "Bir sorun oluştu!";
            enqueueSnackbar(msg, { variant: "error" });
          }
        });

      setLoading(false);
      if (getList) {
        getList(); // Get user list
      }
      handleClose();
    } catch (error) {
      setLoading(false);
      const msg = error?.message || "Bir sorun oluştu!";
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const getFirmaList = async () => {
    try {
      const res = await service.getCustomerManagementFilters();
      setFirmaList(
        res.entity.data[0].customers.map((i) => ({
          ...i,
          value: i.key,
          label: i.value,
        }))
      );
    } catch (error) {}
  };

  React.useEffect(() => {
    getFirmaList();
  }, []);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <BootstrapDialogTitle onClose={handleClose}>{label}</BootstrapDialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            firm_id: data?.firm_id || firmaId || "",
            name: data?.name || "",
            code: data?.code || "",
            city: data?.city || "",
            county: data?.county || "",
            address: data?.address || "",
            post_code: data?.post_code || "",
            latitude: data?.latitude || "",
            longitude: data?.longitude || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            firm_id: Yup.string().required("Proje alanı zorunlu"),
            name: Yup.string().required("Depo İsmi alanı zorunlu"),
            code: Yup.string().required("Kod alanı zorunlu"),
            city: Yup.string().required("İl alanı zorunlu"),
            county: Yup.string().required("İlçe alanı zorunlu"),
            address: Yup.string().required("Adres alanı zorunlu"),
            post_code: Yup.string().required("Posta Kodu alanı zorunlu"),
            latitude: Yup.string().required("Enlem alanı zorunlu"),
            longitude: Yup.string().required("Boylam Kodu alanı zorunlu"),
          })}
        >
          {(formik) => (
            <Form>
              <Box
                sx={{
                  mb: 2,
                  display: "grid",
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: `repeat(2, 1fr)`,
                    // lg: `repeat(2, 1fr)`,
                  },
                }}
              >
                <SelectField
                  required
                  fullWidth
                  size="small"
                  label="Proje"
                  name="firm_id"
                  options={firmaList}
                  disabled={firmaId ? true : false}
                />
                <InputField
                  required
                  size="small"
                  label="Depo İsmi"
                  name="name"
                />
                <InputField required size="small" label="Kod" name="code" />
                <InputField
                  required
                  size="small"
                  label="İl"
                  name="city"
                  onlyLetter
                />
                <InputField
                  required
                  size="small"
                  label="İlçe"
                  name="county"
                  onlyLetter
                />

                <InputField
                  required
                  size="small"
                  label="Posta Kodu"
                  name="post_code"
                />
                <InputField
                  required
                  size="small"
                  label="Enlem"
                  name="latitude"
                  // type="number"
                />
                <InputField
                  required
                  size="small"
                  label="Boylam"
                  name="longitude"
                  // type="number"
                />
              </Box>
              <InputField
                fullWidth
                required
                size="small"
                label="Adres"
                name="address"
              />
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  {data ? "Düzenle" : "Kaydet"}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
