import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, Drawer } from "@mui/material";
// components
import Logo from "components/Logo";
import Scrollbar from "components/Scrollbar";
import { NavSectionVertical } from "components/nav-section";
//
import navConfig from "./NavConfig";
import NavbarDocs from "./NavbarDocs";
import NavbarAccount from "./NavbarAccount";
import { NAVBAR } from "../../config";
import { getLogoUrl } from "common/getLogoUrl";
// import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ links }) {
  const { user } = useSelector((s) => s.userReducer);
  const { pathname } = useLocation();

  const XX = links.map((link) => ({
    subheader: link.title,
    items: link.subLinks.map((sub) => ({
      title: sub.title,
      path: sub.path,
      icon: sub.icon,
    })),
  }));

  const renderContent = (
    <Box
      sx={{
        height: 1,
        overflow: "auto",
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
          borderRadius: 0.5,
          backgroundColor: "#FFFFFF",
        },

        "&::-webkit-scrollbar": {
          width: "6px",
          backgroundColor: "green",
        },

        "&::-webkit-scrollbar-thumb": {
          borderRadius: 0.5,
          boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: "#f2f2f2",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            component="img"
            src={getLogoUrl(user && user.tenant_logo_id)}
            sx={{ width: 120, objectFit: "contain" }}
          />
        </Stack>

        <NavbarAccount />
      </Stack>
      <NavSectionVertical navConfig={XX} />
      <Box sx={{ flexGrow: 1 }} />
      <NavbarDocs />
    </Box>
  );

  return (
    <RootStyle>
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            zIndex: 999,
            width: NAVBAR.DASHBOARD_WIDTH,
            borderRightStyle: "dashed",
            bgcolor: "background.default",
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
              }),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
