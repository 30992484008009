import moment from "moment";
import _ from "lodash";

const initialState = {
  start_date: moment().format("YYYY-MM-DD"),
  branches: [],
  vehicle_types: [],
  cities: [],
  vehicles: [],
  drivers: [],
  counties: [],
  customers: [],
  tenants: [],
  shipment_number: "",
  package_code: "",
  max_count: 4,
};

export const dashboardFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_FILTER_SET":
      return {
        ...state,
        ...action.payload,
      };
    case "DASHBOARD_FILTER_RESET":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
