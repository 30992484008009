import React from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";
//
import ListItem from "./Item";
import { Services } from "api/Services";
import OrderChangeList from "./OrderChangeList";
import { usePlanner } from "contexts/PlannerProvider";
import { OrderProps, RouteProps } from "contexts/PlannerProvider/types";

const server = new Services();

export interface Props {
  route: RouteProps;
  updateCount(count: number): void;
  onClickVehice(): void;
}

export default function RoutesListOrders(props: Props) {
  const planner = usePlanner();
  const { route, updateCount, onClickVehice } = props;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [visibleDragList, setVisibleDragList] = React.useState<boolean>(false);
  const [orders, setOrders] = React.useState<OrderProps[]>([]);

  const getOrders = React.useCallback(
    async (isDeleted?: boolean) => {
      setLoading(true);
      try {
        const res = await server.getDistributionDetailList({
          routeID: route.route_id,
        });
        setOrders(res.entity.data);
        updateCount(res.entity.data.length);
        setLoading(false);
        if (isDeleted) {
          planner.getOrders(planner.filters);
          planner.getRoutes(planner.routeFilters);
        }
      } catch (error) {}
    },
    [planner.filters, planner.routeFilters, route.route_id, updateCount]
  );

  React.useEffect(() => {
    getOrders();
  }, []);

  if (loading) {
    return (
      <Stack alignItems="center" spacing={1} sx={{ p: 1 }}>
        <CircularProgress size={20} />
        <Typography variant="caption">Siparişler getiriliyor...</Typography>
      </Stack>
    );
  }

  const planDaySequenceByOrders = _.orderBy(orders, "plan_day_sequence", [
    "asc",
  ]);

  const ordersCount = orders.length;

  return (
    <Box sx={{ p: 1 }}>
      {ordersCount > 0 && (
        <>
          <TableContainer>
            <Table size="small">
              <TableHead sx={{ th: { bgcolor: "grey.200" } }}>
                <TableRow>
                  <TableCell sx={{ boxShadow: "none!important" }}>
                    <Typography variant="caption" fontWeight="600">
                      Sıra
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" fontWeight="600">
                      Alıcı
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" fontWeight="600">
                      Durumu
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" fontWeight="600">
                      Paket
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ boxShadow: "none!important" }}>
                    <Typography variant="caption" fontWeight="600">
                      Atamayı Kaldır
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planDaySequenceByOrders.map((order, index) => {
                  return (
                    <ListItem
                      key={index}
                      order={order}
                      route={route}
                      handleDeleteOrder={() => getOrders(true)}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Button
              size="small"
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={onClickVehice}
            >
              Siparişleri Farklı Araç'a Ata
            </Button>
            <Button
              size="small"
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => setVisibleDragList(true)}
            >
              Siparişlerin Sırasını Değiştir
            </Button>
          </Stack>
        </>
      )}
      {visibleDragList && (
        <OrderChangeList
          route={route}
          getOrders={getOrders}
          orders={planDaySequenceByOrders}
          onClose={() => setVisibleDragList(false)}
        />
      )}
    </Box>
  );
}
