import * as React from "react";
import * as Icon from "iconsax-react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
//
import AutoPlanningList from "./List";
import { Services } from "api/Services";
import AutoPlanningCreate from "./Modal";
import AutoPlanningEmptyList from "./EmptyList";
import { IAutoPlaningListItem } from "./List/types";

const service = new Services();

interface Props {}

export default function AutoPlanning(props: Props) {
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [detail, setDetail] = React.useState<IAutoPlaningListItem | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<IAutoPlaningListItem[]>([]);

  const getData = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await service.automaticRuleGet();
      setData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  const RenderView = React.useCallback(() => {
    if (loading) {
      return (
        <Stack
          flex={1}
          spacing={2}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (data.length === 0) {
      return <AutoPlanningEmptyList onClick={() => setVisibleModal(true)} />;
    }

    return (
      <AutoPlanningList
        data={data}
        getData={getData}
        openDetail={(item) => {
          setDetail(item);
          setVisibleModal(true);
        }}
      />
    );
  }, [data, loading]);

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ px: 2, height: 44, bgcolor: "background.neutral" }}
      >
        <Typography flex={1} variant="subtitle2">
          Otomatik Planlama - Sipariş Kuralları
        </Typography>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={() => setVisibleModal(true)}
          startIcon={<Icon.AddCircle variant="Bulk" />}
        >
          Sipariş Kuralı Oluştur
        </Button>
      </Stack>
      <RenderView />
      {visibleModal && (
        <AutoPlanningCreate
          detail={detail}
          onClose={() => {
            setVisibleModal(false);
            getData();
            setDetail(null);
          }}
        />
      )}
    </>
  );
}
