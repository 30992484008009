import TYPES from "../types";

const initialState = {
  filtered: [],
};

export const projectApplicationDetailReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TYPES.PROJECT_APPLICATION_DETAIL:
      //return { ...state, data: action.payload.data }
      return { ...state, data: action.payload.data };
    case TYPES.PROJECT_APPLICATION_DETAIL_EVALUATION:
      return {
        ...state,
        data: state.data.map((content) =>
          content.id == action.payload.data[0].id
            ? {
                ...content,
                evaluation_result: action.payload.data[0].evaluation_result,
              }
            : content
        ),
      };
    case TYPES.PROJECT_APPLICATION_DETAIL_REVIEW:
      return { ...state, review: action.payload.data };
    case TYPES.FILTER_EVALUATION:
      let x;
      if (state.data) {
        if (typeof action.payload === "object") {
          x = state.data.filter(
            (dt) =>
              dt.evaluation_result == action.payload[0] ||
              dt.evaluation_result == action.payload[1]
          );
        } else {
          x = state.data.filter((dt) => dt.evaluation_result == action.payload);
        }
      }

      //let x = state.data.filter( dt => dt.evaluation_result == action.payload);
      return { ...state, filtered: action.payload == "All" ? state.data : x };

    default:
      return state;
  }
};
