import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
//import { Checkbox } from "@material-ui/core";
import TYPES from "../../store/types/index";
import { Button } from "primereact/button";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  FeatureGroup,
  Circle,
  Polygon,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
// import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import drawLocales from "leaflet-draw-locales";
import {
  branchDrawing,
  branchDrawingGet,
} from "../../store/actions/branchAction";

const BranchDrawing = (props) => {
  const dispatch = useDispatch();
  const { dialogBranchDrawing, setDialogBranchDrawing, product } = props;
  drawLocales("tr");

  // Customize locale language

  // get the drawing
  const getDrawing = useSelector((state) => state.branchDrawingReducer);

  const [drawing, setDrawing] = useState([]);
  const [lastCreateDraw, setLastCreateDraw] = useState(null);

  const hideDialogBranchDrawing = () => {
    setDialogBranchDrawing(false);
    // dispatch({
    //     type: TYPES.GET_BRANCH_DRAWING,
    //     payload: {data:[]}
    // });
  };

  //const saveBranchAssigment = () => {};

  // useEffect(() => {
  //     console.log("selectedBranch: ", selectedBranch)
  // }, [selectedBranch])

  const branchDrawingDialogFooter = (
    <React.Fragment>
      <Button
        label="Kaydet"
        icon="pi pi-check"
        //className="p-button-text"
        className="p-button-warning"
        onClick={(e) => saveBranchDrawing()}
      />
    </React.Fragment>
  );

  var pp = [];
  const _onCreate = (e) => {
    //e.layer._latlngs
    //console.log("E: ", e);

    let draw = e.layer._latlngs[0]
      .map((d) => {
        return [d.lat + " " + d.lng];
      })
      .toString();

    dispatch({
      type: TYPES.BRANCH_DRAWING,
      payload: `POLYGON((${draw}))`,
    });
    setLastCreateDraw(`POLYGON((${draw}))`);

    //pp.push(`POLYGON((${draw}))`);

    // dispatch(
    //     branchDrawing(product.id, [draw])
    // )
  };

  const _onDeleted = (e) => {
    //console.log("kk:", e.layers);
    let removeDraw = Object.keys(e.layers._layers).map(
      (d, i) => e.layers._layers[d]._latlngs[0]
    );
    //console.log(removeDraw);

    let removeArr = removeDraw.map((c) => {
      return c.map((d, i) => [d.lat + " " + d.lng]);
    });
    //console.log(removeArr);

    dispatch({
      type: TYPES.REMOVE_BRANCH_DRAWING,
      payload: removeArr,
    });
  };

  const _onEdited = (e) => {
    console.log("kk:", e.target.attributionControl._map._layers);
    let temp1 = e.target.attributionControl._map._layers;
    let editedDraw = Object.keys(temp1).map(function (key, index) {
      return typeof temp1[key]._latlngs == "object" && temp1[key]._latlngs[0];
    });
    let editedDrawFilter = editedDraw.filter((d) => d);

    let editArr = editedDrawFilter.map((c) => {
      return c.map((d, i) => [d.lat + " " + d.lng]);
    });

    dispatch({
      type: TYPES.EDIT_BRANCH_DRAWING,
      payload: editArr,
    });
  };

  const saveBranchDrawing = () => {
    if (typeof getDrawing.deleted != "string") {
      dispatch(branchDrawing(product.id, getDrawing.deleted));
    } else {
      dispatch(branchDrawing(product.id, getDrawing.data));
    }

    // dispatch({
    //     type: TYPES.BRANCH_DRAWING,
    //     payload: []
    // });

    setDialogBranchDrawing(false);
    setLastCreateDraw(null);
  };

  const purpleOptions = { color: "purple" };
  // const polygon = [
  //     {
  //         "lat": 41.25071925202102,
  //         "lng": 28.075561462094775
  //     },
  //     {
  //         "lat": 41.23178764651275,
  //         "lng": 28.073844885728004
  //     },
  //     {
  //         "lat": 41.23336549058601,
  //         "lng": 28.107413498382424
  //     },
  //     {
  //         "lat": 41.24641709677459,
  //         "lng": 28.101119381545256
  //     },
  //     {
  //         "lat": 41.24641709677459,
  //         "lng": 28.101119381545256
  //     }
  // ];

  // const polygon1 = [
  //     {
  //         "lat": 41.243061218471865,
  //         "lng": 28.028927413148246
  //     },
  //     {
  //         "lat": 41.22527505651551,
  //         "lng": 28.02759229936044
  //     },
  //     {
  //         "lat": 41.22541851310625,
  //         "lng": 28.05105217462662
  //     },
  //     {
  //         "lat": 41.245786090478646,
  //         "lng": 28.047809758421167
  //     }
  // ]

  // const pax = [
  //     [40.73407282392847, 35.77130555507214],
  //     [40.73349019021912, 35.77107906237274],
  //     [40.73354890552623, 35.77230689116506],
  //     [40.73402314215921, 35.77228900994707],
  //     [40.73442330501555, 35.772076240088424]
  // ]

  // var tt = [
  //     "40.73407282392847,35.77130555507214,40.73349019021912,35.77107906237274,40.73354890552623,35.77230689116506,40.73402314215921,35.77228900994707,40.73402314215921,35.77228900994707,40.73442330501555,35.772076240088424"
  // ]

  // var coordinates = tt.join(",")
  //     .split(',')
  //     .reduce((acc, curr, idx, arr) => {
  //         if (idx % 2 === 0) {
  //             acc.push({
  //                 lat: Number(curr),
  //                 lng: Number(arr[idx + 1])
  //             })
  //         }
  //         return acc;
  //     }, []);

  const drawFormat = (draw) => {
    let x = [draw]
      .join(",")
      .split(",")
      .reduce((acc, curr, idx, arr) => {
        if (idx % 2 === 0) {
          acc.push({
            lat: Number(curr),
            lng: Number(arr[idx + 1]),
          });
        }
        return acc;
      }, []);
    return x;
  };

  useEffect(() => {
    dispatch({
      type: TYPES.GET_BRANCH_DRAWING,
      payload: { data: [] },
    });

    dialogBranchDrawing == true &&
      product.id &&
      dispatch(branchDrawingGet(product.id));
  }, [dialogBranchDrawing]);

  return (
    <Dialog
      header="Şube Çizim"
      visible={dialogBranchDrawing}
      style={{ width: "1000px" }}
      onHide={hideDialogBranchDrawing}
      footer={branchDrawingDialogFooter}
    >
      <div
        className="confirmation-content"
        style={{ display: "flex", height: "500px" }}
      >
        <div style={{ width: "100%" }}>
          <MapContainer
            style={{ width: "100%", height: "500px", marginTop: "10px" }}
            center={
              product && Object.keys(product).length > 0 && product.address
                ? [product.address.latitude, product.address.longitude]
                : [0, 0]
            }
            zoom={13}
          >
            <FeatureGroup>
              <EditControl
                position="topright"
                onCreated={_onCreate}
                onDeleted={_onDeleted}
                onEdited={_onEdited}
                draw={{
                  rectangle: false,
                  marker: false,
                  polyline: false,
                  circlemarker: false,
                  circle: false,
                }}
                edit={{
                  edit: true,
                  remove: true,
                }}
              />
              {/* <Circle center={[51.51, -0.06]} radius={200} /> */}
              {/* <Polygon pathOptions={purpleOptions} positions={polygon} /> */}
              {/* <Polygon pathOptions={purpleOptions} positions={coordinates} /> */}

              {/* <Polygon pathOptions={purpleOptions} positions={polygon} /> */}
              {/* <Polygon pathOptions={purpleOptions} positions={polygon1} /> */}
              {getDrawing.data[0] &&
                getDrawing.data[0].length > 0 &&
                getDrawing.data.map((d, i) => {
                  return (
                    getDrawing.data[i] != lastCreateDraw && (
                      <Polygon
                        id={i}
                        positions={drawFormat(
                          d.replace(/[^0-9\.]+/g, ",").slice(1, -1)
                        )}
                      />
                    )
                  );
                })}

              {/* {
                                (getDrawing.drawing && getDrawing.drawing.length > 0) && getDrawing.drawing.map((d, i) => {
                                    return <Polygon id={i} pathOptions={purpleOptions} positions={getDrawing.drawing.map(d => drawFormat(d.replace(/[^0-9\.]+/g, ",").slice(1, -1)))} />
                                })
                            } */}
            </FeatureGroup>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker> */}
          </MapContainer>
        </div>
      </div>
    </Dialog>
  );
};

export default BranchDrawing;
