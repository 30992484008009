import * as React from "react";
import { useDrop } from "react-dnd";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import {
  Box,
  Chip,
  Badge,
  Stack,
  Avatar,
  styled,
  TableRow,
  Tooltip,
  TableCell,
  Typography,
  IconButton,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
//
import { BASE_URL } from "api/Env";
import Label from "components/Label";
import { Services } from "api/Services";
import RoutesListOrders from "../List/Orders";
import { usePlanner } from "contexts/PlannerProvider";
import { RouteProps } from "contexts/PlannerProvider/types";
import { DndTypes } from "screens/DispatcherPlannerNew/dragable";

const server = new Services();

//
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

interface RoutesDriverProps {
  route: RouteProps;
  onClickVehice(): void;
}

export default function RoutesDriver(props: RoutesDriverProps) {
  const { route, onClickVehice } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [changeOrdersLoading, setChangeOrdersLoading] =
    React.useState<boolean>(false);
  const {
    updateNewRoute,
    handleSelectRoute,
    deleteSelectRoute,
    selectRoutes,
    getOrders,
    getRoutes,
    routeFilters,
    filters,
    selectMapRouteOrders,
  } = usePlanner();

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setLoading(true);
      handleSelectRoute(route, route.route_color, () => {
        setLoading(false);
      });
    } else {
      deleteSelectRoute(route);
    }
  };

  const onChangeOrders = async (ids: string[]) => {
    setVisible(false);
    setChangeOrdersLoading(true);
    try {
      await server.planningRouteChangeOrders(route.route_id, ids);
      updateNewRoute({
        route,
        callback: async () => {
          await getOrders(filters);
          await getRoutes(routeFilters);
          setChangeOrdersLoading(false);
          setVisible(true);
          enqueueSnackbar("Siparişler başarıyla atandı.", {
            variant: "success",
          });
        },
      });
    } catch (error: any) {
      setChangeOrdersLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // Eğer kement seçiminde bir değişiklik olduysa ve detay açıksa kapatıyorum.
  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [selectMapRouteOrders]);

  // DRAGGABLE
  const [{ isOver }, drop] = useDrop({
    accept: [DndTypes.ORDER, DndTypes.CARD],
    drop: (item: any) => {
      onChangeOrders(item.cardsIDs);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const isSelectRoute = Boolean(
    selectRoutes.find((r) => r.route.route_id === route.route_id)
  );

  const isName = Boolean(route.driver?.first_name && route.driver?.last_name);
  const name = isName
    ? `${route.driver.first_name} ${route.driver.last_name}`
    : route.driver.username;

  return (
    <>
      <TableRow
        ref={drop}
        sx={{
          position: "relative",
          bgcolor: "background.default",
          borderBottomWidth: visible ? 0 : 1,
        }}
      >
        <TableCell align="left" sx={{ padding: "0!important" }}>
          <Tooltip title="Siparişleri Gör" placement="left">
            <IconButton
              onClick={() => {
                if (route.order_count === 0) {
                  enqueueSnackbar("Henüz gösterilecek hiç sipariş yok.", {
                    variant: "info",
                    autoHideDuration: 3000,
                  });
                } else {
                  setVisible(!visible);
                }
              }}
            >
              {visible ? (
                <Icon.EyeSlash variant="Bulk" />
              ) : (
                <Icon.Eye variant="Bulk" />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: "0!important" }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Box
              sx={{
                width: 5,
                height: 1,
                borderRadius: 1,
                bgcolor: route.route_color,
              }}
            />
            {loading ? (
              <CircularProgress size={14} color="info" />
            ) : (
              <Box
                type="checkbox"
                component="input"
                onChange={onChange}
                checked={isSelectRoute}
              />
            )}
            <Stack
              sx={{
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                size={55}
                value={75}
                thickness={3}
                variant="determinate"
                sx={{ bgcolor: "grey.400", borderRadius: 4 }}
              />
              <StyledBadge
                sx={{
                  inset: 0,
                  display: "flex",
                  position: "absolute",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="dot"
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Avatar
                  alt={route.driver.username}
                  sx={{ width: 50, height: 50, fontSize: 12 }}
                  src={`${BASE_URL}api/public/document/${route.driver.photo_id}`}
                />
              </StyledBadge>
            </Stack>
            <Stack width={120}>
              <Typography
                variant="subtitle2"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {route.driver.phoneNumber}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Tooltip title="Araç Değiştir" placement="top">
            <Stack
              spacing={1}
              onClick={() => {
                if (route.order_count > 0) {
                  onClickVehice();
                }
              }}
            >
              <Chip
                size="small"
                variant="soft"
                label={route.vehicle.name}
                icon={<Icon.Routing2 variant="Bulk" size={16} />}
                color={route.order_count > 0 ? "secondary" : "default"}
                sx={{
                  cursor: route.order_count > 0 ? "pointer" : "not-allowed",
                }}
              />
              <Chip
                size="small"
                variant="soft"
                color="warning"
                label={route.vehicle.vehicle_type}
                icon={<Icon.Car variant="Bulk" size={16} />}
              />
            </Stack>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {(route.distance / 1000).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Label variant="ghost" color="primary">
            {route.order_count}
          </Label>
        </TableCell>
        <TableCell>
          <Label variant="ghost" color="info">
            {route.package_count}
          </Label>
        </TableCell>
        <TableCell sx={{ paddingRight: "0!important" }}>
          <Label variant="ghost" color="warning">
            {route.total_deci}
          </Label>
        </TableCell>

        {(isOver || changeOrdersLoading) && (
          <Stack
            spacing={1}
            direction="row"
            sx={{
              inset: 0,
              zIndex: 2,
              borderRadius: 1,
              mt: "0!important",
              textAlign: "center",
              bgcolor: `grey.300`,
              border: "1px dashed",
              position: "absolute",
              alignItems: "center",
              borderTopLeftRadius: 0,
              borderColor: `grey.400`,
              justifyContent: "center",
              borderBottomLeftRadius: 0,
            }}
          >
            {changeOrdersLoading ? (
              <Stack spacing={0.5}>
                <Typography variant="caption" color="text.secondary">
                  Siparişler atanıyor...
                </Typography>
                <LinearProgress variant="indeterminate" color="secondary" />
              </Stack>
            ) : (
              <>
                <Icon.BoxAdd size={20} variant="Bulk" />
                <Typography variant="caption" sx={{ opacity: 0.5 }}>
                  {route.driver.first_name} {route.driver.last_name}
                </Typography>
              </>
            )}
          </Stack>
        )}
      </TableRow>
      {visible && (
        <TableRow>
          <TableCell colSpan={6} sx={{ padding: "0!important" }}>
            <RoutesListOrders
              route={route}
              updateCount={async () => {
                if (isSelectRoute) {
                  await deleteSelectRoute(route);
                  setLoading(true);
                  handleSelectRoute(route, route.route_color, () => {
                    setLoading(false);
                  });
                }
              }}
              onClickVehice={onClickVehice}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
