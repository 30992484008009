import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import {
  CalendarMonth,
  DriveEta,
  Person,
  Phone,
  Route,
} from "@mui/icons-material";
import { TextFirstLetter } from "utils";
import moment from "moment";

PlanBox.propTypes = {
  data: PropTypes.object,
  rut: PropTypes.any,
};

export default function PlanBox({ data, rut = 0 }) {
  if (!data) {
    return (
      <Alert severity="info">
        <AlertTitle>Planlanmadı</AlertTitle>
        <Typography variant="body2">Sipariş henüz planlanmamıştır.</Typography>
      </Alert>
    );
  }

  const fullName = TextFirstLetter(
    data.driver.first_name + " " + data.driver.last_name
  );

  return (
    <Alert severity="success">
      <AlertTitle>Planlandı</AlertTitle>
      <Stack direction="row" flexWrap="wrap" alignItems="flext-start">
        <Stack direction="row" alignItems="center">
          <Person fontSize="small" />
          <Typography variant="subtitle2">&nbsp;Kurye:&nbsp;</Typography>
          <Typography variant="body2">{fullName}&nbsp;&nbsp;&nbsp;</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <DriveEta fontSize="small" />
          <Typography variant="subtitle2">&nbsp;Araç:&nbsp;</Typography>
          <Typography variant="body2">
            {data.vehicle.name}&nbsp;&nbsp;&nbsp;
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Phone fontSize="small" />
          <Typography variant="subtitle2">&nbsp;Telefon:&nbsp;</Typography>
          <Typography variant="body2">
            {data.driver.username}&nbsp;&nbsp;&nbsp;
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <CalendarMonth fontSize="small" />
          <Typography variant="subtitle2">&nbsp;Tarih:&nbsp;</Typography>
          <Typography variant="body2">
            {moment(data.date).add(3, "hours").format("DD.MM.YYYY HH:mm")}
            &nbsp;&nbsp;&nbsp;
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Route fontSize="small" />
          <Typography variant="subtitle2">&nbsp;Rut Sırası:&nbsp;</Typography>
          <Typography variant="body2">{rut}</Typography>
        </Stack>
      </Stack>
    </Alert>
  );
}
