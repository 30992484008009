import React from "react";
import * as Icon from "iconsax-react";
import { Box, Menu, MenuItem, Stack, useTheme } from "@mui/material";
//
import MapNightStyle from "../../../assets/map-style.json";

const IconButton = ({ src, onClick }: { src: string; onClick(): void }) => {
  return (
    <Box sx={{ width: 24, height: 24, cursor: "pointer" }} onClick={onClick}>
      <Box
        src={src}
        component="img"
        sx={{ width: 1, height: 1, objectFit: "contain" }}
      />
    </Box>
  );
};

export interface Props {
  map: google.maps.Map | null;
}

export default function DispatcherNewMapControls(props: Props) {
  const { map } = props;
  const { palette } = useTheme();
  const [zoom, setZoom] = React.useState<number>(7);
  const [mapNight, setMapNight] = React.useState<boolean>(
    localStorage.getItem("mapIsDark") === "1"
  );
  const [mapType, setMapType] = React.useState(
    localStorage.getItem("mapType") || google.maps.MapTypeId.ROADMAP
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (!map) {
      return;
    }

    google.maps.event.addListener(map, "zoom_changed", function () {
      setZoom(map.getZoom() as any);
    });
  }, [map]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    map.setZoom(zoom);
  }, [map, zoom]);

  React.useEffect(() => {
    if (!map) {
      return;
    }
    map.setOptions({ styles: mapNight ? MapNightStyle : [] });
  }, [map, mapNight]);

  React.useEffect(() => {
    if (!map) {
      return;
    }
    if (mapType) {
      localStorage.setItem("mapType", mapType);
    }
    map.setOptions({ mapTypeId: mapType });
  }, [map, mapType]);

  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        top: 84,
        py: 1.25,
        right: 20,
        zIndex: 2,
        width: 44,
        bgcolor: "grey.0",
        borderRadius: 1.2,
        position: "absolute",
      }}
    >
      <IconButton
        src="/images/planner-new/map/zoom-in.svg"
        onClick={() => {
          setZoom((z) => z + 1);
        }}
      />
      <IconButton
        src="/images/planner-new/map/zoom-out.svg"
        onClick={() => {
          if (zoom !== 0) {
            setZoom((z) => z - 1);
          }
        }}
      />
      <IconButton
        src="/images/planner-new/map/global.svg"
        onClick={handleClick as any}
      />
      <IconButton
        src="/images/planner-new/map/dark-mode.svg"
        onClick={() => {
          setMapNight((s) => {
            const newNight = !s;
            localStorage.setItem("mapIsDark", newNight ? "1" : "0");
            return newNight;
          });
        }}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setMapType(google.maps.MapTypeId.HYBRID)}>
          <Icon.Map
            variant="Bulk"
            style={{ marginRight: 10 }}
            color={
              mapType === google.maps.MapTypeId.HYBRID
                ? palette.primary.main
                : palette.grey[600]
            }
          />
          HİBRİT
        </MenuItem>
        <MenuItem onClick={() => setMapType(google.maps.MapTypeId.ROADMAP)}>
          <Icon.Map
            variant="Bulk"
            style={{ marginRight: 10 }}
            color={
              mapType === google.maps.MapTypeId.ROADMAP
                ? palette.primary.main
                : palette.grey[600]
            }
          />
          YOL HARİTASI
        </MenuItem>
        <MenuItem onClick={() => setMapType(google.maps.MapTypeId.SATELLITE)}>
          <Icon.Map
            variant="Bulk"
            style={{ marginRight: 10 }}
            color={
              mapType === google.maps.MapTypeId.SATELLITE
                ? palette.primary.main
                : palette.grey[600]
            }
          />
          UYDU
        </MenuItem>
        <MenuItem onClick={() => setMapType(google.maps.MapTypeId.TERRAIN)}>
          <Icon.Map
            variant="Bulk"
            style={{ marginRight: 10 }}
            color={
              mapType === google.maps.MapTypeId.TERRAIN
                ? palette.primary.main
                : palette.grey[600]
            }
          />
          ARAZİ
        </MenuItem>
      </Menu>
    </Stack>
  );
}
