import * as React from "react";
import * as Icon from "iconsax-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Collapse,
  useTheme,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Switch,
  styled,
  SwitchProps,
  Box,
} from "@mui/material";
//
import { usePlanner } from "contexts/PlannerProvider";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const permissionsRadio = [
  {
    value: "izin",
    label: "Mevcut çözümdeki tüm değişikliklere izin ver",
  },
  {
    value: "plan",
    label:
      "Planlanan rotaları değiştirmeden tutun, kullanılmayan sürücülere yeni siparişler ekleyin",
  },
  {
    value: "plan-surucu",
    label: "Sürücüleri planlı siparişler için tutun, yeni siparişlere uyun",
  },
];
const balancingsRadio = [
  {
    value: "dengeleme-yok",
    label: "Dengeleme Yok",
  },
  {
    value: "rotalari-dengele",
    label: "Rotaları Dengeleyin",
  },
  {
    value: "rotalari-dengele-2",
    label: "Rotaları Dengeleyin ve Tüm Sürücüleri Kullanın",
  },
];

interface AllOrderPlannedProps {
  onClose(): void;
}

export default function AllOrderPlanned(props: AllOrderPlannedProps) {
  const { onClose } = props;
  const { palette } = useTheme();
  const { selectRoutes } = usePlanner();
  const [state, setState] = React.useState({
    permission: "izin",
    permissionOpen: false,
    //
    balancing: "dengeleme-yok",
    balancingOpen: false,
  });

  const PermissionArrowIcon = state.permissionOpen
    ? Icon.ArrowUp2
    : Icon.ArrowDown2;
  const BalancingArrowIcon = state.balancingOpen
    ? Icon.ArrowUp2
    : Icon.ArrowDown2;

  const permissionActiveTitle =
    permissionsRadio.find((r) => r.value === state.permission)?.label || "";
  const balancingActiveTitle =
    balancingsRadio.find((r) => r.value === state.balancing)?.label || "";

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle variant="h4" mb={2}>
        Otomatik Planlama
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack
            sx={{
              p: 1.5,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "background.neutral",
            }}
          >
            <Stack
              spacing={0.5}
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setState({ ...state, permissionOpen: !state.permissionOpen });
              }}
            >
              <PermissionArrowIcon
                size={20}
                variant="Bold"
                color={palette.text.secondary}
              />
              <Typography variant="subtitle2">
                İzin verilen değişiklikler
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                ({permissionActiveTitle})
              </Typography>
            </Stack>
            <Collapse
              unmountOnExit
              timeout="auto"
              sx={{ pl: 0.5 }}
              in={state.permissionOpen}
            >
              <RadioGroup
                value={state.permission}
                onChange={(a, permission) => setState({ ...state, permission })}
              >
                {permissionsRadio.map((radio) => {
                  return (
                    <FormControlLabel
                      key={radio.value}
                      value={radio.value}
                      label={radio.label}
                      control={<Radio size="small" color="secondary" />}
                    />
                  );
                })}
              </RadioGroup>
            </Collapse>
          </Stack>
          <Stack
            sx={{
              p: 1.5,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "background.neutral",
            }}
          >
            <Stack
              spacing={0.5}
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setState({ ...state, balancingOpen: !state.balancingOpen });
              }}
            >
              <BalancingArrowIcon
                size={20}
                variant="Bold"
                color={palette.text.secondary}
              />
              <Typography variant="subtitle2">Dengeleme</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                ({balancingActiveTitle})
              </Typography>
            </Stack>
            <Collapse
              unmountOnExit
              timeout="auto"
              sx={{ pl: 0.5 }}
              in={state.balancingOpen}
            >
              <RadioGroup
                value={state.balancing}
                onChange={(a, balancing) => setState({ ...state, balancing })}
              >
                {balancingsRadio.map((radio) => {
                  return (
                    <FormControlLabel
                      key={radio.value}
                      value={radio.value}
                      label={radio.label}
                      control={<Radio size="small" color="secondary" />}
                    />
                  );
                })}
              </RadioGroup>
            </Collapse>
          </Stack>
          <Box
            sx={{
              py: 1,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "background.neutral",
            }}
          >
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={40} />
                  <TableCell>Sürücü</TableCell>
                  <TableCell>Araç</TableCell>
                  <TableCell>Sipariş</TableCell>
                  <TableCell>Paket</TableCell>
                  <TableCell>Mesafe</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectRoutes.map((route) => {
                  const newRoute = route.route;
                  return (
                    <TableRow key={newRoute.route_id}>
                      <TableCell>
                        <IOSSwitch defaultChecked />
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {newRoute.driver.first_name}{" "}
                          {newRoute.driver.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {newRoute.vehicle.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {newRoute.order_count}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {newRoute.package_count}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {newRoute.distance}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={onClose}>
          Vazgeç
        </Button>
        <Button color="secondary" variant="contained">
          Planla
        </Button>
      </DialogActions>
    </Dialog>
  );
}
