import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import Tabs from "./Tabs";
import Filter from "./Filter";
import TableHead from "./TableHead";
import TableNoData from "./TableNoData";
import TableLoading from "./TableLoading";

const boxSx = {
  // overflow: "hidden",
  borderRadius: "16px",
  boxShadow:
    "0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)",
};
const box2Sx = {
  backgroundColor: "#ffffff",
  padding: "20px 20px 0 20px",
};

const CustomTable = (props) => {
  const {
    loading,
    dense,
    onChangeDense,
    // Tabs
    tabs,
    tabsActive,
    tabsOnChange,
    // Filter
    filterHidden,
    filterOption,
    filterOptions,
    filterOptionsOnChange,
    filterOptionsPlaceholder,
    filterDate,
    filterDateDisable,
    filterDateOnChange,
    filterSearch,
    filterSearchOnChange,
    filterSearchPlaceholder,
    // Table
    // selected
    rowCount,
    totalCount,
    numSelected,
    onSelectAllRows,
    //
    tableData,
    tableHeadData,
    tableBodyRender,
    tableFooterRender,
    tableDataFiltered,
    tableHeadDataOrder,
    tableHeadDataOrderBy,
    // UseTable
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    // container
    containerChildren,
    // style
    style,
    style2,
  } = props;

  // is empty
  if (!tableData) {
    return null;
  }

  const isNotFound = !tableDataFiltered.length;

  return (
    <Box sx={{ ...boxSx, ...style }}>
      <Tabs
        tabs={tabs}
        tableData={tableData}
        tabsActive={tabsActive}
        tabsOnChange={tabsOnChange}
      />
      <Box sx={{ ...box2Sx, ...style2 }}>
        <Filter
          filterHidden={filterHidden}
          filterOption={filterOption}
          filterOptions={filterOptions}
          filterOptionsPlaceholder={filterOptionsPlaceholder}
          filterOptionsOnChange={filterOptionsOnChange}
          filterDate={filterDate}
          filterDateDisable={filterDateDisable}
          filterSearchPlaceholder={filterSearchPlaceholder}
          filterDateOnChange={(newDate) => {
            // date control for crash problem
            if (newDate.filter((i) => i).length == 2) {
              filterDateOnChange(newDate);
            }
          }}
          filterSearch={filterSearch}
          filterSearchOnChange={filterSearchOnChange}
        />
        <TableContainer sx={{ position: "relative" }}>
          {containerChildren}
          <Table size={dense ? "medium" : "small"}>
            <TableHead
              rowCount={rowCount}
              numSelected={numSelected}
              onSelectAllRows={onSelectAllRows}
              data={tableHeadData}
              order={tableHeadDataOrder}
              orderBy={tableHeadDataOrderBy}
            />
            <TableBody>
              {loading ? (
                <TableLoading colSpan={tableHeadData.length} />
              ) : (
                <>
                  {tableBodyRender}
                  <TableNoData
                    colSpan={tableHeadData.length}
                    isNotFound={isNotFound}
                  />
                </>
              )}
            </TableBody>
            <TableFooter>{tableFooterRender}</TableFooter>
          </Table>
        </TableContainer>
      </Box>
      <Divider />
      <Stack
        bottom={0}
        direction="row"
        position="sticky"
        alignItems="center"
        bgcolor="background.default"
      >
        {onChangeDense && (
          <Stack sx={{ px: 2 }} direction="row" alignItems="center">
            <Switch checked={dense} onChange={onChangeDense} />
            <Typography variant="body1">
              {dense ? "Daralt" : "Genişlet"}
            </Typography>
          </Stack>
        )}
        <Box flex={1}>
          <TablePagination
            size={dense ? "small" : "medium"}
            rowsPerPageOptions={[5, 10, 25, 35, 50, 100]}
            component="div"
            count={totalCount ? totalCount : tableData.length}
            page={filterSearch === "" ? page : 0}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage="Satır sayısı:"
            sx={{ "& p": { margin: "0!important" } }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

Filter.defaultProps = {
  filterSearchPlaceholder: "Lütfen aramak istediğiniz sefer numarasını giriniz",
  filterOptionsPlaceholder:
    "Lütfen aramak istediğiniz sefer numarasını giriniz",
};

CustomTable.propTypes = {
  loading: PropTypes.bool,
  // tabs
  tabs: PropTypes.array,
  tabsActive: PropTypes.string,
  tabsOnChange: PropTypes.func,
  // Filter
  filterHidden: PropTypes.bool,
  filterOptions: PropTypes.array,
  filterOption: PropTypes.string,
  filterOptionsOnChange: PropTypes.func,
  filterOptionsPlaceholder: PropTypes.string,
  filterDate: PropTypes.array,
  filterDateDisable: PropTypes.bool,
  filterDateOnChange: PropTypes.func,
  filterSearch: PropTypes.string,
  filterSearchOnChange: PropTypes.func,
  filterSearchPlaceholder: PropTypes.string,
  // Table
  tableData: PropTypes.array,
  tableHeadData: PropTypes.array,
  tableBodyRender: PropTypes.any,
  tableFooterRender: PropTypes.any,
  tableDataFiltered: PropTypes.array,
  tableHeadDataOrder: PropTypes.string,
  tableHeadDataOrderBy: PropTypes.string,
  // UseTable
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  // container
  containerChildren: PropTypes.any,
};

export default CustomTable;
