import * as React from "react";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
//
import Label from "components/Label";
import { Services } from "api/Services";
import SelectRouteOrdersBoxItem from "./Item";
import { usePlanner } from "contexts/PlannerProvider";
import { RouteProps } from "contexts/PlannerProvider/types";

const services = new Services();

interface Props {}

export default function SelectRouteOrdersBox(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    routes,
    filters,
    selectRoutes,
    routeFilters,
    getOrders,
    getRoutes,
    handleSelectRoutes,
    selectMapRouteOrders,
    setSelectMapRouteOrders,
  } = usePlanner();
  const [selectRoute, setSelectRoute] = React.useState<RouteProps | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const groupArr = _.groupBy(selectMapRouteOrders, "routeId");

  const onClear = () => {
    setSelectMapRouteOrders([]);
  };

  const handleRemoveOrders = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Emin misiniz?")) {
      return;
    }

    setLoading(true);

    try {
      for (const group of Object.entries(groupArr)) {
        const sendData = {
          order_ids: group[1].map((o) => o.id),
          route_id: group[0],
        };
        await services.deletePlanningRouteOrder(sendData);
      }
      setLoading(false);

      // Rotaları yeniden seçtir
      handleSelectRoutes(
        selectRoutes.map((r) => r.route.route_id),
        () => {}
      );
      enqueueSnackbar("Başarıyla kaldırıldı.", { variant: "success" });
      // Siparişleri kement seçiminden kaldır.
      setSelectMapRouteOrders([]);
      //
      await getOrders(filters);
      await getRoutes(routeFilters);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      for (const group of Object.entries(groupArr)) {
        const oldRouteId = group[0];
        const oldRouteOrderIds = group[1].map((p) => p.id);
        await services.getPlanningCourierToCourier(
          oldRouteId,
          selectRoute?.route_id,
          oldRouteOrderIds
        );
        enqueueSnackbar("Siparişler başarıyla atandı.", {
          variant: "success",
        });
      }

      setLoading(false);
      setSelectRoute(null);

      // Siparişleri kement seçiminden kaldır.
      setSelectMapRouteOrders([]);
      // Rotaları Yeniden seçtir
      handleSelectRoutes(
        selectRoutes.map((r) => r.route.route_id),
        async () => {
          await getOrders(filters);
          await getRoutes(routeFilters);
        }
      );
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const newRoutes = routes.map((item, index: number) => {
    return {
      ...item,
      index,
      name: `${index} - ${moment(item.date).format("DD.MM.YYYY HH:mm")} - ${
        item.name
      } - ${item.driver.first_name} ${item.driver.last_name}`,
    };
  });

  const uniqByRoutes = _.sortBy(
    newRoutes.map((r) => ({ ...r, sortDate: moment(r.date).unix() })),
    ["index", "sortDate"]
  );

  const sortByPlanNoOrders = _.sortBy(selectMapRouteOrders, [
    "route_color",
    "plan_day_sequence",
  ]);

  return (
    <Stack
      spacing={1}
      sx={{
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        position: "absolute",
        justifyContent: "space-between",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          p: 2,
          pt: 0,
          overflow: "hidden",
          borderBottomLeftRadius: 8,
          bgcolor: "background.default",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            top: 0,
            zIndex: 3,
            position: "sticky",

            bgcolor: "background.default",
          }}
        >
          <Typography variant="subtitle1" flex={1}>
            Seçilen Rota Siparişleri ({sortByPlanNoOrders.length})
          </Typography>
          <IconButton onClick={onClear}>
            <Close />
          </IconButton>
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableHead sx={{ position: "sticky", top: 0, zIndex: 2 }}>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" whiteSpace="nowrap">
                    Seçim
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" whiteSpace="nowrap">
                    Sıra
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" whiteSpace="nowrap">
                    Rota Adı
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" whiteSpace="nowrap">
                    Sipariş Kodu
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" whiteSpace="nowrap">
                    Sil
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortByPlanNoOrders.map((order) => {
                return (
                  <SelectRouteOrdersBoxItem key={order.id} order={order} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack spacing={2}>
          <LoadingButton
            fullWidth
            color="error"
            loading={loading}
            variant="contained"
            onClick={handleRemoveOrders}
          >
            Tüm Seçilenlerin Atamasını İptal Et
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        bgcolor="background.default"
        sx={{
          p: 2,
          bottom: 0,
          position: "sticky",
          borderTopLeftRadius: 8,
        }}
      >
        <Typography variant="subtitle1" pb={1}>
          Seçilen Siparişleri Ata
        </Typography>
        <Autocomplete
          fullWidth
          value={selectRoute}
          options={uniqByRoutes}
          getOptionLabel={(option) => option.name}
          onChange={(a, selectRoutes) => {
            setSelectRoute(selectRoutes);
          }}
          renderOption={(props, option: any, { selected }) => {
            return (
              // @ts-ignore
              <Stack
                {...props}
                spacing={1}
                sx={{ p: 1 }}
                direction="row"
                alignItems="center"
              >
                <Label>{option.index}</Label>
                <Typography variant="caption">{option.name}</Typography>
              </Stack>
            );
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rota ve Kurye"
              placeholder="Rota ve Kurye Seçiniz"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          disabled={!selectRoute}
        >
          Siparişleri Ata
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
