import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import withScrolling from "react-dnd-scrolling";
//
import RoutesDriver from "./ListItem";
import { RouteProps } from "contexts/PlannerProvider/types";
import ChangeVehicleModal from "../List/ChangeVehicleModal";

const ScrollingComponent = withScrolling("div");

interface RoutesDriversProps {
  detailRoutes: RouteProps[];
}

export default function RoutesDrivers(props: RoutesDriversProps) {
  const { palette } = useTheme();
  const { detailRoutes } = props;
  const [route, setRoute] = React.useState<RouteProps | null>(null);

  const closeModal = React.useCallback(() => {
    setRoute(null);
  }, []);

  const openModal = React.useCallback((route: RouteProps) => {
    setRoute(route);
  }, []);

  return (
    <>
      <ScrollingComponent
        style={{
          gap: 12,
          padding: 12,
          display: "flex",
          overflowY: "auto",
          flexDirection: "column",
          height: "calc(100vh - 486px)",
          backgroundColor: palette.background.default,
        }}
      >
        <TableContainer>
          <Table size="small">
            <TableHead sx={{ th: { bgcolor: "grey.200" } }}>
              <TableRow>
                <TableCell />
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Sürücü
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Araç
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    KM
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Sipariş
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Paket
                  </Typography>
                </TableCell>
                <TableCell sx={{ boxShadow: "none!important" }}>
                  <Typography variant="caption" fontWeight="600">
                    Desi
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailRoutes.map((route, index: number) => {
                const lastItem = detailRoutes.length - 1 === index;
                return (
                  <React.Fragment key={route.route_id}>
                    <RoutesDriver
                      route={route}
                      onClickVehice={() => openModal(route)}
                    />
                    {!lastItem && (
                      <TableRow
                        sx={{ display: "block", height: "4px!important" }}
                      >
                        <TableCell />
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollingComponent>
      {route && <ChangeVehicleModal onClose={closeModal} route={route} />}
    </>
  );
}
