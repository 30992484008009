import { GridLocaleText } from "@mui/x-data-grid";

export default {
  toolbarDensity: "Boyut",
  toolbarDensityLabel: "Boyut",
  toolbarDensityCompact: "Küçük",
  toolbarDensityStandard: "Orta",
  toolbarDensityComfortable: "Büyük",
  columnMenuHideColumn: "Alanı Gizle",
  columnMenuManageColumns: "Alanları Yönet",
  columnMenuUnsort: "Sıralama",
  columnMenuSortAsc: "Artan Sıralama",
  columnMenuSortDesc: "Azalan Sıralama",
  columnMenuFilter: "Filtrele",
  filterPanelOperator: "Operatör",
  filterValueAny: "Değer",
  filterPanelColumns: "Alan",
  filterOperatorContains: "İçerir",
  filterOperatorEquals: "Eşittir",
  filterOperatorStartsWith: "İle Başlar",
  filterOperatorEndsWith: "İle Biter",
  filterOperatorIsEmpty: "Boş",
  filterOperatorIsNotEmpty: "Boş Değil",
  filterOperatorIsAnyOf: "Herhangi Biri",
  columnHeaderSortIconLabel: "Sırala",
  columnsPanelShowAllButton: "Tümünü Göster",
  columnsPanelHideAllButton: "Tümünü Gizle",
  toolbarFilters: "Filtrele",
  toolbarExport: "Dışarı Aktar",
  toolbarExportCSV: "CSV olarak indir",
  toolbarExportPrint: "Yazdır",
  toolbarColumns: "Sütunlar",
  filterPanelInputLabel: "Değer",
  filterPanelInputPlaceholder: "Aranacak kelimeyi giriniz.",
  toolbarQuickFilterPlaceholder: "Aranacak kelimeyi giriniz.",
  columnsPanelTextFieldPlaceholder: "Sütun ara",
  columnsPanelTextFieldLabel: "Sütun Ara",
  noResultsOverlayLabel: "Gösterilecek satır yok",
  noRowsLabel: "Gösterilecek satır yok",
} as Partial<GridLocaleText>;
