import React from "react";
import { Close } from "@mui/icons-material";
import { DirectionsRenderer } from "@react-google-maps/api";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { Cursors } from "..";

export interface Props {
  deleteMarkerOnClick(): void;
  setCursor(cursor: Cursors): void;
  addMarkers: google.maps.Marker[];
}

type StateProps = {
  distance: string;
  duration: string;
  from: string;
  to: string;
};

export default function DispatcherNewMapDistanceStatus(props: Props) {
  const { addMarkers, deleteMarkerOnClick, setCursor } = props;
  const [state, setState] = React.useState<StateProps | null>(null);
  const [response, setResponse] = React.useState(null);

  React.useEffect(() => {
    const service = new google.maps.DistanceMatrixService();
    const directionsService = new google.maps.DirectionsService();

    service.getDistanceMatrix(
      {
        origins: [addMarkers[0].getPosition()] as any,
        destinations: [addMarkers[1].getPosition()] as any,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK" && response) {
          if (response.rows[0].elements[0].status !== "OK") {
            return;
          }
          const distance = response.rows[0].elements[0].distance.text;
          const duration = response.rows[0].elements[0].duration.text;
          setState({
            distance,
            duration,
            from: response.originAddresses[0],
            to: response.destinationAddresses[0],
          });

          directionsService.route(
            {
              origin: {
                lat: addMarkers[0].getPosition()?.lat(),
                lng: addMarkers[0].getPosition()?.lng(),
              } as any,
              destination: {
                lat: addMarkers[1].getPosition()?.lat(),
                lng: addMarkers[1].getPosition()?.lng(),
              } as any,
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result: any, status: any) => {
              if (status === "OK" && result !== null) {
                setResponse(result);
                addMarkers.forEach((m) => m.setMap(null));
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        }
      }
    );
  }, []);

  const clearAll = () => {
    setState(null);
    setResponse(null);
    deleteMarkerOnClick();
    setCursor("centered");
  };

  if (!state) {
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          p: 2,
          m: 2,
          right: 0,
          bottom: 0,
          zIndex: 2,
          width: 420,
          borderRadius: 2,
          bgcolor: "grey.0",
          position: "absolute",
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ pb: 2 }}>
          <Typography flex={1} variant="h4">
            Ölçülen Mesafe
          </Typography>
          <IconButton onClick={clearAll}>
            <Close />
          </IconButton>
        </Stack>
        <Stack divider={<Divider />}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Typography variant="body2">Toplam Mesafe</Typography>
            <Typography variant="subtitle2" flex={1} textAlign="right">
              {state.distance}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Typography variant="body2">Tahmini Varış Süresi</Typography>
            <Typography variant="subtitle2" flex={1} textAlign="right">
              {state.duration}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Typography variant="body2">Başlangıç Konumu:</Typography>
            <Typography variant="subtitle2" flex={1} textAlign="right">
              {state.from}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Typography variant="body2">Bitiş Konumu:</Typography>
            <Typography variant="subtitle2" flex={1} textAlign="right">
              {state.to}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {response !== null && (
        <DirectionsRenderer options={{ directions: response }} />
      )}
    </>
  );
}
