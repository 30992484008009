import * as React from "react";
import * as Icon from "iconsax-react";
import { useDrag, useDrop } from "react-dnd";
import { Typography, TableRow, TableCell, Stack } from "@mui/material";
//
import Label from "components/Label";
import { OrderProps } from "contexts/PlannerProvider/types";
import { DndTypes } from "screens/DispatcherPlannerNew/dragable";

interface Props {
  order: OrderProps;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
export default function OrderChangeListItem(props: Props) {
  const { index, order, moveCard } = props;
  const ref = React.useRef<HTMLTableRowElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, any>({
    accept: DndTypes.CHANGE_LIST_CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      // @ts-ignore
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      // @ts-ignore
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DndTypes.CHANGE_LIST_CARD,
    item: () => {
      return { id: order.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <TableRow
      ref={ref}
      data-handler-id={handlerId}
      style={{ cursor: "move", opacity }}
    >
      <TableCell>
        <Label variant="ghost" color="primary">
          {index + 1}
        </Label>
      </TableCell>
      <TableCell>
        <Label variant="ghost" color="error">
          {order.plan_day_sequence}
        </Label>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {order.address.first_name} {order.address.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{order.order_code}</Typography>
      </TableCell>
      <TableCell align="right">
        <Icon.HambergerMenu variant="Linear" size={24} />
      </TableCell>
    </TableRow>
  );
}
