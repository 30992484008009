import React from "react";
import moment from "moment";
import {
  Box,
  Card,
  Stack,
  CardHeader,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Services } from "api/Services";
import MapMarker from "../LiveTracking/MapMarker";
import Loader from "react-loader-spinner";
import MultipleSelect from "components/MultipleSelect";
import { fi } from "date-fns/locale";

const server = new Services();

const containerStyle = {
  width: "100%",
  height: "45vh",
};
const center = {
  lat: 39.006959567426534,
  lng: 35.4689090160141,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const NewMap = ({ userFilter }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCd7VzhABeme4cXeWwtv8bY2AWXKleRCbk",
  });
  const [loading, setLoading] = React.useState(true);
  const [routes, setRoutes] = React.useState([]);

  const statuFilterOptions = [
    { value: "Teslim Edildi", key: "DELIVERED" },
    { value: "Teslim Edilemedi", key: "NOT_DELIVERED" },
    { value: "Kurye Zimmetinde", key: "COURIER_DEBIT" },
    { value: "Şube kabul yapıldı", key: "BRANCH_ACCEPTED" },
  ];

  const [status, setStatus] = React.useState([]);

  const [map, setMap] = React.useState(null);
  const date = userFilter.start_date;

  React.useEffect(() => {
    const getRoutes = async () => {
      setLoading(true);
      try {
        const res = await server.liveTrackingGetDetails({
          date: moment(date).format("yyyy-MM-DD"),
        });
        setRoutes(
          res
            .map((item) => item.orders)
            .flat()
            .filter((item) => item.delivery_status !== status)
            .map((item, i) => ({
              ...item,
              routeColor: "#f00",
            }))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getRoutes();
  }, [date, status]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds();

      if (routes.length > 0) {
        for (const order of routes) {
          bounds.extend({ lat: order.latitude, lng: order.longitude });
        }
        map.fitBounds(bounds);
      } else {
        bounds.extend(center);
        map.fitBounds(bounds);
        setTimeout(() => map.setZoom(6), 1000);
      }
    }
  }, [map, routes]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setStatus(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return isLoaded ? (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
        width={1}
      >
        <CardHeader title="Rota Haritası" />
        <Box sx={{ display: "flex", alignSelf: "flex-end" }} width={1 / 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              Gönderi Durumu
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              fullWidth
              onChange={handleChange}
              input={<OutlinedInput label="Gönderi Durumu" />}
              value={status}
              renderValue={(selected) =>
                selected
                  .map(
                    (item) =>
                      statuFilterOptions.find((i) => i.key === item)?.value
                  )
                  .join(", ")
              }
              MenuProps={MenuProps}
            >
              {statuFilterOptions.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  <Checkbox checked={status?.includes(item.key)} />
                  <ListItemText primary={item.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack p={3}>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="Oval" color="tomato" height={40} width={40} />
          </Box>
        ) : (
          <GoogleMap
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={containerStyle}
            options={{
              clickableIcons: false,
              mapTypeControl: true,
              streetViewControl: true,
              fullscreenControl: true,
            }}
          >
            {routes.map((item, i) => (
              <MapMarker key={i} order={item} />
            ))}
          </GoogleMap>
        )}
      </Stack>
    </Card>
  ) : (
    <div>loading</div>
  );
};

export default NewMap;
