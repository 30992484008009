import React from "react";
import moment from "moment";
import {
  Box,
  Card,
  Stack,
  CardHeader,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Services } from "api/Services";
import MapMarker from "../LiveTracking/MapMarker";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import qs from "query-string";
import { useHistory } from "react-router";
import _ from "lodash";
import { getStatus } from "helper";
import { get } from "http";

const server = new Services();

const containerStyle = {
  width: "100%",
  height: "45vh",
};
const center = {
  lat: 39.006959567426534,
  lng: 35.4689090160141,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const onUniqBy = (data = []) =>
  _.uniqBy(
    data.map((i) => i.key),
    (i) => i
  );

const NewMap = ({ userFilter }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCd7VzhABeme4cXeWwtv8bY2AWXKleRCbk",
  });
  const [loading, setLoading] = React.useState(true);
  const [routes, setRoutes] = React.useState([]);
  const data = useSelector((s) => s.dashboardFilterReducer);
  const history = useHistory();
  const queryParam = qs.parse(history.location.search);

  const [statuses, setStatuses] = React.useState([]);
  const deliveryStatusLabels = statuses.map((status) => getStatus(status));

  const [status, setStatus] = React.useState([]);

  const [map, setMap] = React.useState(null);

  const getAllData = async () => {
    setLoading(true);
    const newFilter = {
      route_id: queryParam?.routeId,
      statuses: queryParam?.statuses,
      // Redux
      end_date: moment(data.start_date).format("YYYY-MM-DD"),
      start_date: data.start_date,
      branches: onUniqBy(data.branches),
      cities: onUniqBy(data.cities),
      counties: onUniqBy(data.counties),
      customers: onUniqBy(data.customers),
      drivers: onUniqBy(data.drivers),
      vehicle_types: onUniqBy(data.vehicle_types),
      vehicles: onUniqBy(data.vehicles),
      in_route: true,
    };

    const res = await server.getAllOrderFilter(newFilter);
    setLoading(false);
    if (res) {
      const newRoutes =
        status.length > 0
          ? res.entity.data[0].orders
              .filter((item) => status.includes(item.delivery_status))
              .map((item) => ({
                ...item,
                routeColor: "#f00",
              }))
          : res.entity.data[0].orders.map((item) => ({
              ...item,
              routeColor: "#f00",
            }));
      setRoutes(newRoutes);
      setStatuses(res.entity.data[0].delivery_statuses);
    }
  };

  React.useEffect(() => {
    getAllData();
  }, [data, status]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      if (routes.length > 0) {
        for (const item of routes) {
          bounds.extend({
            lat: item.address.latitude,
            lng: item.address.longitude,
          });
        }
        map.fitBounds(bounds);
      } else {
        bounds.extend(center);
        map.fitBounds(bounds);
        setTimeout(() => map.setZoom(6), 1000);
      }
    }
  }, [map, routes]);

  return isLoaded ? (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
        width={1}
      >
        <CardHeader title="Rota Haritası" />
        <Box sx={{ display: "flex", alignSelf: "flex-end" }} width={1 / 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              Gönderi Durumu
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              fullWidth
              multiple
              onChange={(e) => setStatus([...e.target.value])}
              input={
                <OutlinedInput
                  label="Gönderi Durumu"
                  placeholder="Gönderi Durumu"
                />
              }
              MenuProps={MenuProps}
              value={status.map((i) => i)}
              renderValue={(selected) =>
                selected.map((i) => getStatus(i)).join(", ")
              }
            >
              {statuses.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={status.indexOf(item) > -1} />
                  <ListItemText primary={getStatus(item)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack p={3}>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="Oval" color="tomato" height={40} width={40} />
          </Box>
        ) : (
          <GoogleMap
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={containerStyle}
            options={{
              clickableIcons: false,
              mapTypeControl: true,
              streetViewControl: true,
              fullscreenControl: true,
            }}
          >
            {routes.map((item, i) => (
              <MapMarker key={i} order={item} isDashboard />
            ))}
          </GoogleMap>
        )}
      </Stack>
    </Card>
  ) : (
    <div>loading</div>
  );
};

export default NewMap;
