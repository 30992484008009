import * as React from "react";
import { Trash } from "iconsax-react";
import { useSnackbar } from "notistack";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Services } from "api/Services";
import { usePlanner } from "contexts/PlannerProvider";
import { OrderProps } from "contexts/PlannerProvider/types";

const services = new Services();

interface Props {
  order: OrderProps;
}

export default function SelectRouteOrdersBoxItem(props: Props) {
  const { order } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectRoutes,
    deleteSelectRoute,
    handleSelectRoute,
    selectMapRouteOrders,
    setSelectMapRouteOrders,
    getRoutes,
    getOrders,
    routeFilters,
    filters,
  } = usePlanner();
  const [loading, setLoading] = React.useState<boolean>(false);

  const selectRoute = selectRoutes.find(
    (r) => r.route.route_id === order.routeId
  );
  const selectRouteOrder = selectRoute?.orders.find((o) => o.id === order.id);

  const handleSelectRemoveOrder = () => {
    setSelectMapRouteOrders(
      selectMapRouteOrders.filter((o) => o.id !== order.id)
    );
  };

  const handleRemoveOrder = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Emin misiniz?")) {
      return;
    }
    const sendData = {
      order_ids: [order.id],
      route_id: order?.routeId,
    };
    setLoading(true);
    try {
      await services.deletePlanningRouteOrder(sendData);

      if (selectRoute) {
        deleteSelectRoute(selectRoute.route); // Önce haritadan kaldırıyorum.
        // Sonra yeniden seçtirip yeniliyorum.
        // Orders length kontrolünün nedeni 1 tane sipariş kaldıysa zaten tekrardan seçtirmeye gerek yok.
        if (selectRoute.orders.length > 1) {
          handleSelectRoute(
            selectRoute.route,
            selectRoute.route.route_color,
            () => {}
          );
        }
        await getOrders(filters);
        await getRoutes(routeFilters);
        setLoading(false);
        handleSelectRemoveOrder();
        enqueueSnackbar("Sipariş başarıyla havuza taşındı.", {
          variant: "success",
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Bir sorun oluştu.", {
        variant: "error",
      });
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Checkbox checked onChange={handleSelectRemoveOrder} />
      </TableCell>
      <TableCell>
        <Stack
          justifyContent="center"
          sx={{
            width: 26,
            height: 26,
            borderRadius: 2,
            textAlign: "center",
            borderColor: "grey.400",
            bgcolor: order.route_color,
            border: order.route_color ? "none" : "1px solid",
          }}
        >
          <Typography
            variant="caption"
            fontWeight="bold"
            color={order.route_color ? "white" : "grey.900"}
          >
            {selectRouteOrder?.plan_day_sequence || order.plan_day_sequence}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="caption" whiteSpace="nowrap">
          {order.plan?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" whiteSpace="nowrap">
          {order.order_code}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Atamayı Kaldır">
          <IconButton
            size="small"
            color="error"
            disabled={loading}
            onClick={() => handleRemoveOrder()}
          >
            {loading ? (
              <CircularProgress size={14} color="error" />
            ) : (
              <Trash variant="Bulk" size={16} />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
