import React from "react";
import { Box, Stack } from "@mui/material";
//
import List from "./List";
import ListServer from "./List/index-server";
import Header from "./Header";
import RouteList from "./RouteList";
import SelectRouteListOrders from "./SelectRouteListOrders";

export interface DispatcherNewOrdersProps {
  fullScreen: boolean;
  toggleFullScreen(): void;
}

export default function DispatcherNewOrders(props: DispatcherNewOrdersProps) {
  const { fullScreen, toggleFullScreen } = props;
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const RenderList = React.useMemo(() => {
    if (activeTab === 0) {
      return <ListServer />;
    }

    if (activeTab === 1) {
      return <List />;
    }

    if (activeTab === 2) {
      return <RouteList />;
    }

    if (activeTab === 3) {
      return <SelectRouteListOrders />;
    }

    return null;
  }, [activeTab]);

  return (
    <Stack height={1}>
      <Header
        activeTab={activeTab}
        fullScreen={fullScreen}
        setActiveTab={setActiveTab}
        toggleFullScreen={toggleFullScreen}
      />
      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
          "& .theme--header": { bgcolor: "background.default" },
        }}
      >
        {RenderList}
      </Box>
    </Stack>
  );
}
