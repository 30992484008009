import React from "react";
import _ from "lodash";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import {
  Stack,
  Switch,
  Tooltip,
  Divider,
  useTheme,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";
//
import { Services } from "api/Services";
// import DriverVehicleModal from "./DriverVehicleModal";
import { usePlanner } from "contexts/PlannerProvider";
import {
  PointProps,
  RouteProps,
} from "../../../contexts/PlannerProvider/types";
import moment from "moment";
import Label from "components/Label";

import { convertTimezone } from "utils/convertTimezone";

const services = new Services();

const Wrapper = ({ animation, onClick, children }: any) => {
  return (
    <Stack
      onClick={onClick}
      sx={{
        p: 2,
        m: 2,
        right: 0,
        bottom: 0,
        zIndex: 2,
        maxWidth: 420,
        borderRadius: 1,
        bgcolor: "grey.0",
        cursor: "pointer",
        position: "absolute",
        width: animation ? "auto" : 1,
        animation: `custom1 1s ease ${animation ? "infinite" : "forwards"}`,
        "@keyframes custom1": {
          "0%": {
            opacity: 1,
            transform: "scale(1)",
          },
          "50%": {
            opacity: 0.75,
            transform: "scale(0.98)",
          },
          "100%": {
            opacity: 1,
            transform: "scale(1)",
          },
        },
      }}
    >
      {children}
    </Stack>
  );
};

type InfoCountProps = {
  totalDeci: number;
  totalPackage: number;
};
export interface Props {
  selectPoints: PointProps[];
  onClose(): void;
}

export default function DispatcherNewMapSelectMarkers(props: Props) {
  const { selectPoints, onClose } = props;
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    routes,
    filters,
    getOrders,
    getRoutes,
    routeFilters,
    autoPlanning,
    deleteSelectPoints,
    selectPointsTableVisible,
    handleSelectPointsToggleTable,
  } = usePlanner();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectRoute, setSelectRoute] = React.useState<RouteProps | null>(null);
  const [infoCount, setInfoCount] = React.useState<InfoCountProps>({
    totalDeci: 0,
    totalPackage: 0,
  });

  const getCounts = React.useCallback(async () => {
    try {
      const orderIDS = selectPoints.map((p) => p.order_id);
      const res = await services.getPlanningSelectMarkersDeciPackageCount(
        orderIDS
      );
      setInfoCount({
        totalDeci: res[0].total_deci || 0,
        totalPackage: res[0].total_package || 0,
      });
    } catch (error) {}
  }, [selectPoints]);

  const handleClose = () => {
    onClose();
    deleteSelectPoints();
  };

  const onSubmit = async () => {
    if (!selectRoute) {
      enqueueSnackbar("Lütfen Atanacak Rotayı seçiniz.", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    const orderIds = selectPoints.map((p) => p.order_id);
    try {
      await services.planningRouteChangeOrders(selectRoute.route_id, orderIds);
      await getOrders(filters);
      await getRoutes(routeFilters);
      enqueueSnackbar("Siparişler başarıyla atandı.", {
        variant: "success",
      });
      setLoading(false);
      handleClose();
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const newRoutes = routes.map((item, index: number) => {
    return {
      ...item,
      index,
      name: `${index} - ${convertTimezone(item.date)} - ${item.name} - ${
        item.driver.first_name
      } ${item.driver.last_name}`,
    };
  });

  const uniqByRoutes = _.sortBy(
    newRoutes.map((r) => ({ ...r, sortDate: moment(r.date).unix() })),
    ["index", "sortDate"]
  );

  React.useEffect(() => {
    getCounts();
  }, [getCounts]);

  if (autoPlanning) {
    return (
      <Wrapper>
        <Stack direction="row" alignItems="center">
          <Tooltip title="Seçilen Siparişleri Temizle">
            <Icon.CloseCircle
              variant="Bulk"
              onClick={handleClose}
              color={palette.error.main}
            />
          </Tooltip>
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            divider={
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  border: "none",
                  bgcolor: "background.neutral",
                }}
              />
            }
          >
            <Stack
              flex={1}
              spacing={0.5}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Icon.Location variant="Bulk" color={palette.primary.main} />
              <Typography variant="subtitle2" whiteSpace="nowrap">
                {selectPoints.length} Sipariş
              </Typography>
            </Stack>
            <Stack
              flex={1}
              spacing={0.5}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Icon.Box variant="Bulk" color={palette.primary.main} />
              <Typography variant="subtitle2" whiteSpace="nowrap">
                {infoCount.totalPackage} Paket
              </Typography>
            </Stack>
            <Stack
              flex={1}
              spacing={0.5}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Icon.Convert3DCube variant="Bulk" color={palette.primary.main} />
              <Typography variant="subtitle2" whiteSpace="nowrap">
                {infoCount.totalDeci} Desi
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <Typography flex={1} variant="subtitle1">
              Seçilen Siparişler
            </Typography>
            <Tooltip title="Seçilen Siparişleri Temizle">
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack
            sx={{
              p: 2,
              borderRadius: 1,
              border: "2px solid",
              borderColor: "grey.200",
              bgcolor: "background.neutral",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center" flex={1}>
                <Typography variant="subtitle2">Sipariş</Typography>
                <Label>{selectPoints.length}</Label>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center" flex={1}>
                <Typography variant="subtitle2">Paket</Typography>
                <Label>{infoCount.totalPackage}</Label>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center" flex={1}>
                <Typography variant="subtitle2">Desi</Typography>
                <Label>{infoCount.totalDeci}</Label>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Icon.Eye variant="Bulk" size={30} />
              <Typography
                width={1}
                textAlign="left"
                variant="subtitle2"
                color="text.disabled"
              >
                Seçilen siparişleri tabloda göster
              </Typography>
              <Switch
                checked={!selectPointsTableVisible}
                onClick={handleSelectPointsToggleTable}
              />
            </Stack>
          </Stack>
        </Stack>
        <Autocomplete
          fullWidth
          value={selectRoute}
          options={uniqByRoutes}
          getOptionLabel={(option) => option.name}
          onChange={(a, selectRoutes) => {
            setSelectRoute(selectRoutes);
          }}
          renderOption={(props, option: any, { selected }) => {
            return (
              // @ts-ignore
              <Stack
                {...props}
                spacing={1}
                sx={{ p: 1 }}
                direction="row"
                alignItems="center"
              >
                <Label>{option.index}</Label>
                <Typography variant="caption">{option.name}</Typography>
              </Stack>
            );
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rota ve Kurye"
              placeholder="Rota ve Kurye Seçiniz"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <LoadingButton
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          disabled={selectPoints.length === 0 || !selectRoute}
        >
          {selectPoints.length === 0
            ? "Hiç sipariş bulunamadı"
            : "Siparişleri Ata"}
        </LoadingButton>
      </Stack>
    </Wrapper>
  );
}
