import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import TYPES from "../../store/types";
import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "./DataTableStyle.css";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import InputDialogs from "./InputDialogs";
import ProgressPayment from "../ProgressPayment/ProgressPayment";
import LiveTrackingHistory from "../../screens/Tabs/LiveTrackingHistory";
import LiveTrackingChangeDeliveryStatus from "../../screens/Tabs/LiveTrackingChangeDeliveryStatus";
import Sgk from "../../screens/Tabs/Sgk";

import { BASE_URL } from "../../api/Env";

// import { Divider, Divider, Segment, Tab } from 'semantic-ui-react';
import {
  Table,
  Icon,
  Image,
  Divider,
  Segment,
  Tab,
  Grid,
  Input,
  Label,
  GridRow,
  TextArea,
  Form,
  Radio,
} from "semantic-ui-react";

import {
  getAllListWarehouse,
  getActiveWarehouse,
} from "../../store/actions/warehouseAllAction";
import {
  customerAdd,
  getUserContacts,
} from "../../store/actions/customerAction";
import { getWarehouses } from "../../store/actions/warehouseAction";
//import { getUnplannedOrders } from '../../store/actions/unplannedOrders';
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "react-router";
import { Checkbox } from "@material-ui/core";
import { unplannedCheckReducer } from "../../store/reducers/unplannedCheckReducer";
import {
  vehicleZoneList,
  vehicleProjectList,
} from "../../store/actions/vehicleManagementAction";

import { VehicleManagement } from "../../screens/DialogPopup/VehicleManagement";
import {
  delayVisit,
  willBeReturn,
} from "../../store/actions/liveTrackingAction";
import { getAdminCustomerWarehouse } from "../../store/actions/customerWarehouseAction";
import { Services } from "api/Services";
//import { saveProgressPayment, getProgressPayment } from "../../store/actions/dialogsProgressPaymentAction";

/**
 * a object to props
 * @typedef {Object<string,any,number, Array>} dialogtypes
 * @property {Array} product liste
 */

/**
 * this function creates dialogs on the table
 *
 * @param {dialogtypes} props from data table
 * @returns jsx
 */
const DialogModal = (props) => {
  const {
    productDialog,
    productDialogFooter,
    hideDialog,
    product,
    onInputChange,
    submitted,
    onCategoryChange,
    onInputNumberChange,
    deleteProductDialog,
    deleteProductDialogFooter,
    hideDeleteProductDialog,
    deleteProductsDialog,
    deleteProductsDialogFooter,
    hideDeleteProductsDialog,
    modalType,
    projectDetailDialogFooter,
    arrivalTime,
    toastCallback,
    VehicleCallback,
    convertDateToAnotherTimezone,
    differentPage,
    liveDialogFooter,
    willBeReturnClick,
    setProductDialog,
    orderAddressUpdateGeocodeEditVisible,
  } = props;
  const [selectedContact, setSelectedContact] = useState();
  const [dropDownList, setdropDownList] = useState();
  const [dropDownAdminWarehouseList, setDropDownAdminWarehouseList] =
    useState();
  const [tabStatus, setTabStatus] = useState(false);
  const [vehicleZoneDialog, setVehicleZoneDialog] = useState(false);
  const [zoneTab, setZoneTab] = useState(false);
  const [zoneSelectedID, setZoneSelectedID] = useState([]);
  const [zoneSelectedHtml, setZoneSelectedHtml] = useState([]);
  const [vehicleProjectDialog, setVehicleProjectDialog] = useState(false);
  const [projectSelectedID, setProjectSelectedID] = useState([]);
  const [projectSelectedHtml, setProjectSelectedHtml] = useState([]);
  const [regionDropdownList, setRegionDropdownList] = useState();
  const [displayInput, setDisplayInput] = useState();
  const [musteriler, setMusteriler] = useState([]);
  //const [countPricNumber, setCountPricNumber] = useState([]);
  const [countPricNumber, setCountPricNumber] = useState({
    min_price: null,
    fixed_price: null,
    count_calculators: [
      // {"op":"between", "min":0,"max":10, "price": 5},
      // {"op":"between", "min":10,"max":20, "price": 6.3},
      // {"op":">", "value":20, "price": 3.4}
    ],
    deci_calculators: [
      // {"op":">", "value":0, "price": 3}
    ],
  });

  const [vehicleId, setVehicleId] = useState("");

  const [visitDelayDt, setVisitDelayDt] = useState("");

  const dispatch = useDispatch();

  const getMusteriler = async () => {
    try {
      const res = await new Services().getList();
      setMusteriler(res.entity.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    getMusteriler();
  }, []);

  const projectApplicationDetailReview = useSelector(
    (state) => state.projectApplicationDetailReducer
  );

  const vehicleZoneData = useSelector((state) => state.vehicleZoneReducer);
  const vehicleProjectData = useSelector(
    (state) => state.vehicleProjectReducer
  );
  const activeWarehouse = useSelector((state) => state.activeWarehouseReducer);
  const getBranchList = useSelector((state) => state.branchReducer);
  //const getAdminCustomerWarehouse = useSelector((state) => state.customerWarehouseReducer);
  //const customDialogData = useSelector(state => state.customDialogReducer);

  const CustomerDialog = [
    // { field: product.code, name: "code", header: "Müşteri Kodu", "type": "Input" },
    { field: product.name, name: "name", header: "Müşteri Adı", type: "Input" },
    //{ field: product.address, name: "address", header: "Adres", "type": "Input" },
    {
      field:
        product.address == ""
          ? product.address
          : product.address && product.address.address,
      name: "address",
      header: "Adres",
      type: "Input",
    },
    // { field: typeof product.address === 'object' && product.address.county ? product.address.county : product.county, name: "county", header: "ilçe/il", "type": "Input" },
    //{ field: product.county == "" ? product.county : product.address && product.address.county, name: "county", header: "İlçe/İl", "type": "dropdown-extra", placeholder: "İl,İlçe" },
    {
      field:
        product && product.city
          ? product.city
          : product.address && product.address.city,
      name: "city",
      header: "İl",
      type: "Input",
      placeholder: "İl",
    },
    {
      field:
        product && product.county
          ? product.county
          : product.address && product.address.county,
      name: "county",
      header: "İlçe",
      type: "Input",
      placeholder: "İlçe",
    },
    //{ field: typeof product.address === 'object' && product.address.first_name ? product.address.first_name : product.compony , name: "compony", header: "Şirket Yetkilisi", "type": "Input" },
    {
      field:
        product && product.compony == ""
          ? product.compony
          : product.address && product.address.first_name,
      name: "compony",
      header: "Şirket Yetkilisi",
      type: "Input",
    },
    //{ field: typeof product.address === 'object' && product.address.gsm_number ? product.address.gsm_number : product.phone_1, name: "phone_1", header: "Telefon-1", "type": "Input" },
    {
      field:
        product && product.phone_1 == ""
          ? product.phone_1
          : product.address && product.address.gsm_number,
      name: "phone_1",
      header: "Telefon-1",
      type: "Input",
      placeholder: "0(555)-555-555",
    },
    //{ field: product.bringo, name: "bringo", header: "Bringo Sorumlusu", "type": "Dropdown" },
    //{ field: product.customer, name: "name", header: "Bringo Sorumlusu", "type": "Dropdown" },
    {
      field:
        product && product.customer != undefined
          ? product.customer
          : product.contact,
      name: "username",
      header: "Bringo Sorumlusu",
      type: "Dropdown",
      contactType: "customer",
    },
    //{ field: product && product.barcode, name: "barcode", header: "Barkod", "type": "Checkbox" },
    {
      field: product && product.firm_is_follow,
      name: "firm_is_follow",
      header: "Canlı Takip Butonu Görünsün mü?",
      type: "GeneralCheckbox",
    },
  ];

  const vehicleLeftFrame = [
    { field: product.vehicle_id, name: "name", header: "Plaka", type: "Input" },
    {
      field: product.project_name,
      name: "proje_name",
      header: "Proje",
      type: "Input",
    },
    // { field: product.vehicle_brand, name: "vehicle_brand", header: "Marka", "type": "Input" },
    {
      field: product.vehicle_model,
      name: "vehicle_model",
      header: "Model",
      type: "Input",
    },
    {
      field: product.k_certificate,
      name: "k_certificate",
      header: "K Belgesi",
      type: "Input",
    },
    {
      field: product.insurance,
      name: "insurance",
      header: "Kasko",
      type: "Input",
    },
    {
      field: product.traffic_insurance,
      name: "traffic_insurance",
      header: "Trafik Sigortası",
      type: "Input",
    },
  ];

  const vehicleRightFrame = [
    {
      field: product.vehicle_inspection,
      name: "vehicle_inspection",
      header: "Muayene",
      type: "Input",
    },
    {
      field: product.frigo,
      name: "frigo",
      header: "Frigo/izalasyon var?",
      type: "Input",
    },
    {
      field: product.name,
      name: "name",
      header: "Araç Sürücüsü",
      type: "Input",
    },
    {
      field: product.vehicle_licenc,
      name: "vehicle_licenc",
      header: "Ruhsat Resmi",
      type: "Input",
    },
    {
      field: product.last_maintenance_date,
      name: "last_maintenance_date",
      header: "Son Bakım Tarihi",
      type: "Input",
    },
    {
      field: product.last_maintenance_km,
      name: "last_maintenance_km",
      header: "Son Bakım Km",
      type: "Input",
    },
  ];

  const ProjectDialog = [
    //{ field: product.customer != undefined  ? product.customer.name : product.name, name: "name", header: "Proje Sahibi", type: "Input" },
    //{ field: product.code, name: "code", header: "Proje Sahibi", type: "Dropdown" },

    //{ field:  product.customer != undefined && product.customer, name: "name", header: "Proje Sahibi", type: "Dropdown" },
    {
      field: product.customer != undefined ? product.customer : product.contact,
      name: "name",
      header: "Proje Sahibi",
      type: "Dropdown",
      contactType: "customer",
    },

    {
      field: product.application_due_date,
      name: "application_due_date",
      header: "Son Başvuru Tarihi",
      type: "Calender",
    },
    {
      field: product.start_date,
      name: "start_date",
      header: "Başlangıç Tarihi",
      type: "Calender",
    },
    {
      field: product.end_date,
      name: "end_date",
      header: "Bitiş Tarihi",
      type: "Calender",
    },
    {
      field: product.loading_points,
      name: "loading_points",
      header: "Operasyon Bölgesi",
      type: "Input",
    },
    {
      field: product.quota,
      name: "quota",
      header: "Alım Sayısı",
      type: "InputNumber",
    },
    {
      field: product.requirements,
      name: "requirements",
      header: "Gereksinimler",
      type: "InputTextarea",
    },
  ];

  const ProjectDetailDialog = [
    {
      field: product.company_name,
      name: "company_name",
      header: "Şirket Adı",
      type: "Input",
      placeholder: "Şirket Adı",
    },
    {
      field: product.user && product.user.first_name,
      name: "first_name",
      header: "Adı",
      type: "Input",
      placeholder: "Adı",
    },
    {
      field: product.user && product.user.last_name,
      name: "last_name",
      header: "Soyadı",
      type: "Input",
      placeholder: "Soyadı",
    },
    {
      field: product.user && product.user.birthday,
      name: "birthday",
      header: "Doğum Tarihi",
      type: "Calender",
      placeholder: "Doğum Tarihi",
    },
    // {
    //   field: product.address == "" ? product.address : product.address && product.address.address,
    //   name: "address",
    //   header: "Address",
    //   type: "Input",
    // },
    {
      field:
        product.county == ""
          ? product.county
          : product.address && product.address.county,
      name: "county",
      header: "İl/İlçe",
      type: "Input",
      placeholder: "İl/İlçe",
    },
  ];

  // const aaa = [
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.identity_number) ||
  //       "-",
  //     header: "TCKN",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.first_name) ||
  //       "-",
  //     header: "Adı",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.last_name) ||
  //       "-",
  //     header: "Soyadı",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.birthday) ||
  //       "01.01.2000",
  //     header: "Doğum Tarihiniz",
  //     type: "Calender",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.email) ||
  //       "-",
  //     header: "E-posta Adresi",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.user.phone) ||
  //       "-",
  //     header: "Mobil Telefon",
  //     type: "Input",
  //   },
  //   // { field: projectApplicationDetailReview.review && projectApplicationDetailReview.review.advertisement.loading_points || "-", header: "Ülke", type: "Input" },
  //   // { field: projectApplicationDetailReview.review && projectApplicationDetailReview.review.advertisement.loading_points || "-", header: "İl", type: "Input" },
  //   // { field: projectApplicationDetailReview.review && projectApplicationDetailReview.review.advertisement.loading_points || "-", header: "ilçe", type: "Input" },
  //   // { field: projectApplicationDetailReview.review && projectApplicationDetailReview.review.advertisement.loading_points || "-", header: "Adres", type: "InputTextarea" },

  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.advertisement &&
  //         projectApplicationDetailReview.review.advertisement.loading_points) ||
  //       "-",
  //     header: "Ülke",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.advertisement &&
  //         projectApplicationDetailReview.review.advertisement.loading_points) ||
  //       "-",
  //     header: "İl",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.advertisement &&
  //         projectApplicationDetailReview.review.advertisement.loading_points) ||
  //       "-",
  //     header: "ilçe",
  //     type: "Input",
  //   },
  //   {
  //     field:
  //       (projectApplicationDetailReview.review &&
  //         projectApplicationDetailReview.review.advertisement &&
  //         projectApplicationDetailReview.review.advertisement.loading_points) ||
  //       "-",
  //     header: "Adres",
  //     type: "InputTextarea",
  //   },
  // ];

  const convertDate = (seconds, showSecond) => {
    if (seconds && typeof seconds != "number") {
      return seconds;
    } else {
      seconds = seconds || 0;
      var mins = Math.floor(seconds / 60);
      var s = Math.round(seconds - mins * 60);
      var minutes = Math.floor(mins) % 60;
      if (minutes < 10) minutes = "0" + minutes;
      var hours = Math.floor(mins / 60);
      return hours + 3 + ":" + minutes + (showSecond ? ":" + s : "");
    }
  };

  const deliveryStatus = (value) => {
    switch (value) {
      case "IN_WAREHOUSE":
      case "DELIVERY_PLANNED":
      case "ON_BOARD":
        return <div>Depoda</div>;
        break;
      case "ON_THE_WAY_TO_CUSTOMER":
      case "CUSTOMER_INFORMED_1":
      case "CUSTOMER_INFORMED_2":
      case "CUSTOMER_READINESS_RECEIVED":
        return <div>Dağıtımda</div>;
        break;
      case "CUSTOMER_INFORMED_3":
        return <div>Son 30 dakika</div>;
        break;

      case "DELIVERED":
        return <div>Teslim edildi</div>;
        break;
      case "PARTIALLY_DELIVERED":
        return <div>Kısmen teslim edildi</div>;
        break;
      case "NOT_DELIVERED":
        return <div>Teslim edilmedi</div>;
        break;
      case "DELIVERY_POSTPONED":
        return <div>Teslim ertelendi</div>;
        break;
      default:
        break;
    }
  };

  const inputActiveState = () => {
    if (
      differentPage != null &&
      product.delivery_status == "DELIVERY_POSTPONED"
    ) {
      return false;
    } else {
      return true;
    }
  };

  let estimatedTime = convertDate(product.arrival_time, false);
  const trackingLiveLeftFrame = [
    {
      field: (product && product.order_code) || "-",
      header: "Sipariş Kodu",
      type: "Input",
      disabled: true,
    },
    {
      field:
        (product && new Date(product.order_date).toLocaleDateString("tr-Tr")) ||
        "-",
      header: "Sipariş Tarihi",
      type: "Input",
      disabled: true,
    },
    {
      field: "-",
      header: "Taşımalı İrsaliye No",
      type: "Input",
      disabled: true,
    },
    {
      field: (product && product.name) || "-",
      header: "Alıcı Adı Soyadı",
      type: "Input",
      disabled: true,
    },
    {
      field: (product && product.gsm_number) || "-",
      name: "gsm_number",
      header: "Alıcı Telefon",
      type: "Input",
      disabled: inputActiveState(),
    },
    {
      field: (estimatedTime && estimatedTime) || "-",
      header: "Tahmini Teslimat Tarihi & Saati",
      type: "Input",
      disabled: true,
    },
    //{ field: product && product.city + " " + product.county || "-", header: "Teslimat İl-İlçe", type: "Input", disabled: inputActiveState() },
    {
      field: (product && product.city) || "-",
      name: "city",
      header: "Teslimat İl",
      type: "Input",
      disabled: inputActiveState(),
    },
    {
      field: product.county || "-",
      name: "county",
      header: "Teslimat İlçe",
      type: "Input",
      disabled: inputActiveState(),
    },
    //{ field: product && product.city + " " + product.county, header: "Teslimat Adresi", type: "InputTextarea", disabled:true },
    {
      field: product && product.address,
      name: "address",
      header: "Teslimat Adresi",
      type: "InputTextarea",
      disabled: inputActiveState(),
    }, // product.delivery_status == "DELIVERY_POSTPONED"
    {
      field: "-",
      header: "Sipariş Özeti",
      type: "InputTextarea",
      disabled: true,
    },
    {
      field: (product && product.customer_rate) || "-",
      header: "Sms Değerlendirme Puanı",
      type: "Input",
      disabled: true,
    },
  ];

  let deliveryProps = deliveryStatus(product.delivery_status);
  const trackingLiveRightFrame = [
    {
      field: product.quantity || "-",
      header: "Paket Sayısı",
      type: "Input",
      disabled: true,
    },
    {
      field: product.package_type || "-",
      header: "Kargo Cinsi",
      type: "Input",
      disabled: true,
    },
    {
      field: product.deci || "-",
      header: "Kargo kg/desi",
      type: "Input",
      disabled: true,
    },
    {
      field: (deliveryProps && deliveryProps.props.children) || "-",
      header: "Durumu",
      type: "InputTextarea",
      disabled: true,
    },
    {
      field: (product && product.delivery_to) || "-",
      header: "Teslim Alan",
      type: "Input",
      disabled: true,
    },
    {
      field:
        (product &&
          new Date(product.delivery_date).toLocaleDateString("tr-Tr")) ||
        "-",
      header: "Tarih & Saat",
      type: "Input",
      disabled: true,
    },
    {
      field: (product && product.driver_name) || "-",
      header: "Kurye Adı Soyadı",
      type: "Input",
      disabled: true,
    },
    {
      field: (product && product.driver_phone) || "-",
      header: "Kurye Telefon",
      type: "Input",
      disabled: true,
    },
    {
      field: (product && product.customer_feedback) || "-",
      header: "Müşteri Notu",
      type: "InputTextarea",
      disabled: true,
    },
    differentPage == "LiveTrackingAdmin" && {
      field: (product && product.delivery_description) || "-",
      header: "Dağıtıcı Notu",
      type: "InputTextarea",
      disabled: true,
    },
  ];

  //product.sent_to_plan -> orders sent to planning
  const UnplannedOrderLeftSide = [
    // { field: modalType == 'order-management-edit-order' ? (product.warehouse && product.warehouse.name) : product.customer != undefined ? product.customer : product.contact, name: "name", header: "Depo", type: "Dropdown", contactType: "customer" },
    modalType !== "order-management-update-order" && {
      field: product.customer != undefined ? product.customer : product.contact,
      name: "name",
      header: "Depo",
      type: "Dropdown",
      contactType: "customer",
    },
    {
      field: product.customer ? product.customer.code : product.outlet_code,
      name: "outlet_code",
      header: "Müşteri No",
      type: "Input",
    },
    {
      field: product.order_code,
      name: "order_code",
      header: "Sipariş No",
      type: "Input",
      readOnly: modalType == "order-management-update-order" ? true : false,
    },
    {
      field: product.customer
        ? product.customer.first_name
        : product.first_name,
      name: "first_name",
      header: "Alıcı Adı",
      type: "Input",
    },
    {
      field: product.customer ? product.customer.last_name : product.last_name,
      name: "last_name",
      header: "Alıcı Soyadı",
      type: "Input",
    },
    //{ field: product.address ? product.address.address : product.address, name: "address", header: "Alıcı Adresi", "type": "InputTextarea", placeholder: "Örn: ........ Mahallesi ........ Cadde/Sokak No:....... şekilde yazınız", disabled: true }, // product.sent_to_plan == true ? true : false
    {
      field:
        product.address instanceof Object
          ? product.address.address
          : product.address,
      name: "address",
      header: "Alıcı Adresi",
      type: "InputTextarea",
      placeholder:
        "Örn: ........ Mahallesi ........ Cadde/Sokak No:....... şekilde yazınız",
      disabled: true,
    }, // product.sent_to_plan == true ? true : false
    {
      type: "Button",
      name: "Adresi Güncelle",
      func: orderAddressUpdateGeocodeEditVisible,
    },
    //{ field: product.address ? product.address.city : product.city, name: "city", header: "İl/İlçe", "type": "Input" },
    //{ field: product.address ? product.address.city : product.city, name: "city", header: "İl", "type": "Input", disabled: true  }, //product.sent_to_plan == true ? true : false
    {
      field:
        product.address instanceof Object ? product.address.city : product.city,
      name: "city",
      header: "İl",
      type: "Input",
      disabled: true,
    }, //product.sent_to_plan == true ? true : false
    //{ field: product.address ? product.address.county : product.county, name: "county", header: "İlçe", "type": "Input", disabled: true }, // product.sent_to_plan == true ? true : false
    {
      field:
        product.address instanceof Object
          ? product.address.county
          : product.county,
      name: "county",
      header: "İlçe",
      type: "Input",
      disabled: true,
    }, // product.sent_to_plan == true ? true : false
    {
      field:
        product.address instanceof Object
          ? product.address.latitude &&
            product.address.latitude.toString().substring(0, 7)
          : product.latitude && product.latitude.toString().substring(0, 7),
      name: "latitude",
      header: "Enlem",
      type: "Input",
      disabled: true,
    },
    {
      field:
        product.address instanceof Object
          ? product.address.longitude &&
            product.address.longitude.toString().substring(0, 7)
          : product.longitude && product.longitude.toString().substring(0, 7),
      name: "longitude",
      header: "Boylam",
      type: "Input",
      disabled: true,
    },
    {
      field: product.created_at
        ? convertDateToAnotherTimezone(product.created_at)[1]
        : "",
      name: "created_date",
      header: "Siparişin bize gelme saati ",
      type: "Input",
    },
    {
      field: product.created_user ? product.created_user : "",
      name: "created_user",
      header: "Siparişi gönderen kullanıcı",
      type: "Input",
    },
  ];

  const UnplannedOrderRightSide = [
    {
      field: product.customer
        ? product.customer.gsm_number
        : product.gsm_number,
      name: "gsm_number",
      header: "Cep Telefonu",
      type: "Input",
      disabled: product.sent_to_plan == true ? true : false,
    },
    { field: product.deci, name: "deci", header: "Desi", type: "Input" },
    {
      field: product.products ? product.products[0].quantity : product.quantity,
      name: "quantity",
      header: "Paket",
      type: "Input",
    },
    {
      field: product.sms_notification,
      name: "sms_notification",
      header: "SMS Bilgilendirme",
      type: "Radio",
    },
    {
      field: product.taken_by ? product.taken_by : product.send_first_name,
      name: "send_first_name",
      header: "Gönderen",
      type: "Input",
    },
    {
      field: product.barcode,
      name: "barcode",
      header: "Barkod",
      type: "Input",
    },
    {
      field: product.customer ? product.customer.email : product.email,
      name: "email",
      header: "Email",
      type: "Input",
    },
    {
      field: product.customer
        ? product.customer.delivery_note
        : product.delivery_note,
      name: "delivery_note",
      header: "Not",
      type: "Input",
    },
    {
      field: product.order_date,
      name: "order_date",
      header: "Siparis tarihi",
      type: "Calender",
      select: "range",
      placeholder: "",
    },
    {
      field: product.delivery_date,
      name: "delivery_date",
      header: "Teslim tarihi",
      type: "Calender",
      select: "range",
      placeholder: "",
    },
  ];

  // const OrderDialog =  allWarehouseList && allWarehouseList.data.length > 1 ? [
  //   { field: product.warehouse != undefined ? product.warehouse : product.contact, name: "name", header: "Depo", type: "Dropdown", contactType: "warehouse" },
  //   { field: "file", name: "file", header: "Dosya", type: "FileInput" }
  // ]:[
  //   { field: "file", name: "file", header: "Dosya", type: "FileInput" }
  // ]

  const OrderDialog = [
    // date refresh stop
    {
      field: product.date ? product.date : new Date(),
      name: "date",
      header: "Tarih",
      type: "Calender",
    },
    {
      field:
        product.warehouse != undefined ? product.warehouse : product.contact,
      name: "name",
      header: "Depo",
      type: "Dropdown",
      contactType: "warehouse",
    },
    { field: "file", name: "file", header: "Dosya", type: "FileInput" },
  ];

  const OrderDialog1 = [
    {
      field: product.date ? product.date : new Date(),
      name: "date",
      header: "Tarih",
      type: "Calender",
    },
    { field: "file", name: "file", header: "Dosya", type: "FileInput" },
  ];

  const OrderDialogAdmin = [
    {
      field: product.date ? product.date : new Date(),
      name: "date",
      header: "Tarih",
      type: "Calender",
    },
    {
      field: product && product.adminCustomer,
      name: "name",
      header: "Müşteri",
      type: "Dropdown",
      contactType: "adminCustomer",
    },
    {
      field: product && product.adminWarehouse,
      name: "name",
      header: "Depo",
      type: "DropdownAdminWarehouse",
      contactType: "adminWarehouse",
    },
    { field: "file", name: "file", header: "Dosya", type: "FileInput" },
  ];

  useEffect(() => {
    product.adminCustomer &&
      dispatch(
        getAdminCustomerWarehouse(
          product.adminCustomer,
          setDropDownAdminWarehouseList
        )
      );
  }, [product]);

  // const OrderEdit = [

  // ];

  // const OrderUnplannedDialog = [

  // ];

  // const bbb = [
  //   { header: "TCKN", type: "Input" },
  // ];

  const [customDialog, setCustomDialog] = useState(CustomerDialog);

  //const dispatch = useDispatch();
  const list = useSelector((state) => state.customerReducer);

  const allWarehouseList = useSelector((state) => state.warehouseAllReducer);

  const warehouses = useSelector((state) => state.warehousesReducer);
  const unplannedOrders = useSelector((state) => state.getUnplannedOrders);

  let unplanned_orders = unplannedOrders.unplannedOrders;

  const userType = useSelector((state) => state.userReducer);
  //console.log(userType);

  // const regionValue = new Map();
  // regionValue.set(['İl', 'ilce'][0], "İl");
  // regionValue.set(['İl', 'İlçe'][1], "İlçe");

  const regionValue = [
    {
      id: 1,
      value: "İl",
    },
    {
      id: 2,
      value: "İlçe",
    },
  ];

  useEffect(() => {
    dispatch(getUserContacts());
    // userType.user.user_type != "ADMIN" && dispatch(
    //   getWarehouses()
    // )
  }, []);

  useEffect(() => {
    userType.user.user_type != "ADMIN" && dispatch(getAllListWarehouse());
  }, []);

  // get firm id
  useEffect(() => {
    //userType.id -> firm_id
    userType.user.user_type != "ADMIN" &&
      dispatch(getActiveWarehouse(userType.id));
  }, []);

  // useEffect(() => {
  //   setCustomDialog(CustomerDialog);
  //   setRegionDropdownList({ dropDownData: regionValue, label: 'value', placeholder: 'Seciniz' });
  //   setdropDownList({ dropDownData: list.user_contant.data, labelName: "username", placeholder: "Seciniz" });
  // }, [displayInput]);

  useEffect(() => {
    switch (props.modalType) {
      case "Customer-new":
        setCustomDialog(CustomerDialog);
        setRegionDropdownList({
          dropDownData: regionValue,
          label: "value",
          placeholder: "Seciniz",
        });
        setdropDownList({
          dropDownData: list.user_contant.data,
          labelName: "username",
          placeholder: "Seciniz",
        });
        break;

      case "Customer-update":
        setCustomDialog(CustomerDialog);
        setdropDownList({
          dropDownData: list.user_contant.data,
          labelName: "username",
          placeholder: "Seciniz",
        });
        // setSelectedContact(product.contact? product.contact.username : product.contact);
        break;

      case "ProjectDetail-detail":
        //setCustomDialog(aaa)
        setTabStatus(true);

        // //return empty state to other records
        // setCountPricNumber({
        //   "min_price": null,
        //   "fixed_price": null,
        //   "count_calculators": [
        //     // {"op":"between", "min":0,"max":10, "price": 5},
        //     // {"op":"between", "min":10,"max":20, "price": 6.3},
        //     // {"op":">", "value":20, "price": 3.4}
        //   ],
        //   "deci_calculators": [
        //     // {"op":">", "value":0, "price": 3}
        //   ]
        // });
        //Progress service will be requested here
        //dispatch(getProgressPayment(projectApplicationDetailReview.review.id, setCountPricNumber))
        break;

      case "livetracking":
        break;

      case "order-management-new":
        setCustomDialog(
          activeWarehouse && activeWarehouse.data.length > 1
            ? OrderDialog
            : OrderDialog1
        );
        //setCustomDialog(allWarehouseList && allWarehouseList.data.length > 1 ? OrderDialog : OrderDialog1);
        //setdropDownList({ dropDownData: allWarehouseList.data, labelName: "name", placeholder: "Depo seciniz" });
        //allWarehouseList && allWarehouseList.data.length > 1 && setdropDownList({ dropDownData: allWarehouseList.data, labelName: "name", placeholder: "Depo seciniz" });
        activeWarehouse &&
          activeWarehouse.data.length > 1 &&
          setdropDownList({
            dropDownData: activeWarehouse.data,
            labelName: "name",
            placeholder: "Depo seciniz",
          });
        break;
      case "admin-order-management-new":
        // console.log("popop :", list);
        setCustomDialog(OrderDialogAdmin);
        //setCustomDialog(allWarehouseList && allWarehouseList.data.length > 1 ? OrderDialog : OrderDialog1);
        //setdropDownList({ dropDownData: allWarehouseList.data, labelName: "name", placeholder: "Depo seciniz" });
        //allWarehouseList && allWarehouseList.data.length > 1 && setdropDownList({ dropDownData: allWarehouseList.data, labelName: "name", placeholder: "Depo seciniz" });
        //activeWarehouse && activeWarehouse.data.length > 1 && setdropDownList({ dropDownData: activeWarehouse.data, labelName: "name", placeholder: "Depo seciniz" });
        setdropDownList({
          dropDownData: musteriler,
          labelName: "name",
          placeholder: "Seciniz",
        });
        break;
      case "order-management-manual-order":
        setdropDownList({
          dropDownData: allWarehouseList.data,
          labelName: "name",
          placeholder: "Depo seciniz",
        });
        // setdropDownList({ dropDownData: warehouses.warehouses, labelName: "Depo", placeholder: "Depo seciniz" });
        break;
      case "order-management-unplanned-order":
        break;
      case "order-management-update-order":
        setdropDownList({
          dropDownData: allWarehouseList.data,
          labelName: "name",
          placeholder: "Depo seciniz",
        });
        // setCustomDialog(orderEdit)
        break;
      case "ProjectDetail-update":
        setCustomDialog(ProjectDetailDialog);
        setTabStatus(false);
        break;
      default:
        setCustomDialog(ProjectDialog);
        setdropDownList({
          dropDownData: musteriler,
          labelName: "name",
          placeholder: "Seciniz",
        });
        break;
    }
  }, [props]); // re-render if props change

  const onContactChange = (e, name, contactType) => {
    onInputChange(e, name, contactType);
  };

  const selectedZoneMethod = (e) => {
    for (var i = 0; i < zoneSelectedID.length; i++) {
      if (zoneSelectedID[i] === e.target.id) {
        zoneSelectedID.splice(i, 1);
        return;
      }
    }

    setZoneSelectedID((zoneList) => [...zoneList, e.target.id]);
  };

  const selectedProjectMethod = (e) => {
    for (var i = 0; i < projectSelectedID.length; i++) {
      if (projectSelectedID[i] === e.target.id) {
        projectSelectedID.splice(i, 1);
        return;
      }
    }

    setProjectSelectedID((projectList) => [...projectList, e.target.id]);
  };

  const visitDelaySave = () => {
    //new Date(visitDelayDt).toLocaleDateString()

    dispatch(
      delayVisit(visitDelayDt, product.order_oid, toastCallback, hideDialog)
    );

    // applicationEvaluationUpdate(RowData, 0, (res) => {
    //   toastCallback("success");
    // })
  };

  // const LivePopup = () => {
  //   // return (
  //   //   <React.Fragment>
  //   //     <Segment>
  //   //       <Grid columns={2} relaxed='very'>
  //   //         <Grid.Column>
  //   //           <InputDialogs
  //   //             onInputChange={onInputChange}
  //   //             onContactChange={onContactChange}
  //   //             selectedContact={selectedContact}
  //   //             list={dropDownList}
  //   //             submitted={submitted}
  //   //             onInputNumberChange={onInputNumberChange}
  //   //             customDialog={trackingLiveLeftFrame}
  //   //           />

  //   //           {/** only shown on undelivered orders */}
  //   //           {product.delivery_status == "NOT_DELIVERED" && <div className="p-field">
  //   //             <label>Ziyareti Ertele</label>
  //   //             <Calendar id="basic" className="visitDCa" dateFormat="dd.mm.yy" value={new Date(visitDelayDt)} onChange={(e) => setVisitDelayDt(e.value)} />
  //   //             <p className="visitDelayP" onClick={() => visitDelayDt && visitDelaySave()}>Kaydet</p>
  //   //           </div>}

  //   //         </Grid.Column>
  //   //         <Grid.Column>
  //   //           <InputDialogs
  //   //             onInputChange={onInputChange}
  //   //             onContactChange={onContactChange}
  //   //             selectedContact={selectedContact}
  //   //             list={dropDownList}
  //   //             submitted={submitted}
  //   //             onInputNumberChange={onInputNumberChange}
  //   //             customDialog={trackingLiveRightFrame}
  //   //           />
  //   //         </Grid.Column>
  //   //       </Grid>

  //   //       <Divider vertical>-</Divider>
  //   //     </Segment>
  //   //   </React.Fragment>
  //   // )

  //   const liveTrackingTab = [
  //     {
  //       menuItem: 'Canlı Takip Detay',
  //       pane: {
  //         key: 'tab1',
  //         content: (
  //           <div>
  //             {
  //               <div style={{ overflow: "overlay" }}>
  //                 <React.Fragment>
  //                   <Segment>
  //                     <Grid columns={2} relaxed='very'>
  //                       <Grid.Column>
  //                         <InputDialogs
  //                           onInputChange={onInputChange}
  //                           onContactChange={onContactChange}
  //                           selectedContact={selectedContact}
  //                           list={dropDownList}
  //                           submitted={submitted}
  //                           onInputNumberChange={onInputNumberChange}
  //                           customDialog={trackingLiveLeftFrame}
  //                         />

  //                         {/** only shown on undelivered orders */}
  //                         {product.delivery_status == "NOT_DELIVERED" && <div className="p-field">
  //                           <label>Ziyareti Ertele</label>
  //                           <Calendar id="basic" className="visitDCa" dateFormat="dd.mm.yy" value={new Date(visitDelayDt)} onChange={(e) => setVisitDelayDt(e.value)} />
  //                           <p className="visitDelayP" onClick={() => visitDelayDt && visitDelaySave()}>Kaydet</p>
  //                         </div>}

  //                         {/** only shown on undelivered orders */}
  //                         {product.delivery_status == "NOT_DELIVERED" && <div className="p-field" style={{"marginTop": "40px","marginLeft": "-11px"}}>
  //                           <p className="visitDelayP" onClick={() => dispatch(willBeReturn(product.order_oid, toastCallback, hideDialog))}>İade Et</p>
  //                         </div>}

  //                       </Grid.Column>
  //                       <Grid.Column>
  //                         <InputDialogs
  //                           onInputChange={onInputChange}
  //                           onContactChange={onContactChange}
  //                           selectedContact={selectedContact}
  //                           list={dropDownList}
  //                           submitted={submitted}
  //                           onInputNumberChange={onInputNumberChange}
  //                           customDialog={trackingLiveRightFrame}
  //                         />
  //                       </Grid.Column>
  //                     </Grid>

  //                     <Divider vertical>-</Divider>
  //                   </Segment>
  //                 </React.Fragment>
  //               </div>
  //             }
  //           </div>
  //         ),
  //       }
  //     },
  //     {
  //       menuItem: 'Sipariş Geçmişi',
  //       pane: {
  //         key: 'tab2',
  //         content: (
  //           <div>
  //             {
  //               <div style={{ overflow: "overlay" }}>
  //                 <LiveTrackingHistory product={product} />
  //               </div>
  //             }
  //           </div>
  //         )
  //       }
  //     },
  //     {
  //       menuItem: 'Teslimat Durumu Değiştirme',
  //       pane: {
  //         key: 'tab3',
  //         content: (
  //           <div>
  //           {
  //             <div style={{ overflow: "overlay" }}>
  //               <LiveTrackingChangeDeliveryStatus />
  //             </div>
  //           }
  //         </div>
  //         )
  //       }
  //     }

  //   ]
  //   return (
  //     <Tab panes={liveTrackingTab} renderActiveOnly={false} />
  //   )
  // }

  const zoneOpenPopup = () => {
    setVehicleZoneDialog(true);
    setZoneTab(true);
  };

  const vehicleHideDialog = () => {
    setVehicleZoneDialog(false);
  };

  const projectOpenPopup = () => {
    setVehicleProjectDialog(true);
  };

  const projectHideDialog = () => {
    setVehicleProjectDialog(false);
  };

  const saveZone = () => {
    vehicleZoneData.data.data.map((zone, i) => {
      {
        zone.zones.map((zonesDt, i) => {
          let a = zoneSelectedID.includes(zonesDt.zone_id);
          let b = zoneSelectedHtml.some((x) => x.zone_id == zonesDt.zone_id);
          if (a == true && b == false) {
            setZoneSelectedHtml((zoneList) => [...zoneList, zonesDt]);
          }
        });
      }
    });

    if (zoneSelectedID.length < zoneSelectedHtml.length) {
      var duplicates = zoneSelectedHtml.filter(function (val) {
        return zoneSelectedID.indexOf(val.zone_id) != -1;
      });
      setZoneSelectedHtml(duplicates);
    }

    setVehicleZoneDialog(false);
  };

  const saveProject = () => {
    vehicleProjectData.data.map((project, i) => {
      {
        let a = projectSelectedID.includes(project.id);
        let b = projectSelectedHtml.some((x) => x.id == project.id);
        if (a == true && b == false) {
          setProjectSelectedHtml((projectList) => [...projectList, project]);
        }
      }
    });

    if (projectSelectedID.length < projectSelectedHtml.length) {
      var duplicates = projectSelectedHtml.filter(function (val) {
        return projectSelectedID.indexOf(val.id) != -1;
      });
      setProjectSelectedHtml(duplicates);
    }

    setVehicleProjectDialog(false);
  };

  const vehicleDialogFooter = (
    <React.Fragment>
      <Button
        label="İptal"
        icon="pi pi-times"
        className="p-button-text"
        //onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Kaydet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveZone}
      />
    </React.Fragment>
  );

  const projectDialogFooter = (
    <React.Fragment>
      <Button
        label="İptal"
        icon="pi pi-times"
        className="p-button-text"
        //onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Kaydet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProject}
      />
    </React.Fragment>
  );

  const vehicleUpdateData = {};
  // const onInputChangeVehicle = (e,name) => {
  //   const val = (e.target && e.target.value) || '';
  //   vehicleUpdateData[`${name}`] = val;
  //   console.log("Vehicle : ", vehicleUpdateData);

  //   // dispatch({
  //   //   type: TYPES.DIALOG_CURRENT_DATA,
  //   //   payload: vehicleUpdateData
  //   // })

  // }

  const vehicleManagementTabs = [
    {
      menuItem: "Araç Bilgileri",
      pane: {
        key: "tab3",
        content: (
          <div style={{ overflow: "overlay" }}>
            <React.Fragment>
              <Segment>
                <Grid columns={2} relaxed="very">
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    {/* <InputDialogs
                      onInputChange={onInputChange}
                      onContactChange={onContactChange}
                      selectedContact={selectedContact}
                      list={dropDownList}
                      submitted={submitted}
                      onInputNumberChange={onInputNumberChange}
                      customDialog={vehicleRightFrame}
                    /> */}
                  </Grid.Column>
                </Grid>

                <Divider vertical>-</Divider>
              </Segment>
            </React.Fragment>
          </div>
        ),
      },
    },
    {
      menuItem: "Bölge",
      pane: {
        key: "tab2",
        content: (
          <div style={{ overflow: "overlay" }}>
            <p onClick={zoneOpenPopup}>Bölge Ekle</p>
            <Table celled striped>
              <Table.Body>
                {/* <Table.Row>
                  <Table.Cell>çekmeköy</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>çekmeköy</Table.Cell>
                </Table.Row> */}
                {zoneSelectedHtml &&
                  zoneSelectedHtml.map((zn) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          {zn.name} ({zn.group_name})
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
        ),
      },
    },
    {
      menuItem: "Projeler",
      pane: {
        key: "tab1",
        content: (
          <div style={{ overflow: "overlay" }}>
            <p onClick={projectOpenPopup}>Proje Ekle</p>
            <Table celled striped>
              <Table.Body>
                {/* <Table.Row>
                  <Table.Cell>çekmeköy</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>çekmeköy</Table.Cell>
                </Table.Row> */}
                {projectSelectedHtml &&
                  projectSelectedHtml.map((pr) => {
                    return (
                      <Table.Row>
                        <Table.Cell>({pr.name})</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
        ),
      },
    },
  ];

  // useEffect(() => {
  //   dispatch(
  //     vehicleZoneList()
  //   );
  //   dispatch(
  //     vehicleProjectList()
  //   )
  // }, [])

  const VehicleManagementPopup = () => {
    // return (
    //   <React.Fragment>
    //     <Segment>
    //       <Grid columns={2} relaxed='very'>
    //         <Grid.Column>
    //           <p onClick={zoneOpenPopup}>Zone Ekle: xx, xx3, xx34</p>
    //           <InputDialogs
    //             onInputChange={onInputChange}
    //             onContactChange={onContactChange}
    //             selectedContact={selectedContact}
    //             list={dropDownList}
    //             submitted={submitted}
    //             onInputNumberChange={onInputNumberChange}
    //             customDialog={vehicleLeftFrame}
    //           />
    //         </Grid.Column>
    //         <Grid.Column>
    //           <InputDialogs
    //             onInputChange={onInputChange}
    //             onContactChange={onContactChange}
    //             selectedContact={selectedContact}
    //             list={dropDownList}
    //             submitted={submitted}
    //             onInputNumberChange={onInputNumberChange}
    //             customDialog={vehicleRightFrame}
    //           />
    //         </Grid.Column>
    //       </Grid>

    //       <Divider vertical>-</Divider>
    //     </Segment>
    //   </React.Fragment>
    // )

    // return (
    //    <Tab panes={vehicleManagementTabs} defaultActiveIndex={zoneTab == true ? 1 : 0} renderActiveOnly={false} />
    // )
    return <p>skadklsd</p>;
  };

  const projectApplicationDetailReviewUser =
    projectApplicationDetailReview.review &&
    projectApplicationDetailReview.review.user;
  const projectApplicationDetailReviewAddress =
    projectApplicationDetailReview.review &&
    projectApplicationDetailReview.review.address;
  const projectApplicationDetailReviewVehicleOptions =
    projectApplicationDetailReview.review &&
    projectApplicationDetailReview.review.vehicle_option;
  const projectApplicationDetailReviewCompanyType =
    projectApplicationDetailReview.review &&
    projectApplicationDetailReview.review.company_type;

  const reviewCompanyType = (compName) => {
    switch (compName) {
      case "NOT_YET":
        return "Şirketim yok";
        break;
      case "PERSONEL":
        return "Şahıs şirketi";
        break;
      case "LIMITED":
        return "Limited şirketi";
        break;

      default:
        return "-";
        break;
    }
  };

  const reviewVehicleOption = (vehicleOption) => {
    switch (vehicleOption) {
      case "HAS_VEHICLE":
        return "Aracı var";
        break;
      case "NO_VEHICLE":
        return "Aracı yok";
        break;
      case "WANT_TO_OWN":
        return "Araç istiyorum";
        break;

      default:
        return "-";
        break;
    }
  };

  // const oPItems = [
  //   { label: 'Arasında', value: 'between' },
  //   { label: 'Büyüktür', value: '>' },
  //   //{ label: 'test', value: 'test' }
  // ];

  const minValueCheck = (min, max) => {
    // min > max , value check
    if (parseInt(min) > max) {
      toastCallback("warn", "Minumum değer maksimumdan değerden büyük olamaz!");
      return true;
    }
    return false;
  };

  const maxValueCheck = (max, min) => {
    // max < min , value check
    if (parseInt(max) < min) {
      toastCallback("warn", "Maksimum değer minumumdan değerden küçük olamaz!");
      return true;
    }
    return false;
  };

  const panes = [
    {
      menuItem: "Kullanıcı Bilgileri",
      pane: {
        key: "tab3",
        content: (
          <div>
            {/* <InputDialogs
              onInputChange={onInputChange}
              onContactChange={onContactChange}
              selectedContact={selectedContact}
              list={dropDownList}
              submitted={submitted}
              onInputNumberChange={onInputNumberChange}
              customDialog={aaa}
            /> */}

            {/* {
              <>
                <p>{projectApplicationDetailReview.review && projectApplicationDetailReview.review.evaluation_result || "-askdkjasdjkhasjkhdkjshajkdhhj"}</p>
                <p>{projectApplicationDetailReview.review && projectApplicationDetailReview.review.vehicle_option || "-askdkjasdjkhasjkhdkjshajkdhhj"}</p>
              </>
            } */}

            {
              <div style={{ overflow: "overlay" }}>
                <Table celled striped>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>TCKN : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.identity_number
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Adı : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.first_name
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Soyadı : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.last_name
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Doğum Tarihi : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? new Date(
                              projectApplicationDetailReviewUser.birthday
                            ).toLocaleDateString("tr-TR")
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Cinsiyet : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.gender == "MALE"
                            ? "Erkek"
                            : projectApplicationDetailReviewUser.gender ==
                              "OTHER"
                            ? "Diğer"
                            : "Kadın"
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>E-posta Adresi : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.email
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Mobil Telefon : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewUser
                          ? projectApplicationDetailReviewUser.phone
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Ülke : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewAddress
                          ? projectApplicationDetailReviewAddress.country
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> il : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewAddress
                          ? projectApplicationDetailReviewAddress.city
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> ilçe : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewAddress
                          ? projectApplicationDetailReviewAddress.county
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Adres : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReviewAddress
                          ? projectApplicationDetailReviewAddress.address
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Dagıtım tecrübesi : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReview.review
                          ? projectApplicationDetailReview.review
                              .has_delivery_exprience == true
                            ? "Deneyimli"
                            : "Deneyimsiz"
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Açıklama : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReview.review
                          ? projectApplicationDetailReview.review.description ||
                            "-"
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Araç Seçenekleri : </Table.Cell>
                      <Table.Cell>
                        {" "}
                        {reviewVehicleOption(
                          projectApplicationDetailReviewVehicleOptions
                        )}
                      </Table.Cell>
                      {/* <Table.Cell>{projectApplicationDetailReview.review ? (projectApplicationDetailReviewVehicleOptions == "HAS_VEHICLE" ? "Aracı var" : (projectApplicationDetailReviewVehicleOptions == "NO_VEHICLE") ? "Aracı yok" : (projectApplicationDetailReviewVehicleOptions == "WANT_TO_OWN") ? "Araç istiyorum" : "-") : "-"}</Table.Cell> */}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Firma : </Table.Cell>
                      <Table.Cell>
                        {" "}
                        {reviewCompanyType(
                          projectApplicationDetailReviewCompanyType
                        )}{" "}
                      </Table.Cell>
                      {/* <Table.Cell>{projectApplicationDetailReview.review ? (projectApplicationDetailReviewCompanyName == "NOT_YET" ? "Şirketim yok" : (projectApplicationDetailReviewCompanyName == "PERSONEL") ? "Şahıs şirketi" : (projectApplicationDetailReviewCompanyName == "LIMITED") ? "Limited şirketi" : "-ss" || "-e") : "ssss"}</Table.Cell> */}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> Firma Adı : </Table.Cell>
                      <Table.Cell>
                        {projectApplicationDetailReview.review
                          ? projectApplicationDetailReview.review.company_name
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            }
          </div>
        ),
      },
    },
    {
      menuItem: "Araçlar",
      pane: {
        key: "tab2",
        content: (
          <div style={{ overflow: "overlay" }}>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Araç Adı</Table.HeaderCell>
                  <Table.HeaderCell>Marka</Table.HeaderCell>
                  <Table.HeaderCell>Plaka</Table.HeaderCell>
                  <Table.HeaderCell>Model</Table.HeaderCell>
                  <Table.HeaderCell>Sınıf</Table.HeaderCell>
                  <Table.HeaderCell>Araç Ruhsatı</Table.HeaderCell>
                  <Table.HeaderCell>Kapasite</Table.HeaderCell>
                  <Table.HeaderCell>K Sertifikası</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.entries(
                  projectApplicationDetailReview.review
                    ? projectApplicationDetailReview.review.vehicles
                    : {}
                ).map(([key, data], i) => (
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Icon name="car" />
                    </Table.Cell>
                    <Table.Cell>{data.name || "-"}</Table.Cell>
                    <Table.Cell>{data.vehicle_brand.name || "-"}</Table.Cell>
                    <Table.Cell>{data.vehicle_id || "-"}</Table.Cell>
                    <Table.Cell>{data.vehicle_model.name || "-"}</Table.Cell>
                    {/* <Table.Cell>{data.vehicle_type.name || "-"}</Table.Cell> */}
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.vehicle_licence ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.vehicle_licence.id}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı!</p>
                      )}
                    </Table.Cell>
                    <Table.Cell>{data.capacity || "-"}</Table.Cell>
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.k_certificate ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.k_certificate.id}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı!</p>
                      )}
                      {/* <Label as='a' href={`http://api-dev.heybringo.com/api/public/document/${data.k_certificate && data.k_certificate.id || "-" }`} target="blank">
                        <Icon name='file pdf' /> İndir
                      </Label> */}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ),
      },
    },
    {
      menuItem: "Sürücü Bilgileri",
      pane: {
        key: "tab1",
        content: (
          <div style={{ overflow: "overlay" }}>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Adı</Table.HeaderCell>
                  <Table.HeaderCell>Soyadı</Table.HeaderCell>
                  <Table.HeaderCell>Telefon</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Doğum Tarihi</Table.HeaderCell>
                  <Table.HeaderCell>Sabıka Kaydı</Table.HeaderCell>
                  <Table.HeaderCell>Eğitim Durumu</Table.HeaderCell>
                  <Table.HeaderCell>Ehliyet Belgesi</Table.HeaderCell>
                  <Table.HeaderCell>Src 4 Belgesi</Table.HeaderCell>
                  <Table.HeaderCell>
                    Src 4 Belgesi Başlangıç Tarihi{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Src 4 Belgesi Bitiş Tarihi{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Psikoteknik Belgesi</Table.HeaderCell>
                  <Table.HeaderCell>
                    Psikoteknik Belgesi Başlangıç Tarihi{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Psikoteknik Belgesi Bitiş Tarihi{" "}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.entries(
                  projectApplicationDetailReview.review
                    ? projectApplicationDetailReview.review.drivers
                    : {}
                ).map(([key, data], i) => (
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Icon name="user" />
                    </Table.Cell>
                    <Table.Cell>{data.first_name || "-"}</Table.Cell>
                    <Table.Cell>{data.last_name || "-"}</Table.Cell>
                    <Table.Cell>{data.phone || "-"}</Table.Cell>
                    <Table.Cell>{data.email || "-"}</Table.Cell>
                    <Table.Cell>
                      {new Date(data.birthday).toLocaleDateString("tr-TR") ||
                        "-"}
                    </Table.Cell>
                    {/* <Table.Cell>{data.criminal_record || "-"}</Table.Cell> */}
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.criminal_record ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.criminal_record}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı! </p>
                      )}
                    </Table.Cell>
                    <Table.Cell>{data.education_status || "-"}</Table.Cell>
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.licence_doc ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.licence_doc.id}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı! </p>
                      )}
                      {/* <Label as='a' href={`http://api-dev.heybringo.com/api/public/document/${data.licence_doc && data.licence_doc.id || "-"}`} target="blank">
                        <Icon name='file pdf' /> İndir
                      </Label> */}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.src_doc ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.src_doc.id}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı! </p>
                      )}
                      {/* <Label as='a' href={`http://api-dev.heybringo.com/api/public/document/${data.psycho_doc && data.psycho_doc.id || "-"}`} target="blank">
                        <Icon name='file pdf' /> İndir
                      </Label> */}
                    </Table.Cell>
                    <Table.Cell>
                      {(data.src_doc &&
                        new Date(data.src_doc.doc_date).toLocaleDateString(
                          "tr-TR"
                        )) ||
                        "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {(data.src_doc && data.src_doc.doc_expiration) || "-"}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      {/* <Icon name='file pdf'/> */}
                      {data.psycho_doc ? (
                        <Label
                          as="a"
                          href={`${BASE_URL}api/public/document/${data.psycho_doc.id}`}
                          target="blank"
                        >
                          <Icon name="file pdf" /> İndir
                        </Label>
                      ) : (
                        <p> Bulunamadı! </p>
                      )}
                      {/* <Label as='a' href={`http://api-dev.heybringo.com/api/public/document/${data.psycho_doc && data.psycho_doc.id || "-"}`} target="blank">
                        <Icon name='file pdf' /> İndir
                      </Label> */}
                    </Table.Cell>
                    <Table.Cell>
                      {(data.psycho_doc &&
                        new Date(data.psycho_doc.doc_date).toLocaleDateString(
                          "tr-TR"
                        )) ||
                        "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {(data.psycho_doc && data.psycho_doc.doc_expiration) ||
                        "-"}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ),
      },
    },
    {
      menuItem: "Hakediş",
      pane: {
        key: "tab4",
        content: (
          // <div style={{ overflow: "overlay" }}>
          //   <div>
          //     <div className="paymentContent pc-w-250">
          //       <span className="pc-span-fontw">Minumum günlük hakediş</span>
          //       <InputText
          //         //placeholder="Min. price"
          //         value={countPricNumber.min_price}
          //         onChange={(e) => setCountPricNumber({ ...countPricNumber, min_price: e.target.value })} />
          //     </div>
          //     <div className="paymentContent pc-w-250">
          //       <span className="pc-span-fontw">Sabit günlük hakediş</span>
          //       <InputText
          //         //placeholder="Fixed price"
          //         value={countPricNumber.fixed_price}
          //         onChange={(e) => setCountPricNumber({ ...countPricNumber, fixed_price: e.target.value })} />
          //     </div>

          //     {/* =========================== Count Price ===========================*/}

          //     <div className="paymentContent">
          //       <p className="pc-span-fontw" >Ziyaret başı hakediş</p>
          //       {/* <Button icon="pi pi-plus"  onClick={() => setCountPricNumber([...countPricNumber, {name : "CountPrice-" + countPricNumber.length, op: null, min: null, max: null, price: null}])} />

          //       { countPricNumber.map(d =>  <div className="countPriceContent">
          //           <div className="paymentContent pc-w-100">
          //             <Dropdown placeholder="op" className="opHeight" options={oPItems} />
          //           </div>
          //           <div className="paymentContent pc-w-100 pc-ml-15">
          //             <InputText placeholder="min" />
          //           </div>
          //           <div className="paymentContent pc-w-100 pc-ml-15">
          //             <InputText placeholder="max" />
          //           </div>
          //           <div className="paymentContent pc-w-100 pc-ml-15">
          //             <InputText placeholder="price" />
          //           </div>
          //        </div> )} */}

          //       <Button icon="pi pi-plus" onClick={(e) => {
          //         let operatorLess = countPricNumber.count_calculators.filter(ch => ch.op == ">");
          //         if (operatorLess.length > 0) {
          //           toastCallback("warn", "Büyüktür operatörü 1 tane ekleyebilirsiniz");
          //           return
          //         }
          //         setCountPricNumber({ ...countPricNumber, count_calculators: [...countPricNumber.count_calculators, { "op": ">", "value": null, "price": null }] })
          //       }
          //       }
          //       />

          //       <div className="pc-header">
          //         <span>Silme</span>
          //         <span>Oparetör</span>
          //         <span>Tutar</span>
          //         <span>Minumum değer</span>
          //         <span>Maksimum değer</span>
          //       </div>

          //       {countPricNumber.count_calculators && countPricNumber.count_calculators.map((d, i) => <div className="countPriceContent">

          //         <div className="paymentContent pc-mr-10">
          //           <Button id={i} icon="pi pi-trash" className="p-button-danger" onClick={(e) => {
          //             let temporary = countPricNumber;
          //             temporary.count_calculators.splice(i, 1);
          //             // temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
          //             setCountPricNumber({ ...countPricNumber, temporary })
          //           }} />
          //         </div>

          //         <div className="paymentContent pc-w-110">
          //           <Dropdown id={i} placeholder="op" className="opHeight" value={d.op} options={oPItems}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.count_calculators];
          //               temporary[i] = e.target.value == ">" ? { "op": e.target.value, "value": temporary[i].value, "price": temporary[i].price } : { "op": e.target.value, "price": temporary[i].price, "min": temporary[i].min, "max": temporary[i].max }
          //               setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
          //             }}
          //           />
          //         </div>
          //         <div className="paymentContent pc-w-100 pc-ml-15">
          //           <InputText
          //             id={i}
          //             placeholder="Tutar"
          //             value={d.price}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.count_calculators];
          //               temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
          //               setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
          //             }}
          //           />
          //         </div>

          //         {d.op == ">" ? <div className="paymentContent pc-w-100 pc-ml-15">
          //           <InputText
          //             id={i}
          //             placeholder="Min. Değer"
          //             value={d.value}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.count_calculators];
          //               temporary[i] = { "op": temporary[i].op, "value": e.target.value, "price": temporary[i].price }
          //               setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
          //             }}
          //           />
          //         </div>
          //           :
          //           <>
          //             <div className="paymentContent pc-w-100 pc-ml-15">
          //               <InputText
          //                 placeholder="Min. Değer"
          //                 value={d.min}
          //                 onChange={(e) => {
          //                   let temporary = [...countPricNumber.count_calculators];

          //                   // min > max , value check
          //                   // if (parseInt(e.target.value) > temporary[i].max) {
          //                   //   toastCallback("warn", "Minumum değer maksimumdan değerden büyük olamaz!")
          //                   //   return
          //                   // }
          //                   //if (minValueCheck(e.target.value, temporary[i].max) == true) return;

          //                   temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": e.target.value, "max": temporary[i].max }
          //                   setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
          //                 }}
          //               />
          //             </div>
          //             <div className="paymentContent pc-w-100 pc-ml-15">
          //               <InputText
          //                 placeholder="Max. Değer"
          //                 value={d.max}
          //                 onChange={(e) => {
          //                   let temporary = [...countPricNumber.count_calculators];

          //                   // max < min , value check
          //                   // if (parseInt(e.target.value) < temporary[i].min) {
          //                   //   toastCallback("warn", "Maksimum değer minumumdan değerden küçük olamaz!")
          //                   //   return
          //                   // }
          //                   //if (maxValueCheck(e.target.value, temporary[i].min) == true) return;

          //                   temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": temporary[i].min, "max": e.target.value }
          //                   setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
          //                 }}
          //               />
          //             </div>
          //           </>
          //         }
          //       </div>)}
          //     </div>

          //     {/* =========================== Deci Price ===========================*/}

          //     <div className="paymentContent" style={{ "marginTop": "50px" }}>
          //       <p className="pc-span-fontw">Desi başı hakediş</p>

          //       <Button icon="pi pi-plus" onClick={(e) => {
          //         let operatorLess = countPricNumber.deci_calculators.filter(ch => ch.op == ">");
          //         if (operatorLess.length > 0) {
          //           toastCallback("warn", "Büyüktür operatörü 1 tane ekleyebilirsiniz");
          //           return
          //         }
          //         setCountPricNumber({ ...countPricNumber, deci_calculators: [...countPricNumber.deci_calculators, { "op": ">", "value": null, "price": null }] })
          //       }
          //       }
          //       />

          //       <div className="pc-header">
          //         <span>Silme</span>
          //         <span>Oparetör</span>
          //         <span>Tutar</span>
          //         <span>Minumum değer</span>
          //         <span>Maksimum değer</span>
          //       </div>

          //       {countPricNumber.deci_calculators && countPricNumber.deci_calculators.map((d, i) => <div className="countPriceContent">

          //         <div className="paymentContent pc-mr-10">
          //           <Button id={i} icon="pi pi-trash" className="p-button-danger" onClick={(e) => {
          //             let temporary = countPricNumber;
          //             temporary.deci_calculators.splice(i, 1);
          //             // temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
          //             setCountPricNumber({ ...countPricNumber, temporary })
          //           }} />
          //         </div>

          //         <div className="paymentContent pc-w-110">
          //           <Dropdown id={i} placeholder="op" className="opHeight" value={d.op} options={oPItems}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.deci_calculators];
          //               temporary[i] = e.target.value == ">" ? { "op": e.target.value, "value": temporary[i].value, "price": temporary[i].price } : { "op": e.target.value, "price": temporary[i].price, "min": temporary[i].min, "max": temporary[i].max }
          //               setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
          //             }}
          //           />
          //         </div>
          //         <div className="paymentContent pc-w-100 pc-ml-15">
          //           <InputText
          //             id={i}
          //             placeholder="Tutar"
          //             value={d.price}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.deci_calculators];
          //               temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
          //               setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
          //             }}
          //           />
          //         </div>

          //         {d.op == ">" ? <div className="paymentContent pc-w-100 pc-ml-15">
          //           <InputText
          //             id={i}
          //             placeholder="Min. Değer"
          //             value={d.value}
          //             onChange={(e) => {
          //               let temporary = [...countPricNumber.deci_calculators];
          //               temporary[i] = { "op": temporary[i].op, "value": e.target.value, "price": temporary[i].price }
          //               setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
          //             }}
          //           />
          //         </div>
          //           :
          //           <>
          //             <div className="paymentContent pc-w-100 pc-ml-15">
          //               <InputText
          //                 placeholder="Min. Değer"
          //                 value={d.min}
          //                 onChange={(e) => {
          //                   let temporary = [...countPricNumber.deci_calculators];
          //                   //if (minValueCheck(e.target.value, temporary[i].max) == true) return;
          //                   temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": e.target.value, "max": temporary[i].max }
          //                   setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
          //                 }}
          //               />
          //             </div>
          //             <div className="paymentContent pc-w-100 pc-ml-15">
          //               <InputText
          //                 placeholder="Max. Değer"
          //                 value={d.max}
          //                 onChange={(e) => {
          //                   let temporary = [...countPricNumber.deci_calculators];
          //                   //if (maxValueCheck(e.target.value, temporary[i].min) == true) return;
          //                   temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": temporary[i].min, "max": e.target.value }
          //                   setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
          //                 }}
          //               />
          //             </div>
          //           </>
          //         }
          //       </div>)}
          //     </div>

          //     <div className="paymentContent pc-w-250">
          //       {/* <Button label="Kaydet" onClick={(e) => dispatch(saveProgressPayment(countPricNumber))} /> */}
          //       <Button label="Kaydet" onClick={(e) => {
          //         // countPricNumber.count_calculators.map(dt => {
          //         //   //console.log(dt)
          //         //   if (dt.max < dt.min) {
          //         //     toastCallback("warn", "Maksimum değer minumumdan değerden küçük olamaz!")
          //         //     return
          //         //   } else if (dt.min > dt.max) {
          //         //     toastCallback("warn", "Minumum değer maksimumdan değerden büyük olamaz!")
          //         //     return
          //         //   }
          //         // })

          //         let maxMinValueCheckCount = countPricNumber.count_calculators.filter(dt => (parseInt(dt.max) < parseInt(dt.min) || parseInt(dt.min) > parseInt(dt.max)))
          //         let maxMinValueCheckDeci = countPricNumber.deci_calculators.filter(dt => (parseInt(dt.max) < parseInt(dt.min) || parseInt(dt.min) > parseInt(dt.max)))

          //         //let bb =  countPricNumber.count_calculators.filter(dt => dt.min > dt.max)
          //         //if(aa.length > 0 || bb.length > 0 ){
          //         if (maxMinValueCheckCount.length > 0 || maxMinValueCheckDeci.length > 0) {
          //           toastCallback("warn", "Minumum yada maksimum değerler yanlış")
          //         } else {
          //           dispatch(saveProgressPayment(projectApplicationDetailReview.review.id, countPricNumber, toastCallback))
          //         }

          //       }
          //       } />
          //     </div>

          //   </div>
          // </div>
          <ProgressPayment
            toastCallback={toastCallback}
            id={
              projectApplicationDetailReview.review &&
              projectApplicationDetailReview.review.id
            }
            type="jobApplicationManagement"
          />
        ),
      },
    },
    {
      menuItem: "Sgk",
      pane: {
        key: "tab5",
        content: (
          <Sgk
            id={
              projectApplicationDetailReview.review &&
              projectApplicationDetailReview.review.id
            }
            toastCallback={toastCallback}
          />
        ),
      },
    },
  ];

  const modalStyle = {
    width: modalType === "order-management-new" ? "450px" : "650px",
  };

  const aa = useSelector((state) => state.unplannedCheckReducer);

  const unplannedCheckState = (e) => {
    let ch = {
      id: e.target.id,
      value: e.target.defaultValue,
      checked: e.target.checked,
    };

    dispatch({
      type: TYPES.CHECK_UNPLANNED,
      payload: ch,
    });

    //checked={checked} id={"check-" + i} value={unplanned.delivery_date}
  };

  const UnplannedOrderTable = () => {
    return (
      <React.Fragment>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Sipariş sayı</Table.HeaderCell>
              <Table.HeaderCell>Tarih</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {unplanned_orders &&
              unplanned_orders.map((unplanned, i) => (
                <Table.Row>
                  {/* <Checkbox toggle/> 
                 <Checkbox id={"check-" + i} checked={"check-" + i == (Object.keys(aa).length === 0 ? "" : aa.data.id) ? aa.data.checked : false} value={unplanned.delivery_date} onChange={e => unplannedCheckState(e)} ></Checkbox> */}

                  <Checkbox
                    id={"check-" + i}
                    value={unplanned.delivery_date}
                    defaultChecked={
                      aa.data && aa.data.map((d) => d.id).includes("check-" + i)
                    }
                    onChange={(e) => unplannedCheckState(e)}
                  ></Checkbox>

                  <Table.Cell>{unplanned.order_count}</Table.Cell>
                  <Table.Cell>
                    {new Date(unplanned.delivery_date).toLocaleDateString(
                      "tr-TR"
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <i class="file word icon"></i>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  };

  const UnplannedOrderSend = () => {
    return (
      <React.Fragment>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Sipariş sayı</Table.HeaderCell>
              <Table.HeaderCell>Tarih</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {unplanned_orders &&
              unplanned_orders.map((unplanned, i) => (
                <Table.Row>
                  {/* <Checkbox toggle/> */}
                  <Checkbox></Checkbox>
                  <Table.Cell>{unplanned.order_count}</Table.Cell>
                  <Table.Cell>{unplanned.delivery_date}</Table.Cell>
                  <i class="file word icon"></i>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  };

  let ManualOrder = {
    content: (
      <React.Fragment>
        <Segment>
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <InputDialogs
                onInputChange={onInputChange}
                onContactChange={onContactChange}
                selectedContact={selectedContact}
                list={dropDownList}
                submitted={submitted}
                onInputNumberChange={onInputNumberChange}
                customDialog={UnplannedOrderLeftSide}
              />
            </Grid.Column>
            <Grid.Column>
              <InputDialogs
                onInputChange={onInputChange}
                onContactChange={onContactChange}
                selectedContact={selectedContact}
                list={dropDownList}
                submitted={submitted}
                onInputNumberChange={onInputNumberChange}
                customDialog={UnplannedOrderRightSide}
              />

              {modalType == "order-management-update-order" && (
                <>
                  <p>Dağıtım Şubesi</p>{" "}
                  <Dropdown
                    value={
                      product.assigned_branch &&
                      typeof product.assigned_branch == "object"
                        ? product.assigned_branch.id
                        : product.assigned_branch
                    }
                    optionValue="id"
                    options={getBranchList.data}
                    optionLabel="name"
                    onChange={(e) => onInputChange(e, "assigned_branch")}
                  />
                </>
              )}
            </Grid.Column>
          </Grid>
          <Divider vertical></Divider>
        </Segment>
      </React.Fragment>
    ),
  };

  // <LivePopup /> component.
  // live tracking popup tab content
  const liveTrackingTab = [
    {
      menuItem: "Canlı Takip Detay",
      pane: {
        key: "tab1",
        content: (
          <div>
            {
              <div style={{ overflow: "overlay" }}>
                <React.Fragment>
                  <Segment>
                    <Grid columns={2} relaxed="very">
                      <Grid.Column>
                        <InputDialogs
                          onInputChange={onInputChange}
                          onContactChange={onContactChange}
                          selectedContact={selectedContact}
                          list={dropDownList}
                          submitted={submitted}
                          onInputNumberChange={onInputNumberChange}
                          customDialog={trackingLiveLeftFrame}
                        />

                        {/** only shown on undelivered orders */}
                        {product.delivery_status == "NOT_DELIVERED" && (
                          <div className="p-field">
                            <label>Ziyareti Ertele</label>
                            <Calendar
                              id="basic"
                              className="visitDCa"
                              dateFormat="dd.mm.yy"
                              value={new Date(visitDelayDt)}
                              onChange={(e) => setVisitDelayDt(e.value)}
                            />
                            <p
                              className="visitDelayP"
                              onClick={() => visitDelayDt && visitDelaySave()}
                            >
                              Kaydet
                            </p>
                          </div>
                        )}

                        {/** only shown on undelivered orders */}
                        {product.delivery_status == "NOT_DELIVERED" && (
                          <div
                            className="p-field"
                            style={{ marginTop: "40px", marginLeft: "-11px" }}
                          >
                            {/* <p className="visitDelayP" onClick={() => dispatch(willBeReturn(product.order_oid, toastCallback, hideDialog))}>İade Et</p> */}
                            <p
                              className="visitDelayP"
                              onClick={() => willBeReturnClick(product)}
                            >
                              İade Et
                            </p>
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <InputDialogs
                          onInputChange={onInputChange}
                          onContactChange={onContactChange}
                          selectedContact={selectedContact}
                          list={dropDownList}
                          submitted={submitted}
                          onInputNumberChange={onInputNumberChange}
                          customDialog={trackingLiveRightFrame}
                        />
                      </Grid.Column>
                    </Grid>

                    <Divider vertical>-</Divider>
                  </Segment>
                </React.Fragment>
              </div>
            }
          </div>
        ),
      },
    },
    {
      menuItem: "Sipariş Geçmişi",
      pane: {
        key: "tab2",
        content: (
          <div>
            {
              <div style={{ overflow: "overlay" }}>
                <LiveTrackingHistory product={product} />
              </div>
            }
          </div>
        ),
      },
    },
    differentPage != null && {
      // customer panel control. hide this tab
      menuItem: "Teslimat Durumu Değiştirme",
      pane: {
        key: "tab3",
        content: (
          <div>
            {
              <div style={{ overflow: "overlay" }}>
                <LiveTrackingChangeDeliveryStatus
                  product={product}
                  setProductDialog={setProductDialog}
                  toastCallback={toastCallback}
                  willBeReturnClick={willBeReturnClick}
                />
              </div>
            }
          </div>
        ),
      },
    },
  ];

  return (
    <>
      <Dialog
        visible={productDialog}
        style={modalStyle}
        //header={modalType === "order-management-new" || modalType === "order-management-unplanned-order" || modalType === "order-management-manual-order" ? modalType : ""}
        header={
          (modalType === "order-management-new" && "Yeni Siparişleri Yükle") ||
          (modalType === "order-management-unplanned-order" &&
            "Planlanmamış Siparişler") ||
          (modalType === "order-management-unplanned-order-send-to-plan" &&
            "Planlamaya Gönderilen Siparişler") ||
          (modalType === "order-management-manual-order" && "Yeni Sipariş") ||
          (modalType === "order-management-update-order" &&
            "Siparişi güncelle") ||
          (modalType === "ProjectDetail-detail" && "İncele") ||
          "Düzenle"
        }
        modal
        className="p-fluid"
        // footer={() => tabStatus != true ? (modalType === "livetracking") ? differentPage != null && liveDialogFooter : productDialogFooter(modalType) : projectDetailDialogFooter()}
        footer={() =>
          !tabStatus
            ? productDialogFooter(modalType)
            : projectDetailDialogFooter()
        }
        onHide={hideDialog}
        modalType={modalType}
      >
        <div>
          {/* {modalType} */}
          {tabStatus == true ? (
            <Tab panes={panes} renderActiveOnly={false} />
          ) : modalType === "livetracking" ? (
            <Tab panes={liveTrackingTab} renderActiveOnly={false} />
          ) : modalType === "vehicle-management-new" ? (
            <VehicleManagementPopup />
          ) : modalType === "vehicle-management-update" ? (
            <VehicleManagementPopup />
          ) : modalType === "order-management-unplanned-order" ? (
            <UnplannedOrderTable />
          ) : modalType === "order-management-unplanned-geocoding" ? (
            <UnplannedOrderTable />
          ) : modalType === "order-management-unplanned-order-send-to-plan" ? (
            <UnplannedOrderTable />
          ) : modalType === "order-management-unplanned-order-send" ? (
            <UnplannedOrderSend />
          ) : modalType === "order-management-manual-order" ? (
            ManualOrder.content
          ) : modalType === "order-management-update-order" ? (
            ManualOrder.content
          ) : (
            <InputDialogs
              onInputChange={onInputChange}
              onContactChange={onContactChange}
              selectedContact={selectedContact}
              list={dropDownList}
              region={regionDropdownList}
              dropDownAdminWarehouseList={dropDownAdminWarehouseList}
              submitted={submitted}
              onInputNumberChange={onInputNumberChange}
              customDialog={customDialog}
              toastCallback={toastCallback}
              InputControlled={(selected) => setDisplayInput(selected)}
              displayInput={displayInput}
            />
          )}

          {/* {inputText} */}
          {/* {Calender}
          {inputNumber}
          {dropDowm} */}

          {/*<label htmlFor={col.field}>{col.header}</label>*/}
          {/* <InputText
                  id={col.field}
                  value={col.field}
                  onChange={(e) => onInputChange(e, col.name)}
                  required
                  autoFocus
                  className={classNames({ "p-invalid": submitted && !col.field })}
                />*/}

          {/*submitted && !col.field && (
                  <small className="p-error">{col.header} is required.</small>
                )*/}
        </div>

        {/*product.image && (
          <img
            src={`showcase/demo/images/product/${product.image}`}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={product.image}
            className="product-image"
          />
        )} 
        <div className="p-field">
          <label htmlFor="name">Müşteri Adı</label>
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            value={product.description}
            onChange={(e) => onInputChange(e, "description")}
            required
            rows={3}
            cols={20}
          />
        </div>

        <div className="p-field">
          <label className="p-mb-3">Category</label>
          <div className="p-formgrid p-grid">
            <div className="p-field-radiobutton p-col-6">
              <RadioButton
                inputId="category1"
                name="category"
                value="Accessories"
                onChange={onCategoryChange}
                checked={product.category === "Accessories"}
              />
              <label htmlFor="category1">Accessories</label>
            </div>
            <div className="p-field-radiobutton p-col-6">
              <RadioButton
                inputId="category2"
                name="category"
                value="Clothing"
                onChange={onCategoryChange}
                checked={product.category === "Clothing"}
              />
              <label htmlFor="category2">Clothing</label>
            </div>
            <div className="p-field-radiobutton p-col-6">
              <RadioButton
                inputId="category3"
                name="category"
                value="Electronics"
                onChange={onCategoryChange}
                checked={product.category === "Electronics"}
              />
              <label htmlFor="category3">Electronics</label>
            </div>
            <div className="p-field-radiobutton p-col-6">
              <RadioButton
                inputId="category4"
                name="category"
                value="Fitness"
                onChange={onCategoryChange}
                checked={product.category === "Fitness"}
              />
              <label htmlFor="category4">Fitness</label>
            </div>
          </div>
        </div>

        <div className="p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="price">Price</label>
            <InputNumber
              id="price"
              value={product.price}
              onValueChange={(e) => onInputNumberChange(e, "price")}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div className="p-field p-col">
            <label htmlFor="quantity">Quantity</label>
            <InputNumber
              id="quantity"
              value={product.quantity}
              onValueChange={(e) => onInputNumberChange(e, "quantity")}
              integeronly
            />
          </div>
        </div> */}
      </Dialog>
      {/* <Dialog
        visible={productDialog_update}
        style={{ width: "450px" }}
        header="Update Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter_update}
        onHide={hideDialog_update}
      >


        <div>
          {inputText}
          {dropDowm}
        </div>
      </Dialog> */}

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "450px" }}
        header="Uyarı"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {/* {(() => {
            if (product && modalType != "livetracking") {
              return (
                <span>
                  Silmek istediğinize emin misiniz <b>{product.name || product.code || product.order_code}</b>?
                </span>
              )
            } else {
              return ( //live tracking 
                <span>
                  Siparişin durumu iade statüsüne dönüştürülecek. Onaylıyor musunuz ?
                </span>
              )
            }
          })()} */}

          {product && (
            <span>
              Silmek istediğinize emin misiniz{" "}
              <b>{product.name || product.code || product.order_code}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={vehicleZoneDialog}
        style={{ width: "450px" }}
        onHide={vehicleHideDialog}
        footer={vehicleDialogFooter}
      >
        {/* <dl>
          <dt>İstanbul</dt>
          <dd><Checkbox /> çekmeköy</dd>
          <dd><Checkbox />  ümraniye</dd>
          <dt>Koceli</dt>
          <dd><Checkbox />  Gebze</dd>
        </dl> */}
        <dl>
          {/* {vehicleZoneData && (
            vehicleZoneData.data.data.map((zone, i) => {
              return (
                <dt>
                  {zone.name}
                  {zone.zones.map((zonesDt, j) => {
                    // return (
                    //    <dd><Checkbox id={zonesDt.zone_id} key={j} defaultChecked={zonesDt.zone_id == zoneSelectedID[j] ? true : false} onChange={e => selectedZoneMethod(e)} /> {zonesDt.name}</dd>
                    // )

                    return (
                      <dd><Checkbox id={zonesDt.zone_id} key={j} defaultChecked={zoneSelectedID.includes(zonesDt.zone_id)} onChange={e => selectedZoneMethod(e)} /> {zonesDt.name}</dd>
                    )
                  })}
                </dt>
              )
            })
          )} */}
        </dl>
      </Dialog>

      <Dialog
        visible={vehicleProjectDialog}
        style={{ width: "450px" }}
        onHide={projectHideDialog}
        footer={projectDialogFooter}
      >
        <p>Projeler</p>
        <dl>
          {/* {vehicleProjectData && (
            vehicleProjectData.data.map((prj, i) => {
              return (
                <dd><Checkbox id={prj.id} key={i} defaultChecked={projectSelectedID.includes(prj.id)} onChange={e => selectedProjectMethod(e)} /> {prj.name}</dd>
              )
            })
          )} */}
        </dl>
      </Dialog>
    </>
  );
};

export default DialogModal;
