import PropTypes from "prop-types";
import isString from "lodash/isString";
import { useDropzone } from "react-dropzone";
// @mui
import { Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import Image from "../Image";
import Iconify from "../Iconify";
import RejectionFiles from "./RejectionFiles";
import { CloseIcon } from "theme/overrides/CustomIcons";
import { DeleteForever, UploadFile } from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadAvatar({
  error,
  file,
  helperText,
  sx,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  const handleRemove = () => {
    other.onRemove(null);
  };
  console.log("other", other);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={file && fileRejections.length !== 0 ? 12 : 6}>
        <RootStyle
          sx={{
            ...((isDragReject || error) && {
              borderColor: "error.light",
            }),
            ...sx,
          }}
        >
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.72 }),
            }}
          >
            <input {...getInputProps()} />

            {file && (
              <Image
                alt="avatar"
                src={isString(file) ? file : file.preview}
                sx={{ zIndex: 8 }}
              />
            )}

            <PlaceholderStyle
              className="placeholder"
              sx={{
                ...(file && {
                  opacity: 0,
                  color: "common.white",
                  bgcolor: "grey.900",
                  "&:hover": { opacity: 0.72 },
                }),
                ...((isDragReject || error) && {
                  bgcolor: "error.lighter",
                }),
                "& svg": {
                  mr: 1,
                  width: 20,
                  height: 20,
                  overflow: "visible !important",
                  display: "block !important",
                },
              }}
            >
              <Typography variant="caption" color={error ? "error" : "inherit"}>
                {isDragReject ? "Dosya Seçilemedi" : "Dosya Seç"}
              </Typography>
            </PlaceholderStyle>
          </DropZoneStyle>
        </RootStyle>
      </Grid>
      {file && fileRejections.length === 0 && (
        <Grid item xs={6} md={4} lg={6}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {file.name}
            </Typography>
            <DeleteForever
              sx={{ color: "error.main", width: 20, cursor: "pointer" }}
              onClick={handleRemove}
            />
          </Stack>
        </Grid>
      )}
      {helperText && helperText}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={file} />}
    </Grid>
  );
}
