import TYPES from "../store/types";

export const getStatus = (type) => {
  switch (type) {
    case "ORDER_ACCEPTED":
      return "Paket kabul yapıldı";
    case "COMPLETED":
      return "Rota Tamamlandı";
    case "BRANCH_ACCEPTED":
      return "Şube kabul yapıldı";
    case "BRANCH_RETURN_ACCEPTED":
      return "Şubeye iade kabul yapıldı";
    case "ON_THE_WAY":
    case "ON_THE_WAY_TO_CUSTOMER":
    case "CUSTOMER_INFORMED_1":
    case "CUSTOMER_INFORMED_2":
    case "CUSTOMER_READINESS_RECEIVED":
      return "Dağıtımda";
    case "COURIER_DEBIT":
      return "Kurye zimmetine aldı";
    case "WILL_BE_RETURN":
      return "İade edilecek";
    case "DELIVERY_CANCELLED":
      return "Teslimat iptal edildi";
    case "CUSTOMER_INFORMED_3":
      return "Son 30 dakika";
    case "DELIVERED":
      return "Teslim edildi";
    case "PARTIALLY_DELIVERED":
      return "Kısmen teslim edildi";
    case "NOT_DELIVERED":
      return "Teslim edilemedi";
    case "DELIVERY_POSTPONED":
      return "Teslim ertelendi";
    case "DELIVERY_PLANNED":
      return "Planlandı";
    case "REVERT_FROM_PLAN":
      return "Plandan Çıkarıldı";
    case "ORDER_RECEIVED":
      //return "Sipariş alındı";
      return "Paket kabul bekliyor";
    case "SENT_TO_PLAN":
      return "Planlamaya gönderildi";
    case "PACKAGE_NOT_FOUND":
      return "Depoda Bulunamadı";
    case "PACKAGE_CANCELLED":
      return "Teslim Edilemedi";
    case "PACKAGE_RECEIVED":
      return "Paket kabul bekliyor";
    case "PACKAGE_ACCEPTED":
      return "Paket kabul edildi";
    case "SCANNED_AT_OUTLET":
      return "Müşteride Okundu";
    default:
      return type;
  }
};

export const GONDERIM_TURLERI = [
  { value: "RETURN", label: "İade Siparişi" },
  { value: "EXPRESS", label: "Hemen Teslimat" },
];

export const loadingActive = (dispatch, status) => {
  if (status === true) {
    dispatch({
      type: TYPES.LOADING_CHANGE,
      payload: true,
    });
  } else {
    dispatch({
      type: TYPES.LOADING_CHANGE,
      payload: false,
    });
  }
};

export const ARAC_TIPLERI = {
  Minivan: {
    icon: "/images/customerDashboard/frigo.svg",
    color: "warning",
  },
  Motorsiklet: {
    icon: "/images/customerDashboard/motorcycle.svg",
    color: "success",
  },
  Panelvan: {
    icon: "/images/customerDashboard/panelvan.svg",
    color: "info",
  },
  Binek: {
    icon: "/images/customerDashboard/panelvan.svg",
    color: "info",
  },
  "Kapalı Kasa": {
    icon: "/images/customerDashboard/standart.svg",
    color: "error",
  },
  "Standart Kapalı Kasa": {
    icon: "/images/customerDashboard/standart.svg",
    color: "error",
  },
  "Kapalı Kasa Frigolu": {
    icon: "/images/customerDashboard/standart.svg",
    color: "error",
  },
};

export const statusFilter = (order) => {
  if (order.fail_code) {
    return getStatus(order.fail_code);
  }

  return getStatus(
    ["DELIVERY_PLANNED", "REVERT_FROM_PLAN"].includes(order.delivery_status)
      ? order.debit_type || "ORDER_RECEIVED"
      : order.delivery_status
  );
};

export const COUNTRYID_TURKIYE = "402881c83ea3a40a013ea3a4eb520001";

export const COMPANY_TYPE_LIST = [
  {
    value: 0,
    label: "Belirsiz",
  },
  {
    value: 1,
    label: "Kişisel",
  },
  {
    value: 2,
    label: "Sınırlı",
  },
];

export const DRIVER_TYPE_LIST = [
  {
    value: 1,
    label: "Araç Sahibi",
  },
  {
    value: 2,
    label: "Bağlı Kişi",
  },
  {
    value: 3,
    label: "Bağlı Şirket",
  },
];

export const SLOT_DAY_LIST = [
  {
    id: 1,
    name: "Pazartesi",
  },
  {
    id: 2,
    name: "Salı",
  },
  {
    id: 3,
    name: "Çarşamba",
  },
  {
    id: 4,
    name: "Perşembe",
  },
  {
    id: 5,
    name: "Cuma",
  },
  {
    id: 6,
    name: "Cumartesi",
  },
  {
    id: 7,
    name: "Pazar",
  },
];
