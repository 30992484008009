import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";
import List from "./List";
import Detail from "./Detail";
import { Services } from "api/Services";
import Loader from "react-loader-spinner";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

const server = new Services();

DriverManagement.propTypes = {
  style: PropTypes.array,
};

export default function DriverManagement(props) {
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  const [detail, setDetail] = React.useState(null);
  const [drivers, setDrivers] = React.useState([]);
  const [driverFilterOptions, setDriverFilterOptions] = React.useState([]);
  const [getFiltersData, setGetFiltersData] = React.useState(false);
  const [driverFilter, setDriverFilter] = React.useState({
    branchId: "",
    companyId: "",
    name: "",
    active: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isEditItem, setIsEditItem] = React.useState(false);

  const getDrivers = async () => {
    try {
      const res = await server.getDriverList(driverFilter);
      setDrivers(res.entity.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getDriverFilter = async () => {
    try {
      const res = await server.getDriverFilter();
      setDriverFilterOptions(res.entity.data[0]);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      await server.deleteDriver(dialogVisible);
      enqueueSnackbar("Sürücü başarıyla silindi", { variant: "success" });
      getDrivers();
      setDialogVisible(false);
    } catch (error) {
      enqueueSnackbar("Sürücü silinirken bir hata oluştu", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getDriverFilter();
  }, []);

  useEffect(() => {
    getDrivers();
    setGetFiltersData(false);
  }, [getFiltersData]);

  const toggleModal = (item) => {
    setIsEditItem(true);
    setDetail(item);
    setShowModal(true);
  };

  function handleCloseDetail() {
    setShowModal(false);
    setDetail(null);
    history.push({
      hash: "",
    });
  }

  useEffect(() => {
    if (history.location.hash === "#form") {
      setShowModal(true);
    }
  }, [history.location.hash]);

  return (
    <Layout
      {...props}
      heading={showModal ? "Yeni Kurye Oluştur" : "Kurye Yönetimi"}
      links={[{ name: "Genel", href: "/" }, { name: "Kurye Yönetimi" }]}
      action={
        showModal ? null : (
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setIsEditItem(false);
              setDetail(null);
              setShowModal(true);
              history.push({
                hash: "#form",
              });
            }}
          >
            Kurye Ekle
          </Button>
        )
      }
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Oval" color="tomato" height={40} width={40} />
        </Box>
      ) : (
        <>
          {showModal ? (
            <Detail
              getDrivers={getDrivers}
              handleClose={handleCloseDetail}
              isEditItem={isEditItem}
              detail={detail}
            />
          ) : (
            <List
              data={drivers}
              filters={driverFilter}
              setFilters={setDriverFilter}
              options={driverFilterOptions}
              setGetFiltersData={setGetFiltersData}
              handleDelete={(id) => setDialogVisible(id)}
              toggleModal={toggleModal}
            />
          )}
        </>
      )}

      <Dialog open={Boolean(dialogVisible)}>
        <DialogTitle>Silmek istiyor musunuz?</DialogTitle>
        <DialogActions>
          <Button
            fullWidth
            color="info"
            onClick={() => setDialogVisible(false)}
          >
            Vazgeç
          </Button>
          <Button fullWidth color="error" onClick={handleDelete} autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
