import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./Item";

const listFilterData = (data) => {
  return data.map((item) => {
    return {
      detailData: item,
      // Custom render item
      id: item.id,
      firma: item.firm?.name || "",
      fullName: `${item.first_name || ""} ${item.last_name || ""}`,
      username: item.username,
      phone: item.phone,
      email: item.email,
    };
  });
};

const TableList = ({ loading, data, handleDeleteItem, handleEditItem }) => {
  const tableData = listFilterData(data);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  // Table Head Data
  const tableHeadData = [
    { key: "firma", title: "Proje", onSort },
    { key: "fullName", title: "Ad Soyad", onSort },
    { key: "username", title: "Kullanıcı Adı", onSort },
    { key: "phone", title: "Telefon", onSort },
    { key: "email", title: "E-Posta", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((item) => item.key)],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  return (
    <CustomTable
      {...{
        loading,
        // Filter
        filterHidden: true,
        filterSearch,
        filterSearchOnChange,
        filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
        // Table
        tableHeadData,
        tableHeadDataOrder: order,
        tableHeadDataOrderBy: orderBy,
        tableData,
        tableDataFiltered,
        // UseTable
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        tableBodyRender: tableBodyRenderData.map((item, index) => {
          return (
            <Item
              key={`${item.id}-${index}`}
              item={item}
              handleEdit={handleEditItem}
              handleDelete={handleDeleteItem}
            />
          );
        }),
      }}
    />
  );
};

export default TableList;
