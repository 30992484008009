import React from "react";
import * as Icon from "iconsax-react";
import { OverlayView } from "@react-google-maps/api";
import {
  Fade,
  Stack,
  Paper,
  Popper,
  Divider,
  useTheme,
  Typography,
} from "@mui/material";

export default function MapLegendsBranchesMarker(props: any) {
  const { palette } = useTheme();
  const { branch } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <OverlayView
      zIndex={open ? 11 : -1}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      bounds={
        {
          ne: { lat: branch.address.latitude, lng: branch.address.longitude },
          sw: { lat: branch.address.latitude, lng: branch.address.longitude },
        } as any
      }
    >
      <Stack
        onMouseEnter={handleClick()}
        onMouseLeave={() => setOpen(false)}
        sx={{
          zIndex: open ? 11 : -1,
          cursor: "pointer",
          width: 40,
          height: 40,
          position: "relative",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          animation: "effectBgBranch 1.5s linear infinite",
          "@keyframes effectBgBranch": {
            "0%": {
              bgcolor: `transparent`,
            },
            "50%": {
              bgcolor: palette.error.main,
            },
            "100%": {
              bgcolor: `transparent`,
            },
          },
        }}
      >
        <Stack
          sx={{
            width: 30,
            height: 30,
            textAlign: "center",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: palette.common.white,
            border: `2px solid ${palette.error.main}`,
          }}
        >
          <Icon.Building3 size={18} variant="Bulk" color={palette.error.main} />
          <Popper
            transition
            open={open}
            disablePortal
            placement="top"
            anchorEl={anchorEl}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ px: 2, py: 1, minWidth: 250 }}>
                  <Typography variant="subtitle2" flex={1} textAlign="left">
                    Şube Bilgisi
                  </Typography>
                  <Divider />
                  <Stack spacing={1} pt={1} textAlign="left">
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        Adı:
                      </Typography>
                      <Typography variant="caption">{branch.name}</Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        Kodu:
                      </Typography>
                      <Typography variant="caption">
                        {branch.address.code}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography noWrap variant="caption" minWidth={60}>
                        İl/İlçe:
                      </Typography>
                      <Typography variant="caption">
                        {branch.address.city}/{branch.address.county}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack spacing={0.5}>
                      <Typography noWrap variant="subtitle2">
                        Proje Depoları
                      </Typography>
                      <Stack spacing={1}>
                        {branch.warehouses.map((warehouse: any) => {
                          return (
                            <Typography key={warehouse.id} variant="caption">
                              {warehouse.name}
                            </Typography>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Stack>
      </Stack>
    </OverlayView>
  );
}
