import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import { useHistory } from "react-router";
import qs from "query-string";
import { TextFirstLetter } from "utils";
import moment from "moment";

Item.propTypes = {
  item: PropTypes.object,
  pageTitle: PropTypes.string,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
};

export default function Item({ item, pageTitle, selected, onSelectRow }) {
  const history = useHistory();

  const goDetail = () => {
    history.push({
      pathname: `/distribution/detail/sub-detail`,
      search: qs.stringify({
        siparisKodu: item.detailData.order_code,
        pageTitle: pageTitle,
      }),
    });
  };

  const CustomSlot = (slot) => {
    if (!slot.start || !slot.end) return "-";
    return (
      <Typography variant="caption">
        <span>{moment(slot.start).format("DD/MM/YYYY")}</span> <br />
        {moment(slot.start).add(3, "hours").format("HH:mm")} -
        {moment(slot.end).add(3, "hours").format("HH:mm")}
      </Typography>
    );
  };

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          {item.sira}
        </Typography>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Stack direction="row" spacing={1}>
          <Avatar
            alt={item.kuryeName}
            color={createAvatar(item.kuryeName).color}
          >
            {createAvatar(item.kuryeName).name}
          </Avatar>
          <Stack>
            <Typography variant="body2" noWrap>
              {TextFirstLetter(item.kuryeName)}
            </Typography>
            <Typography noWrap variant="body2" sx={{ color: "text.disabled" }}>
              {TextFirstLetter(item.kuryeUsername)}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="caption">
          {TextFirstLetter(item.projeBilgisi)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.sender)}
        </Typography>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="caption">{TextFirstLetter(item.sube)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.depo)}</Typography>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="caption">{item.packageCode}</Typography>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="caption">{item.siparisKodu}</Typography>
      </TableCell>
      <TableCell
        onClick={() => goDetail(item.siparisKodu)}
        sx={{ cursor: "pointer" }}
      >
        <Stack>
          <Typography variant="caption" noWrap>
            {TextFirstLetter(item.alici.name)}
          </Typography>
          <Typography noWrap variant="caption" sx={{ color: "text.disabled" }}>
            {item.alici.phone}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Label color={item.durumx === "DELIVERED" ? "success" : "warning"}>
          {item.durumx}
        </Label>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.siparisTarih}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.geoScore}</Typography>
      </TableCell>
      <TableCell>{CustomSlot(item.slot)}</TableCell>
      <TableCell>
        <Typography variant="caption">{item.teslimatZamani}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.city)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.county)}
        </Typography>
      </TableCell>
      <TableCell sx={{ minWidth: "180px" }}>
        <Typography variant="caption">
          {TextFirstLetter(item.address)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
          {item.quantity} adet
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.deci}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.description || ""}</Typography>
      </TableCell>
    </TableRow>
  );
}
