import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import moment from "moment";
import qs from "query-string";
import { NavLink } from "react-router-dom";
import {
  AccessTimeOutlined,
  SnoozeOutlined,
  TaskAltOutlined,
  Videocam,
} from "@mui/icons-material";
import { TextFirstLetter } from "utils";

Item.propTypes = {
  item: PropTypes.object,
};

// {
//   "outlet_count": 4,
//   "route_id": "f2c5f490-51cd-4aff-b6d2-785f5c1930eb",
//   "route_start_time": 1665652985013,
//   "route_status": "ON_THE_WAY",
//   "order_count": 4,
//   "deci": 212,
//   "driver_name": "KURYE BEŞ",
//   "package_count": 6,
//   "delivered_count": 2,
//   "delivered_deci": 44,
//   "branch_name": "ANADOLU-AVRUPA",
//   "not_delivered_count": 2,
//   "route_end_time": null,
//   "route_date": 1662508800000,
//   "driver_username": "5666666666",
//   "vehicle_name": "KURYEBES"
// }

export default function Item({ item }) {
  const DetailProps = {
    pathname: `/shipments-detail`,
    search: qs.stringify({
      routeId: item.route_id,
      pageTitle: "Gönderiler",
      in_route: true,
    }),
    hash: "#fromDashboard",
  };

  const routeTime = item.route_start_time
    ? moment(item.route_start_time).format("DD.MM.YYYY HH:mm")
    : null;
  const routeEndTime = item.route_end_time
    ? moment(item.route_end_time).format("DD.MM.YYYY HH:mm")
    : null;

  const renderStatus = useMemo(() => {
    if (item.route_status === "COMPLETED") {
      return <TaskAltOutlined fontSize="small" color={"success"} />;
    } else if (item.route_status === "ON_THE_WAY") {
      return <AccessTimeOutlined fontSize="small" color={"secondary"} />;
    } else if (item.route_status === "PLANNED") {
      return <SnoozeOutlined fontSize="small" color="warning" />;
    }
  }, [item.route_status]);

  const renderStatusText = useMemo(() => {
    if (item.route_status === "COMPLETED") {
      return "Tamamlandı";
    } else if (item.route_status === "ON_THE_WAY") {
      return "Başladı";
    } else if (item.route_status === "PLANNED") {
      return "Başlamadı";
    }
  }, [item.route_status]);

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.driver_name)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {moment(item.route_date).add(3, "hours").format("DD.MM.YYYY HH:mm")}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {renderStatus}
          <Typography variant="caption">{renderStatusText}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack>
          {routeTime && <Typography variant="caption">{routeTime}</Typography>}
          {routeEndTime ? (
            <Typography variant="caption">{routeEndTime}</Typography>
          ) : (
            <Typography
              variant="caption"
              color="#b1b1b1"
              sx={{ letterSpacing: "2.5px!important" }}
            >
              --.--.---- --:--
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item?.branch_name || "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.vehicle_name)}
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          color="primary"
          disabled={item.order_count === 0}
          component={NavLink}
          to={DetailProps}
        >
          <Label
            color="primary"
            sx={{ cursor: item.order_count > 0 ? "pointer" : "auto" }}
          >{`${item.order_count} adet`}</Label>
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="secondary"
          disabled={item.package_count === 0}
          component={NavLink}
          to={DetailProps}
        >
          <Label
            color="secondary"
            sx={{ cursor: item.package_count > 0 ? "pointer" : "auto" }}
          >{`${item.package_count} adet`}</Label>
        </Button>
      </TableCell>

      <TableCell>
        <Button
          color="success"
          disabled={item.delivered_count === 0}
          component={NavLink}
          to={{
            pathname: `/shipments-detail`,
            search: qs.stringify({
              routeId: item.route_id,
              status: "DELIVERED",
              in_route: true,
              pageTitle: "Gönderiler",
            }),
            hash: "#fromDashboard",
          }}
        >
          <Label
            color="success"
            sx={{ cursor: item.delivered_count > 0 ? "pointer" : "auto" }}
          >{`${item.delivered_count} adet`}</Label>
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="warning"
          disabled={item.not_delivered_count === 0}
          component={NavLink}
          to={{
            pathname: `/shipments-detail`,
            search: qs.stringify({
              routeId: item.route_id,
              in_route: true,
              pageTitle: "Gönderiler",
              status: "NOT_DELIVERED",
            }),
            hash: "#fromDashboard",
          }}
        >
          <Label
            color="error"
            sx={{ cursor: item.not_delivered_count > 0 ? "pointer" : "auto" }}
          >
            {`${item.not_delivered_count} adet`}
          </Label>
        </Button>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{`${item.outlet_count} adet`}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {(item.deci * 10) % 10 === 0 ? item.deci : item.deci.toFixed(2)}
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="caption">
          {item.delivered_percentage.toFixed(2)}%
        </Typography>
        <LinearProgress
          value={item.delivered_percentage}
          variant="determinate"
          color="success"
        />
      </TableCell> */}
      {/* <TableCell>
        <Typography variant="caption">
          {item.quantity_percentage.toFixed(2)}%
        </Typography>
        <LinearProgress
          value={item.quantity_percentage}
          variant="determinate"
          color="success"
        />
      </TableCell> */}
      <TableCell align="right">
        <IconButton
          LinkComponent={NavLink}
          to={{
            pathname: `/live-tracking`,
            search: qs.stringify({ routeId: item.route_id }),
          }}
        >
          <Videocam />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
