import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { statusFilter } from "helper";
import tableTextLabels from "utils/tableTextLabels";
import { LocalShipping, Visibility } from "@mui/icons-material";
import exportCsv from "utils/exportCsv";

const DataGrid = styled(MUIDataTable)(({ theme }) => ({
  "& thead th": { backgroundColor: "#F4F6F8!important", whiteSpace: "nowrap" },
  "& tbody tr": { cursor: "pointer" },
}));

const columnsInitial = [
  {
    name: "Sipariş kodu",
    options: { filterType: "textField", display: "true" },
  },
  { name: "Teslim durumu", options: { display: "true" } },
  {
    name: "Barkod",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "",
    options: {
      filter: false,
      display: false,
      viewColumns: false,
      download: false,
    },
  },
];

const AllPackages = (props) => {
  const { packages, onRowClick } = props;
  const [columns, setColumns] = React.useState(columnsInitial);
  const newPackages = packages.map((order) => {
    return [
      order.order.order_code,
      order.delivery_status,
      order.barcode,
      order,
    ];
  });
  return (
    <DataGrid
      title={`Tüm Paketler (${packages.length})`}
      data={newPackages}
      columns={columns}
      options={{
        onDownload: (buildHead, buildBody, columns, data) => {
          exportCsv({ fileName: "canliTakipOrders", columns, data });
          return false;
        },
        setTableProps: () => ({ size: "small" }),
        search: true,
        download: true,
        print: true,
        viewColumns: false,
        filterType: "dropdown",
        tableBodyHeight: "calc(55vh - 166px)",
        responsive: "vertical",
        pagination: false,
        selectableRowsHeader: false,
        selectableRows: "none",
        textLabels: tableTextLabels,
        onViewColumnsChange: (changedColumn, action) => {
          var newCols = columns.map((col) => {
            let newCol = Object.assign({}, col);
            if (changedColumn === newCol.name) {
              if (action === "add") {
                newCol.options.display = "true";
              } else {
                newCol.options.display = "false";
              }
            }
            return newCol;
          });
          setColumns(newCols);
        },
        customRowRender: (data, dataIndex, rowIndex) => {
          const item = data[data.length - 1];
          const isCompleted = ["DELIVERED", "NOT_DELIVERED"].includes(
            item.delivery_status
          );
          return (
            <TableRow key={rowIndex} hover>
              <TableCell>
                <Link
                  href={`/distribution/detail/sub-detail?siparisKodu=${data[0]}`}
                  target="_blank"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "secondary.main",
                  }}
                >
                  <Visibility fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="subtitle2">{data[0]}</Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <LocalShipping
                    fontSize="small"
                    sx={{
                      color: isCompleted ? "success.main" : "warning.main",
                    }}
                  />
                  <Typography variant="caption">
                    {data[1] ? data[1] : "-"}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{data[2]}</Typography>
              </TableCell>
            </TableRow>
          );
        },
      }}
    />
  );
};

export default AllPackages;
