import { useEffect } from "react";
import { Router, Route, Switch, useLocation, useHistory } from "react-router";
import {
  Login,
  Reports,
  Jobs,
  Project,
  CustomerManagement,
  CustomerManagementProjectDefinition,
  CustomerManagementLiveTracking,
  ProjectApplication,
  ProjectDetail,
  DistributionReport,
  VehicleReport,
  VehicleStatusReport,
  ProgressPayment,
  MessageManagement,
  LiveTracking,
  OrderManagement,
  UnhandledUrl,
  BringoCustomer,
  VehicleManagement,
  DriverManagement,
  VehicleUsageReport,
  Debit,
  AdminOrderGeocode,
  Branch,
  AdminOrderManagement,
  Distribution,
  DistributionDetail,
  DistributionDetailSub,
  CustomerDashboard,
  CustomerDashboardList,
  DispatcherLive,
  DispatcherPlanner,
  CustomerManagementShipmentsDetail,
  Analytics,
  UsersManagement,
  WarehouseManagement,
  RegionalList,
  DispatcherPlannerNew,
  Hierarchy,
} from "../screens";
import SuperAdminTenantDefinition from "screens/SuperAdminTenantDefinition";
import SuperAdminUsersManagement from "screens/SuperAdminUsersManagement";
import SuperAdminDriverCompanyManagement from "screens/SuperAdminDriverCompanyManagement";
import SuperAdminVehicleManagement from "screens/SuperAdminVehicleManagement/Home";
import SuperAdminDriverManagement from "screens/SuperAdminDriverManagement/Home";
import SuperAdminCustomerManagement from "screens/SuperAdminCustomerManagement/Home";
import AuthRoutes from "../components/AuthRoutes";
import { Auth } from "../api/Auth";
import history from "../store/history";
import { useSelector } from "react-redux";
import PlannerProvider from "contexts/PlannerProvider";

function RootRouter() {
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  const userdata = useSelector((state) => state.userReducer);
  const location = useLocation();
  const urlHistory = useHistory();
  const superadminurls = [
    "/",
    "/login",
    "/bringo-tenant/tenant-definition",
    "/bringo-tenant/customer-management",
    "/bringo-tenant/analytics",
    "/bringo-tenant/users-management",
    "/bringo-tenant/driver-company-management",
    "/bringo-tenant/vehicle-management",
    "/bringo-tenant/driver-management",
    "/driver-management",
    "/shipments-detail",
    "/distribution",
    "/distribution/detail",
    "/distribution/detail/sub-detail",
  ];
  const adminurls = [
    "/",
    "/login",
    "/customer-management",
    "/analytics",
    "/project-definition",
    "/shipments-detail",
    "/users-management",
    "/warehouse-management",
    "/dispatcherlive",
    "/jobs",
    "/project",
    "/reports",
    "/report1",
    "/report2",
    "/report3",
    "/report4",
    "/report5",
    "/report6",
    "/project-application",
    "/project-application/:id",
    "/messages-management",
    "/vehicle-management",
    "/driver-management",
    "/dbt",
    "/geocode",
    "/kpi",
    "/branch",
    "/admin-order-management",
    "/distribution",
    "/live-tracking",
    "/distribution/detail",
    "/distribution/detail/sub-detail",
    "/regional-list",
    "/dispatcher-new-planner",
    "/hierarchy",
  ];
  const urls = [
    "/",
    "/login",
    "/bringo-customer",
    "/bringo-customer/kpi",
    "/bringo-customer/live-tracking",
    "/bringo-customer/messages-management",
    "/bringo-customer/order-management",
    "/bringo-customer/dashboard",
    "/bringo-customer/dashboard/list",
    "/distribution",
    "/distribution/detail",
    "/distribution/detail/sub-detail",
    "/bringo-customer/dispatcherlive",
  ];

  useEffect(() => {
    if (userdata.user != "") {
      if (userdata.user.user_type == "ADMIN") {
        const isValid = adminurls.find(
          (e) => e == `/${location.pathname.split("/")[1]}`
        );
        if (!isValid) {
          urlHistory.push("/404");
        }
      } else if (userdata.user.user_type == "SUPERADMIN") {
        const isValid = superadminurls.find((e) => {
          const result = e == location.pathname;
          return result;
        });
        if (!isValid) {
          urlHistory.push("/404");
        }
      } else {
        const isValid = urls.find((e) => e == location.pathname);
        if (!isValid) {
          urlHistory.push("/404");
        }
      }
    }

    // <!-- Global site tag (gtag.js) - Google Analytics -->
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("config", "G-B9BC62GB6W", { page_path: location.pathname });
    //   gtag('event', 'page_view', {
    //     page_title: 'xxxx',
    //     page_path:  location.pathname,
    //     send_to: 'G-B9BC62GB6W'
    //   })
    //   gtag('set', 'page_path', location.pathname);
    //   gtag('event', 'page_view');
    //   gtag('config', 'G-B9BC62GB6W', {
    //     page_title : location.pathname,
    //     page_location: location.pathname
    // });

    gtag("js", new Date());
    gtag("set", { page_location: location.pathname });
    gtag("config", "G-B9BC62GB6W");
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    // <Suspense fallback={<div>Loading...</div>}>
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={["/", "/login"]}
          type="public"
          isAuthUser={isAuthUser}
          component={Login}
        />

        <AuthRoutes
          exact
          path="/bringo-tenant/tenant-definition"
          isAuthUser={isAuthUser}
          type="private"
          component={SuperAdminTenantDefinition}
        />
        <AuthRoutes
          exact
          path="/bringo-tenant/users-management"
          isAuthUser={isAuthUser}
          type="private"
          component={SuperAdminUsersManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-tenant/driver-company-management"
          isAuthUser={isAuthUser}
          type="private"
          component={SuperAdminDriverCompanyManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-tenant/vehicle-management"
          isAuthUser={isAuthUser}
          type="private"
          component={SuperAdminVehicleManagement}
        />
        <AuthRoutes
          exact
          // path="/bringo-tenant/driver-management"
          path="/driver-management"
          isAuthUser={isAuthUser}
          type="private"
          component={DriverManagement}
          // component={SuperAdminDriverManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-tenant/customer-management"
          isAuthUser={isAuthUser}
          type="private"
          component={SuperAdminCustomerManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-tenant/analytics"
          isAuthUser={isAuthUser}
          type="private"
          component={Analytics}
        />
        <AuthRoutes
          exact
          path="/regional-list"
          isAuthUser={isAuthUser}
          type="private"
          component={RegionalList}
        />
        <AuthRoutes
          exact
          path="/users-management"
          isAuthUser={isAuthUser}
          type="private"
          component={UsersManagement}
        />
        <AuthRoutes
          exact
          path="/warehouse-management"
          isAuthUser={isAuthUser}
          type="private"
          component={WarehouseManagement}
        />
        <AuthRoutes
          exact
          path="/customer-management"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerManagement}
        />
        <AuthRoutes
          exact
          path="/analytics"
          isAuthUser={isAuthUser}
          type="private"
          component={Analytics}
        />
        <AuthRoutes
          exact
          path="/project-definition"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerManagementProjectDefinition}
        />
        <AuthRoutes
          exact
          path="/shipments-detail"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerManagementShipmentsDetail}
        />
        <AuthRoutes
          exact
          path="/live-tracking"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerManagementLiveTracking}
        />
        <AuthRoutes
          exact
          path="/jobs"
          isAuthUser={isAuthUser}
          type="private"
          component={Jobs}
        />
        <AuthRoutes
          exact
          path="/project"
          isAuthUser={isAuthUser}
          type="private"
          component={Project}
        />
        <AuthRoutes
          exact
          path="/reports"
          isAuthUser={isAuthUser}
          type="private"
          component={Reports}
        />
        <AuthRoutes
          exact
          path="/report1"
          isAuthUser={isAuthUser}
          type="private"
          component={DistributionReport}
        />
        <AuthRoutes
          exact
          path="/report2"
          isAuthUser={isAuthUser}
          type="private"
          component={VehicleReport}
        />
        <AuthRoutes
          exact
          path="/vehicle-management"
          isAuthUser={isAuthUser}
          type="private"
          component={VehicleManagement}
        />
        <AuthRoutes
          exact
          path="/driver-management"
          isAuthUser={isAuthUser}
          type="private"
          component={DriverManagement}
        />
        <AuthRoutes
          exact
          path="/dbt"
          isAuthUser={isAuthUser}
          type="private"
          component={Debit}
        />
        <AuthRoutes
          exact
          path="/geocode"
          isAuthUser={isAuthUser}
          type="private"
          component={AdminOrderGeocode}
        />
        <AuthRoutes
          exact
          path="/branch"
          isAuthUser={isAuthUser}
          type="private"
          component={Branch}
        />
        <AuthRoutes
          exact
          path="/report3"
          isAuthUser={isAuthUser}
          type="private"
          component={VehicleStatusReport}
        />
        <AuthRoutes
          exact
          path="/report4"
          isAuthUser={isAuthUser}
          type="private"
          component={ProgressPayment}
        />
        <AuthRoutes
          exact
          path="/report5"
          isAuthUser={isAuthUser}
          type="private"
          component={VehicleUsageReport}
        />
        <AuthRoutes
          exact
          path="/report6"
          isAuthUser={isAuthUser}
          type="private"
          component={LiveTracking}
        />
        <AuthRoutes
          exact
          path="/admin-order-management"
          isAuthUser={isAuthUser}
          type="private"
          component={AdminOrderManagement}
        />
        <AuthRoutes
          exact
          path="/kpi"
          isAuthUser={isAuthUser}
          type="private"
          component={BringoCustomer}
        />
        <AuthRoutes
          exact
          path="/project-application"
          isAuthUser={isAuthUser}
          type="private"
          component={ProjectApplication}
        />
        <AuthRoutes
          exact
          path="/project-application/:id"
          isAuthUser={isAuthUser}
          type="private"
          component={ProjectDetail}
        />
        <AuthRoutes
          exact
          path="/messages-management"
          isAuthUser={isAuthUser}
          type="private"
          component={MessageManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/kpi"
          isAuthUser={isAuthUser}
          type="private"
          component={BringoCustomer}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/live-tracking"
          isAuthUser={isAuthUser}
          type="private"
          component={LiveTracking}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/messages-management"
          isAuthUser={isAuthUser}
          type="private"
          component={MessageManagement}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/order-management"
          isAuthUser={isAuthUser}
          type="private"
          component={OrderManagement}
        />
        <AuthRoutes
          exact
          path="/distribution"
          isAuthUser={isAuthUser}
          type="private"
          component={Distribution}
        />
        <AuthRoutes
          exact
          path="/distribution/detail"
          isAuthUser={isAuthUser}
          type="private"
          component={DistributionDetail}
        />
        <AuthRoutes
          exact
          path="/distribution/detail/sub-detail"
          isAuthUser={isAuthUser}
          type="private"
          component={DistributionDetailSub}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/dashboard"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerDashboard}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/dashboard/list"
          isAuthUser={isAuthUser}
          type="private"
          component={CustomerDashboardList}
        />
        <AuthRoutes
          exact
          path="/bringo-customer/dispatcherlive"
          isAuthUser={isAuthUser}
          type="private"
          component={DispatcherLive}
        />
        <AuthRoutes
          exact
          path="/dispatcherlive"
          isAuthUser={isAuthUser}
          type="private"
          component={DispatcherLive}
        />
        <AuthRoutes
          exact
          path="/dispatcherlive"
          isAuthUser={isAuthUser}
          type="private"
          component={DispatcherLive}
        />
        {/* <AuthRoutes
          exact
          path="/dispatcherplanner"
          isAuthUser={isAuthUser}
          type="private"
          component={DispatcherPlanner}
        /> */}
        <AuthRoutes
          exact
          path="/hierarchy"
          isAuthUser={isAuthUser}
          type="private"
          component={Hierarchy}
        />
        <AuthRoutes
          exact
          path="/dispatcher-new-planner"
          isAuthUser={isAuthUser}
          type="private"
          component={(props) => {
            return (
              <PlannerProvider>
                <DispatcherPlannerNew {...props} />
              </PlannerProvider>
            );
          }}
        />
        <Route path="/404" component={UnhandledUrl} />
      </Switch>
    </Router>
    // </Suspense>
  );
}

export default RootRouter;
