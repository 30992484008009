import TYPES from "../../store/types";
import React, { useEffect, useRef, useState } from "react";
import "./AutoComplete.css";
import { InputText } from "primereact/inputtext";
import { getGeocodeSuggestion } from "../../store/actions/orderAction";
import { useSelector, useDispatch } from "react-redux";

const AutoComplete = (props) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState([]);

  let newAddress = useRef();

  const dispatch = useDispatch();
  const getSuggestion = useSelector((state) => state.suggestionReducer);

  useEffect(() => {
    props.onChange(selectedSuggestion);
  }, [selectedSuggestion]);

  const handleSelect = (value) => {
    let newAdressValue = value.address;
    newAddress.current.value = newAdressValue;
    setSelectedSuggestion(newAdressValue);
    props.setGeocodeCoordinate({
      latitude: parseFloat(value.latitude),
      longitude: parseFloat(value.longitude),
    });
    dispatch({
      type: TYPES.GET_SUGGESTION,
      payload: {},
    });
  };

  const onKeyUp = (event) => {
    let eventValue = event.target.value;
    if (eventValue.length === 0) {
      dispatch({
        type: TYPES.DELETE_LIST_SUGGESTION,
        payload: [],
      });
    }
    setTimeout(function () {
      if (newAddress.current.value != eventValue || eventValue.length < 3)
        return;

      dispatch(getGeocodeSuggestion(eventValue));
    }, 1000);
  };
  return (
    <>
      <InputText
        type="text"
        className="p-d-block p-mb-2 w100"
        onKeyUp={onKeyUp}
        ref={newAddress}
        placeholder="Arama"
      />
      <div>
        {Object.keys(getSuggestion).length > 0 &&
          typeof getSuggestion?.data !== "undefined" && (
            <div>
              <ul className="suggestionList">
                {getSuggestion?.data?.map((item, i) => {
                  return (
                    <li
                      className="suggestionListItem"
                      onMouseDown={() => handleSelect(item)}
                      key={i}
                    >
                      <span>{item.address}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
      </div>
    </>
  );
};

export default AutoComplete;
