import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
  Input,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Services } from "api/Services";

// form
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFSelect, RHFTextField } from "components/hook-form";
import {
  DatePicker,
  DateTimePicker,
  LoadingButton,
  LocalizationProvider,
  TimePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ChevronLeft } from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { VehicleEvaluationResult } from "screens/VehicleManagement/VehicleEvaluationResult";
import { useTranslation } from "react-i18next";
import RHFMultiSelect from "components/hook-form/RHFMultiSelect";
import { tr } from "date-fns/locale";
import { underAgeValidate } from "utils";
import { COUNTRYID_TURKIYE, SLOT_DAY_LIST } from "helper";
import CustomSelect from "components/CustomSelect";

const SPACING = 2;

const getDate = (date) => moment(date).format("YYYY-MM-DD");

const server = new Services();

Form.propTypes = {
  style: PropTypes.array,
};

const refrigerateField = [
  {
    key: true,
    value: "Evet",
  },
  {
    key: false,
    value: "Hayır",
  },
];

// branches
// companies
// customers
// drivers
// vehicle_models
// vehicle_types

export default function Form({
  isEdit,
  currentData,
  cities,
  counties,
  options,
  handleClose,
  refreshData,
  activeStep,
  changeStep,
  prevButtonDisable,
  steps,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [cityList, setCityList] = useState(cities || []);
  const [countyList, setCountyList] = useState(counties || []);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [currentStartAsInt, setCurrentStartAsInt] = useState(0);
  const [currentStart, setCurrentStart] = useState(null);
  const [currentEndAsInt, setCurrentEndAsInt] = useState(0);
  const [currentEnd, setCurrentEnd] = useState(null);
  const [currentDayNumber, setCurrentDayNumber] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [cityId, setCityId] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [countyId, setCountyId] = React.useState(null);
  const [selectedCounty, setSelectedCounty] = React.useState(null);
  const [selectedCitiesAndCounties, setSelectedCitiesAndCounties] =
    React.useState([]);

  const getCityList = async () => {
    try {
      const city = await server.getCityList(COUNTRYID_TURKIYE);
      setCityList(city);
    } catch (error) {}
  };

  const getCountyList = async (cityId) => {
    const county = await server.getCountyList(cityId);
    setCountyList(county);
  };

  React.useState(() => {
    if (!isEdit) {
      getCityList();
    }
  }, []);

  /* React.useState(() => {
    if (
      cities.length &&
      counties.length &&
      isEdit &&
      currentData &&
      currentData.city &&
      currentData.city.id
    ) {
      setCityList(cities);
      console.log("test county list", counties);
      setCountyList(counties);
      setCityId(currentData.city.id);
      setCountyId(currentData.county.id);
    }
  }, [cities, counties]); */

  React.useState(() => {
    if (isEdit && currentData) {
      if (currentData.slots && currentData.slots.length) {
        setSelectedSlots(currentData.slots);
      }
      if (currentData.city && currentData.city.id) {
        getCountyList(currentData.city.id);
      }
      if (currentData.counties && currentData.counties.length >= 0) {
        const cityAnyCountyList = [];
        currentData.counties.forEach((element) => {
          const model = {
            cityId: element.city_oid,
            cityName: element.city_name,
            countyId: element.county_oid,
            countyName: element.county_name,
          };
          cityAnyCountyList.push(model);
        });

        setSelectedCitiesAndCounties(cityAnyCountyList);
      }
    }
  }, [currentData]);

  React.useState(() => {
    if (isEdit && currentData && currentData.city && currentData.city.id) {
      setCityId(currentData.city.id);
      // getCountyList(currentData.city.id);
    }
  }, [cityList]);

  React.useState(() => {
    if (isEdit && currentData && currentData.county && currentData.county.id) {
      setCountyId(currentData.county.id);
    }
  }, [countyList]);

  const onChangeCity = (v) => {
    setCountyId([]);
    getCountyList(v.target.value);
    setCityId(v.target.value);
    // setValue("city_oid", v.target.value);
    const city = cityList.find((item) => item.id === v.target.value);
    setSelectedCity({ id: v.target.value, name: city.name });
  };

  const onChangeCounty = (v) => {
    setCountyId(v.target.value);
    // setValue("county_oid", v.target.value);
    const county = countyList.find((item) => item.id === v.target.value);
    setSelectedCounty({ id: v.target.value, name: county.name });
  };

  const handleSlotDayChange = (event, value) => {
    if (value && value.props) {
      setCurrentDayNumber(value.props.value);
      setCurrentDay({ id: value.props.value, name: value.props.children });
    } else {
      setCurrentDayNumber(null);
      setCurrentDay({ id: "", name: "" });
    }
  };

  const getIntValueFromDate = (date) => {
    const dateValue = new Date(date);
    return dateValue.getHours() * 60 + dateValue.getMinutes();
  };

  const handleSlotStart = (event) => {
    if (event) {
      const date = new Date(event);
      setCurrentStart(date);
      setCurrentStartAsInt(getIntValueFromDate(event));
    }
  };

  const handleSlotEnd = (event) => {
    setCurrentEnd(new Date(event));
    setCurrentEndAsInt(getIntValueFromDate(event));
  };

  const handleClearCountyList = () => {
    setCountyList([]);
  };

  const handleClearSelectedSlotStateValue = () => {
    setCurrentDayNumber(null);
    setCurrentDay({ id: "", name: "" });
    setCurrentEnd(null);
    setCurrentEndAsInt(0);
    setCurrentStart(null);
    setCurrentStartAsInt(0);
    handleClearCountyList();
  };

  const handleClearSelectedCitiesAndCountiesStateValue = () => {
    setSelectedCity(null);
    setSelectedCounty(null);
    setCityId(null);
    setCountyId(null);
    handleClearCountyList();
  };

  const handleCloseAndReset = () => {
    handleClose();
    handleClearSelectedSlotStateValue();
    handleClearSelectedCitiesAndCountiesStateValue();
    setSelectedCitiesAndCounties([]);
    setSelectedSlots([]);
  };

  const handleCountyIdListForApi = () => {
    const countyIdList = [];
    selectedCitiesAndCounties.forEach((item) => {
      countyIdList.push(item.countyId);
    });
    return countyIdList;
  };

  const handleAddSlotInSlotList = () => {
    if (
      currentDayNumber &&
      currentStart &&
      currentStartAsInt &&
      currentEnd &&
      currentEndAsInt
    ) {
      const model = {
        day_number: currentDayNumber,
        dayName: SLOT_DAY_LIST.find((item) => item.id === currentDayNumber)
          .name,
        start: currentStart,
        startAsInt: currentStartAsInt,
        end: currentEnd,
        endAsInt: currentEndAsInt,
      };
      if (!checkIsDataInTheSelectedSlotslist()) {
        selectedSlots.push(model);
        setSelectedSlots(selectedSlots);
        handleClearSelectedSlotStateValue();
        // handle2ClearSelectedSlotFormValue();
      } else {
        enqueueSnackbar("Eklenen slot bilgileri listede mevcut", {
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Lütfen slot bilgilerini seçiniz", {
        variant: "warning",
      });
    }
  };

  const handleAddCountiesInCityAndCountList = () => {
    if (
      cityId &&
      countyId &&
      selectedCity &&
      selectedCity.name &&
      selectedCity.id &&
      selectedCounty &&
      selectedCounty.name &&
      selectedCounty.id
    ) {
      const model = {
        cityId: selectedCity.id,
        cityName: selectedCity.name,
        countyId: selectedCounty.id,
        countyName: selectedCounty.name,
      };
      if (!checkIsDataInTheSelectedCitiesAnyCountieslist()) {
        selectedCitiesAndCounties.push(model);
        setSelectedCitiesAndCounties(selectedCitiesAndCounties);
        handleClearSelectedCitiesAndCountiesStateValue();
      } else {
        enqueueSnackbar("Eklenen adres bilgileri listede mevcut", {
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Lütfen adres bilgilerini seçiniz", {
        variant: "warning",
      });
    }
  };

  const checkIsDataInTheSelectedSlotslist = () => {
    const index = selectedSlots.findIndex(
      (item) =>
        item.day_number === currentDayNumber &&
        item.startAsInt === currentStartAsInt &&
        item.endAsInt === currentEndAsInt
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  const checkIsDataInTheSelectedCitiesAnyCountieslist = () => {
    const index = selectedCitiesAndCounties.findIndex(
      (item) =>
        item.cityId === selectedCity.id &&
        item.cityName === selectedCity.name &&
        item.countyId === selectedCounty.id &&
        item.countyName === selectedCounty.name
    );
    if (index === -1) {
      return false;
    }
    return true;
  };

  const getDateValueFromInt = (number) => {
    const hour = Math.floor(number / 60);
    const minute = number % 60;

    let datetoReturn = hour.toString();
    let minuteToAdd = minute.toString();

    if (hour.toString().length < 2) {
      datetoReturn = `0${hour}`;
    }
    if (minute.toString().length < 2) {
      minuteToAdd = `0${hour}`;
    }

    return `${datetoReturn}:${minuteToAdd}`;
  };

  const handleHourFormatFromDateString = (date) => {
    let result;
    if (date) {
      if (typeof date === "number") {
        result = getDateValueFromInt(date);
        return result;
      }
      return date.toLocaleTimeString().substring(0, 5);
    }
    return "";
  };

  const handleSlotDayToDisplay = (option) => {
    if (option) {
      if (option.dayName) {
        return option.dayName;
      } else {
        const result = SLOT_DAY_LIST.find(
          (item) => item.id === option.day_number
        );
        return result.name || "";
      }
    }
  };

  const handleDeleteSlotFromList = (option, i) => {
    const result = selectedSlots.filter((item, index) => index !== i);

    setSelectedSlots(result);
  };

  const handleDeleteCityAndCountyFromList = (option, i) => {
    const result = selectedCitiesAndCounties.filter(
      (item, index) => index !== i
    );

    setSelectedCitiesAndCounties(result);
  };

  const evaluationResults = t("vehicle_evaluation_result", {
    returnObjects: true,
  });

  const NewSchema = Yup.object().shape({
    name: Yup.string().required("Plaka alanı zorunlu"),
    company: Yup.string().required("Firma alanı zorunlu"),
    // branches: Yup.array().required("Şube alanı zorunlu"),
    vehicle_model_oid: Yup.string().required("Model alanı zorunlu"),
    refrigerate: Yup.bool().required("İzolasyon alanı zorunlu"),
    vehicle_type_oid: Yup.string().required("Araç tipi alanı zorunlu"),
    drivers: Yup.string().required("Araç sürücüsü alanı zorunlu"),
    maintanence_km: Yup.string().required("Son bakım km alanı zorunlu"),
    maintanence_date: Yup.date().required("Son bakım tarihi alanı zorunlu"),
  });

  const defaultValues = React.useMemo(
    () => ({
      name: currentData?.vehicle_id || "",
      company: currentData?.company.id || "",
      vehicle_model_oid: currentData?.vehicle_model.id || "",
      refrigerate: currentData?.refrigerate,
      vehicle_type_oid: currentData?.vehicle_type?.id || "",
      drivers: currentData?.drivers[0]?.id || "",
      maintanence_km: currentData?.maintanence_km || "",
      maintanence_date: currentData?.maintanence_date || "",
      evaluation_result: currentData?.evaluation_result || "",
      city_oid: currentData?.city?.id || cityId,
      county_oid: currentData?.county?.id || countyId,
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const getSlotListForAPI = () => {
    const list = [];
    selectedSlots.forEach((item) => {
      const model = {
        day_number: item.day_number,
        start: item.startAsInt || item.start,
        end: item.endAsInt || item.end,
      };
      list.push(model);
    });
    return list;
  };

  const onSubmit = async () => {
    if (moment(values.maintanence_date).get("years") < 1900) {
      enqueueSnackbar("Son Bakım tarihi alanını kontrol ediniz.", {
        variant: "warning",
      });
      return;
    }
    const sendData = {
      ...values,
      vehicle_id: values.name,
      name: values.name,
      branches: values.branches,
      drivers: [values.drivers],
      maintanence_date: getDate(values.maintanence_date),
      evaluation_result: values.evaluation_result,
      /* city_oid: cityId,
      county_oid: countyId, */
      counties: handleCountyIdListForApi(),
      vehicle_slots: getSlotListForAPI(),
      slots: getSlotListForAPI(),
    };

    if (isEdit) {
      sendData.id = currentData.id;
    }

    try {
      await server.createUpdateVehicle(sendData);
      enqueueSnackbar(
        `Araç başarıyla ${isEdit ? "düzenlendi" : "kaydedildi"}!`,
        { variant: "success" }
      );
      refreshData();
      handleCloseAndReset();
    } catch (error) {
      let msg = "Bir sorun oluştu";
      if (error && error.message) {
        msg = error.message;
      }
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          py: 4,
          px: 2,
          mt: 4,
          border: "1px dotted #F4F6F8",
          boxShadow: "none",
        }}
      >
        {activeStep === 1 ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  gap: 2,
                  "& > div": {
                    width: { sm: "calc(50% - 16px)" },
                    flexGrow: 1,
                  },
                }}
              >
                <RHFTextField
                  required
                  size="small"
                  name="name"
                  label="Plaka"
                  type="name"
                  disabled={isEdit}
                />
                <RHFSelect
                  required
                  size="small"
                  label="Firma"
                  name="company"
                  SelectProps={{ native: false }}
                >
                  {options.companies.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect
                  required
                  size="small"
                  label="Model"
                  name="vehicle_model_oid"
                  SelectProps={{ native: false }}
                >
                  {options.vehicle_models.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect
                  required
                  size="small"
                  label="İzolasyon Var/Yok"
                  name="refrigerate"
                  SelectProps={{ native: false }}
                >
                  {refrigerateField.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect
                  required
                  size="small"
                  label="Araç Tipi/Yok"
                  name="vehicle_type_oid"
                  SelectProps={{ native: false }}
                >
                  {options.vehicle_types.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect
                  required
                  size="small"
                  label="Araç Sürücüsü"
                  name="drivers"
                  SelectProps={{ native: false }}
                >
                  {options.drivers.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFTextField
                  required
                  size="small"
                  name="maintanence_km"
                  label="Son Bakım KM"
                  type="number"
                />
                <Controller
                  name="maintanence_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={tr}
                    >
                      <DatePicker
                        mask="__.__.____"
                        inputFormat="dd.MM.yyyy"
                        label="Son Bakım Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={
                              error
                                ? "Girmiş olduğunuz tarih formatı hatalı. Lütfen geçerli bir tarih giriniz."
                                : null
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <Grid xs={12}>
                  <RHFSelect
                    size="small"
                    label="Değerlendirme"
                    name="evaluation_result"
                    SelectProps={{ native: false }}
                    style={{ width: "calc(50% - 8px)" }}
                  >
                    {Object.keys(VehicleEvaluationResult).map((option, i) => (
                      <MenuItem key={`${option.key}-${i}`} value={option}>
                        {/* {t("vehicle_evaluation_result:"+option.toLowerCase())} */}
                        {evaluationResults[option.toLowerCase()]}
                      </MenuItem>
                    ))}
                    {/* {options.drivers.map((option, i) => (
                    <MenuItem key={`${option.key}-${i}`} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))} */}
                  </RHFSelect>
                </Grid>
                {/* <>
                  {isEdit && !cityId && cityList.length === 0 ? (
                    <>
                      <Stack
                        alignItems="center"
                        sx={{
                          py: 2,
                          bgcolor: "white",
                          borderRadius: "0 0 8px 8px",
                        }}
                      >
                        <CircularProgress size={15} />
                      </Stack>
                    </>
                  ) : (
                    <FormControl>
                      <TextField
                        select
                        required
                        size="small"
                        label="İl"
                        // name="city_oid"
                        style={{ width: "100%" }}
                        SelectProps={{ native: false }}
                        onChange={onChangeCity}
                        value={cityId || ""}
                      >
                        {cityList.map((option, i) => (
                          <MenuItem key={`${option.id}-${i}`} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                  {isEdit && !countyId && countyList.length === 0 ? (
                    <>
                      <Stack
                        alignItems="center"
                        sx={{
                          py: 2,
                          bgcolor: "white",
                          borderRadius: "0 0 8px 8px",
                        }}
                      >
                        <CircularProgress size={15} />
                      </Stack>
                    </>
                  ) : (
                    <FormControl fullWidth>
                      <TextField
                        select
                        required
                        size="small"
                        label="İlçe"
                        // name="county_oid"
                        style={{ width: "calc(50% - 8px)" }}
                        SelectProps={{ native: false }}
                        onChange={onChangeCounty}
                        value={countyId || ""}
                      >
                        {countyList.map((option, i) => (
                          <MenuItem key={`${option.id}-${i}`} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                </> */}

                <Grid xs={12}>
                  <Divider />
                </Grid>
                <Stack spacing={2} mt={2}>
                  <Grid container spacing={SPACING}>
                    <Grid item xs={10}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField
                                  select
                                  size="small"
                                  label="İl"
                                  name="city_oid"
                                  style={{ width: "100%" }}
                                  SelectProps={{ native: false }}
                                  onChange={onChangeCity}
                                  value={cityId || ""}
                                >
                                  {cityList.map((option, i) => (
                                    <MenuItem
                                      key={`${option.id}-${i}`}
                                      value={option.id}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField
                                  select
                                  size="small"
                                  label="İlçe"
                                  name="county_oid"
                                  style={{ width: "100%" }}
                                  SelectProps={{ native: false }}
                                  onChange={onChangeCounty}
                                  value={countyId || ""}
                                >
                                  {countyList.map((option, i) => (
                                    <MenuItem
                                      key={`${option.id}-${i}`}
                                      value={option.id}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }} />
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleAddCountiesInCityAndCountList}
                      >
                        Listeye Ekle
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={SPACING} flex={1}>
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="subtitle1" flex={1}>
                                  Eklenen İl ve İlçeler
                                </Typography>
                              </Stack>
                              <TableContainer>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>İl</TableCell>
                                      <TableCell>İlçe</TableCell>
                                      <TableCell>Sil</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {selectedCitiesAndCounties.map(
                                      (option, i) => (
                                        <TableRow
                                          hover
                                          sx={{ "& td": { border: 0 } }}
                                        >
                                          <TableCell>
                                            {option.cityName}
                                          </TableCell>
                                          <TableCell>
                                            {option.countyName}
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              onClick={() =>
                                                handleDeleteCityAndCountyFromList(
                                                  option,
                                                  i
                                                )
                                              }
                                            >
                                              Sil
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                    {selectedCitiesAndCounties.length === 0 && (
                                      <TableRow>
                                        <TableCell align="center" colSpan={6}>
                                          <Box sx={{ py: 1 }}>
                                            <Typography
                                              gutterBottom
                                              align="center"
                                            >
                                              List Boş
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Stack spacing={2}>
            <Grid container spacing={SPACING}>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            size="small"
                            label="Slot Gün"
                            style={{ width: "100%" }}
                            SelectProps={{ native: false }}
                            onChange={handleSlotDayChange}
                            value={currentDay?.id || ""}
                          >
                            {SLOT_DAY_LIST.map((option, i) => (
                              <MenuItem
                                key={`${option.id}-${i}`}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={tr}
                        >
                          <TimePicker
                            label="Slot Başlangıç Tarihi"
                            onChange={(newValue) => {
                              handleSlotStart(newValue);
                            }}
                            value={currentStart}
                            type="time"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                type="time"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={tr}
                        >
                          <TimePicker
                            label="Slot Başlangıç Tarihi"
                            onChange={(newValue) => {
                              handleSlotEnd(newValue);
                            }}
                            value={currentEnd}
                            type="time"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                type="time"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddSlotInSlotList}
                >
                  Listeye Ekle
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={SPACING} flex={1}>
                  <Divider />
                  <Box>
                    <Grid container spacing={SPACING}>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" mb={1}>
                          <Typography variant="subtitle1" flex={1}>
                            Eklenen Slotlar
                          </Typography>
                        </Stack>
                        <Divider sx={{ my: SPACING }} />
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Gün</TableCell>
                                <TableCell>Başlangıç Saati</TableCell>
                                <TableCell>Bitiş Saati</TableCell>
                                <TableCell>Sil</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedSlots.map((option, i) => (
                                <TableRow hover sx={{ "& td": { border: 0 } }}>
                                  <TableCell>
                                    {handleSlotDayToDisplay(option)}
                                  </TableCell>
                                  <TableCell>
                                    {handleHourFormatFromDateString(
                                      option.start
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {handleHourFormatFromDateString(option.end)}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        handleDeleteSlotFromList(option, i)
                                      }
                                    >
                                      Sil
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {selectedSlots.length === 0 && (
                                <TableRow>
                                  <TableCell align="center" colSpan={6}>
                                    <Box sx={{ py: 1 }}>
                                      <Typography gutterBottom align="center">
                                        List Boş
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Card>
      <Box width={1}>
        <Divider />
        <Stack
          pt={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            startIcon={<ChevronLeft />}
            color="inherit"
            onClick={() => {
              if (prevButtonDisable) {
                handleCloseAndReset();
              } else {
                changeStep("prev");
              }
            }}
          >
            {activeStep === 1
              ? "Listeye Geri Dön"
              : `${steps[activeStep - 2]}'ye Geri Dön`}
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              color="error"
              variant="outlined"
              onClick={handleCloseAndReset}
            >
              İptal
            </Button>
            {activeStep === 2 ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {isEdit ? "Düzenle" : "Kaydet"}
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let isError = false;
                  let text = "";

                  if (activeStep === 1) {
                    if (
                      [
                        values.branches,
                        values.city,
                        values.company,
                        values.county,
                        values.drivers,
                        values.evaluation_result,
                        values.maintanence_date,
                        values.maintanence_km,
                        values.name,
                        values.refrigerate,
                        values.vehicle_model_oid,
                        values.vehicle_type_oid,
                      ].includes("")
                    ) {
                      text = "Lütfen Araç bilgilerini boş bırakmayınız.";
                      isError = true;
                    }
                  } else if (activeStep === 2) {
                    if (selectedSlots.length === 0) {
                      text = "Lütfen en az bir slot ekleyiniz";
                      isError = true;
                    }
                  }

                  if (isError) {
                    enqueueSnackbar(text, { variant: "warning" });
                  } else {
                    changeStep("next");
                  }
                }}
              >
                İleri
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>

      <Stack spacing={SPACING}>
        <Box
          sx={{
            display: "grid",
            rowGap: 2,
            columnGap: 1,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            },
          }}
        ></Box>

        <Divider />
        {/* <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="error" variant="outlined" onClick={handleClose}>
            Vazgeç
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {isEdit ? "Düzenle" : "Kaydet"}
          </LoadingButton>
        </Stack> */}
      </Stack>
    </FormProvider>
  );
}
