import { Box, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fDateTime } from "utils/formatTime";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
import { getStatus } from "helper";
import moment from "moment";

const PackageTimeline = ({
  activeTimeline,
  data,
  setActiveTimeline,
  packages,
}) => {
  return (
    <Card
      sx={{
        p: 3,
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      <Stack direction="row" spacing={4} mb={2}>
        <Typography
          onClick={() => setActiveTimeline("order")}
          sx={{
            color:
              activeTimeline === "order" ? "primary.main" : "text.secondary",
            cursor: "pointer",
          }}
        >
          Sipariş Hareketleri
        </Typography>
        <Typography
          onClick={() => setActiveTimeline("package")}
          sx={{
            color:
              activeTimeline === "package" ? "primary.main" : "text.secondary",
            cursor: "pointer",
          }}
        >
          Paket Hareketleri
        </Typography>
      </Stack>
      <CardContent sx={{ px: 0, pt: 0 }}>
        {packages?.map((item, index) => (
          <Accordion
            sx={{ backgroundColor: "#f4f5f6" }}
            defaultExpanded={index === 0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{index + 1}. Paket</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Timeline sx={{ px: 0 }}>
                {data.package_history
                  .filter((i) => i.packageCode === item.code)
                  .map((item) => item.history)[0]
                  .map((item) => {
                    const isLast = true;
                    return (
                      <OrderItem key={index} item={item} isLast={isLast} />
                    );
                  })}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        ))}
      </CardContent>
    </Card>
  );
};

export default PackageTimeline;

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

const colors = {
  ORDER_RECEIVED: "success",
  ORDER_ACCEPTED: "primary",
  DELIVERY_PLANNED: "info",
  BRANCH_ACCEPTED: "warning",
  COURIER_DEBIT: "secondary",
  ON_THE_WAY_TO_CUSTOMER: "error",
};

function OrderItem({ item, isLast }) {
  const {
    status,
    username,
    date,
    user_first_name,
    user_last_name,
    courierName,
    delivery_to,
    description,
  } = item;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={colors[status]} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {status === "DELIVERED" && delivery_to ? (
          <Box>
            <Typography variant="subtitle2">
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                <Box>{status}</Box>
                <Box fontStyle="italic">( {delivery_to} )</Box>
              </Stack>
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle2">{getStatus(status)}</Typography>
        )}
        {status === "DELIVERED" ||
        status === "NOT_DELIVERED" ||
        status === "BRANCH_ACCEPTED" ? ( // latest statuses
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {"updateduser"} ( {username} ) -{" "}
            {moment(date).add(3, "hours").format("DD/MM/YYYY HH:mm")}
          </Typography>
        ) : (
          <>
            {/* ???????????? */}
            {user_first_name && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {user_first_name + user_last_name} ( {username} ) -{" "}
                {moment(date).add(3, "hours").format("DD/MM/YYYY HH:mm")}
              </Typography>
            )}
            {!user_first_name && courierName && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {courierName} ( {user_first_name + user_last_name} ) -{" "}
                {moment(date).add(3, "hours").format("DD/MM/YYYY HH:mm")}
              </Typography>
            )}

            {!user_first_name && !courierName && (
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {user_first_name + user_last_name} -{" "}
                {moment(date).add(3, "hours").format("DD/MM/YYYY HH:mm")}
              </Typography>
            )}
          </>
        )}
        {description && (
          <Typography
            component="div"
            variant="caption"
            sx={{ color: "text.secondary" }}
          >
            {description}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}
