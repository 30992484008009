import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

const ALPHA_LETTER_DASH_REGEX = /^[A-Za-zÇĞİÖŞÜçğıöşü ]+$/;
const ONLY_NUMBER_REGEX =
  /^([0-9]|Backspace|Tab|Delete|ArrowLeft|ArrowRight|\.)+$/;

export default function RHFTextField({ name, type, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          onKeyDown={(event) => {
            const numberRegexWitoutDot =
              /^[0-9]|Backspace|Tab|Delete|ArrowLeft|ArrowRight+$/;
            if (
              !["number", "email", "address", "name"].includes(type) &&
              !ALPHA_LETTER_DASH_REGEX.test(event.key)
            ) {
              event.preventDefault();
            }
            if (
              type === "number" &&
              !ONLY_NUMBER_REGEX.test(event.key) &&
              (name === "maintanence_km" || name !== "tc")
            ) {
              event.preventDefault();
            }

            if (name === "tc" && !numberRegexWitoutDot.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(event) => {
            if (type === "number" && name === "tc") {
              if (event.target.value.length < 12) {
                field.onChange(event.target.value);
              } else {
                return;
              }
            } else {
              field.onChange(event.target.value);
            }
          }}
        />
      )}
    />
  );
}
