import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControl, Popper, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  LoadingButton,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/lab";
import { DeleteSweep } from "@mui/icons-material";
import { tr } from "date-fns/locale";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
Filters.propTypes = {
  data: PropTypes.object,
  filter: PropTypes.object,
  handleChangeFilter: PropTypes.func,
  handleFilterReset: PropTypes.func,
  filterLoading: PropTypes.bool,
};

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: "fit-content" }}
      placement="bottom-start"
    />
  );
};

export default function Filters({
  data,
  filter,
  handleChangeFilter,
  filterLoading,
  handleFilterReset,
}) {
  const {
    branches,
    vehicle_types,
    vehicles,
    drivers,
    cities,
    counties,
    customers,
    tenants
    //warehouses
  } = data;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 2,
        columnGap: 0.5,
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)",
          md: "repeat(5, 1fr)",
          lg: "repeat(11, 1fr)",
        },
      }}
    >
      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          size="small"
          limitTags={2}
          multiple
          autoHighlight
          id="tags-standard"
          defaultValue={filter.tenants || null}
          value={filter.tenants || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={tenants || []}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} label="Kiracı" placeholder="Kiracı" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("tenants", newVal)}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          size="small"
          limitTags={2}
          multiple
          autoHighlight
          id="tags-standard"
          defaultValue={filter.customers || null}
          value={filter.customers || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={customers}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField {...params} label="Projeler" placeholder="Projeler" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("customers", newVal)}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={branches}
          defaultValue={filter.branches || null}
          value={filter.branches || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Şubeler" placeholder="Şubeler" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("branches", newVal)}
        />
      </FormControl>
      {/* Depo */}
      {/* <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={warehouses}
          defaultValue={filter.warehouses || null}
          value={filter.warehouses || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Şubeler" placeholder="Şubeler" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("branches", newVal)}
        />
      </FormControl> */}

      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          multiple
          limitTags={2}
          autoHighlight
          size="small"
          id="tags-standard"
          options={vehicle_types}
          defaultValue={filter.vehicle_types || null}
          value={filter.vehicle_types || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Araç Tipi" placeholder="Araç Tipi" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("vehicle_types", newVal)}
        />
      </FormControl>

      <TextField
        size="small"
        fullWidth
        label="Sipariş No"
        value={filter.shipment_number}
        onChange={(val) => handleChangeFilter("shipment_number", val)}
      />

      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={vehicles}
          defaultValue={filter.vehicles || null}
          value={filter.vehicles || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Plaka" placeholder="Plaka" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("vehicles", newVal)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={drivers}
          defaultValue={filter.drivers || null}
          value={filter.drivers || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Şoför" placeholder="Şoför" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("drivers", newVal)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={cities}
          defaultValue={filter.cities || null}
          value={filter.cities || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="İl" placeholder="İl" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("cities", newVal)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <Autocomplete
          PopperComponent={PopperMy}
          limitTags={2}
          size="small"
          multiple
          autoHighlight
          id="tags-standard"
          options={counties}
          defaultValue={filter.counties || null}
          value={filter.counties || null}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="İlçe" placeholder="İlçe" />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          onChange={(e, newVal) => handleChangeFilter("counties", newVal)}
        />
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
        <MobileDatePicker
          mask="__.__.____"
          toolbarPlaceholder="Tarih"
          cancelText="Vazgeç"
          okText="Filtrele"
          inputFormat="dd.MM.yyyy"
          value={filter.start_date}
          onChange={() => {}}
          onAccept={(val) => {
            handleChangeFilter("start_date", val);
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
        />
      </LocalizationProvider>
      <LoadingButton
        color="error"
        onClick={handleFilterReset}
        loading={filterLoading}
        startIcon={<DeleteSweep />}
      >
        Temizle
      </LoadingButton>
    </Box>
  );
}
