import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./item";
import Filter from "./Filter";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  vehicleBracnhList,
  vehicleProjectList,
  vehicleZoneList,
} from "store/actions/vehicleManagementAction";
import { BASE_URL } from "api/Env";
import _ from "lodash";
import moment from "moment";

const listFilterData = (data) => {
  const arr = data.map((item) => {
    return {
      detailData: item,
      // Custom render item
      avatar: item.photo
        ? `${BASE_URL}api/public/document/${item.photo.id}`
        : "",
      id: item.id,
      lastUpdated: item.lastupdated,
      status: item.status ? item.status : "-",
      company: item.company ? item.company.name : "-",
      userType: item.user_type,
      fullName: item.full_name ? item.full_name : "-",
      plaka: item.vehicles[0]?.name || "",
      address: item.address ? item.address : "-",
      phone: item.phone || "",
      createdAt: item.created_at
        ? moment(item.created_at).format("DD.MM.YYYY HH:mm")
        : "-",
    };
  });

  return _.uniqBy(arr, "id");
};

const TableList = ({
  data,
  options,
  handleDetailItemEdit,
  setFilters,
  filters,
  setGetFiltersData,
  handleDelete,
  toggleModal,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(listFilterData(data));
  const [filterSearch, filterSearchOnChange] = React.useState("");

  React.useEffect(() => {
    dispatch(vehicleZoneList());
    dispatch(vehicleBracnhList());
    dispatch(vehicleProjectList());
  }, []);

  const convertDate = (date) => {
    const dateArr = date.split(" ") || [];
    const dateArr2 = dateArr[0]?.split(".");
    const dateArr3 = dateArr[1]?.split(":");
    const dateArr4 = dateArr2[2] + "-" + dateArr2[1] + "-" + dateArr2[0];
    const dateArr5 =
      dateArr4 + "T" + dateArr3[0] + ":" + dateArr3[1] + ":00.000Z";
    return dateArr5;
  };

  React.useEffect(() => {
    if (filters.active === "") {
      setTableData(listFilterData(data));
    } else {
      setTableData(
        filters.active
          ? listFilterData(data).filter((i) => i.status === "ACTIVE")
          : listFilterData(data).filter((i) => i.status !== "ACTIVE")
      );
    }
  }, [data, filters.active]);

  const newData = tableData?.map((item) => {
    return {
      ...item,
      createdAt: convertDate(item.createdAt),
    };
  });
  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "lastUpdated", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "fullName", title: "Kurye Adı", onSort },
    { key: "plaka", title: "Plaka", onSort },
    // { key: "company", title: "Bağlı Firma", onSort },
    { key: "address", title: "Adres", onSort },
    { key: "phone", title: "Telefon", onSort },
    { key: "createdAt", title: "Kayıt Tarihi", onSort },
    { key: "status", title: "Durumu", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData: newData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((i) => i.key)],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  const vehicleProjectData = useSelector(
    (state) => state.vehicleProjectReducer
  );

  const getItemProjectNames = (projects) => {
    if (!vehicleProjectData?.data?.data) {
      return [];
    }

    const vehicleProjects = vehicleProjectData.data.data;
    if (vehicleProjects && projects && projects.length > 0) {
      return vehicleProjects
        .map((d) => projects.includes(d.project_id) == true && d.name)
        .filter(Boolean)
        .map((p) => p);
    }
    return [];
  };

  return (
    <Stack spacing={2}>
      <Filter
        options={options}
        setFilters={setFilters}
        filters={filters}
        setGetFiltersData={setGetFiltersData}
      />
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => {
            return (
              <Item
                key={`${item.id}-${index}`}
                item={{ ...item, projeler: getItemProjectNames(item.projeler) }}
                handleDetailEdit={() => handleDetailItemEdit(item.detailData)}
                handleDelete={handleDelete}
                toggleModal={() => toggleModal(item.detailData)}
              />
            );
          }),
        }}
      />
    </Stack>
  );
};

export default TableList;
