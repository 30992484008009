import React, { useEffect, useMemo } from "react";
import Layout from "components/Layout";
import CustomTable from "components/CustomTable";
import { Services } from "api/Services";
import { applySortFilter } from "components/CustomTable/utils";
import useTable, { getComparator } from "hooks/useTable";
import Item from "./item";
import { statusFilter } from "helper";
import moment from "moment";
import { useHistory } from "react-router";
import qs from "query-string";
import { useSelector } from "react-redux";
import TableSelectedActions from "components/CustomTable/TableSelectedActions";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import { BASE_URL } from "api/Env";
import { useSnackbar } from "notistack";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import Label from "components/Label";
import _ from "lodash";

const server = new Services();

const onUniqBy = (data = []) =>
  _.uniqBy(
    data.map((i) => i.key),
    (i) => i
  );

export default function ShipmentsDetail(props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { hash } = history.location;
  const data = useSelector((s) => s.dashboardFilterReducer);
  const analyticsData = useSelector((s) => s.analyticsFilterRecuder);
  const queryParam = qs.parse(history.location.search);

  const [loading, setLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [filterSearch, filterSearchOnChange] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [totalPackages, setTotalPackages] = React.useState(0);
  const [allData, setAllData] = React.useState([]);
  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable({ defaultOrderBy: "created_at", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "sira", title: "Sıra", onSort },
    { key: "kuryeName", title: "Kurye Bilgisi", onSort },
    { key: "projeBilgisi", title: "Proje", onSort },
    { key: "sender", title: "Gönderen Firma", onSort },
    { key: "sube", title: "Şube", onSort },
    { key: "depo", title: "Depo", onSort },
    { key: "packageCode", title: "Paket Kodu", onSort },
    { key: "siparisKodu", title: "Sipariş Kodu", onSort },
    { key: "aliciName", title: "Alıcı", onSort },
    { key: "durumx", title: "Sipariş Durumu" },
    { key: "siparisTarih", title: "Sipariş Tarihi", onSort },
    { key: "geoScore", title: "Skor", onSort },
    { key: "slot", title: "Slot", onSort },
    { key: "teslimatZamani", title: "Teslimat Zamanı", onSort },
    { key: "city", title: "İl", onSort },
    { key: "county", title: "İlçe", onSort },
    { key: "addressFilter", title: "Adres", onSort },
    { key: "quantity", title: "Paket", onSort },
    { key: "deci", title: "Desi", onSort },
    { key: "description", title: "Açıklama", onSort },
  ];

  const getAllData = async () => {
    let newFilter = {};
    if (hash === "#fromDashboard") {
      newFilter = {
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        // Redux
        end_date: moment(data.start_date).format("YYYY-MM-DD"),
        start_date: data.start_date,
        branches: onUniqBy(data.branches),
        cities: onUniqBy(data.cities),
        counties: onUniqBy(data.counties),
        customers: onUniqBy(data.customers),
        drivers: onUniqBy(data.drivers),
        vehicle_types: onUniqBy(data.vehicle_types),
        vehicles: onUniqBy(data.vehicles),
        in_route: queryParam?.in_route,
      };
    } else {
      newFilter = {
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        start_date: data.start_date,
        branches: onUniqBy(analyticsData.branches),
        cities: onUniqBy(analyticsData.cities),
        counties: onUniqBy(analyticsData.counties),
        customers: onUniqBy(analyticsData.customers),
        in_route: queryParam?.in_route,
      };
    }
    if (queryParam?.in_route) {
      newFilter.in_route = true;
    }
    if (
      ![
        undefined,
        "ON_BOARD",
        "DELIVERED",
        "NOT_DELIVERED",
        "PACKAGE_NOT_FOUND",
        "PACKAGE_CANCELLED",
        "COURIER_DEBIT",
      ].includes(queryParam?.status)
    ) {
      delete newFilter.start_date;
      delete newFilter.end_date;
    }
    try {
      const res = await server.getAllOrderFilter(newFilter);
      if (res) {
        setAllData(
          res.entity.data[0].orders.flatMap((item) =>
            item.packages.map((packageItem, index) => {
              const deci = item.products.map((product) => product.deci);
              return {
                id: item.id,
                created_at: item.created_at,
                sira: item.plan_day_sequence || "-",
                sender: item.sender || "-",
                kuryeName: `${item.plan_driver?.first_name || ""} ${
                  item.plan_driver?.last_name || ""
                }`,
                kuryeUsername: item.plan_driver?.username || "",
                siparisKodu: item.order_code,
                depo: item.warehouse?.name || "-",
                siparisTarih: item.created_at
                  ? moment(item.created_at)
                      .add(3, "hours")
                      .format("DD/MM/YYYY HH:mm")
                  : "-",
                geoScore: item.address?.geocode_score || "-",
                slot: {
                  start: item.slot_start_time,
                  end: item.slot_finish_time,
                },
                aliciName: `${item.customer?.first_name || ""}${
                  item.customer?.last_name || ""
                }`.toLocaleLowerCase("tr"),
                alici: {
                  name: `${item.customer?.first_name || ""}${
                    item.customer?.last_name || ""
                  }`,
                  phone: item.customer?.gsm_number || "",
                },
                projeBilgisi: item.warehouse?.firm_name,
                durumx: statusFilter(item),
                teslimatZamani:
                  item.delivery_status === "DELIVERED" && item.delivery_date
                    ? moment(
                        item.delivery_status === "DELIVERED"
                          ? item.delivery_date
                          : item.delivery_date
                      )
                        .add(3, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : "-",
                code: item.customer?.code,
                addressFilter:
                  `${item.address?.address}`.toLocaleLowerCase("tr") || "",
                address: item.address?.address || "",
                city: item.address?.city || "",
                county: item.address?.county || "",
                gsm_number: item.address?.gsm_number || "",
                quantity: 1,
                deci: deci.length > 0 ? deci[index] : null,
                description: item.delivery_description || "",
                sube: item?.assigned_branch?.name || "",
                packageCode: packageItem?.barcode || "",
                packages: item.packages,
                // barcode: `${BASE_URL}qrcode/?bulkId=${item.id}&notPrint=false`,
              };
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getData = async () => {
    let newFilter = {};
    if (hash === "#fromDashboard") {
      newFilter = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        // Redux
        end_date: moment(data.start_date).format("YYYY-MM-DD"),
        start_date: data.start_date,
        branches: onUniqBy(data.branches),
        cities: onUniqBy(data.cities),
        counties: onUniqBy(data.counties),
        customers: onUniqBy(data.customers),
        drivers: onUniqBy(data.drivers),
        vehicle_types: onUniqBy(data.vehicle_types),
        vehicles: onUniqBy(data.vehicles),
        in_route: queryParam?.in_route,
      };
    } else {
      newFilter = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        end_date: moment(data.start_date).format("YYYY-MM-DD"),
        start_date: data.start_date,
        branches: onUniqBy(analyticsData.branches),
        cities: onUniqBy(analyticsData.cities),
        counties: onUniqBy(analyticsData.counties),
        customers: onUniqBy(analyticsData.customers),
        in_route: queryParam?.in_route,
      };
    }

    if (
      ![
        undefined,
        "ON_BOARD",
        "DELIVERED",
        "NOT_DELIVERED",
        "PACKAGE_NOT_FOUND",
        "PACKAGE_CANCELLED",
        "COURIER_DEBIT",
      ].includes(queryParam?.status)
    ) {
      delete newFilter.start_date;
      delete newFilter.end_date;
    }

    try {
      const res = await server.getOrderFilter(newFilter);
      setTotalCount(res.entity.data[0].total);
      setTotalPackages(res.entity.data[0].totalPackage);
      setTableData(
        res.entity.data[0].result
          .sort((a, b) => {
            return a.plan_day_sequence - b.plan_day_sequence;
          })
          .flatMap((item, itemIndex) =>
            item.packages.map((packageItem, index) => {
              const deci = item.products.map((product) => product.deci);

              return {
                id: item.id,
                created_at: item.created_at,
                sira: item.plan_day_sequence || "-",
                sender: item.sender || "-",
                kuryeName: `${item.plan_driver?.first_name || ""} ${
                  item.plan_driver?.last_name || ""
                }`,
                kuryeUsername: item.plan_driver?.username || "",
                siparisKodu: item.order_code,
                depo: item.warehouse?.name || "-",
                siparisTarih: item.created_at
                  ? moment(item.created_at)
                      .add(3, "hours")
                      .format("DD/MM/YYYY HH:mm")
                  : "-",
                geoScore: item.address?.geocode_score || "-",
                slot: {
                  start: item.slot_start_time,
                  end: item.slot_finish_time,
                },
                aliciName: `${item.customer?.first_name || ""}${
                  item.customer?.last_name || ""
                }`.toLocaleLowerCase("tr"),
                alici: {
                  name: `${item.customer?.first_name || ""}${
                    item.customer?.last_name || ""
                  }`,
                  phone: item.customer?.gsm_number || "",
                },
                projeBilgisi: item.warehouse?.firm_name,
                durumx: statusFilter(item),
                teslimatZamani:
                  item.delivery_status === "DELIVERED" && item.delivery_date
                    ? moment(
                        item.delivery_status === "DELIVERED"
                          ? item.delivery_date
                          : item.delivery_date
                      )
                        .add(3, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : "-",
                code: item.customer?.code,
                addressFilter:
                  `${item.address?.address}`.toLocaleLowerCase("tr") || "",
                address: item.address?.address || "",
                city: item.address?.city || "",
                county: item.address?.county || "",
                gsm_number: item.address?.gsm_number || "",
                quantity: 1,
                deci: deci.length > 0 ? deci[index] : null,
                description: item.delivery_description || "",
                sube: item?.assigned_branch?.name || "",
                packageCode: packageItem?.barcode || "",
                detailData: item,
                // barcode: `${BASE_URL}qrcode/?bulkId=${item.id}&notPrint=false`,
              };
            })
          )
      );
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getAllData();
  }, [page, rowsPerPage, hash]);

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((e) => e.key)],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === "" ? tableData : tableDataFiltered;

  const updateBarcodeKey = (id) => {
    setTableData((t) => {
      // update item
      const newArray = [...t];
      const getIndex = t.findIndex((e) => e.id === id);
      if (getIndex > -1) {
        newArray[getIndex].detailData.barcode_printed = true;
      }
      return newArray;
    });
  };

  const handleBarcodeRows = async () => {
    const ids = tableData
      .filter((row) => selected.includes(row.id))
      .map((i) => i.id);

    //bulk services
    let barcodeID = new Date().getTime();
    server.post_print_barcode_bulk(ids, barcodeID);

    try {
      //await Promise.all(requests);
      // update table
      ids.forEach((id) => {
        updateBarcodeKey(id);
      });
      setTimeout(() => {
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}&notPrint=false`);
      }, 500);
      setSelected([]);
      enqueueSnackbar("Barkodlar başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  return (
    <Layout
      {...props}
      heading={queryParam.pageTitle || ""}
      links={[
        { name: "Genel", href: "/" },
        { name: queryParam.pageTitle || "" },
      ]}
      action={
        allData.length > 0 ? (
          <DownloadExcel
            name={queryParam.pageTitle || ""}
            tableHeadData={tableHeadData}
            tableData={allData}
          />
        ) : (
          <Typography variant="body1">Exel Yükleniyor...</Typography>
        )
      }
    >
      <Label variant="ghost" sx={{ mb: 2 }}>
        Toplam paket: {totalPackages}
      </Label>
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz sipariş kodunu giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          totalCount,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <Item
              key={`${item.id}-${index}`}
              item={item}
              selected={selected.includes(item.id)}
              onSelectRow={() => onSelectRow(item.id)}
              pageTitle={queryParam.pageTitle}
            />
          )),
          // Selected
          rowCount: totalCount,
          numSelected: selected.length,
          onSelectAllRows: (checked) => {
            onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            );
          },
          containerChildren: selected.length > 0 && (
            <TableSelectedActions
              dense={false}
              numSelected={selected.length}
              rowCount={totalCount}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Tooltip title="Tümünü Barkodları Bas">
                  <IconButton color="primary" onClick={handleBarcodeRows}>
                    <Iconify icon={"bx:barcode-reader"} />
                  </IconButton>
                </Tooltip>
              }
            />
          ),
        }}
      />
    </Layout>
  );
}
