import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import { BASE_URL } from "api/Env";
import { TextFirstLetter } from "utils";
import { useHistory } from "react-router";
import AddUser from "components/AddUser";
import AddWarehouse from "components/AddWarehouse";

Item.propTypes = {
  item: PropTypes.object,
  handleDetailEdit: PropTypes.func,
};

export default function Item({ item, handleDetailEdit, handleDelete }) {
  const history = useHistory();
  const [openMenu, setOpenMenuActions] = React.useState(null);
  const [addUser, setAddUser] = React.useState(null);
  const [addWarehouse, setAddWarehouse] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    handleDetailEdit();
  };

  const handleAddUserClose = () => {
    setAddUser(null);
  };

  const handleAddWarehouseClose = () => {
    setAddWarehouse(null);
  };

  const photo = item.detailData.customer_logo_id
    ? `${BASE_URL}api/public/document/${item.detailData.customer_logo_id}`
    : null;

  return (
    <>
      <TableRow hover sx={{ "& td": { border: 0 } }}>
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              alt={item.name}
              color={createAvatar(item.name).color}
              src={photo}
            >
              {createAvatar(item.name).name}
            </Avatar>
            <Box flex={1}>
              <Typography variant="subtitle2" noWrap>
                {TextFirstLetter(item.name)}
              </Typography>
              <Typography variant="caption">{item.code}</Typography>
            </Box>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(`${item.first_name} ${item.last_name}`)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(item.group_name)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(item.city)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(item.county)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(item.address)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{item.gsm_number}</Typography>
        </TableCell>
        <TableCell>
          <Label>{item.username}</Label>
        </TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
                  <Iconify icon={"eva:trash-2-outline"} />
                  Sil
                </MenuItem>
                <MenuItem onClick={handleEdit}>
                  <Iconify icon={"eva:edit-fill"} />
                  Düzenle
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    history.push(`/messages-management?customerID=${item.id}`);
                  }}
                >
                  <Iconify icon={"ant-design:message-filled"} />
                  Mesaj yönetimi
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setAddUser(item.id);
                  }}
                >
                  <Iconify icon={"bxs:user-plus"} />
                  Kullanıcı ekle
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setAddWarehouse(item.id);
                  }}
                >
                  <Iconify icon={"bi:box-fill"} />
                  Depo ekle
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      {addUser && (
        <AddUser
          firmaId={addUser}
          label="Kullanıcı Ekle"
          handleClose={handleAddUserClose}
        />
      )}
      {addWarehouse && (
        <AddWarehouse
          firmaId={addWarehouse}
          label="Depo Ekle"
          handleClose={handleAddWarehouseClose}
        />
      )}
    </>
  );
}
