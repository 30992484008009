/* eslint-disable */
import moment from "moment";
import { Cookies } from "react-cookie";
import { getUserInfo, post, remove, get, put } from "./Ajax";
import data from "screens/DistributionReport/mockdata";

const clientId = "c2136144fa40488f99ea9110341d5b93";

export function Services() {
  // const clientId = "c2136144fa40488f99ea9110341d5b93";
  var that = this;

  this.login = (username, password, grecaptcha) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `authorizeToKeycloak`,
        body: {
          username: username,
          password: password,
          // "g-recaptcha-response": grecaptcha,
          // "cliendId": clientId
        },
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Super Admin Requests Start
  this.getTenantList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/tenant/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addTenant = (data) => {
    return new Promise(async (resolve, reject) => {
      return await put({
        url: `api/tenant`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateTenant = (tenant_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/tenant`,
        body: tenant_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.delTenant = (tenant_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/tenant/${tenant_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getTenantUsers = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: "api/tenant/tenant-user/all",
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getTenantUserPopup = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/tenant/tenant-user/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addTenantUserPopup = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/tenant/tenant-user`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteTenantUser = async ({ id }) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/tenant/tenant-user/${id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addDriverCompany = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `register-company/simple`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateDriverCompany = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/update`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  /* this.deleteDriverCompany = (driver_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/driver-company/${driver_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  }; */
  // Super Admin Requests End

  this.getDriverCompanyList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCityList = async (countryId) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/public/city?countryId=${countryId}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCountyList = async (cityId) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/public/county?cityId=${cityId}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
      return await put({
        url: `api/firm`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUserContacts = (data) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/user/contacts`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getReportsGeneral = (startDate, endDate, customerId) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/customer-general-report?start=${startDate}&end=${endDate}&customer_oid=${customerId}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getReportsDelivery = (startDate, endDate, customerId) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/customer-delivery-report?start=${startDate}&end=${endDate}&customer_oid=${customerId}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getReportsProduct = (startDate, endDate, customerId) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/product-report?start=${startDate}&end=${endDate}&customer_oid=${customerId}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getNotificationMessages = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/web/notification/by-firm/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.notificationPreview = (message, id) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("message", message);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/web/notification/by-firm/${id}/preview`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.saveNotificationMessages = (data, id) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/web/notification/by-firm/${id} `,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/firm/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getBranchList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/branch/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.warehouseListToBeAssigned = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.branchSave = (firmID, data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/warehouse/${firmID}/branches`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.assignWarehouseSave = (id, data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/branch/${id}/warehouses`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.branchDrawing = (id, data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/branch/${id}/regions`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.branchDrawingGet = (id, data) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/branch/${id}/regions`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.branchAssignmnetAppointed = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/${id}/branches`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.branchDrivers = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        //url: `api/driver/all`,
        url: `api/branch/drivers`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // this.getBranchAssignmentList = () => {
  //     return new Promise(async (resolve, reject) => {
  //         return await get({
  //             url: `api/branch/all`,
  //         }).then((e) => resolve(e))
  //             .catch(that.handleError.bind(null, reject));
  //     })
  // }

  this.getCurrentProgressPayment = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/${id}/payment`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCurrentProgressPaymentVehicle = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/${id}/payment`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicle = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.vehicleBranchList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/branch/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleUsageReport = (dt) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/vehicle-daily-report?start=${dt}&end=${dt}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleUsageReportBetween = (start, end) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/vehicle-daily-report?start=${start}&end=${end}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDrivers = (all) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url:
          all != true
            ? `api/driver/all?date=` + new Date().toISOString().split("T")[0]
            : `api/driver/all`, // date of the day
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleZone = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/zone/group`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleProject = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/project`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleModels = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/public/vehicle/brand?hasModels=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleCompany = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehicleDrivers = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDriversVehicle = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // vehicle status bottleneck handler: NODEJS
  this.changeVehicleStatus = (id, reason, status) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        // url: `api/vehicle/${id}?status=${status}&reason=${reason}`,
        url: `api/vehicle/${id}/status?&reason=${reason}&status=${status}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.changeDriverStatus = (id, reason, status) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        // url: `api/vehicle/${id}?status=${status}&reason=${reason}`,
        url: `api/driver/${id}/status?&reason=${reason}&status=${status}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // get inactive dates
  this.getInactiveDate = (start_date, id, end_date) => {
    let start_date_inc = new Date(start_date);
    start_date_inc.setDate(start_date_inc.getDate() + 1);

    let end_date_inc = new Date(end_date);
    end_date_inc.setDate(end_date_inc.getDate() + 1);

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/inactive?endDate=${end_date_inc
          .toISOString()
          .slice(0, 10)}&id=${id}&startDate=${start_date_inc
          .toISOString()
          .slice(0, 10)}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // delete inactive dates
  this.deleteInactiveDateReason = (inactive_date_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/vehicle/inactive/${inactive_date_id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // get driver inactive dates
  this.getDriversInactiveDate = (start_date, id, end_date) => {
    let start_date_inc = new Date(start_date);
    start_date_inc.setDate(start_date_inc.getDate() + 1);

    let end_date_inc = new Date(end_date);
    end_date_inc.setDate(end_date_inc.getDate() + 1);

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/driver/inactive?endDate=${end_date_inc
          .toISOString()
          .slice(0, 10)}&id=${id}&startDate=${start_date_inc
          .toISOString()
          .slice(0, 10)}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // delete driver inactive dates
  this.deleteDriversInactiveDateReason = (inactive_date_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/driver/inactive/${inactive_date_id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.vehicleDeleteRecord = (id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/vehicle/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  const timeZone = (par) => {
    if (par && par.doc_expiration) {
      return par.doc_expiration;
    } else {
      var d = new Date(par);
      var a = d.setHours(d.getHours() + d.getTimezoneOffset() / -60);
      var b = new Date(a).toISOString();
      return b;
    }
  };

  this.vehicleNewAdd = (data) => {
    let rq = data;

    // var dt = {
    //     "capacity": 0,
    //     "company": rq.company && rq.company.id,
    //     "drivers": [
    //         rq.drivers && rq.drivers
    //     ],
    //     "id": null,
    //     "inception": rq.inceptionDoc && rq.inceptionDoc,
    //     "inception_expiration_date": rq.inception && rq.inception.toISOString(),
    //     "insurance": rq.insuranceDoc && rq.insuranceDoc,
    //     "insurance_expiration_date":rq.insurance && rq.insurance.toISOString(),
    //     "k_certificate":rq.k_certificateDoc && rq.k_certificateDoc ,
    //     "k_certificate_expiration_date":rq.k_certificate && rq.k_certificate.doc_expiration.toISOString(),
    //     "loading_time": 0,
    //     "maintanence_date": rq.maintanence_date && rq.maintanence_date.toISOString(),
    //     "maintanence_km": rq.last_maintenance_km && rq.last_maintenance_km,
    //     "name": rq.vehicle_id && rq.vehicle_id,
    //     "projects": rq.projects && rq.projects.map(p => p.project_id),
    //     "refrigerate": rq.refrigerate && rq.refrigerate.code,
    //     "status": "ACTIVE",
    //     "vehicle_id": rq.vehicle_id && rq.vehicle_id,
    //     "vehicle_licence": rq.vehicle_LicenseDoc &&  rq.vehicle_LicenseDoc,
    //     "vehicle_licence_expiration_date": rq.vehicle_license && rq.vehicle_license.toISOString(),
    //     "vehicle_model_oid": rq.vehicle_model && rq.vehicle_model.id,
    //     "zones": rq.zones && rq.zones.map(p => p.zone_id)

    // }

    var dt = {
      capacity: 0,
      company: rq.company && rq.company.id,
      drivers: [(rq.drivers && rq.drivers.id) || rq.drivers],
      id: null,
      branches: rq.branch && rq.branch,
      inception: rq.inceptionDoc && rq.inceptionDoc,
      inception_expiration_date: rq.inception && timeZone(rq.inception),
      insurance: rq.insuranceDoc && rq.insuranceDoc,
      insurance_expiration_date: rq.insurance && timeZone(rq.insurance),
      k_certificate: rq.k_certificateDoc && rq.k_certificateDoc,
      k_certificate_expiration_date:
        rq.k_certificate && timeZone(rq.k_certificate),
      loading_time: 0,
      maintanence_date: rq.maintanence_date && timeZone(rq.maintanence_date),
      maintanence_km: rq.maintanence_km && rq.maintanence_km,
      name: rq.vehicle_id && rq.vehicle_id,
      projects: rq.projects && rq.projects.map((p) => p.project_id),
      refrigerate: rq.refrigerate && rq.refrigerate.code,
      status: "ACTIVE",
      vehicle_id: rq.vehicle_id && rq.vehicle_id,
      vehicle_licence: rq.vehicle_LicenseDoc && rq.vehicle_LicenseDoc,
      vehicle_licence_expiration_date:
        rq.vehicle_licence && timeZone(rq.vehicle_licence),
      vehicle_model_oid: rq.vehicle_model && rq.vehicle_model.id,
      zones: rq.zones && rq.zones.map((p) => p.zone_id),
    };

    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api​/vehicle`,
        url: `api/vehicle`,
        body: dt,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.driverNewAdd = (data) => {
    let rq = data;

    var dt = {
      address_line: rq.address_line && rq.address_line,
      birthday: rq.birthday && timeZone(rq.birthday),
      //"city": "",
      company: rq.company && rq.company.id,
      //"country": "string",
      //"county": "string",
      criminal_record: rq.criminal_recordDoc && rq.criminal_recordDoc,
      //"education_status": "string",
      email: rq.email && rq.email,
      //"employement_date": "2021-10-27T11:46:47.419Z",
      first_name: rq.first_name && rq.first_name,
      //"gender": "string",
      id: null,
      identity_number: rq.identity_number && rq.identity_number,
      last_name: rq.last_name && rq.last_name,
      licence_doc: rq.licence_Doc && rq.licence_Doc,
      //"licence_expiration_date": rq.licence_expiration_date  && rq.licence_expiration_date.toISOString(),
      licence_expiration_date: rq.licence_doc && timeZone(rq.licence_doc),
      licence_number: rq.licence_number && rq.licence_number,
      //"password": "",
      phone: rq.phone && rq.phone,
      photo_url: rq.photo_doc && rq.photo_doc,
      //"previous_experience": "",
      //"psycho_date": "",
      psycho_doc: rq.psycho_Doc && rq.psycho_Doc,
      psycho_expiration_date: rq.psycho_doc && timeZone(rq.psycho_doc),
      //"src_date": "",
      src_doc: rq.src_Doc && rq.src_Doc,
      //"src_expiration_date": rq.src_expiration_date && rq.src_expiration_date.toISOString(),
      src_expiration_date: rq.src_doc && timeZone(rq.src_doc),
      status: "ACTIVE",
      user_type: "DRIVER",
      roles: rq.role && rq.role,
      vehicles: [rq.vehicles && rq.vehicles],
    };

    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api​/vehicle`,
        url: `api/driver`,
        body: dt,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.driverUpdateRecord = (data) => {
    let rq = data;

    var dt = {
      address_line: rq.address_line && rq.address_line,
      //"birthday": rq.birthday && typeof rq.birthday == "string" ? rq.birthday  : rq.birthday.toISOString(),
      birthday:
        rq.birthday && typeof rq.birthday == "string"
          ? rq.birthday
          : timeZone(rq.birthday),
      //"city": "",
      company: rq.company && rq.company.id,
      //"country": "",
      //"county": "",
      criminal_record: rq.criminal_recordDoc && rq.criminal_recordDoc,
      //"education_status": "",
      email: rq.email && rq.email,
      //"employement_date": "2021-10-27T11:46:47.419Z",
      first_name: rq.first_name && rq.first_name,
      //"gender": "",
      id: rq.id && rq.id,
      identity_number: rq.identity_number && rq.identity_number,
      last_name: rq.last_name && rq.last_name,
      licence_doc: (rq.licence_Doc && rq.licence_Doc) || rq.licence_ext,
      licence_expiration_date:
        rq.licence_doc && typeof rq.licence_doc == "string"
          ? rq.licence_doc
          : timeZone(rq.licence_doc),
      licence_number: rq.licence_number && rq.licence_number,
      //"password": "",
      phone: rq.phone && rq.phone,
      photo_url: rq.photo_doc && rq.photo_doc,
      //"previous_experience": "",
      //"psycho_date": "",
      psycho_doc: (rq.psycho_Doc && rq.psycho_Doc) || rq.psycho_ext,
      psycho_expiration_date:
        rq.psycho_doc && typeof rq.psycho_doc == "string"
          ? rq.psycho_doc
          : timeZone(rq.psycho_doc),
      //"src_date": "",
      src_doc: (rq.src_Doc && rq.src_Doc) || rq.src_ext,
      src_expiration_date:
        rq.src_doc && typeof rq.src_doc == "string"
          ? rq.src_doc
          : timeZone(rq.src_doc),
      //"src_expiration_date":rq.src_doc_date && timeZone(rq.src_doc_date)  || rq.src_Doc.doc_expiration,
      status: rq.status && rq.status,
      user_type: "DRIVER",
      roles: rq.role && rq.role,
      // "vehicles": [
      //   rq.vehicles &&  typeof rq.vehicles == "string" ? rq.vehicles : rq.vehicles[0].id
      // ]
      vehicles:
        rq.vehicles.length > 0
          ? [
              rq.vehicles && typeof rq.vehicles == "string"
                ? rq.vehicles
                : rq.vehicles[0].id,
            ]
          : [],
    };

    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api​/vehicle`,
        url: `api/driver`,
        body: dt,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.vehicleUpdateRecord = (data) => {
    let rq = data;

    var dt = {
      capacity: 0,
      company: rq.company && rq.company.id,
      drivers: [(rq.drivers && rq.drivers.id) || rq.drivers],
      id: rq.id && rq.id,
      branches: rq.branch && rq.branch,
      // "inception": rq.inceptionDoc && rq.inceptionDoc,
      inception: rq.inceptionDoc ? rq.inceptionDoc : rq.inception_ext,
      inception_expiration_date: rq.inception && timeZone(rq.inception),

      //"inception_expiration_date": rq.inception && (rq.inception.doc_expiration == undefined ? rq.inception.toISOString() : rq.inception.doc_expiration),
      insurance: rq.insuranceDoc ? rq.insuranceDoc : rq.insurance_ext,

      insurance_expiration_date: rq.insurance && timeZone(rq.insurance),

      //"insurance_expiration_date": rq.insurance && (rq.insurance.doc_expiration == undefined ? rq.insurance.toISOString() : rq.insurance.doc_expiration),
      // "k_certificate":rq.k_certificateDoc && rq.k_certificateDoc ,
      //"k_certificate_expiration_date": rq.k_certificate && (rq.k_certificate.doc_expiration == undefined ? rq.k_certificate.toISOString() : rq.k_certificate.doc_expiration),
      k_certificate: rq.k_certificateDoc
        ? rq.k_certificateDoc
        : rq.k_certificate_ext,

      k_certificate_expiration_date:
        rq.k_certificate && timeZone(rq.k_certificate),

      //"k_certificate_expiration_date": rq.k_certificate.id ? {"k_certificate": rq.k_certificate.id,"doc_expiration": rq.k_certificate.doc_expiration} : rq.k_certificate.toISOString() ,
      loading_time: 0,
      // "maintanence_date": rq.maintanence_date && (rq.maintanence_date.doc_expiration == undefined ? rq.maintanence_date.toISOString() :  rq.maintanence_date.doc_expiration),
      maintanence_date: rq.maintanence_date && timeZone(rq.maintanence_date),
      maintanence_km: rq.maintanence_km && rq.maintanence_km,
      name: rq.vehicle_id && rq.vehicle_id,
      //"projects": rq.project && rq.project.map(p => p.project_id),
      projects:
        typeof rq.projects[0] == "string"
          ? rq.projects
          : rq.projects.map((p) => p.project_id),
      refrigerate: rq.refrigerate && rq.refrigerate.code,
      status: rq.status && rq.status,
      vehicle_id: rq.vehicle_id && rq.vehicle_id,
      vehicle_licence: rq.vehicle_LicenseDoc && rq.vehicle_LicenseDoc,
      vehicle_licence_expiration_date:
        rq.vehicle_licence && timeZone(rq.vehicle_licence),
      //"vehicle_licence_expiration_date": rq.vehicle_license && (rq.vehicle_license.doc_expiration == undefined ? rq.vehicle_license.toISOString() : rq.vehicle_license.doc_expiration),
      vehicle_model_oid: rq.vehicle_model && rq.vehicle_model.id,
      //"zones": rq.zones && rq.zones.map(p => p.zone_id)
      zones:
        typeof rq.zones[0] == "string"
          ? rq.zones
          : rq.zones.map((p) => p.zone_id),
    };

    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api​/vehicle`,
        url: `api/vehicle`,
        body: dt,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteVehicle = (vehicle_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/vehicle/${vehicle_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteDriver = (driver_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/driver/${driver_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getWarehouses = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/all`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getLiveFilter = (keyword) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/live-tracking-filter?text=${keyword}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDeliveryReport = (start, end) => {
    let today = new Date();
    let todayAddHours = new Date(today.setHours(today.getHours() + 5));
    let todayFormat = todayAddHours.toISOString().substring(0, 10);

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/live-tracking?start=${todayFormat}&end=${todayFormat}`,
        //url: `api/report/web/live-tracking?start=2021-07-09&end=2021-07-09`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.trackingPageInfo = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/public/tracking-page-info/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDeliveryReportFilter = (startDate, endDate, deliveryStatus) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/live-tracking?start=${startDate}&end=${endDate}&deliveryStatus=${deliveryStatus}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getAdminOrderFilter = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/all?start=${startDate}&end=${endDate}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Content-Type: application/x-www-form-urlencoded
  this.delayVisit1 = (new_date, order_oid) => {
    var urlencoded = new URLSearchParams();
    //urlencoded.append('message', message);
    //let data = new FormData();
    var newDt = new Date(new_date).setHours(3);
    urlencoded.append(
      "new_date",
      new Date(newDt).toISOString().substring(0, 19)
    );
    urlencoded.append("order_oid", order_oid);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/postpone`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.manualDeliverySuccessful = (obj) => {
    var urlencoded = new URLSearchParams();

    urlencoded.append("orderId", obj.orderId);
    urlencoded.append("recipientName", obj.recipientName);
    urlencoded.append("deliveryCode", obj.deliveryCode);
    urlencoded.append("notificationEnabled", obj.notificationEnabled);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/${obj.routeId}/delivered`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.manualDeliveryFailed = (obj) => {
    var urlencoded = new URLSearchParams();

    urlencoded.append("orderId", obj.orderId);
    urlencoded.append("reasonText", obj.reasonText);
    urlencoded.append("notificationEnabled", obj.notificationEnabled);
    obj.orderNewDate &&
      urlencoded.append(
        "orderNewDate",
        timeZone(obj.orderNewDate).split("T")[0]
      );
    //urlencoded.append('deliveryCode', obj.deliveryCode);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/${obj.routeId}/delivered-failed`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.manuallyUpdateOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/customer/contact/by-order`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCustomerCountyUserDebit = (customer_id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/firm/customer-county-user?customer_id=${customer_id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getListCustomerWarehouse = (customer_id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/all?firmId=${customer_id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteCustomerWarehouse = (code) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/warehouse/${code}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteBranch = (id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/branch/${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addCustomerWarehouse = (data) => {
    return new Promise(async (resolve, reject) => {
      return await put({
        url: `api/warehouse`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addbranch = (data) => {
    return new Promise(async (resolve, reject) => {
      return await put({
        url: `api/branch`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateCustomerWarehouse = (data, id) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/warehouse/${id}`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateBranch = (data, id) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/branch/${id}`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.postCustomerCountyUserDebit = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/firm/customer-county-user`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.willBeReturn = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/will-be-return?isPackage=${data.isPackage}`,
        body: data.oids,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.delayVisit2 = (new_date, order_oid) => {
    var urlencoded = new URLSearchParams();
    //urlencoded.append('message', message);
    //let data = new FormData();
    var newDt = new Date(new_date).setHours(3);
    //urlencoded.append('new_date', new Date(newDt).toISOString().substring(0,19));
    //urlencoded.append('order_oid', order_oid.map(dt => dt.order_oid));

    return new Promise(async (resolve, reject) => {
      return await post({
        // url: `api/order/postpone-by-ids?new_date=${new Date(newDt).toISOString().substring(0,19)}`,
        // body: urlencoded,
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        // skip: true
        url: `api/order/postpone-by-ids?new_date=${new Date(newDt)
          .toISOString()
          .substring(0, 19)}`,
        body: order_oid.map((dt) => dt.order_oid),
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getProjectList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/advertisement/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getSgk = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/${id}/sgk-info`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addOrderManuel = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/manual`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.progressPayment = (id, data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/${id}/payment`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.sgk = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/sgk-info`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.progressPaymentVehicle = (id, data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api/company/${id}/payment`,
        url: `api/vehicle/${id}/payment`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addCustomerPopup = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        //url: `api/firm/customer-user`,
        url: `api/user`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCustomerPopup = (firmId) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/firm/customer-user/${firmId}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getOrderUnplannedList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/unplanned`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDispatcherLink = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/dispatcher-live-url`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDispatcherPlannerLink = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/dispatcher-planner-url`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getAdminOrderList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/all`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.adminGetOrderUnplannedList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/geocode/ungeocoded`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getOrderUnplanned = (warehouseCode, orderCode) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/${warehouseCode}/${orderCode}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUnplannedByDeliveryDate = (dd) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/unplanned-by-date?delivery_dates=${dd}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUnplannedByDeliveryDateGeocode = (dd) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        //url: `api/order/unplanned-by-date?delivery_date=${dd}`, //remove
        url: `api/order/unplanned-by-date?delivery_dates=${dd}&ungeocoded=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.geocodeCoordinate = (data) => {
    // console.log("geocodeCoordinate =>", data);
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/geocode/save-coordinates`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.sendToPlan = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/send-to-plan`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.revertToPlan = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/revert-to-plan`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.submittedPlans = (dt) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/unplanned-by-date?delivery_dates=${dt}&sentToPlan=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUnplannedOrdersSendToPlan = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        //url:`api/order/unplanned-groupby-date` //remove
        url: `api/order/unplanned-groupby-date?sentToPlan=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getGeocodeSuggestionList = (keyword) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/geocode/suggest/${keyword
          .replace(/\//g, " ")
          .replace(/#/g, " ")}`,
        //url: `api/order/unplanned-by-date?delivery_date=${dd}&ungeocoded=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteOrderUnplanned = (warehouseCode, orderCode) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/order/${warehouseCode}/${orderCode}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.orderMultipleDeleteServices = (oids) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/order`,
        body: oids,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getAllWarehouse = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/all`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getActiveWarehouse = (id) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/warehouse/all?firmId=${id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getAdvertisementApplicants = (par) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/advertisement/${par}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.post_print_barcode_bulk = (ids, parID) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/print_bulk_barcode/${parID}`,
        body: ids,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  (this.getJobList = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/company/advertisement`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  }),
    (this.updateJob = (data) => {
      return new Promise(async (resolve, reject) => {
        return await post({
          url: `api/company/advertisement`, // api link not correct
          body: data,
        });
      });
    });

  this.getUnplannedOrders = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/unplanned-groupby-date`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUnplannedOrdersUngeocoded = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        //url:`api/order/unplanned-groupby-date` //remove
        url: `api/order/unplanned-groupby-date?ungeocoded=true`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.advertisementEvaluationApprove = (par) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/approve/${par}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.post_print_barcode = (par) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/print_barcode/${par}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.advertisementEvaluationReject = (par) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/reject/${par}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.advertisementEvaluationReserve = (par) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/company/reserve/${par}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.publishProjectUpdate = (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/advertisement`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.vehicleEvaluationApprove = (id) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/vehicle/approve?id=${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.vehicleEvaluationReject = (id) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/vehicle/reject?id=${id}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getProjectBetweenDate = (start, end) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/advertisement/between-date?start=${start}&end=${end}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addProject = (data) => {
    return new Promise(async (resolve, reject) => {
      return await put({
        url: `api/advertisement`,
        body: data,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.logout = () => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `authorize`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.user = (slug) => {
    // var slug = new Date().getTime();
    return new Promise(async (resolve, reject) => {
      return await getUserInfo({
        url: `api/user/me?${slug}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.delCustomer = (customer_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/firm/${customer_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.delProject = (project_id) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/advertisement/${project_id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateCustomer = (customer_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/firm`,
        body: customer_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateProject = (project_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/advertisement`,
        body: project_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.updateOrder = (new_order_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/manual`,
        body: new_order_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.uploadExcel = (file, warehouse, date) => {
    let data = new FormData();
    data.append("file", file);
    data.append("warehouseOid", warehouse.id || warehouse);
    data.append("delivery_date", date);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/upload`,
        body: data,
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // upload vehicle doc : NODEJS_METHOD
  // SINGLE_THREAD_BOTTLENECK_BYPASSER
  this.uploadVehicleDocument = (file) => {
    let document = new FormData();
    document.append("file", file);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/document/upload`,
        body: document,
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.saveInactiveDate = (inactive_date_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/vehicle/inactive`,
        body: inactive_date_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.saveDriversInactiveDate = (inactive_date_data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/driver/inactive`,
        body: inactive_date_data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDriversOnDate = (date) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/driver/all?date=${date.slice(0, 10)}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDriversRole = () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/user/assignable-roles `,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehiclesOnDate = (date) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/all?date=${date.slice(0, 10)}`,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.savePaymentExtraDate = (payment_extra) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/vehicle/payment-extra`,
        body: payment_extra,
      })
        .then((e) => resolve(e))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getPaymentExtraDate = (startDate, vehicle_id, endDate) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/payment-extra?endDate=${endDate}&id=${vehicle_id}&startDate=${startDate}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.handleError = function (reject, response) {
    if (response.message === "Failed to fetch") {
      return reject({ message: "server_is_down" });
    }
    var entity = response.entity;
    if (response.status && response.status === 401) {
      var state = {
        message: entity.message,
        redirect: window.location.pathname,
      };
      // props.push({ pathname: "/login", state: state });
      return Promise.resolve(response);
    }
    if (entity?.message && entity?.code)
      return reject({ message: entity.message, code: entity.code });

    return reject();
  };

  // Get Distribution
  this.getDistribution = (date) => {
    const sDate = moment(date[0]).format("YYYY-MM-DD");
    const eDate = moment(date[1]).format("YYYY-MM-DD");
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/route-report?start=${sDate}&end=${eDate}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Get Distribution Detail List
  this.getDistributionDetailList = (props) => {
    const { routeID, routeKey } = props;

    const routeID_URL = routeID ? `route_id=${routeID}&` : "";
    const routeKey_URL = routeKey ? `key=${routeKey}` : "";

    const url = `api/order/route-orders?${routeID_URL}${routeKey_URL}`;

    return new Promise(async (resolve, reject) => {
      return await get({ url })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getRoutesOrdersCoords = (ids) => {
    const url = `api/planning/route-coordinates/list`;

    return new Promise(async (resolve, reject) => {
      return await post({ url, body: { route_ids: ids } })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Get Distribution Detail List for Dashboard
  this.getDistributionDetailListDashboardv2 = (props) => {
    const { start, end, routeKey, limit, offset } = props;

    const url = `api/order/dashboard-orders/v2?limit=${limit}&offset=${offset}&start=${start}&end=${end}&key=${
      routeKey ?? ""
    }`;

    return new Promise(async (resolve, reject) => {
      return await get({ url })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getDistributionDetailListDashboard = (props) => {
    const { start, end, routeKey } = props;

    const url = `api/order/dashboard-orders/v3?start=${start}&end=${end}&key=${
      routeKey ?? ""
    }`;

    return new Promise(async (resolve, reject) => {
      return await get({ url })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Get Distribution Detail List Item
  this.getDistributionDetailListItem = async (props) => {
    const { routeID, routeKey, siparisKodu } = props;
    try {
      const res = await this.getDistributionDetailList({ routeID, routeKey });
      const getItem = res.entity.data.find((i) => i.order_code == siparisKodu);
      return getItem;
    } catch (error) {
      return null;
    }
  };

  // Get Order Search
  this.getOrderSearch = async (value) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/search?term=${value}`,
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Get Dashboard Counts
  this.getDashboardCounts = async (date) => {
    if (date.includes(null)) {
      return;
    }

    // const start = ((typeof (date[0]) !== 'string') && (moment(date[0]).isSame(date[1])))
    //   ? moment(date[0]).subtract(1, 'day').format("YYYY-MM-DD")
    //   : moment(date[0]).format("YYYY-MM-DD");
    const start = moment(date[0]).format("YYYY-MM-DD");
    const end = moment(date[1]).format("YYYY-MM-DD");

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/order/dashboard-counts?start=${start}&end=${end}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCustomerManagementFilters = async () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/report/web/home-report/filters/`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getCustomerManagementAsyncFilters = async (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/report/web/home-report/filters/`,
        body: data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getFilterResult = async (section, body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/report/web/home-report/${section}/`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getOrderFilter = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/filter/v2`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getAllOrderFilter = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/filter/v3`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  // Vehicle Management - Get Vehicle List

  this.getVehicleList = async (vehicleFilter) => {
    let filter = "";
    filter = `vehicleTypeId=${vehicleFilter.vehicleTypeId?.key || ""}`;
    if (vehicleFilter.companyId) {
      filter += `&companyId=${vehicleFilter.companyId?.key || ""}`;
    }
    if (vehicleFilter.branchId) {
      filter += `&branchId=${vehicleFilter.branchId?.key || ""}`;
    }

    filter += `&propertyType=${vehicleFilter.propertyType}`;

    filter += `&vehicleName=${vehicleFilter.vehicleName}`;

    filter += `&active=${vehicleFilter.active}`;

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/list?${filter}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.createUpdateVehicle = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/vehicle`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getVehiclesFilter = async () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/vehicle/list/filters`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });

    // Driver Management - Get Driver List
  };
  this.getDriverList = async (driverFilter) => {
    let filter = "";
    filter = `name=${driverFilter.name}`;

    if (driverFilter.companyId) {
      filter += `&companyId=${driverFilter.companyId?.key || ""}`;
    }
    if (driverFilter.branchId) {
      filter += `&branchId=${driverFilter.branchId?.key || ""}`;
    }

    filter += `&active=${driverFilter.active}`;

    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/driver/list?${filter}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDriverFilter = async () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/driver/list/filters`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.createUpdateDriver = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/driver`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getAllProjects = async () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/project`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getNewDashboardCountsLast = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: "api/report/web/analysis",
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.addDriver = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/driver`,
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getUsers = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: "api/firm/customer-user/all",
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteUser = async ({ id }) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/firm/customer-user/${id}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getWarehouse = async (body) => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: "api/warehouse/all",
        body,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.addWarehouse = async (body, method) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/warehouse${method === "post" ? `/${body.id}` : ""}`;

      const requestMethod = method === "post" ? post : put;

      return await requestMethod({ url, body })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.deleteWarehouse = async (id) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/warehouse/${id}`;

      return await remove({ url })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getOrder = async ({ orderCode }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/order/find?orderCode=${orderCode}`;

      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getPlan = async ({ orderID }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/current-by-order/summary?orderId=${orderID}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.liveTrackingGetDetails = async ({ date }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/all-by-date/detailed?date=${date}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.liveTrackingGetOrders = async ({ routeId }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/${routeId}/details`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getOrderImages = async ({ orderID }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/order/delivery-images?orderId=${orderID}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getFailedReasons = async () => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/fail-reasons`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.changeOrderDeliveryStatusFailedSuccess = (obj, type) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("orderId", obj.orderId);
    urlencoded.append("recipientName", obj.recipientName);
    urlencoded.append("deliveryCode", obj.deliveryCode);
    urlencoded.append("notificationEnabled", obj.notificationEnabled);
    if (type === "package") {
      urlencoded.append("packageId", obj.packageId);
    }

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/delivery`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.changeOrderDeliveryStatusFailed = (obj, type) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("orderId", obj.orderId);
    urlencoded.append("failDescription", obj.failDescription);
    urlencoded.append("notificationEnabled", obj.notificationEnabled);
    urlencoded.append("failReasonId", obj.failReasonId);
    if (type === "package") {
      urlencoded.append("packageId", obj.packageId);
    }

    obj.orderNewDate &&
      urlencoded.append(
        "orderNewDate",
        timeZone(obj.orderNewDate).split("T")[0]
      );
    //urlencoded.append('deliveryCode', obj.deliveryCode);

    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/order/delivery-failed`,
        body: urlencoded,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skip: true,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getPlanlananRotalar = async ({ routeId }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/${routeId}/coordinates`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getGerceklesenRotalar = async ({ routeId }) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/${routeId}/gps/list`;
      return await post({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getDriverMine = () => {
    return new Promise(async (resolve, reject) => {
      const url = "api/driver/mine";
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  // Planner New
  this.getPlanningPointsAll = async (body) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/points/filter";
      return await post({ url, body })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningRouteFilters = async (body) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/route/filters";
      return await post({ url, body })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningOrders = async (body, limit, offset) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/planning/orders?limit=${limit}&offset=${offset}`;
      return await post({ url, body })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningGetOrdersIDS = async (orderIDS) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/planning/orders?limit=${orderIDS.length}&offset=0`;
      return await post({ url, body: { order_ids: orderIDS } })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningVehicles = async () => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/vehicles";
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.postPlanningRouteCreate = async (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/planning/create/bulk`,
        body: data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.deletePlanningRouteOrder = async (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/planning/remove/orders`,
        body: data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.changePlanningRouteVehicle = async (routeID, driverName, vehicleId) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/planning/${routeID}/change/vehicle?newDriverId=${driverName}&newVehicleId=${vehicleId}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.planningRouteChangeOrders = async (routeId, ids) => {
    return new Promise(async (resolve, reject) => {
      const body = { orders: ids, route_id: routeId };
      const url = `api/planning/assign/orders`;
      return await post({ url, body })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningProjects = async () => {
    return new Promise(async (resolve, reject) => {
      const url = `api/firm/all`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningBranches = async () => {
    return new Promise(async (resolve, reject) => {
      const url = `api/branch/all`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningDrivers = (branch_ids) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/driver/list`;
      return await post({ url, body: { branch_ids } })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningRoute = (routeId) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/${routeId}/details`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningRoutes = (ids) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/routes/list";
      return await post({
        url,
        body: {
          route_ids: [...ids],
        },
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningGetOrder = (id, code) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/order/find?orderCode=${code}&orderId=${id}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.deletePlanningRoute = (routeId) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/planning/delete/${routeId}`;
      return await remove({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };

  this.getGroupHyrarchy = () => {
    return new Promise(async (resolve, reject) => {
      const url = `api/keycloak/group`;
      return await get({
        url,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => resolve(res.entity))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.createGroupHyrarchy = (createGroupRef) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/keycloak/group`;
      return await post({
        url,
        body: createGroupRef,
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.updateGroupHyrarchy = (updateGroupRef) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/keycloak/group`;
      return await put({
        url,
        body: updateGroupRef,
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.deleteGroupHyrarchy = (id) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/keycloak/group/${id}`;
      return await remove({
        url,
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningFilters = (body) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/filters";
      return await post({ url, body })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningSelectMarkersDeciPackageCount = (orderIDS) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/orders/info";
      return await post({ url, body: { order_ids: orderIDS } })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningChangeOrderOrders = (routeId, orderIds) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/route/routing/${routeId}/reorder-manual`;
      return await post({ url, body: { orders: orderIds } })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningPointSearch = (term) => {
    return new Promise(async (resolve, reject) => {
      const url = `api/planning/search?term=${term}`;
      return await get({ url })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.getPlanningCourierToCourier = (oldRouteId, newRouteId, ordersIds) => {
    return new Promise(async (resolve, reject) => {
      const url = "api/planning/assign/order/courier-to-courier";
      return await post({
        url,
        body: {
          old_route_id: oldRouteId,
          new_route_id: newRouteId,
          orders: ordersIds,
        },
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.automaticRuleGet = async () => {
    return new Promise(async (resolve, reject) => {
      return await get({
        url: `api/route/routing/automatic-rule`,
      })
        .then((res) => resolve(res.entity.data))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.automaticRuleCreate = async (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/route/routing/automatic-rule/create`,
        body: data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.automaticRoutingCreate = async (data) => {
    return new Promise(async (resolve, reject) => {
      return await post({
        url: `api/route/routing/${data.branch.id}`,
        body: data,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
  this.automaticRoutingDelete = async (branchId, planName) => {
    return new Promise(async (resolve, reject) => {
      return await remove({
        url: `api/route/routing/automatic-rule/${branchId}/${planName}`,
      })
        .then((res) => resolve(res))
        .catch(that.handleError.bind(null, reject));
    });
  };
}

// if (typeof module === "object" && module.exports) {
//     module['exports'] = Services;
// };

// if (typeof Module === 'undefined')
//     Module = {};
// return Services(Module);

/* eslint-disable */
