import * as React from "react";
//
import WarehousesMarker from "./marker";
import { usePlanner } from "contexts/PlannerProvider";

interface WarehousesProps {}

export default function Warehouses(props: WarehousesProps) {
  const { mapLegendsWarehouses, mapLegends } = usePlanner();

  if (!mapLegends.depolar) {
    return null;
  }

  return (
    <>
      {mapLegendsWarehouses.map((warehouse) => {
        return <WarehousesMarker key={warehouse.id} warehouse={warehouse} />;
      })}
    </>
  );
}
