import React from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import List from "./List";
import { Services } from "api/Services";
import AddUser from "components/AddUser";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const service = new Services();

UsersManagement.propTypes = {
  style: PropTypes.array,
};

export default function UsersManagement(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState([]);
  const [addUserVisible, setAddUserVisible] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const getList = async () => {
    try {
      const res = await service.getUsers();
      setList(res.entity.data);
      setLoading(false);
    } catch (error) {}
  };

  const handleEditItem = (user) => {
    console.log(user);
    setUserData({
      ...user.detailData,
      firmaId: user.detailData.firm?.id || "",
    });
    setAddUserVisible(true);
  };

  const handleDeleteItem = async () => {
    try {
      await service.deleteUser({ id: dialogVisible });
      enqueueSnackbar("Başarıyla silindi!", { variant: "success" });
      getList();
      setDialogVisible(false);
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu!", { variant: "error" });
    }
  };

  const handleCloseUserPopup = () => {
    setUserData(null);
    setAddUserVisible(false);
  };

  React.useState(() => {
    getList();
  }, []);

  return (
    <Layout
      {...props}
      heading="Kullanıcı Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Kullanıcı Yönetimi" }]}
      action={
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setAddUserVisible(true)}
        >
          Ekle
        </Button>
      }
    >
      <List
        data={list}
        loading={loading}
        handleEditItem={handleEditItem}
        handleDeleteItem={({ id }) => setDialogVisible(id)}
      />
      {addUserVisible && (
        <AddUser
          userData={userData}
          label="Kullanıcı Ekle"
          getList={getList}
          handleClose={handleCloseUserPopup}
        />
      )}
      <Dialog open={Boolean(dialogVisible)}>
        <DialogTitle>Silmek istiyor musunuz?</DialogTitle>
        <DialogActions>
          <Button
            fullWidth
            color="info"
            onClick={() => setDialogVisible(false)}
          >
            Vazgeç
          </Button>
          <Button fullWidth color="error" onClick={handleDeleteItem} autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
