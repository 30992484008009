import {
  Box,
  Card,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { DatePicker, LocalizationProvider } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { tr } from "date-fns/locale";
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const ChangeDeliveryOrderStatus = ({
  dDLiveTrackingItems,
  deliverySituation,
  setDeliverySituation,
  failedReasons,
}) => {
  return (
    <Card sx={{ p: 2 }}>
      <LabelStyle pb={2}>Teslimat Durumunu Değiştir</LabelStyle>
      <div style={{ padding: "2px" }}>
        <Stack
          direction="column"
          spacing={2}
          alignItems="start"
          justifyContent="center"
        >
          <TextField
            label="Teslimat Durumu"
            value={deliverySituation.deliveryStatus}
            onChange={(e) =>
              setDeliverySituation((prev) => ({
                ...prev,
                deliveryStatus: e.target.value,
              }))
            }
            fullWidth
            select
          >
            {dDLiveTrackingItems.map((item, i) => {
              return (
                <MenuItem
                  key={`dd-${i}`}
                  sx={{ height: "48px" }}
                  value={item.value}
                  divider
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </TextField>

          {deliverySituation.deliveryStatus && (
            <>
              {deliverySituation.deliveryStatus !== "return" ? (
                <>
                  <TextField
                    fullWidth
                    label={
                      deliverySituation.deliveryStatus === "delivered"
                        ? "Teslim Alan Kişi"
                        : "Ek Açıklama"
                    }
                    value={deliverySituation.deliveryText}
                    onInput={(e) =>
                      setDeliverySituation((prev) => ({
                        ...prev,
                        deliveryText: e.target.value,
                      }))
                    }
                  />

                  {deliverySituation.deliveryStatus === "notDelivered" && (
                    <Box width={1}>
                      {/* <Stack direction="row" spacing={2} alignItems="center">
                                                    <Checkbox 
                                                      value={deliverySituation.postponeChecked} 
                                                      onChange={(e)=> setDeliverySituation(prev => ({...prev, postponeChecked: e.target.checked}))}
                                                    />
                                                    <Typography>Ziyareti Ertelemek istiyor musunuz?</Typography>
                                                  </Stack> */}
                      <TextField
                        label="Teslim Edilmeme Nedeni"
                        select
                        sx={{ width: "100%" }}
                        value={deliverySituation.failReasonId || ""}
                        onChange={(e) => {
                          setDeliverySituation((prev) => ({
                            ...prev,
                            failReasonId: e.target.value,
                          }));
                        }}
                      >
                        {failedReasons.map((item) => {
                          return (
                            <MenuItem
                              key={item.id}
                              sx={{ height: "48px" }}
                              value={item.id}
                              divider
                            >
                              {item.text}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      {deliverySituation.postponeChecked && (
                        <div style={{ paddingTop: "10px" }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={tr}
                          >
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              value={deliverySituation.postponeDate}
                              onChange={(date) =>
                                setDeliverySituation((prev) => ({
                                  ...prev,
                                  postponeDate: date,
                                }))
                              }
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <Typography
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                >
                  Siparişin durumu iade statüsüne dönüştürülecek!
                </Typography>
              )}
            </>
          )}

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            alignSelf="end"
          >
            <Typography>Sms Gönder</Typography>
            <Checkbox
              value={deliverySituation.smsChecked}
              onChange={(e) =>
                setDeliverySituation((prev) => ({
                  ...prev,
                  smsChecked: e.target.checked,
                }))
              }
            />
          </Stack>
        </Stack>
      </div>
    </Card>
  );
};

export default ChangeDeliveryOrderStatus;
