import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import withScrolling from "react-dnd-scrolling";

//
import RoutesListItem from "./ListItem";
import { RouteProps } from "contexts/PlannerProvider/types";

export interface RoutesListItemProps {
  [key: string]: RouteProps[];
}
export interface Props {
  data: RoutesListItemProps;
  openDetailRoute(route: RoutesListItemProps): void;
}

const ScrollingComponent = withScrolling("div");

export default function RoutesList(props: Props) {
  const { data, openDetailRoute } = props;
  const { palette } = useTheme();

  return (
    <ScrollingComponent
      style={{
        gap: 12,
        padding: 12,
        display: "flex",
        overflowY: "auto",
        flexDirection: "column",
        height: "calc(100vh - 486px)",
        backgroundColor: palette.background.default,
      }}
    >
      <TableContainer sx={{ borderRadius: 1 }}>
        <Table size="small">
          <TableHead
            sx={{
              "& th": { borderRadius: "0!important", bgcolor: "grey.200" },
            }}
          >
            <TableRow>
              <TableCell sx={{ boxShadow: "none!important" }}>
                <Typography variant="caption" fontWeight="600">
                  Tarih
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" fontWeight="600">
                  Plan Adı
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" fontWeight="600">
                  Şube
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" fontWeight="600">
                  Rotalar
                </Typography>
              </TableCell>
              <TableCell sx={{ boxShadow: "none!important" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map((item, index) => {
              return (
                <RoutesListItem
                  routes={item[1]}
                  key={`route-${index}`}
                  onClick={() => openDetailRoute({ [item[0]]: item[1] })}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollingComponent>
  );
}
