import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Services } from "api/Services";
import { Form, Formik } from "formik";
import InputField from "components/MultiStepForm/InputField";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import SelectField from "components/MultiStepForm/SelectField";
import TelInput from "components/MultiStepForm/TelInput";

const service = new Services();

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddUser.propTypes = {
  firmaId: PropTypes.string,
  label: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function AddUser(props) {
  const { firmaId, userData, label, handleClose, getList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [firmaList, setFirmaList] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [radioToggle , setRadioToggle] = React.useState(true);

  React.useEffect(() => {
    service.getGroupHyrarchy().then((res) => {
      setGroups(res);
    });
  }, []);

  function flattenData(data) {
    let flattenedData = [];

    function flatten(groups) {
      for (let group of groups) {
        flattenedData.push({
          value: group.id,
          label: group.name,
        });

        if (group.sub_groups.length > 0) {
          flatten(group.sub_groups);
        }
      }
    }

    flatten(data);
    return flattenedData;
  }

  const handleSubmit = async (values) => {
    setLoading(true);

    const sendData = {
      ...values,
      status: "ACTIVE",
      //user_type: radioToggle ? "CUSTOMER_ADMIN": "ADMIN",
    };

    if(radioToggle){
      sendData.group_id = null;
      sendData.user_type= "CUSTOMER_ADMIN";
    }else{
      sendData.firm = null;
      sendData.user_type= "ADMIN"
    }

    delete sendData.password;

    if (userData) {
      sendData.id = userData.id;

      // if new password
      if (values.password) {
        sendData.password = values.password;
      }
    } else {
      sendData.password = values.password;
    }

    try {
      await service.addCustomerPopup(sendData);
      enqueueSnackbar(`Başarıyla ${userData ? "güncellendi" : "kaydedildi"}!`, {
        variant: "success",
      });
      setLoading(false);
      if (getList) {
        getList(); // Get user list
      }
      handleClose();
    } catch (error) {
      setLoading(false);
      if (error?.code === "E0000") {
        enqueueSnackbar(
          "Kullanıcı adı veya e-posta adresi zaten kullanılıyor!",
          {
            variant: "error",
          }
        );
      } else {
        const msg = error?.message || "Bir sorun oluştu!";
        enqueueSnackbar(msg, { variant: "error" });
      }
    }
  };

  const getFirmaList = async () => {
    try {
      const res = await service.getCustomerManagementFilters();
      setFirmaList(
        res.entity.data[0].customers.map((i) => ({
          ...i,
          value: i.key,
          label: i.value,
        }))
      );
    } catch (error) {}
  };

  React.useEffect(() => {
    getFirmaList();
  }, []);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <BootstrapDialogTitle onClose={handleClose}>{label}</BootstrapDialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            firm: userData?.firmaId || firmaId || "",
            group_id: userData?.group_id || "",
            first_name: userData?.first_name || "",
            last_name: userData?.last_name || "",
            email: userData?.email || "",
            phone: userData?.phone || "",
            username: userData?.username || "",
            password: userData?.password || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            //firm: Yup.string().required("Proje alanı zorunlu"),
            //group_id: Yup.string().required("Grup alanı zorunlu"),
            first_name: Yup.string().required("Ad alanı zorunlu"),
            last_name: Yup.string().required("Soyad alanı zorunlu"),
            email: Yup.string().required("E-Posta alanı zorunlu"),
            phone: Yup.string().required("Telefon alanı zorunlu"),
            username: Yup.string().required("Kullanıcı adı alanı zorunlu"),
            password: Yup.string(),
          })}
        >
          {(formik) => (
            <Form>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="project"
                  >
                    <FormControlLabel value="project" control={<Radio onChange={() => setRadioToggle(true)} />} label="Müşteri Admin" />
                    <FormControlLabel value="group" control={<Radio  onChange={() => setRadioToggle(false)}/>} label="Admin" />
                </RadioGroup>
                { radioToggle === true ? (
                    <SelectField
                    required
                    fullWidth
                    size="small"
                    label="Proje"
                    name="firm"
                    options={firmaList}
                    disabled={firmaId ? true : false}
                    />
                )
                : (
                  <Box sx={{ mt: 1 }}>
                    <SelectField
                      required
                      fullWidth
                      size="small"
                      label="Grup Seçiniz"
                      name="group_id"
                      options={flattenData(groups)}
                    />
                </Box>
              )
              }
           
              <Box
                sx={{
                  mt: 2,
                  display: "grid",
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: `repeat(2, 1fr)`,
                    lg: `repeat(2, 1fr)`,
                  },
                }}
              >
                <InputField
                  required
                  size="small"
                  label="Ad"
                  name="first_name"
                  onlyLetter
                />
                <InputField
                  required
                  size="small"
                  label="Soyad"
                  name="last_name"
                  onlyLetter
                />
                <InputField
                  required
                  size="small"
                  label="E-Posta"
                  name="email"
                  type="email"
                />
                <TelInput required size="small" label="Telefon" name="phone" />
                <InputField
                  size="small"
                  label="Kullanıcı Adı"
                  name="username"
                />
                <InputField
                  required={!userData}
                  size="small"
                  label="Parola"
                  name="password"
                  type="password"
                />
              </Box>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  {userData ? "Düzenle" : "Kaydet"}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
