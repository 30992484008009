import React, { useCallback } from "react";
import moment from "moment";
import { tr } from "date-fns/locale";
import * as Icon from "iconsax-react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import {
  Chip,
  Stack,
  Button,
  Dialog,
  Divider,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
//
import { getStatus } from "helper";
import { useSnackbar } from "notistack";
import { Services } from "api/Services";
import { Filters, initialFilters, usePlanner } from "contexts/PlannerProvider";
import {
  OrderFilterOptions,
  OrderFilterOptionsData,
  OrderFilterOptionsRequest,
  OrderFilterOptionsRequestData,
} from "./types";

const services = new Services();

export default function DispatcherNewFilters() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    visibleFilters,
    setVisibleFilters,
    ordersLoading,
    filters,
    setFilters,
    getOrders,
  } = usePlanner();
  const [options, setOptions] = React.useState<OrderFilterOptions>(
    OrderFilterOptionsData
  );
  const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);
  const [optionsRequest, setOptionsRequest] =
    React.useState<OrderFilterOptionsRequest>({
      ...OrderFilterOptionsRequestData,
    });
  const [lastOption, setLastOption] = React.useState<any>({});
  const deciRefTime = React.useRef<any>(null);
  const [deci, setDeci] = React.useState<string>("");
  const [dates2, setDates2] = React.useState<any[]>([null, null]);

  const isMalSubeKabulDates =
    moment(dates2[0]).isValid() && moment(dates2[1]).isValid();

  const optionsRequestData: OrderFilterOptionsRequest = {
    ...optionsRequest,
    debit_types: [],
    debit_end_date: null,
    debit_start_date: null,
    // @ts-ignore
    customers: optionsRequest.customers.map((c) => c.key || ""),
    // @ts-ignore
    branches: optionsRequest.branches.map((c) => c.key || ""),
    // @ts-ignore
    cities: optionsRequest.cities.map((c) => c.key || ""),
    // @ts-ignore
    counties: optionsRequest.counties.map((c) => c.key || ""),
    // @ts-ignore
    status: optionsRequest.status.map((c) => c.key || ""),
    // @ts-ignore
    senders: optionsRequest.senders.map((c) => c.key || ""),
    // @ts-ignore
    warehouse_codes: optionsRequest.warehouse_codes.map((c) => c.key || ""),
    // @ts-ignore
    slot_ranges: optionsRequest.slot_ranges.map((c) => c.key || ""),
  };

  const newOrderListFilters: Filters = {
    ...filters,
    dates: [
      moment(optionsRequestData.start_date).isValid()
        ? moment(optionsRequestData.start_date).toDate()
        : null,
      moment(optionsRequestData.end_date).isValid()
        ? moment(optionsRequestData.end_date).toDate()
        : null,
    ],
    // @ts-ignore
    selectProjects: optionsRequest.customers,
    // @ts-ignore
    selectBranches: optionsRequest.branches,
    // @ts-ignore
    selectCities: optionsRequest.cities,
    // @ts-ignore
    selectCounties: optionsRequest.counties,
    //
    selectDesiRange: deci,
    // @ts-ignore
    selectStatus: optionsRequest.status,
    // @ts-ignore
    selectSenders: optionsRequest.senders,
    // @ts-ignore
    selectWarehouses: optionsRequest.warehouse_codes,
    // @ts-ignore
    selectSlot: optionsRequest.slot_ranges,
    //
    dates2: isMalSubeKabulDates ? dates2 : [null, null],
    // @ts-ignore
    dates2Select: isMalSubeKabulDates ? optionsRequest.debit_types : null,
  };

  const getOptions = useCallback(async () => {
    setOptionsLoading(true);

    // Sipariş Filtresini güncelle
    setFilters(newOrderListFilters);

    try {
      const res = await services.getPlanningFilters(optionsRequestData);
      setOptions({ ...res[0], ...lastOption });
      setOptionsLoading(false);
    } catch (error) {
      setOptionsLoading(false);
    }
  }, [lastOption, newOrderListFilters]);

  const onChangeDeci = (value: string) => {
    setDeci(value);

    if (!value) {
      setOptionsRequest((o) => ({ ...o, deci_range: "" }));
      return;
    }

    if (deciRefTime.current) {
      clearTimeout(deciRefTime.current);
    }

    deciRefTime.current = setTimeout(() => {
      setOptionsRequest((o) => ({ ...o, deci_range: value }));
    }, 1000);
  };

  const resetFilters = () => {
    setDeci("");
    setLastOption({});
    setDates2([null, null]);
    setOptionsRequest(OrderFilterOptionsRequestData);
    getOrders(initialFilters);
  };

  const isMalSubeKabulDisable = dates2.filter(Boolean).length !== 2;

  React.useEffect(() => {
    getOptions();
  }, [dates2, filters.dates2Select, optionsRequest]);

  const onSubmit = () => {
    // // Siparişleri Yeni Filtreye göre çek
    getOrders(newOrderListFilters);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={visibleFilters}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography variant="h5" flex={1}>
            Siparişleri Filtrele
          </Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Chip
              variant="soft"
              color="secondary"
              onDelete={resetFilters}
              label="Seçimleri Temizle"
              deleteIcon={<Icon.Refresh2 variant="Bulk" size={20} />}
            />
            <IconButton onClick={() => setVisibleFilters(false)}>
              <Icon.CloseSquare />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ position: "relative" }}>
        <Stack spacing={3} pt={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
            <Stack direction="row" spacing={2}>
              <DatePicker
                onChange={console.log}
                value={filters.dates[0]}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    label="Sipariş Başlangıç Tarihi"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onAccept={(e) => {
                  console.log("START DATE", e);
                  setOptionsRequest((o) => ({
                    ...o,
                    start_date: moment(e).toDate(),
                  }));
                }}
              />
              <DatePicker
                onChange={console.log}
                value={filters.dates[1]}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    label="Sipariş Bitiş Tarihi"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onAccept={(e) => {
                  console.log("END DATE", e);
                  setOptionsRequest((o) => ({
                    ...o,
                    end_date: moment(e).toDate(),
                  }));
                }}
              />
            </Stack>
          </LocalizationProvider>
          <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
            <Stack spacing={2} flex={1}>
              <Autocomplete
                multiple
                size="small"
                options={options.customers}
                value={filters.selectProjects}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField {...params} label="Projeler" />
                )}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return "";
                }}
                onChange={(e, customers: any[]) => {
                  setLastOption(
                    customers.length > 0 ? { customers: options.customers } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, customers }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.branches}
                value={filters.selectBranches}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField {...params} label="Şubeler" />
                )}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return "";
                }}
                onChange={(e, branches: any[]) => {
                  setLastOption(
                    branches.length > 0 ? { branches: options.branches } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, branches }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.cities}
                value={filters.selectCities}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => <TextField {...params} label="İl" />}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return `Boş (${option.count})`;
                }}
                onChange={(e, cities: any[]) => {
                  setLastOption(
                    cities.length > 0 ? { cities: options.cities } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, cities }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.counties}
                value={filters.selectCounties}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => <TextField {...params} label="İlçe" />}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return `Boş (${option.count})`;
                }}
                onChange={(e, counties: any[]) => {
                  setLastOption(
                    counties.length > 0 ? { counties: options.counties } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, counties }));
                }}
              />
              <TextField
                size="small"
                value={deci}
                onChange={(e) => onChangeDeci(e.target.value)}
                label="Desi Aralığı (5-10)"
                placeholder="Desi aralığı giriniz. (5-10)"
              />
            </Stack>
            <Stack spacing={2} flex={1}>
              <Autocomplete
                multiple
                size="small"
                options={options.statuses}
                value={filters.selectStatus}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField {...params} label="Statüler" />
                )}
                getOptionLabel={(option) => {
                  return `${getStatus(option.key || "ORDER_RECEIVED")} (${
                    option.count
                  })`;
                }}
                onChange={(e, status: any[]) => {
                  setLastOption(
                    status.length > 0
                      ? {
                          statuses: options.statuses.map((k) => ({
                            ...k,
                            key: k.key || "ORDER_RECEIVED",
                          })),
                        }
                      : {}
                  );
                  setOptionsRequest((o) => ({
                    ...o,
                    status: status.map((k) => ({
                      ...k,
                      key: k.key || "ORDER_RECEIVED",
                    })),
                  }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.senders}
                value={filters.selectSenders}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField {...params} label="Gönderen" />
                )}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return `Boş (${option.count})`;
                }}
                onChange={(e, senders: any[]) => {
                  setLastOption(
                    senders.length > 0 ? { senders: options.senders } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, senders }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.warehouses}
                value={filters.selectWarehouses}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField {...params} label="Müşteri Depo" />
                )}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return `Boş (${option.count})`;
                }}
                onChange={(e, warehouse_codes: any[]) => {
                  setLastOption(
                    warehouse_codes.length > 0
                      ? { warehouses: options.warehouses }
                      : {}
                  );
                  setOptionsRequest((o) => ({ ...o, warehouse_codes }));
                }}
              />
              <Autocomplete
                multiple
                size="small"
                options={options.slots}
                value={filters.selectSlot}
                ChipProps={{ variant: "soft" }}
                noOptionsText="Sonuç bulunamadı."
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => <TextField {...params} label="Slot" />}
                getOptionLabel={(option) => {
                  if (option.value) {
                    return `${option.value} (${option.count})`;
                  }
                  return `Boş (${option.count})`;
                }}
                onChange={(e, slot_ranges: any[]) => {
                  setLastOption(
                    slot_ranges.length > 0 ? { slots: options.slots } : {}
                  );
                  setOptionsRequest((o) => ({ ...o, slot_ranges }));
                }}
              />
            </Stack>
          </Stack>
          <Divider />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
            <Stack direction="row" spacing={3}>
              <DatePicker
                onChange={console.log}
                value={dates2[0]}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Mal/Şube Kabul Başlangıç Tarihi"
                  />
                )}
                onAccept={(e) => {
                  console.log("START DATE", e);
                  setDates2((o) => [moment(e).toDate(), o[1] ? o[1] : null]);
                }}
              />
              <DatePicker
                onChange={console.log}
                value={dates2[1]}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Mal/Şube Kabul Bitiş Tarihi"
                  />
                )}
                onAccept={(e) => {
                  console.log("END DATE", e);
                  setDates2((o) => [o[0] ? o[0] : null, moment(e).toDate()]);
                }}
              />
              <Autocomplete
                fullWidth
                size="small"
                value={filters.dates2Select}
                options={[
                  { count: 0, key: "ORDER_ACCEPTED", value: "Mal Kabul" },
                  { count: 0, key: "BRANCH_ACCEPTED", value: "Şube Kabul" },
                ]}
                noOptionsText="Sonuç bulunamadı."
                getOptionLabel={(option) => option.value}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mal/Şube Kabul"
                    disabled={isMalSubeKabulDisable}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e, select: any) => {
                  if (!select) {
                    setDates2([null, null]);
                  }
                  if (isMalSubeKabulDisable) {
                    enqueueSnackbar(
                      "Lütfen Mal ve Şube kabul tarihlerini doldurunuz!",
                      { variant: "error", autoHideDuration: 3000 }
                    );
                  } else {
                    setOptionsRequest((o) => ({
                      ...o,
                      debit_types: select,
                    }));
                  }
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onSubmit}>
          Filtreyi Uygula
        </Button>
      </DialogActions>
      {(optionsLoading || ordersLoading) && (
        <Stack
          spacing={2}
          sx={{
            inset: 0,
            zIndex: 9999,
            position: "absolute",
            bgcolor: "grey.50056",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} color="info" />
          <Typography variant="subtitle1">
            {ordersLoading
              ? "Siparişler Filtreleniyor..."
              : "Filtre Yenileniyor..."}
          </Typography>
        </Stack>
      )}
    </Dialog>
  );
}
