import * as React from "react";
import { Close } from "@mui/icons-material";
import {
  Stack,
  Typography,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";

interface Props {
  tab: number;
  activeTab: number;
  label: string;
  onClick?(): void;
  onDelete?(): void;
}

export default function DispatcherNewOrderHeaderTabButton(props: Props) {
  const { palette } = useTheme();
  const { tab, activeTab, label, onClick, onDelete } = props;

  const active = tab === activeTab;

  return (
    <>
      {tab > activeTab && (
        <Divider
          orientation="vertical"
          sx={{ height: 20, bgcolor: "background.netrual", mt: 1 }}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          height: 50,
          cursor: "pointer",
          alignSelf: "flex-end",
          transition: ".4s ease",
          borderRadius: "8px 8px 0 0",
          bgcolor: active ? "grey.200" : "grey.300",
          "&:hover": {
            bgcolor: active ? "grey.200" : `${palette.grey[200]}70`,
          },
        }}
      >
        <Typography
          flex={1}
          onClick={onClick}
          variant="subtitle2"
          sx={{
            px: 2,
            overflow: "hidden",
            WebkitLineClamp: 1,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {label}
        </Typography>
        {onDelete && (
          <IconButton
            size="small"
            disableRipple
            sx={{ mr: 1 }}
            onClick={onDelete}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </>
  );
}
