import React from "react";
import Item from "./item";
import { Card, CardHeader, Stack, Typography } from "@mui/material";

import useTable, { getComparator } from "hooks/useTable";
import { applySortFilter } from "components/CustomTable/utils";
import CustomTable from "components/CustomTable";
import { Services } from "api/Services";
import moment from "moment";
import {
  AccessTimeOutlined,
  AllInclusive,
  CheckCircleOutline,
  SnoozeOutlined,
} from "@mui/icons-material";

const TabButton = ({ activeTab, setActiveTab, tab, label, icon, color }) => {
  return (
    <Typography
      color="InfoText"
      onClick={() => setActiveTab(tab)}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        cursor: "pointer",
        borderRadius: 10,
        borderBottom: activeTab === tab ? `3px solid ${color}` : "none",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      {icon} {label}
    </Typography>
  );
};

const server = new Services();
const List = ({ userFilter }) => {
  const [tableData, setTableData] = React.useState([]);
  const [filterSearch, filterSearchOnChange] = React.useState("");
  const [activeTab, setActiveTab] = React.useState("all");

  React.useEffect(() => {
    userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");
    server
      .getFilterResult("shipments", { ...userFilter, max_count: null })
      .then((res) => {
        setTableData(
          activeTab === "all"
            ? res.entity.data
            : res.entity.data.filter((item) => item.route_status === activeTab)
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [userFilter, activeTab]);

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    dense,
    onChangeDense,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "route_start_time",
    defaultOrder: "desc",
    defaultRowsPerPage: 5,
  });
  // Table Head Data
  const tableHeadData = [
    { key: "driver_name", title: "Kurye", onSort },
    { key: "route_date", title: "Plan Tarihi", onSort },
    { key: "durum", title: "Durum", onSort },
    { key: "route_start_time", title: "Rota Baş. Saati", onSort },
    { key: "branch_name", title: "Şube", onSort },
    { key: "vehicle_name", title: "Plaka", onSort },
    { key: "order_count", title: "Sipariş", onSort },
    { key: "package_count", title: "Paket", onSort },
    { key: "delivered_count", title: "Teslim", onSort },
    { key: "not_delivered_count", title: "Teslim Edilmeyen", onSort },
    { key: "outlet_count", title: "Nokta", onSort },
    { key: "deci", title: "Desi", onSort },
    { key: "actions", title: "İşlemler", align: "right" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((item) => item.key)],
    comparator: getComparator(order, orderBy),
    uniqBy: "route_id",
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  console.log(tableDataFiltered);
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
      >
        <CardHeader title="Rotalar" sx={{ padding: "0 20px !important" }} />
        <Stack direction="row" alignItems="center" spacing={3} pr={3}>
          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tab="all"
            label="Tümü"
            icon={<AllInclusive sx={{ mr: 1 }} />}
            color="InfoText"
          />

          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tab="ON_THE_WAY"
            label="Başladı"
            icon={<AccessTimeOutlined sx={{ mr: 1, color: "blue" }} />}
            color="blue"
          />
          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tab="PLANNED"
            label="Başlamadı"
            icon={<SnoozeOutlined sx={{ mr: 1, color: "#FFC107" }} />}
            color="#FFC107"
          />
          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tab="COMPLETED"
            label="Tamamlandı"
            icon={<CheckCircleOutline sx={{ mr: 1, color: "#54D62C" }} />}
            color="#54D62C"
          />
        </Stack>
      </Stack>
      <CustomTable
        {...{
          dense,
          loading: false,
          // Filter
          onChangeDense,
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <Item key={`${item.id}-${index}`} item={item} />
          )),
        }}
      />
    </Card>
  );
};

export default List;
