import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { MuiTelInput } from "mui-tel-input";

// ----------------------------------------------------------------------

RHFPhoneInput.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhoneInput({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          defaultCountry="TR"
          disableDropdown={true}
          onChange={(value) => {
            if (value.split(" ").join("").length < 16) {
              field.onChange(value.split(" ").join(""));
            } else {
              return;
            }
          }}
        />
      )}
    />
  );
}
