import * as React from "react";
import { useDrag } from "react-dnd";
import { Stack, Typography } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { getEmptyImage } from "react-dnd-html5-backend";
//
import { OrderProps } from "contexts/PlannerProvider/types";
import { DndTypes } from "screens/DispatcherPlannerNew/dragable";

interface Props {
  order: OrderProps;
  selects: OrderProps[];
  clearItemSelection(): void;
}

export default function ItemDrag(props: Props) {
  const { order, selects, clearItemSelection } = props;
  const ref = React.useRef(null);

  const isPlanned = order.delivery_status === "DELIVERY_PLANNED";

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DndTypes.ORDER,
      item: () => {
        if (isPlanned) {
          return;
        }

        const draggedCard = { ...order };
        let cards: any;
        if (selects.find((card: OrderProps) => card.id === order.id)) {
          cards = selects;
        } else {
          clearItemSelection();
          cards = [draggedCard];
        }
        const otherCards = [...cards];
        otherCards.splice(
          cards.findIndex((c: any) => c.id === order.id),
          1
        );
        const cardsDragStack = cards;
        const cardsIDs = cards.map((c: any) => c.id);
        return { cards, cardsDragStack, draggedCard, cardsIDs };
      },
      isDragging: (monitor: any) => {
        return monitor.getItem().cardsIDs.includes(order.id);
      },
      end: (item, monitor) => {
        clearItemSelection();
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [selects]
  );

  React.useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
  }, []);

  drag(ref);

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ opacity: isDragging ? 0.1 : 1 }}
    >
      <Stack
        ref={ref}
        alignItems="center"
        sx={{
          cursor: isPlanned ? "default" : "grab",
          "&:active": { cursor: "grabbing" },
        }}
      >
        <DragIndicator
          fontSize="small"
          sx={{ color: isPlanned ? "grey.300" : "grey.500" }}
        />
      </Stack>
      <Typography variant="caption">{order.order_code}</Typography>
    </Stack>
  );
}
