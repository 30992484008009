import React from "react";
import { Marker } from "@react-google-maps/api";
//
// import RoutesLine from "./RoutesLine";
import { usePlanner } from "contexts/PlannerProvider";
import { OrderProps } from "contexts/PlannerProvider/types";
import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export interface Props {
  order: OrderProps;
}

export default function CustomMarkerRouteOrder(props: Props) {
  const { order } = props;
  const { handleClickPoint, selectMapRouteOrders } = usePlanner();
  const [menuOpen, setMenuOpen] = React.useState<any>(null);

  const isSelect = Boolean(selectMapRouteOrders.find((o) => o.id === order.id));

  const handleMarkerRightClick = React.useCallback((e: any) => {
    setMenuOpen(e);
  }, []);

  const svg = isSelect
    ? `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.999"
    height="32.907"
    viewBox="0 0 25.999 32.907"
  >
    <path
      id="Path_268"
      data-name="Path 268"
      d="M25.068,13.029a12.069,12.069,0,0,0-12.061-9.37h-.014A12.067,12.067,0,0,0,.932,13.02C-.705,20.245,3.716,26.355,7.718,30.2a7.586,7.586,0,0,0,10.564,0C22.284,26.35,26.7,20.254,25.068,13.029Z"
      fill="${order.route_color}"
      stroke="red"
      stroke-width="1.129"
    />
    <rect
      id="Rectangle_23"
      data-name="Rectangle 23"
      width="14"
      height="14"
      rx="3.5"
      transform="translate(6.5 0.5)"
      fill="red"
    />
    <path
      id="Path_270"
      data-name="Path 270"
      d="M15.15,2h-3.3C9.1,2,8,3.1,8,5.85v3.3C8,11.9,9.1,13,11.85,13h3.3C17.9,13,19,11.9,19,9.15V5.85C19,3.1,17.9,2,15.15,2Zm.935,4.439L13.17,9.354a.569.569,0,0,1-.8,0L10.907,7.893A.561.561,0,0,1,11.7,7.1L12.761,8.16l2.522-2.522a.56.56,0,0,1,.794,0A.569.569,0,0,1,16.085,6.439Z"
      fill="#fff"
    />
    <rect
      id="Rectangle_24"
      data-name="Rectangle 24"
      width="14"
      height="14"
      rx="3.5"
      transform="translate(6.5 0.5)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <text
    fill="#fff"
    font-size="9"
    font-weight="700"
    transform="translate(0.5 15)"
    font-family="Public Sans,sans-serif"
    >
      <tspan text-anchor="middle" x="50%" y="10">
      ${order.plan_day_sequence}
      </tspan>
    </text>
  </svg>`
    : `<svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="30"
          viewBox="0 0 23.031 26.408">
          <g transform="translate(-10046.484 13644.5)">
            <path
            stroke-width="1"
            fill="${order.route_color}"
            transform="translate(10047 -13644)"
            stroke="white"
            d="M21.69,8.3A10.691,10.691,0,0,0,11.006,0h-.012A10.689,10.689,0,0,0,.31,8.292c-1.45,6.4,2.466,11.812,6.011,15.22a6.72,6.72,0,0,0,9.358,0C19.224,20.1,23.14,14.7,21.69,8.3Z"
            />
            <text
            fill="#fff"
            font-size="9"
            font-weight="700"
            font-family="Public Sans,sans-serif"
            transform="translate(10047 -13638)"
            >
              <tspan text-anchor="middle" x="50%" y="10">
              ${order.plan_day_sequence}
              </tspan>
            </text>
          </g>
        </svg>`;

  return (
    <>
      <Marker
        clickable
        zIndex={isSelect ? 5 : 4}
        // clusterer={clusterer}
        onRightClick={(e: any) => {
          handleMarkerRightClick({
            x: e.domEvent.clientX,
            y: e.domEvent.clientY,
          });
        }}
        onClick={() =>
          handleClickPoint({
            order_id: order.id,
            order_code: order.order_code,
            address: order.address.address,
            address_line: order.address.address_line,
            latitude: order.address.latitude,
            longitude: order.address.longitude,
            sign_name: `${order.customer.first_name} ${order.customer.last_name}`,
            route_id: "true", // normal de route id si gelmesi gerekiyor ama sipariş içinde olmadığı için böyle yazdım.
          })
        }
        title={`${order.address.first_name} ${order.address.last_name || ""}`}
        position={{ lat: order.address.latitude, lng: order.address.longitude }}
        icon={{
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17.44, 40),
          scaledSize: new window.google.maps.Size(34.88, 40),
          url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
        }}
      />
      {/* <RoutesLine coordinates={order.routeCoords} color={order.route_color} /> */}
      {Boolean(menuOpen) && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <Box
            sx={{
              zIndex: 9,
              borderRadius: 1,
              position: "fixed",
              overflow: "visible",
              top: `${menuOpen.y}px`,
              left: `${menuOpen.x}px`,
            }}
          >
            <Card component={Stack} sx={{ p: 1 }} spacing={1}>
              <Box
                component="img"
                sx={{ width: 40, height: 40, m: "auto" }}
                src={
                  "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg)
                }
              />
              <Stack sx={{ textAlign: "center" }} spacing={0.5}>
                <Typography variant="subtitle2">{order.order_code}</Typography>
                <Typography variant="subtitle2">
                  {order.customer.first_name} {order.customer.last_name}
                </Typography>
              </Stack>
              <TextField fullWidth size="small" placeholder="Yeni Sıra gir" />
              <Button fullWidth variant="contained" color="secondary">
                Bundan sonraki rotaları yeniden sırala
              </Button>
            </Card>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
}
