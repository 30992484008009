import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Layout from "components/Layout";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import UserIcon from "@mui/icons-material/Person";
import FileIcon from "@mui/icons-material/Description";
import UploadIcon from "@mui/icons-material/Backup";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import List from "./List";
import { Services } from "api/Services";
import Loader from "react-loader-spinner";
import { useSnackbar } from "notistack";
import FormModal from "./Detail";
import Form from "./Detail/Form";
import ActiveModal from "./List/ActiveModal";
import { COUNTRYID_TURKIYE } from "helper";

const server = new Services();

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <UserIcon />,
    2: <FileIcon />,
    3: <UploadIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Araç Bilgileri", "Slot Bilgileri"];

function CustomizedSteppers({ activeStep }) {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep - 1}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

SuperAdminVehicleManagement.propTypes = {
  style: PropTypes.array,
};

export default function SuperAdminVehicleManagement(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilterOptions, setVehicleFilterOptions] = useState([]);
  const [getFiltersData, setGetFiltersData] = useState(false);
  const [vehicleFilter, setVehicleFilter] = useState({
    companyId: "",
    propertyType: "",
    vehicleTypeId: "",
    active: "",
    vehicleName: "",
    evaluationResult: "",
  });
  const [formIsEdit, setFormIsEdit] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [visibleForm, setVisibleForm] = React.useState(null);
  const [activePassiveModal, setActivePassiveModal] = React.useState(null);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [cityList, setCityList] = useState([]);
  const [countyList, setCountyList] = useState([]);

  const changeStep = (type) => {
    if (type === "next") {
      if (activeStep < 3) {
        setActiveStep(activeStep + 1);
      }
    } else {
      if (activeStep > 1) {
        setActiveStep(activeStep - 1);
      }
    }
  };

  const getVehicles = async () => {
    try {
      const res = await server.getVehicleList(vehicleFilter);
      setVehicles(res.entity.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCityList = async () => {
    try {
      const city = await server.getCityList(COUNTRYID_TURKIYE);
      setCityList(city);
    } catch (error) {}
  };

  const getCountyList = async (cityId) => {
    const county = await server.getCountyList(cityId);
    setCountyList(county);
  };

  const handleActive = async (id, reason, status) => {
    try {
      await server.changeVehicleStatus(id, reason, status);
      setVehicles((v) => {
        return v.map((i) => ({
          ...i,
          status: i.id === id ? status : i.status,
        }));
      });
      enqueueSnackbar("Başarıyla güncellendi.", { variant: "success" });
      setActivePassiveModal(null);
    } catch (error) {
      console.log("handleActive error =>", error);
    }
  };

  const updateVehicles = async (updateType, id, status) => {
    try {
      setLoading(true);
      const vehicleEvaluationArr = [
        server.vehicleEvaluationApprove,
        server.vehicleEvaluationReject,
      ];
      await vehicleEvaluationArr[updateType](id).then((data) => {
        setVehicles((prev) =>
          prev.map((v) =>
            v.id === id ? { ...v, evaluation_result: status } : v
          )
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getVehiclesFilter = async () => {
    try {
      const res = await server.getVehiclesFilter();
      setVehicleFilterOptions(
        (res &&
          res.entity &&
          res.entity.data &&
          res.entity.data.length &&
          res.entity.data[0]) ||
          []
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteListItem = async () => {
    try {
      await server.deleteVehicle(dialogVisible);
      enqueueSnackbar("Araç başarıyla silindi", { variant: "success" });
      getVehicles();
      setDialogVisible(false);
    } catch (error) {
      enqueueSnackbar("Araç silinirken bir hata oluştu", { variant: "error" });
    }
  };

  useEffect(() => {
    getVehiclesFilter();
  }, []);

  useEffect(() => {
    getVehicles();
    getCityList();
    setGetFiltersData(false);
  }, [getFiltersData]);

  const openForm = ({ type, item }) => {
    if (type === "new") {
      setFormIsEdit(false);
      setFormData(null);
    } else {
      setFormIsEdit(true);
      setFormData(item);
      // getCountyList(item.city.id);
    }
    setVisibleForm(true);
  };

  const closeForm = () => {
    setFormData(null);
    setFormIsEdit(false);
    setVisibleForm(false);
    setActiveStep(1);
  };

  const prevButtonDisable = activeStep === 1;

  return (
    <Layout
      {...props}
      heading="Araç Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Araç Yönetimi" }]}
      action={
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => openForm({ type: "new" })}
        >
          Araç Ekle
        </Button>
      }
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Oval" color="tomato" height={40} width={40} />
        </Box>
      ) : (
        <>
          <List
            data={vehicles}
            vehicleFilter={vehicleFilter}
            setFilters={setVehicleFilter}
            options={vehicleFilterOptions}
            setGetFiltersData={setGetFiltersData}
            loading={loading}
            handleDelete={(id) => setDialogVisible(id)}
            toggleModal={openForm}
            handleUpdate={updateVehicles}
            handleToggleActivePassive={setActivePassiveModal}
          />
          {visibleForm && (
            <FormModal handleClose={closeForm} isEdit={formIsEdit}>
              <CustomizedSteppers activeStep={activeStep} />
              <Form
                steps={steps}
                prevButtonDisable={prevButtonDisable}
                activeStep={activeStep}
                changeStep={changeStep}
                handleClose={closeForm}
                isEdit={formIsEdit}
                currentData={formData}
                cities={cityList}
                counties={countyList}
                options={vehicleFilterOptions}
                refreshData={getVehicles}
              />
            </FormModal>
          )}
        </>
      )}
      {activePassiveModal && (
        <ActiveModal
          data={activePassiveModal}
          handleClose={() => setActivePassiveModal(null)}
          handleActive={handleActive}
        />
      )}

      <Dialog open={Boolean(dialogVisible)}>
        <DialogTitle>Silmek istiyor musunuz?</DialogTitle>
        <DialogActions>
          <Button
            fullWidth
            color="info"
            onClick={() => setDialogVisible(false)}
          >
            Vazgeç
          </Button>
          <Button
            fullWidth
            color="error"
            onClick={handleDeleteListItem}
            autoFocus
          >
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
