import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Checkbox,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import { Services } from "api/Services";

// form
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFSelect, RHFTextField } from "components/hook-form";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { useSnackbar } from "notistack";
import { VehicleEvaluationResult } from "screens/VehicleManagement/VehicleEvaluationResult";
import { useTranslation } from "react-i18next";
import RHFMultiSelect from "components/hook-form/RHFMultiSelect";
import { tr } from "date-fns/locale";

const SPACING = 2;

const getDate = (date) => moment(date).format("YYYY-MM-DD");

const server = new Services();

Form.propTypes = {
  style: PropTypes.array,
};

const refrigerateField = [
  {
    key: true,
    value: "Evet",
  },
  {
    key: false,
    value: "Hayır",
  },
];

// branches
// companies
// customers
// drivers
// vehicle_models
// vehicle_types

export default function Form({
  isEdit,
  currentData,
  options,
  handleClose,
  refreshData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const evaluationResults = t("vehicle_evaluation_result", {
    returnObjects: true,
  });

 //console.log("options: ", options);
     // company: Yup.string().required("Firma alanı zorunlu"),

  const NewSchema = Yup.object().shape({
    name: Yup.string().required("Plaka alanı zorunlu"),
    branches: Yup.array().required("Şube alanı zorunlu"),
    vehicle_model_oid: Yup.string().required("Model alanı zorunlu"),
    refrigerate: Yup.bool().required("İzolasyon alanı zorunlu"),
    vehicle_type_oid: Yup.string().required("Araç tipi alanı zorunlu"),
    drivers: Yup.string().required("Araç sürücüsü alanı zorunlu"),
    maintanence_km: Yup.string().required("Son bakım km alanı zorunlu"),
    maintanence_date: Yup.date().required("Son bakım tarihi alanı zorunlu"),
    //
    // k_certificate_expiration_date: Yup.date().required(
    //   "K Belgesi alanı zorunlu"
    // ),
    // insurance_expiration_date: Yup.date().required(
    //   "Kasko Belgesi alanı zorunlu"
    // ),
    // vehicle_licence_expiration_date: Yup.date().required(
    //   "Muayene alanı zorunlu"
    // ),
    // inception_expiration_date: Yup.date().required("Ruhsat alanı zorunlu"),
    // traffic_insurance_expiration_date: Yup.date().required(
    //   "Trafik Sigortası alanı zorunlu"
    // ),
  });

  const defaultValues = React.useMemo(
    () => ({
      name: currentData?.vehicle_id || "",
      company: currentData?.company?.id || "",
      branches: currentData?.branches?.map((item) => item.id) || [],
      vehicle_model_oid: currentData?.vehicle_model.id || "",
      refrigerate: currentData?.refrigerate,
      vehicle_type_oid: currentData?.vehicle_type?.id || "",
      drivers: currentData?.drivers[0]?.id || "",
      maintanence_km: currentData?.maintanence_km || "",
      maintanence_date: currentData?.maintanence_date || "",
      evaluation_result: currentData?.evaluation_result || "",
      //
      // k_certificate_expiration_date: "",
      // insurance_expiration_date: "",
      // vehicle_licence_expiration_date: "",
      // inception_expiration_date: "",
      // traffic_insurance_expiration_date: "",
    }),
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async () => {
    if (moment(values.maintanence_date).get("years") < 1900) {
      enqueueSnackbar("Son Bakım tarihi alanını kontrol ediniz.", {
        variant: "warning",
      });
      return;
    }

    const sendData = {
      ...values,
      vehicle_id: values.name,
      name: values.name,
      branches: values.branches,
      drivers: [values.drivers],
      maintanence_date: getDate(values.maintanence_date),
      evaluation_result: values.evaluation_result,
      // k_certificate_expiration_date: getDate(
      //   values.k_certificate_expiration_date
      // ),
      // insurance_expiration_date: getDate(values.insurance_expiration_date),
      // vehicle_licence_expiration_date: getDate(
      //   values.vehicle_licence_expiration_date
      // ),
      // inception_expiration_date: getDate(values.inception_expiration_date),
      // traffic_insurance_expiration_date: getDate(
      //   values.traffic_insurance_expiration_date
      // ),
    };

    if (isEdit) {
      sendData.id = currentData.id;
    }

    try {
      await server.createUpdateVehicle(sendData);
      enqueueSnackbar(
        `Araç başarıyla ${isEdit ? "düzenlendi" : "kaydedildi"}!`,
        { variant: "success" }
      );
      refreshData();
      handleClose();
    } catch (error) {
      let msg = "Bir sorun oluştu";
      if (error && error.message) {
        msg = error.message;
      }
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={SPACING}>
        <Box
          sx={{
            display: "grid",
            rowGap: 2,
            columnGap: 1,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            },
          }}
        >
          <RHFTextField
            required
            size="small"
            name="name"
            label="Plaka"
            type="name"
            disabled={isEdit}
          />
          <RHFSelect
            size="small"
            label="Firma"
            name="company"
            SelectProps={{ native: false }}
          >
            {options.companies.map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFMultiSelect
            required
            size="small"
            label="Şube *"
            name="branches"
            SelectProps={{ native: false }}
            renderValue={(selected) =>
              options.branches
                .filter((option) => selected.includes(option.key))
                .map((option) => option.value)
                .join(", ") || currentData?.branches?.map((item) => item.name)
            }
          >
            {options.branches.map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option.key}>
                <Checkbox checked={values.branches.indexOf(option.key) > -1} />
                <ListItemText primary={option.value} />
              </MenuItem>
            ))}
          </RHFMultiSelect>
          <RHFSelect
            required
            size="small"
            label="Model"
            name="vehicle_model_oid"
            SelectProps={{ native: false }}
          >
            {options.vehicle_models.map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFSelect
            required
            size="small"
            label="İzolasyon Var/Yok"
            name="refrigerate"
            SelectProps={{ native: false }}
          >
            {refrigerateField.map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFSelect
            required
            size="small"
            label="Araç Tipi/Yok"
            name="vehicle_type_oid"
            SelectProps={{ native: false }}
          >
            {options.vehicle_types.map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </RHFSelect>
          <Autocomplete
            freeSolo
            options={options.drivers}
            getOptionLabel={(option) => option.value}
            defaultValue={options.drivers.find(
              (item) => item.key === currentData?.drivers?.[0]?.id
            )}
            onChange={(e, value) => {
              setValue("drivers", value.key);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Araç Sürücüsü" size="small" />
            )}
          />
          <RHFTextField
            required
            size="small"
            name="maintanence_km"
            label="Son Bakım KM"
            type="number"
          />
          <Controller
            name="maintanence_date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                <DatePicker
                  mask="__.__.____"
                  inputFormat="dd.MM.yyyy"
                  label="Son Bakım Tarihi"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      size="small"
                      fullWidth
                      error={!!error}
                      helperText={
                        error
                          ? "Girmiş olduğunuz tarih formatı hatalı. Lütfen geçerli bir tarih giriniz."
                          : null
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />

          <RHFSelect
            size="small"
            label="Değerlendirme"
            name="evaluation_result"
            SelectProps={{ native: false }}
          >
            {Object.keys(VehicleEvaluationResult).map((option, i) => (
              <MenuItem key={`${option.key}-${i}`} value={option}>
                {/* {t("vehicle_evaluation_result:"+option.toLowerCase())} */}
                {evaluationResults[option.toLowerCase()]}
              </MenuItem>
            ))}
            {/* {options.drivers.map((option, i) => (
                <MenuItem key={`${option.key}-${i}`} value={option.key}>
                  {option.value}
                </MenuItem>
              ))} */}
          </RHFSelect>
        </Box>
        {/* {!isEdit && (
          <>
            <Divider />
            <Box
              sx={{
                pt: 1,
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
              }}
            >
              <Controller
                name="k_certificate_expiration_date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="K Belgesi (Geçerlilik Tarihi)"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="insurance_expiration_date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Kasko Belgesi (Geçerlilik Tarihi)"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="vehicle_licence_expiration_date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Muayene (Geçerlilik Tarihi)"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="inception_expiration_date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Ruhsat (Geçerlilik Tarihi)"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="traffic_insurance_expiration_date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Trafik sigortası (Geçerlilik Tarihi)"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
          </>
        )} */}

        <Divider />
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="error" variant="outlined" onClick={handleClose}>
            Vazgeç
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {isEdit ? "Düzenle" : "Kaydet"}
          </LoadingButton>
        </Stack>
        {/* <CustomStack>
          <Button
            variant="contained"
            component="label"
            endIcon={<PhotoCamera />}
          >
            K Belgesi
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={() => {
                setFormData({
                  ...formData,
                  k_certificate: "test",
                });
              }}
            />
          </Button>
          <Button
            variant="contained"
            component="label"
            endIcon={<PhotoCamera />}
          >
            Kasko
            <input hidden accept="image/*" multiple type="file" />
          </Button>
          <Button
            variant="contained"
            component="label"
            endIcon={<PhotoCamera />}
          >
            Muayene
            <input hidden accept="image/*" multiple type="file" />
          </Button>
          <Button
            variant="contained"
            component="label"
            endIcon={<PhotoCamera />}
          >
            Araç Ruhsat
            <input hidden accept="image/*" multiple type="file" />
          </Button>
        </CustomStack> */}

        {/* <Stack spacing={SPACING}>
        <Typography variant="subtitle1">Bölge</Typography>
        <Divider />
        <CustomStack>
          {bolgelerData.data.data.map((zone, index) => {
            return (
              <CustomAutoComplete
                key={`zone-${index}`}
                label={zone.name}
                value={""}
                data={zone.zones.map((i) => ({ ...i, title: i.name || "" }))}
                placeholder={`${zone.name} bölgesinden ekle ve çıkar`}
              />
            );
          })}
        </CustomStack>
      </Stack>
      <CustomStack>
        <Stack spacing={SPACING} flex={1}>
          <Typography variant="subtitle1">Aktif Olmadığı Günler</Typography>
          <Divider />
          <CustomCalendar />
        </Stack>
        <Stack spacing={SPACING} flex={1}>
          <Typography variant="subtitle1">Ek Hakediş</Typography>
          <Divider />
          <CustomCalendar />
        </Stack>
      </CustomStack>

      <Stack spacing={SPACING} flex={1}>
        <Typography variant="subtitle1">Hakediş</Typography>
        <Divider />
        <CustomStack>
          <CustomInput
            label="Minimum Günlük Hakediş"
            placeholder="Minimum günlük hakediş giriniz"
          />
          <CustomInput
            label="Sabit Günlük Hakediş"
            placeholder="Sabit günlük hakediş giriniz"
          />
        </CustomStack>
        <Box>
          <Grid container spacing={SPACING}>
            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" mb={1}>
                <Typography variant="subtitle1" flex={1}>
                  Ziyaret Başı Hakediş
                </Typography>
                <Button variant="contained" size="small" color="info">
                  <Add fontSize="small" />
                </Button>
              </Stack>
              <Divider sx={{ my: SPACING }} />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sil</TableCell>
                      <TableCell>Oparetör</TableCell>
                      <TableCell>Tutar</TableCell>
                      <TableCell>Minimum değer</TableCell>
                      <TableCell>Maksimum değer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover sx={{ "& td": { border: 0 } }}>
                      <TableCell>
                        <Button variant="contained" size="small">
                          <Delete fontSize="small" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <CustomSelect
                          data={[
                            { id: "1", name: "Arasında" },
                            { id: "2", name: "Büyüktür" },
                          ]}
                          placeholder="Tutar"
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Tutar" />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Min.Değer" />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Max.Değer" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" mb={1}>
                <Typography variant="subtitle1" flex={1}>
                  Desi Başı Hakediş
                </Typography>
                <Button variant="contained" size="small" color="info">
                  <Add fontSize="small" />
                </Button>
              </Stack>
              <Divider sx={{ my: SPACING }} />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sil</TableCell>
                      <TableCell>Oparetör</TableCell>
                      <TableCell>Tutar</TableCell>
                      <TableCell>Minimum değer</TableCell>
                      <TableCell>Maksimum değer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover sx={{ "& td": { border: 0 } }}>
                      <TableCell>
                        <Button variant="contained" size="small">
                          <Delete fontSize="small" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <CustomSelect
                          data={[
                            { id: "1", name: "Arasında" },
                            { id: "2", name: "Büyüktür" },
                          ]}
                          placeholder="Tutar"
                        />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Tutar" />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Min.Değer" />
                      </TableCell>
                      <TableCell>
                        <CustomInput placeholder="Max.Değer" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Stack> */}
      </Stack>
    </FormProvider>
  );
}
