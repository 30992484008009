import * as React from "react";
import * as Icon from "iconsax-react";
import {
  Card,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
//
import Label from "components/Label";
import { MapBoxWrapper } from "./OrderDetailPoint";
import { usePlanner } from "contexts/PlannerProvider";

interface MapLegendsProps {}

export default function MapLegends(props: MapLegendsProps) {
  const { palette } = useTheme();
  const {
    routes,
    ordersCount,
    mapLegends,
    mapLegendsBranches,
    mapLegendsWarehouses,
    onChangeMapLegends,
    deleteSelectPoints,
    setSelectMapRouteOrders,
  } = usePlanner();
  const [visible, setVisible] = React.useState<boolean>(false);

  if (!visible) {
    return (
      <MapBoxWrapper
        style={{
          left: 0,
          width: 44,
          right: "auto",
          bgcolor: "background.default",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 44, height: 44 }}
          onClick={() => setVisible(true)}
        >
          <Icon.Layer variant="Bulk" color={palette.secondary.main} />
        </Stack>
      </MapBoxWrapper>
    );
  }

  return (
    <MapBoxWrapper style={{ left: 0, right: "auto", maxWidth: 288 }}>
      <Card sx={{ p: 2, pt: 1 }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle1" flex={1}>
            Katmanlar
          </Typography>
          <IconButton onClick={() => setVisible(false)}>
            <Icon.CloseCircle
              size={24}
              variant="Bulk"
              color={palette.text.secondary}
            />
          </IconButton>
        </Stack>
        <Divider />
        <Stack spacing={1} pt={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon.Box variant="Bulk" size={20} />
            <Typography variant="body2" flex={1}>
              Tüm Siparişler
            </Typography>
            <Label>{ordersCount}</Label>
            <Switch
              size="small"
              color="secondary"
              checked={mapLegends.siparisler}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (!isChecked) {
                  deleteSelectPoints();
                }
                onChangeMapLegends({ ...mapLegends, siparisler: isChecked });
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon.Arrow variant="Bulk" size={20} />
            <Typography variant="body2" flex={1}>
              Rotalar
            </Typography>
            <Label>{routes.length}</Label>
            <Switch
              size="small"
              color="secondary"
              checked={mapLegends.rotalar}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (!isChecked) {
                  setSelectMapRouteOrders([]);
                }
                onChangeMapLegends({
                  ...mapLegends,
                  rotalar: isChecked,
                });
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon.Car variant="Bulk" size={20} />
            <Typography variant="body2" flex={1}>
              Sürücüler
            </Typography>
            <Switch
              size="small"
              color="secondary"
              checked={mapLegends.surucular}
              onChange={(e) =>
                onChangeMapLegends({
                  ...mapLegends,
                  surucular: e.target.checked,
                })
              }
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon.Building variant="Bulk" size={20} />
            <Typography variant="body2" flex={1}>
              Müşteri Depoları
            </Typography>
            <Label>{mapLegendsWarehouses.length}</Label>
            <Switch
              size="small"
              color="secondary"
              checked={mapLegends.depolar}
              onChange={(e) =>
                onChangeMapLegends({
                  ...mapLegends,
                  depolar: e.target.checked,
                })
              }
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon.Building3 variant="Bulk" size={20} />
            <Typography variant="body2" flex={1}>
              Dağıtım Şubeleri
            </Typography>
            <Label>{mapLegendsBranches.length}</Label>
            <Switch
              size="small"
              color="secondary"
              checked={mapLegends.subeler}
              onChange={(e) =>
                onChangeMapLegends({
                  ...mapLegends,
                  subeler: e.target.checked,
                })
              }
            />
          </Stack>
        </Stack>
      </Card>
    </MapBoxWrapper>
  );
}
