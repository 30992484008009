import * as React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import { OrderProps } from "contexts/PlannerProvider/types";

interface ItemPackageProps {
  order: OrderProps;
}

export default function ItemPackage(props: ItemPackageProps) {
  const { order } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <Popover
        open={open}
        sx={{ pointerEvents: "none" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }} variant="subtitle1">
          Paketler
        </Typography>
        <Divider />
        <List sx={{ minWidth: "320px" }} dense>
          {order.packages.map((pck, index) => {
            return (
              <ListItem key={index}>
                <ListItemText primary={`• ${pck.barcode}`} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
      <Label
        variant="ghost"
        color={order.packages.length > 0 ? "info" : "default"}
        sx={{
          cursor: order.packages.length > 0 ? "pointer" : "auto",
        }}
      >
        {`${order.packages.length} Paket`}
      </Label>
    </Box>
  );
}
