import * as React from "react";
import "moment/locale/tr";
import moment from "moment";
import * as Icon from "iconsax-react";
import { useSnackbar } from "notistack";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
//
import { Services } from "api/Services";
import { usePlanner } from "contexts/PlannerProvider";
import { RouteProps } from "contexts/PlannerProvider/types";
import { convertTimezone } from "utils/convertTimezone";

const services = new Services();

moment.locale("tr");

type BoxProps = {
  title?: string;
  name: string;
  icon: React.ReactNode;
  onClick?(): void;
};
export const BoxIcon = (props: BoxProps) => {
  const { title, name, icon, onClick } = props;

  return (
    <Tooltip title={title} placement="top-start">
      <Stack
        spacing={0.5}
        direction="row"
        onClick={onClick}
        alignItems="center"
      >
        {icon}
        <Typography variant="caption" color="text.primary">
          {name}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

interface Props {
  routes: RouteProps[];
  onClick?(): void;
}

export default function RoutesListItem(props: Props) {
  const { routes: RoutesArr, onClick } = props;
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectRoutes,
    handleSelectRoute,
    deleteSelectRoute,
    deleteRoute,
    getOrders,
    getRoutes,
    routeFilters,
    filters,
  } = usePlanner();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

  const routes = RoutesArr || [];

  const firstRoute = routes?.length > 0 ? routes[0] : null;

  if (!firstRoute) {
    return null;
  }

  const onChangeChecbox = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setLoading(true);
      for (const route of routes) {
        await handleSelectRoute(route, route.route_color, () => {});
      }
      setLoading(false);
    } else {
      for (const route of routes) {
        deleteSelectRoute(route);
      }
    }
  };

  const selectCount = routes.reduce((total: number, route: RouteProps) => {
    return (
      total +
      (selectRoutes.find((r) => r.route.route_id === route.route_id) ? 1 : 0)
    );
  }, 0);

  const isAllSelect = selectCount === routes.length;

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Emin misiniz?")) {
      return;
    }
    setDeleteLoading(true);

    for (const route of routes) {
      try {
        await services.deletePlanningRoute(route.route_id);
        deleteRoute(route.route_id);
        await getOrders(filters);
        await getRoutes(routeFilters);

        const showDate = moment(route.date).format("DD MMM YYYY HH:mm");
        enqueueSnackbar(`${showDate} Başarıyla silindi.`, {
          variant: "success",
        });
      } catch (error: any) {
        if (error.code === "E0004") {
          enqueueSnackbar("İşlem yapılmış bir rotayı silemezsiniz.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
        }
      }
    }

    setDeleteLoading(false);
  };

  return (
    <TableRow
      sx={{
        cursor: "pointer",
        transition: ".4s ease",
        borderColor: "background.neutral",
        bgcolor: isAllSelect ? "info.lighter" : "background.default",
        "&:hover": { bgcolor: "background.neutral" },
      }}
    >
      <TableCell sx={{ paddingLeft: "8px!important" }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {loading ? (
            <CircularProgress size={12} color="info" />
          ) : (
            <Box
              component="input"
              type="checkbox"
              checked={isAllSelect}
              onChange={onChangeChecbox}
            />
          )}
          <BoxIcon
            title="Tarih"
            onClick={onClick}
            name={convertTimezone(firstRoute.date)}
            icon={
              <Icon.Calendar
                size={14}
                variant="Bulk"
                color={palette.text.secondary}
              />
            }
          />
        </Stack>
      </TableCell>
      <TableCell onClick={onClick}>
        <BoxIcon
          title="Rota Adı"
          name={firstRoute.name}
          icon={
            <Icon.Message
              size={14}
              variant="Bulk"
              color={palette.text.secondary}
            />
          }
        />
      </TableCell>
      <TableCell onClick={onClick}>
        <BoxIcon
          title="Şube"
          name={firstRoute.branch.branch_name}
          icon={
            <Icon.Shop
              size={14}
              variant="Bulk"
              color={palette.text.secondary}
            />
          }
        />
      </TableCell>
      <TableCell onClick={onClick}>
        <BoxIcon
          title="Rota"
          name={`${routes.length}`}
          icon={
            <Icon.Profile2User
              size={14}
              variant="Bulk"
              color={palette.text.secondary}
            />
          }
        />
      </TableCell>
      <TableCell sx={{ paddingRight: "0!important" }}>
        <Tooltip title="Rotaları Sil">
          <IconButton
            size="small"
            color="error"
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? (
              <CircularProgress size={14} color="info" />
            ) : (
              <Icon.Trash variant="Bulk" size={20} />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
