import * as React from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Card,
  Grid,
  Stack,
  Radio,
  Button,
  Dialog,
  Slider,
  styled,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

//
import { Services } from "api/Services";
import AutoPlanningDrivers from "./Drivers";
import CustomSwitch from "components/CustomSwitch";
import { IAutoPlaningListItem } from "./List/types";

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    width: 24,
    height: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const service = new Services();

type IVehicle = {
  deci: number;
  truck_type: null;
  driver_id: string;
  fixed_cost: number;
  vehicle_id: string;
  picture_id: string;
  driver_cost: number;
  loading_time: number;
  distance_cost_per_KM: number;
  overtime_cost_per_min: number;
  limit_overtime_in_minutes: number;
};

type IState = {
  workTime: number;
  plan_name: string;
  maxTripNumber: number;
  useTimeWindow: boolean;
  useMultipleTrip: boolean;
  discardIntersect: boolean;
  dontUseTollRoads: boolean;
  routeMode: "SHORTEST" | "FASTEST";
  depOption: "FIRST" | "LAST" | "FIRSTANDLAST";
  branch: { key: string; value: string } | null;
  workingTimes: { lunchEndTime: string | null; lunchStartTime: string | null };
  dispatchExtraOptions: {
    minFullness: number;
    useRestTime: boolean;
    routeStartPosition: null;
    maxOutletPerVehicle: number;
  };
  vehicles: IVehicle[];
};

type ISelect = {
  key: string;
  value: string;
};

interface Props {
  onClose(): void;
  detail?: IAutoPlaningListItem | null;
}

export type IAutoPlanningSelectDriverVehicle = {
  id: string;
  name: string;
  type: string;
};
export type IAutoPlanningSelectDriver = {
  vehicle: IAutoPlanningSelectDriverVehicle | null;
  driver_id: string;
  checked: boolean;
  picture_id: string;
};

export default function AutoPlanningCreate(props: Props) {
  const { onClose, detail } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [branches, setBranches] = React.useState<ISelect[]>([]);
  const [selectDrivers, setSelectDrivers] = React.useState<
    IAutoPlanningSelectDriver[]
  >([]);
  const [state, setState] = React.useState<IState>({
    maxTripNumber: 1, //
    useTimeWindow: false, //
    useMultipleTrip: false, //
    discardIntersect: true, //
    workTime: detail?.workTime || 0,
    branch: detail?.branch
      ? { key: detail?.branch.id, value: detail?.branch.name }
      : null,
    plan_name: detail?.plan_name || "",
    depOption: detail?.depOption || "FIRST",
    routeMode: detail?.routeMode || "SHORTEST",
    dontUseTollRoads: detail?.dontUseTollRoads || false,
    workingTimes: {
      lunchEndTime: detail?.workingTimes.lunchEndTime || null,
      lunchStartTime: detail?.workingTimes.lunchStartTime || null,
    },
    dispatchExtraOptions: {
      minFullness: detail?.dispatchExtraOptions.minFullness || 0,
      useRestTime: detail?.dispatchExtraOptions.useRestTime || false,
      maxOutletPerVehicle:
        detail?.dispatchExtraOptions.maxOutletPerVehicle || 0,
      routeStartPosition: null, //
    },
    // @ts-ignore
    vehicles: detail?.vehicles || [],
  });

  const getData = async () => {
    try {
      const res = await service.getPlanningBranches();
      setBranches(res.map((item: any) => ({ key: item.id, value: item.name })));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const checkedVehicleWithDrivers = selectDrivers.filter((d) => d.vehicle);

  const onSubmit = async () => {
    setRequestLoading(true);

    const sendData = {
      ...state,
      workingTimes: {
        lunchEndTime: state.workingTimes.lunchEndTime
          ? moment(state.workingTimes.lunchEndTime).format("HH:mm")
          : null,
        lunchStartTime: state.workingTimes.lunchStartTime
          ? moment(state.workingTimes.lunchStartTime).format("HH:mm")
          : null,
      },
      branch: { id: state.branch?.key, name: state.branch?.value },
      vehicles: checkedVehicleWithDrivers.map((d) => {
        return {
          // @ts-ignore
          vehicle_id: d.vehicle.name,
          driver_id: d.driver_id,
          picture_id: d.picture_id,
        };
      }),
    };

    try {
      await service.automaticRuleCreate(sendData);
      if (detail) {
        enqueueSnackbar("Başarıyla güncellendi.", { variant: "success" });
      } else {
        enqueueSnackbar("Başarıyla oluşturuldu.", { variant: "success" });
      }
      setRequestLoading(false);
      onClose();
    } catch (error) {
      enqueueSnackbar("Kural oluşturulamadı.", { variant: "error" });
      setRequestLoading(false);
    }
  };

  const disableBtn =
    !state.plan_name ||
    selectDrivers.length === 0 ||
    checkedVehicleWithDrivers.length === 0;

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>Sipariş Kuralları Oluştur</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={4}>
          <TextField
            value={state.plan_name}
            label="Kural Başlığı Girin"
            placeholder="Kural Başlığını Girin"
            onChange={(e) =>
              setState((s) => ({ ...s, plan_name: e.target.value }))
            }
          />
          <Autocomplete
            options={branches}
            value={state.branch}
            ChipProps={{ variant: "soft" }}
            noOptionsText="Sonuç bulunamadı."
            disabled={loading}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderInput={(params) => (
              <TextField {...params} label="Şubeler" placeholder="Şube ara" />
            )}
            onChange={(e, selectBranches: any) => {
              setSelectDrivers([]);
              setState((s) => ({ ...s, branch: selectBranches }));
            }}
          />
          {state.branch && (
            <AutoPlanningDrivers
              detail={detail}
              branch={state.branch}
              selectDrivers={selectDrivers}
              setSelectDrivers={setSelectDrivers}
            />
          )}
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card
                  p={1.5}
                  spacing={4}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                  sx={{ overflow: "visible" }}
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Çalışma süresi
                  </Typography>
                  <Box pr={2} height={40}>
                    <PrettoSlider
                      min={0}
                      max={24}
                      sx={{ width: 200 }}
                      valueLabelDisplay="auto"
                      value={state.workTime}
                      onChange={(e) => {
                        setState((s) => ({
                          ...s,
                          // @ts-ignore
                          workTime: e.target.value,
                        }));
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  p={1.5}
                  spacing={4}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                  sx={{ overflow: "visible" }}
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Zaman bilgileri (Öğle Yemeği)
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={1} alignSelf="flex-end">
                      <TimePicker
                        ampm={false}
                        value={state.workingTimes.lunchStartTime}
                        slotProps={{
                          textField: { label: "Başlangıç", size: "small" },
                        }}
                        onAccept={(value) => {
                          setState((s) => ({
                            ...s,
                            workingTimes: {
                              ...s.workingTimes,
                              // @ts-ignore
                              lunchStartTime: value.$d,
                            },
                          }));
                        }}
                      />
                      <TimePicker
                        ampm={false}
                        value={state.workingTimes.lunchEndTime}
                        slotProps={{
                          textField: { label: "Bitiş", size: "small" },
                        }}
                        onAccept={(value) => {
                          setState((s) => ({
                            ...s,
                            workingTimes: {
                              ...s.workingTimes,
                              // @ts-ignore
                              lunchEndTime: value.$d,
                            },
                          }));
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  p={1.5}
                  spacing={4}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                  sx={{ overflow: "visible" }}
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Araç doluluk oranı
                  </Typography>
                  <Box pr={2} height={33}>
                    <PrettoSlider
                      sx={{ width: 200 }}
                      valueLabelDisplay="auto"
                      value={state.dispatchExtraOptions.minFullness}
                      onChange={(e) => {
                        setState((s) => ({
                          ...s,
                          dispatchExtraOptions: {
                            ...s.dispatchExtraOptions,
                            // @ts-ignore
                            minFullness: e.target.value,
                          },
                        }));
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  p={1}
                  px={1.5}
                  spacing={1}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Rota Modu
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      label="Minimum KM"
                      control={
                        <Radio
                          value="SHORTEST"
                          checked={state.routeMode === "SHORTEST"}
                          onChange={(e) => {
                            setState((s) => ({ ...s, routeMode: "SHORTEST" }));
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Minimum Süre"
                      control={
                        <Radio
                          value="FASTEST"
                          checked={state.routeMode === "FASTEST"}
                          onChange={(e) => {
                            setState((s) => ({ ...s, routeMode: "FASTEST" }));
                          }}
                        />
                      }
                    />
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  p={2}
                  px={1.5}
                  spacing={1}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Ücretli yollar kullanılsın mı?
                  </Typography>
                  <Box>
                    <CustomSwitch
                      checked={state.dontUseTollRoads}
                      onChange={(e: any) =>
                        setState((s) => ({
                          ...s,
                          dontUseTollRoads: e.target.checked,
                        }))
                      }
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  p={2}
                  px={1.5}
                  spacing={1}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Mola süresi eklensin mi?
                  </Typography>
                  <Box>
                    <CustomSwitch
                      checked={state.dispatchExtraOptions.useRestTime}
                      onChange={(e: any) =>
                        setState((s) => ({
                          ...s,
                          dispatchExtraOptions: {
                            ...s.dispatchExtraOptions,
                            useRestTime: e.target.checked,
                          },
                        }))
                      }
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  p={2}
                  px={1.5}
                  spacing={1}
                  component={Stack}
                  direction={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                >
                  <Typography flex={1} variant="subtitle2" whiteSpace="nowrap">
                    Depooya dönülecek mi?
                  </Typography>
                  <Stack direction={{ xs: "column", md: "row" }}>
                    <FormControlLabel
                      label="Depodan çıksın tekrar depoya dönmesin"
                      control={
                        <Radio
                          value="FIRST"
                          checked={state.depOption === "FIRST"}
                          onChange={(e) => {
                            setState((s) => ({ ...s, depOption: "FIRST" }));
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="İlk noktadan başlasın en son depoya dönsün"
                      control={
                        <Radio
                          value="LAST"
                          checked={state.depOption === "LAST"}
                          onChange={(e) => {
                            setState((s) => ({ ...s, depOption: "LAST" }));
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Depodan çıksın depoya geri dönsün"
                      control={
                        <Radio
                          value="FIRSTANDLAST"
                          checked={state.depOption === "FIRSTANDLAST"}
                          onChange={(e) => {
                            setState((s) => ({
                              ...s,
                              depOption: "FIRSTANDLAST",
                            }));
                          }}
                        />
                      }
                    />
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          size="large"
          color="error"
          variant="outlined"
          onClick={onClose}
        >
          İptal
        </Button>
        <LoadingButton
          fullWidth
          size="large"
          onClick={onSubmit}
          variant="contained"
          disabled={disableBtn}
          loading={requestLoading}
        >
          {detail ? "Güncelle" : "Kaydet"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
