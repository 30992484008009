import * as React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { PersonAddAlt1Rounded } from "@mui/icons-material";
import { CallCalling, Car, Profile2User, TickCircle } from "iconsax-react";
import {
  Button,
  Chip,
  Stack,
  Divider,
  Typography,
  ClickAwayListener,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
  Avatar,
  Card,
} from "@mui/material";
//
import { statusFilter } from "helper";
import { Services } from "api/Services";
import { usePlanner } from "contexts/PlannerProvider";
import {
  OrderProps,
  PointProps,
  RouteProps,
} from "contexts/PlannerProvider/types";
import Label from "components/Label";
import { convertTimezone } from "utils/convertTimezone";

export const MapBoxWrapper = ({ animation, onClick, children, style }: any) => {
  return (
    <Stack
      onClick={onClick}
      sx={{
        m: 2,
        right: 0,
        bottom: 0,
        zIndex: 2,
        maxWidth: 420,
        borderRadius: 1,
        cursor: "pointer",
        position: "absolute",
        width: animation ? "auto" : 1,
        animation: `test .2s ease ${animation ? "infinite" : "forwards"}`,
        "@keyframes test": {
          "0%": {
            opacity: 0,
            transform: "translateY(100px)",
          },
          "100%": {
            opacity: 1,
            transform: " translateY(0px)",
          },
        },
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};

const services = new Services();

interface OrderDetailPointProps {
  clickPoint: PointProps;
  isSelectPoints: boolean;
}

export default function OrderDetailPoint(props: OrderDetailPointProps) {
  const { clickPoint, isSelectPoints } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectMapRouteOrders,
    handleClickClosePoint,
    routes,
    getRoutes,
    routeFilters,
    getOrders,
    filters,
  } = usePlanner();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [order, setOrder] = React.useState<OrderProps | null>(null);
  const [selectRoute, setSelectRoute] = React.useState<RouteProps | null>(null);
  const [atananKurye, setAtananKurye] = React.useState<boolean>(false);
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const [openOrderDetail, setOpenOrderDetail] = React.useState<boolean>(false);

  const onMouseEnter = () => {
    setOpenOrderDetail(true);
  };
  const onMouseLeave = () => {
    setOpenOrderDetail(false);
  };

  const getData = async () => {
    try {
      const res = await services.getPlanningGetOrder(
        clickPoint.order_id,
        clickPoint.order_code
      );
      setOrder(res[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const newRoutes = routes.map((item) => ({
    ...item,
    name: `${item.name} - ${item.driver.first_name} ${item.driver.last_name}`,
  }));

  const uniqByRoutes = _.uniqBy(newRoutes, "name");

  const onSubmit = async () => {
    if (!selectRoute) {
      enqueueSnackbar("Lütfen en az bir tane Rut seçiniz.", {
        variant: "error",
      });
      return;
    }

    setSendLoading(true);

    try {
      await services.planningRouteChangeOrders(selectRoute.route_id, [
        clickPoint.order_id,
      ]);
      await getOrders(filters);
      await getRoutes(routeFilters);
      enqueueSnackbar("Sipariş başarıyla atandı.", {
        variant: "success",
      });
      setSendLoading(false);
      handleClickClosePoint();
    } catch (error: any) {
      setSendLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <MapBoxWrapper
      style={{
        right: isSelectPoints ? 436 : selectMapRouteOrders.length > 0 ? 480 : 0,
      }}
    >
      <ClickAwayListener onClickAway={handleClickClosePoint}>
        {loading ? (
          <Stack
            spacing={2}
            minHeight={300}
            component={Card}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={30} />
            <Typography variant="caption" sx={{ opacity: 0.75 }}>
              Sipariş bilgileri getiriliyor...
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Stack component={Card} p={2} spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  flex={1}
                  target="_blank"
                  component={Link}
                  variant="subtitle2"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  to={`/distribution/detail/sub-detail?siparisKodu=${order?.order_code}`}
                >
                  {openOrderDetail ? "Sipariş Detayına Git" : order?.order_code}
                </Typography>

                <Chip
                  variant="soft"
                  color={clickPoint.route_id ? "primary" : "warning"}
                  label={clickPoint.route_id ? "Planlandı" : "Planlanmadı"}
                />
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Müşteri Adı:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.customer.first_name} {order?.customer.last_name}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Depo Adı:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.warehouse.name}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  İl / İlçe:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.address.city} / {order?.address.county}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Adres:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.address.address}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Paket Sayısı:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.packages.length}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Desi:
                </Typography>
                <Typography variant="caption" flex={1}>
                  {order?.deci}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Durumu:
                </Typography>
                <Chip
                  size="small"
                  variant="soft"
                  label={statusFilter(order)}
                  color={clickPoint.route_id ? "primary" : "warning"}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" fontWeight="600">
                  Sipariş Tarihi:
                </Typography>
                <Label flex={1}>
                  {order?.order_date ? convertTimezone(order?.created_at) : ""}
                </Label>
              </Stack>
              <Divider />
              {clickPoint.route_id ? (
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar>
                      {order?.plan_driver?.first_name.charAt(0)}
                      {order?.plan_driver?.last_name.charAt(0)}
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle2" flex={1}>
                        {order?.plan_driver?.first_name}{" "}
                        {order?.plan_driver?.last_name}
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <CallCalling size={16} variant="Bulk" />
                          <Typography variant="caption" flex={1}>
                            {order?.plan_driver?.username}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Car size={16} variant="Bulk" />
                          <Typography variant="caption" flex={1}>
                            {order?.plan_vehicle?.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                  {!atananKurye && (
                    <Button
                      variant="outlined"
                      onClick={() => setAtananKurye(true)}
                      startIcon={<Profile2User variant="TwoTone" />}
                    >
                      Atanan Kuryeyi Değiştir
                    </Button>
                  )}
                </Stack>
              ) : (
                <Stack spacing={1}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={uniqByRoutes}
                    value={selectRoute}
                    getOptionLabel={(option) =>
                      option.name + " - " + option.driver.username
                    }
                    onChange={(a, selectRoutes) => {
                      setSelectRoute(selectRoutes);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.route_id === value.route_id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Rota ve Kurye Ara"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <LoadingButton
                    variant="outlined"
                    onClick={onSubmit}
                    loading={sendLoading}
                    startIcon={<PersonAddAlt1Rounded />}
                    disabled={!Boolean(selectRoute) || sendLoading}
                  >
                    Siparişi Kuryeye Ata
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
            {atananKurye && (
              <Stack component={Card} p={2} spacing={1}>
                <Box>
                  <Typography variant="subtitle2">
                    Hedef Kurye Seçimi
                  </Typography>
                  <Typography variant="caption">
                    Bu değişiklik geri alınanamaktadır.
                  </Typography>
                </Box>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={uniqByRoutes}
                  value={selectRoute}
                  getOptionLabel={(option) => option.name}
                  onChange={(a, selectRoutes) => {
                    setSelectRoute(selectRoutes);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.route_id === value.route_id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Rota ve Kurye Ara"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <Stack direction="row" spacing={1}>
                  <Button
                    fullWidth
                    size="small"
                    color="inherit"
                    variant="outlined"
                    onClick={() => {
                      setSelectRoute(null);
                      setAtananKurye(false);
                    }}
                  >
                    Vazgeç
                  </Button>
                  <LoadingButton
                    fullWidth
                    size="small"
                    variant="contained"
                    loading={sendLoading}
                    onClick={() => setAtananKurye(true)}
                    disabled={!Boolean(selectRoute) || sendLoading}
                    startIcon={<TickCircle size={24} variant="Bulk" />}
                  >
                    Kaydet
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
      </ClickAwayListener>
    </MapBoxWrapper>
  );
}
