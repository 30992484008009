import React from "react";
import { Marker } from "@react-google-maps/api";
import {
  Box,
  Card,
  MenuItem,
  ClickAwayListener,
  useTheme,
} from "@mui/material";
//
import { PointProps } from "contexts/PlannerProvider/types";
import { usePlanner } from "contexts/PlannerProvider";

export interface Props {
  isSame: boolean;
  selected: boolean;
  point: PointProps;
}

export default function CustomMarkerPoint(props: Props) {
  const { selected, isSame, point } = props;
  const { handleClickPoint } = usePlanner();
  const { palette } = useTheme();
  const [menuOpen, setMenuOpen] = React.useState<any>(null);

  const color = selected
    ? palette.error.main
    : point.route_id
    ? palette.primary.main
    : palette.secondary.main;

  const svg = `
  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_2)">
<path d="M11.0062 25.4889C9.38575 25.4889 7.84501 24.8505 6.66781 23.6914C3.21649 20.2954 -0.603405 14.9204 0.797455 8.59818C2.03811 3.01215 6.8125 0.511651 10.9938 0.511651H11.0062C15.1861 0.511651 19.96 3.01608 21.2027 8.61113C21.8289 11.4374 21.4604 14.3283 20.1072 17.2031C19.0658 19.4153 17.4594 21.5984 15.3323 23.6912C14.1655 24.8511 12.6298 25.4889 11.0062 25.4889Z" 
fill="${isSame && !selected ? palette.warning.main : color}"/>
<path d="M10.9938 1.0233C7.01115 1.0233 2.46455 3.40065 1.28507 8.71133C-0.0642064 14.8007 3.65588 20.0179 7.01448 23.3226C8.09806 24.3896 9.51567 24.9772 11.0062 24.9772C12.499 24.9772 13.911 24.3908 14.9821 23.3261L14.9858 23.3223C18.3431 20.019 22.0622 14.8048 20.715 8.72453C19.5335 3.40459 14.9875 1.0233 11.0062 1.0233H10.9938ZM10.9938 0C11.0062 0 11.0062 0 11.0062 0C15.3938 0 20.3888 2.63809 21.6903 8.49774C23.1402 15.0423 19.2238 20.5722 15.6789 24.0601C14.3775 25.3537 12.6918 26.0005 11.0062 26.0005C9.32057 26.0005 7.63491 25.3537 6.32112 24.0601C2.77632 20.5722 -1.14032 15.0296 0.309835 8.48505C1.61123 2.62541 6.61857 0 10.9938 0Z" fill="white" fill-opacity="0.6"/>
</g>
<defs>
<clipPath id="clip0_1_2">
<rect width="22" height="26" fill="white"/>
</clipPath>
</defs>
</svg>
`;

  const zIndex = isSame ? 9 : selected ? 3 : point.route_id ? 2 : 1;

  const handleMarkerRightClick = React.useCallback((e: any) => {
    setMenuOpen(e);
  }, []);

  return (
    <>
      <Marker
        clickable
        zIndex={zIndex}
        // clusterer={clusterer}
        title={point.sign_name}
        onClick={() => handleClickPoint(point)}
        onRightClick={(e: any) => {
          handleMarkerRightClick({
            x: e.domEvent.clientX,
            y: e.domEvent.clientY,
          });
        }}
        position={{ lat: point.latitude, lng: point.longitude }}
        icon={{
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(11, 28),
          scaledSize: new window.google.maps.Size(22, 26),
          url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
        }}
      />

      {Boolean(menuOpen) && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <Box
            sx={{
              zIndex: 9,
              minWidth: 288,
              borderRadius: 1,
              position: "fixed",
              overflow: "visible",
              top: `${menuOpen.y}px`,
              left: `${menuOpen.x}px`,
            }}
          >
            <Card
              sx={{
                left: 0,
                width: 1,
                bottom: "100%",
                position: "absolute",
                transform: "translateX(-50%)",
              }}
            >
              <MenuItem disableRipple>Ruta Ata</MenuItem>
              <MenuItem disableRipple>Ruta Ata</MenuItem>
              <MenuItem disableRipple>Ruta Ata</MenuItem>
              <MenuItem disableRipple>Ruta Ata</MenuItem>
            </Card>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
  // <CustomMarker key={point.order_id} point={point} />
}
