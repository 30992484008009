import React from "react";
import PropTypes from "prop-types";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import { TextFirstLetter } from "utils";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";

Item.propTypes = {
  item: PropTypes.object,
};

export default function Item(props) {
  const { handleDelete, handleEdit, item } = props;
  const { id, tenant, fullName, username, phone, email } = item;
  const [openMenu, setOpenMenuActions] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>{TextFirstLetter(tenant)}</TableCell>
      <TableCell>{TextFirstLetter(fullName)}</TableCell>
      <TableCell>{username}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleDelete({ firmId: item.detailData.firm?.id, id });
                }}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Sil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleEdit(item);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={"eva:edit-fill"} />
                Düzenle
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
