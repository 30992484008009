import * as React from "react";
import * as Icon from "iconsax-react";
import { Box, Button, Stack, Typography } from "@mui/material";

interface Props {
  onClick(): void;
}

export default function AutoPlanningEmptyList({ onClick }: Props) {
  return (
    <Stack
      flex={1}
      spacing={2}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="auto"
        height={200}
        component="img"
        src="/images/planner-new/auto-list-empty.svg"
      />
      <Box px={20}>
        <Typography variant="h4">Sipariş Kuralı Oluştur!</Typography>
        <Typography variant="body2" color="text.secondary">
          Henüz sipariş kuralı oluşturmadığın görülüyor. Sipariş kuralı
          oluşturmak için aşağıdaki butona tıkla!
        </Typography>
      </Box>
      <Button
        size="large"
        color="secondary"
        onClick={onClick}
        variant="contained"
        startIcon={<Icon.AddCircle variant="Bulk" />}
      >
        Sipariş Kuralı Oluştur
      </Button>
    </Stack>
  );
}
