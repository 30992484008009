import * as React from "react";
import _ from "lodash";
import { useSnackbar } from "notistack";
import update from "immutability-helper";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
//
import { Services } from "api/Services";
import OrderChangeListItem from "./ListItem";
import { OrderProps, RouteProps } from "contexts/PlannerProvider/types";

const service = new Services();

interface Props {
  route: RouteProps;
  orders: OrderProps[];
  onClose(): void;
  getOrders(): void;
}

export default function OrderChangeList(props: Props) {
  const { route, orders, onClose, getOrders } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [newOrders, setNewOrders] = React.useState<OrderProps[]>([...orders]);

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setNewOrders((prevCards: OrderProps[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as OrderProps],
          ],
        })
      );
    },
    []
  );

  // Yeni Sıra eski sıra ile aynı mı diye kontrol ediyorum.
  const isEqual = _.isEqual(orders, newOrders);

  const onSubmitFinishList = React.useCallback(async () => {
    setLoading(true);
    try {
      await service.getPlanningChangeOrderOrders(
        route.route_id,
        newOrders.map((o) => o.id)
      );
      setLoading(false);
      enqueueSnackbar("Sipariş sıraları başarıyla değiştirildi.", {
        variant: "success",
      });
      //
      onClose();
      getOrders();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar("Sipariş sıraları değiştirilemedi.", {
        variant: "error",
      });
    }
  }, [newOrders, route]);

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle
        sx={{ display: "flex", pb: 3, justifyContent: "space-between" }}
      >
        Siparişlerin Sırasını Değiştir
        <Chip variant="soft" color="secondary" label={route.name} />
      </DialogTitle>
      <Divider />
      <DialogContent dividers>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ boxShadow: "none!important" }}>
                  <Typography variant="caption" fontWeight="600">
                    Yeni Sıra
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Sıra
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Alıcı
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="600">
                    Sipariş Kodu
                  </Typography>
                </TableCell>
                <TableCell sx={{ boxShadow: "none!important" }} align="right">
                  <Typography variant="caption" fontWeight="600">
                    Sürükle
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newOrders.map((order, index: number) => {
                return (
                  <OrderChangeListItem
                    key={order.id}
                    order={order}
                    index={index}
                    moveCard={moveCard}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Vazgeç
        </Button>
        <LoadingButton
          loading={loading}
          variant="outlined"
          disabled={isEqual}
          onClick={onSubmitFinishList}
        >
          Değişiklikleri Güncelle
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
