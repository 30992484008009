import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import { BASE_URL } from "api/Env";
import { TextFirstLetter } from "utils";
import { useHistory } from "react-router";
import AddUser from "components/AddUser";
import AddWarehouse from "components/AddWarehouse";
import { COMPANY_TYPE_LIST } from "helper";

Item.propTypes = {
  item: PropTypes.object,
  handleDetailEdit: PropTypes.func,
};

export default function Item({ item, handleDetailEdit, handleDelete }) {
  const history = useHistory();
  const [openMenu, setOpenMenuActions] = React.useState(null);
  const [addUser, setAddUser] = React.useState(null);
  const [addWarehouse, setAddWarehouse] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    handleDetailEdit();
  };

  const getCompanyTypeById = (typeId) => {
    const type = COMPANY_TYPE_LIST.find((item) => item.value === typeId);
    return type ? type.label : "";
  };

  const handleAddUserClose = () => {
    setAddUser(null);
  };

  const handleAddWarehouseClose = () => {
    setAddWarehouse(null);
  };

  const photo = item.detailData.customer_logo_id
    ? `${BASE_URL}api/public/document/${item.detailData.customer_logo_id}`
    : null;
  useEffect(() => {
    console.log("item..", item);
  }, []);

  return (
    <>
      <TableRow hover sx={{ "& td": { border: 0 } }}>
        <TableCell variant="caption">{TextFirstLetter(item.name)}</TableCell>
        <TableCell>
          <Typography variant="caption">
            {/* TextFirstLetter(item.company_type) */}
            {TextFirstLetter(getCompanyTypeById(item.company_type))}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {TextFirstLetter(item.tax_number)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
                  <Iconify icon={"eva:trash-2-outline"} />
                  Sil
                </MenuItem>
                <MenuItem onClick={handleEdit}>
                  <Iconify icon={"eva:edit-fill"} />
                  Düzenle
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      {/* {addUser && (
        <AddUser
          firmaId={addUser}
          label="Kullanıcı Ekle"
          handleClose={handleAddUserClose}
        />
      )}
      {addWarehouse && (
        <AddWarehouse
          firmaId={addWarehouse}
          label="Depo Ekle"
          handleClose={handleAddWarehouseClose}
        />
      )} */}
    </>
  );
}
