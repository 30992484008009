import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./DataTableStyle.css";
import DateFilter from "../dateFilter";
import { DropDownFilter } from "../dropdownFilter";
import { liveTrackingDelivery } from "../dropdownFilter/options";
import { useSelector } from "react-redux";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { Popup } from "semantic-ui-react";
import { Dropdown } from "primereact/dropdown";
import { Stack, Box } from "@mui/material";

import { BASE_URL } from "../../api/Env";
import TYPES from "store/types";
import { useLocation } from "react-router";
import moment from "moment";

const TableBody = (props) => {
  const {
    type,
    dt,
    products,
    selectedProducts,
    setSelectedProducts,
    globalFilter,
    header,
    imageBodyTemplate,
    priceBodyTemplate,
    ratingBodyTemplate,
    statusBodyTemplate,
    actionBodyTemplate,
    projectActionBodyTemplate,
    dateConvert,
    workingPeriodDates,
    detailProject,
    projectDetailTemplate,
    projectDetailPopupTemplate,
    birthdayConvert,
    arrayDriverLength,
    arrayVehiclesLength,
    created_atConvert,
    deliveryStatus,
    orderCodePopup,
    deliveryStatusText,
    arrivalTime,
    tableFooter,
    deleteUnplannedAction,
    liveTrackingDateFormat,
    geocodeEditAction,
    editOrder,
    coordinateReplace,
    customerFeedbackDateFormat,
    vehicleManagementBodyActive,
    barcodePrintedAction,
    convertDateToAnotherTimezone,
    tableFooterLiveTracking,
    dbtUserSelection,
    tableFooterDebit,
    customerWarehouseActionBodyTemplate,
    branchActionBodyTemplate,
    tableFooterAdminOrderManagement,
    coordinateReplaceOrderManagement,
    adminOrderManagementAllDeliveryStatusText,
    differentPage,
  } = props;

  const [deliveryFilterValue, setDeliveryFilterValue] = useState(0);
  const [onTheWayFilterValue, setOnTheWayFilterValue] = useState(0);
  const [warehouseFilterValue, setWarehouseFilterValue] = useState(0);
  const location = useLocation();

  let productsData;
  let ordersData = [];
  let vehiclesData = [];

  const vehicleZoneData = useSelector((state) => state.vehicleZoneReducer);
  const vehicleProjectData = useSelector(
    (state) => state.vehicleProjectReducer
  );

  if (props.products != undefined) {
    productsData = props.products;

    if (type == "order-management" || type == "admin-order-management") {
      productsData &&
        productsData.forEach((order) => {
          order.city_county =
            `${(order.address && order.address.city) || "-"}` +
            "/" +
            `${(order.address && order.address.county) || "-"}`;
        });
      ordersData = productsData;
    }

    if (type == "vehicle-management") {
      productsData &&
        productsData.forEach((vehicle) => {
          vehicle.driver_name = `${
            vehicle.drivers[0] && vehicle.drivers[0].name
          }`;
        });
      vehiclesData = productsData;
    }
  }

  // useEffect(() => {
  //   document.addEventListener('load', () => {

  //   });

  //   let tableHead = document.getElementsByClassName('p-datatable-thead');
  //   tableHead[0].style.position = 'fixed';

  //   document.getElementsByClassName('p-paginator-bottom')[0].style.position = 'fixed';

  // }, []);

  const tableStyle = {
    position: "-webkit-sticky",
    position: "sticky !important",
    top: "0px",
    "z-index": 1,
  };

  const warehouseArrayElements = () => {
    let warehouseFilter = productsData.filter(
      (d) =>
        d.delivery_status == "IN_WAREHOUSE" ||
        d.delivery_status == "DELIVERY_PLANNED" ||
        d.delivery_status == "ON_BOARD"
    );
    return (warehouseFilter && warehouseFilter.length) || 0;
  };

  const onTheWayArrayElements = () => {
    let onTheWayFilter = productsData.filter(
      (d) =>
        d.delivery_status == "CUSTOMER_INFORMED_1" ||
        d.delivery_status == "CUSTOMER_INFORMED_2" ||
        d.delivery_status == "CUSTOMER_INFORMED_3" ||
        d.delivery_status == "ON_THE_WAY_TO_CUSTOMER"
    );
    return (onTheWayFilter && onTheWayFilter.length) || 0;
  };

  const deliveredArrayElements = () => {
    let deliveredFilter = productsData.filter(
      (d) =>
        d.delivery_status == "DELIVERED" ||
        d.delivery_status == "PARTIALLY_DELIVERED" ||
        d.delivery_status == "NOT_DELIVERED" ||
        d.delivery_status == "DELIVERY_POSTPONED"
    );
    return (deliveredFilter && deliveredFilter.length) || 0;
  };

  const fieldExtra = (field) => {
    return `${field.address.city} ${field.address.county}`;
  };

  const dynamicFilter = (data) => {
    //console.log("Dyfilter : ", data);
    //deliveredFilter
    let deliveredFilter =
      data &&
      data.filter(
        (d) =>
          d.delivery_status == "DELIVERED" ||
          d.delivery_status == "PARTIALLY_DELIVERED" ||
          d.delivery_status == "NOT_DELIVERED" ||
          d.delivery_status == "DELIVERY_POSTPONED"
      );
    setDeliveryFilterValue(deliveredFilter && deliveredFilter.length);
    //onTheWayFilter
    let onTheWayFilter =
      data &&
      data.filter(
        (d) =>
          d.delivery_status == "CUSTOMER_INFORMED_1" ||
          d.delivery_status == "CUSTOMER_INFORMED_2" ||
          d.delivery_status == "CUSTOMER_INFORMED_3" ||
          d.delivery_status == "ON_THE_WAY_TO_CUSTOMER" ||
          d.delivery_status == "COURIER_DEBIT"
      );
    setOnTheWayFilterValue(onTheWayFilter && onTheWayFilter.length);
    //warehouseFilter
    let warehouseFilter =
      data &&
      data.filter(
        (d) =>
          d.delivery_status == "IN_WAREHOUSE" ||
          d.delivery_status == "DELIVERY_PLANNED" ||
          d.delivery_status == "ON_BOARD" ||
          d.delivery_status == "BRANCH_ACCEPTED"
      );
    setWarehouseFilterValue(warehouseFilter && warehouseFilter.length);
  };

  useEffect(() => {
    if (type === "LiveTracking") {
      // if page loaded first time
      //deliveredFilter
      let deliveredFilter =
        productsData &&
        productsData.filter(
          (d) =>
            d.delivery_status == "DELIVERED" ||
            d.delivery_status == "PARTIALLY_DELIVERED" ||
            d.delivery_status == "NOT_DELIVERED" ||
            d.delivery_status == "DELIVERY_POSTPONED"
        );
      setDeliveryFilterValue(deliveredFilter && deliveredFilter.length);
      //onTheWayFilter
      let onTheWayFilter =
        productsData &&
        productsData.filter(
          (d) =>
            d.delivery_status == "CUSTOMER_INFORMED_1" ||
            d.delivery_status == "CUSTOMER_INFORMED_2" ||
            d.delivery_status == "CUSTOMER_INFORMED_3" ||
            d.delivery_status == "ON_THE_WAY_TO_CUSTOMER" ||
            d.delivery_status == "COURIER_DEBIT"
        );
      setOnTheWayFilterValue(onTheWayFilter && onTheWayFilter.length);
      //warehouseFilter
      let warehouseFilter =
        productsData &&
        productsData.filter(
          (d) =>
            d.delivery_status == "IN_WAREHOUSE" ||
            d.delivery_status == "DELIVERY_PLANNED" ||
            d.delivery_status == "ON_BOARD" ||
            d.delivery_status == "BRANCH_ACCEPTED"
        );
      setWarehouseFilterValue(warehouseFilter && warehouseFilter.length);

      if (location.state) {
        try {
          dt.current.setState(location.state.dt);

          [...document.querySelectorAll("a")]
            .filter((a) => a.innerHTML.includes(location.state.orderCode))[0]
            .scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
        } catch (err) {
          console.log("scroll error", err);
        }
      }
    }
  }, [productsData]);

  const vehicleProjectField = (rowData) => {
    //return (rowData.projects.map(pr => <p>{pr.substring(0, 8) + "..."}</p>))

    var pr = [];
    if (Object.keys(vehicleProjectData).length > 0) {
      for (let p of vehicleProjectData.data.data) {
        if (
          rowData.projects &&
          rowData.projects.includes(p.project_id) == true
        ) {
          pr.push(p);
        }

        // =>>>>
        // if (rowData.projects == undefined) {
        //   for (var i = 0; i < rowData.vehicles.length; i++) {
        //     for (var j = 0; j < rowData.vehicles[i].projects.length; j++) {
        //       pr.push(rowData.vehicles[i].projects[j])

        //     }
        //   }
        // }
      }
    }

    //return (pr.map(project => <p>{project.name}</p>))

    return (
      <Popup
        trigger={<p>{Object.keys(pr).length > 0 && pr[0].name + "..."}</p>}
        content={pr.map((pr) => pr.name + " / ")}
        basic
      />
    );
  };

  //debit-types

  const debitTypes = (type) => {
    switch (type) {
      case "ORDER_ACCEPT_USER":
        return "Paket Kabul Yapabilir";
        break;
      case "BRANCH_USER":
        return "Kurye Zimmeti Yapabilir";
        break;
      case "BRANCH_RETURN_ACCEPT_USER":
        return "Şubeye İade Kabul Yapabilir";
        break;
      default:
        return "Şube Kabul Yapabilir";
        break;
    }
  };

  const driversRole = (rowData) => {
    //return (rowData.warehouses.map(warehouse => <p>{warehouse.name}</p>))

    return (
      // <Popup
      //   trigger={<p>{(rowData.roles && rowData.roles[0]) && rowData.roles[0] + "..."}</p>}
      //   content={rowData.roles && rowData.roles.map(role => role + " / ")}
      //   basic
      // />

      <Popup
        trigger={
          <p>
            {rowData.roles &&
              rowData.roles[0] &&
              debitTypes(rowData.roles[0]) + "..."}
          </p>
        }
        content={
          rowData.roles && rowData.roles.map((role) => debitTypes(role) + " / ")
        }
        basic
      />
    );
  };

  const vehicleBranch = (rowData) => {
    //return (rowData.warehouses.map(warehouse => <p>{warehouse.name}</p>))
    return (
      <Popup
        trigger={
          <p>
            {rowData.branches &&
              rowData.branches[0] &&
              rowData.branches[0].name + "..."}
          </p>
        }
        content={
          rowData.branches && rowData.branches.map((br) => br.name + " / ")
        }
        basic
      />
    );
  };

  const usersAssignedToTheBranch = (rowData) => {
    //return (rowData.warehouses.map(warehouse => <p>{warehouse.name}</p>))
    return (
      <Popup
        trigger={
          <p>
            {rowData.users &&
              rowData.users[0] &&
              rowData.users[0].username + "..."}
          </p>
        }
        content={
          rowData.users && rowData.users.map((pr) => pr.username + " / ")
        }
        basic
      />
    );
  };

  const warehouseAssignedToTheBranch = (rowData) => {
    //return (rowData.warehouses.map(warehouse => <p>{warehouse.name}</p>))
    return (
      <Popup
        trigger={
          <p>
            {rowData.warehouses &&
              rowData.warehouses[0] &&
              rowData.warehouses[0].name + "..."}
          </p>
        }
        content={
          rowData.warehouses && rowData.warehouses.map((pr) => pr.name + " / ")
        }
        basic
      />
    );
  };

  const branchType = (rowData) => {
    switch (rowData.type) {
      case "STANDART_SHIPPING":
        return "Standart Kargo";
        break;
      case "SAME_DAY_SHIPPING":
        return "Aynı Gün Kargo";
        break;
      default:
        break;
    }
  };

  const vehicleZoneField = (rowData) => {
    // return(rowData.projects.map(zone => <p>{zone.substring(0, 8)+"..."}</p>))

    var de = [];
    if (Object.keys(vehicleZoneData).length > 0) {
      for (let p of vehicleZoneData.data.data) {
        for (let i of p.zones) {
          if (rowData.zones && rowData.zones.includes(i.zone_id) == true) {
            de.push(i);
          }

          // =>>>>
          // if (rowData.zones == undefined) {
          //   for (var a= 0; a < rowData.vehicles.length; a++) {
          //     for (var j = 0; j < rowData.vehicles[a].zones.length; j++) {
          //       de.push(rowData.vehicles[a].zones[j])

          //     }
          //   }
          // }
        }
      }
    }
    //return (de.map(zone => <p>{zone.name}</p>))

    // sementic-ui tooltip - shortening long data
    return (
      <Popup
        trigger={<p>{Object.keys(de).length > 0 && de[0].name + "..."}</p>}
        content={de.map((zone) => zone.name + " / ")}
        basic
      />
    );
  };

  const formatDate = (iso_date) => {
    let date = new Date(iso_date);
    date.setDate(date.getDate() + 1);

    let dd = date.getDate();
    let mm = date.getMonth();
    let yy = date.getFullYear();

    let formated_date = dd + "/" + mm + "/" + yy;

    return formated_date;
  };

  const k_certificateField = (rowData) => {
    return (
      rowData.k_certificate && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.k_certificate.id}`}
            target="_blank"
          >
            {rowData.k_certificate.doc_expiration
              ? new Date(
                  rowData.k_certificate.doc_expiration
                ).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.k_certificate && rowData.k_certificate.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );

    // let date = new Date(rowData.k_certificate && rowData.k_certificate.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;

    // return (rowData.k_certificate && <><a href={`${BASE_URL}/api/public/document/${rowData.k_certificate.id}`} target="_blank">{rowData.k_certificate.doc_expiration ? formated_date : "Tarih yok"}</a>
    //            <i style={{ color: rowData.k_certificate && rowData.k_certificate.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);
  };

  const insuranceField = (rowData) => {
    //return(rowData.insurance && rowData.insurance.doc_expiration)
    // let date = new Date( rowData.insurance && rowData.insurance.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;

    return (
      rowData.insurance && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.insurance.id}`}
            target="_blank"
          >
            {rowData.insurance.doc_expiration
              ? new Date(rowData.insurance.doc_expiration).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.insurance && rowData.insurance.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
    // return (rowData.insurance && <><a href={`${BASE_URL}/api/public/document/${rowData.insurance.id}`} target="_blank">{rowData.insurance.doc_expiration ? formated_date : "Tarih yok"}</a>
    //              <i style={{ color: rowData.insurance && rowData.insurance.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);
  };

  const inceptionField = (rowData) => {
    // return(rowData.inception && rowData.inception.doc_expiration)

    // let date = new Date( rowData.inception && rowData.inception.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;

    return (
      rowData.inception && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.inception.id}`}
            target="_blank"
          >
            {rowData.inception.doc_expiration
              ? new Date(rowData.inception.doc_expiration).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.inception && rowData.inception.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );

    // return (rowData.inception && <><a href={`${BASE_URL}/api/public/document/${rowData.inception.id}`} target="_blank">{rowData.inception.doc_expiration ? formated_date : "Tarih yok"}</a>
    //                                 <i style={{ color: rowData.inception && rowData.inception.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);
  };

  const refrigerateField = (rowData) => {
    return rowData.refrigerate && rowData.refrigerate == true ? "Var" : "Yok";
  };

  // const driversField = (rowData) => {
  //   // return (rowData.drivers ? rowData.drivers.map(drivers => <p>{drivers.name}</p>) : "-")
  //   return (rowData.drivers && Object.keys(rowData.drivers).length > 0 && rowData.drivers[0].name || "-");
  //   //return (rowData ? <p>{rowData.name || "-"}</p> : "-")
  // };

  const vehicleLicenceField = (rowData) => {
    //return(rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration)
    // let date = new Date( rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    //let formated_date = dd + "/" + mm + "/" + yy;
    return (
      rowData.vehicle_licence && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.vehicle_licence.id}`}
            target="_blank"
          >
            {rowData.vehicle_licence
              ? new Date(
                  rowData.vehicle_licence.doc_expiration
                ).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.insurance && rowData.insurance.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );

    // return (rowData.vehicle_licence && <><a href={`${BASE_URL}/api/public/document/${rowData.vehicle_licence.id}`} target="_blank">{rowData.vehicle_licence ? formated_date : "Tarih yok"}</a>
    //          <i style={{ color: rowData.insurance && rowData.insurance.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);
  };

  const maintanenceDateField = (rowData) => {
    // return (rowData.maintanence_date && rowData.maintanence_date || "2021-10-22T20:49");
    // let date = new Date(rowData.maintanence_date && rowData.maintanence_date);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;
    return (
      rowData.maintanence_date &&
      new Date(rowData.maintanence_date).toLocaleDateString()
    );
    //return formated_date;
  };

  const maintanenceKmField = (rowData) => {
    return rowData.maintanence_km && rowData.maintanence_km + " km";
  };

  const birthday = (rowData) => {
    // return (rowData.maintanence_date && rowData.maintanence_date || "2021-10-22T20:49")
    return rowData.birthday && new Date(rowData.birthday).toLocaleDateString();
  };

  const criminalRecord = (rowData) => {
    //return(rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration)
    return (
      rowData.criminal_record && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.criminal_record.id}`}
            target="_blank"
          >
            {new Date(
              rowData.criminal_record.doc_expiration
            ).toLocaleDateString()}
          </a>
          <i
            style={{
              color:
                rowData.criminal_record && rowData.criminal_record.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
  };

  const srcDoc = (rowData) => {
    //return(rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration)
    // return (rowData.src_doc && <><a href={"api.geovision/" + rowData.src_doc.doc_url} target="_blank">{new Date(rowData.src_doc.doc_expiration).toLocaleDateString()}</a>
    //   <i style={{ color: rowData.src_doc && rowData.src_doc.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);

    let date = new Date(rowData.src_doc && rowData.src_doc.doc_expiration);
    date.setDate(date.getDate() + 1);

    let dd = date.getDate();
    let mm = date.getMonth();
    let yy = date.getFullYear();

    let formated_date = dd + "/" + mm + "/" + yy;

    return (
      rowData.src_doc && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.src_doc.id}`}
            target="_blank"
          >
            {formated_date}
          </a>
          <i
            style={{
              color:
                rowData.src_doc && rowData.src_doc.doc_url ? "green" : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
  };

  const driverLicenceField = (rowData) => {
    //return(rowData.insurance && rowData.insurance.doc_expiration)
    // let date = new Date(rowData.licence_doc && rowData.licence_doc.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;

    // return (rowData.licence_doc && <><a href={"api.geovision/" + rowData.licence_doc.doc_url} target="_blank">{rowData.licence_doc.doc_expiration ? new Date(rowData.licence_doc.doc_expiration).toLocaleDateString() : "Tarih yok"}</a>
    //   <i style={{ color: rowData.licence_doc && rowData.licence_doc.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);
    return (
      rowData.licence_doc && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.licence_doc.id}`}
            target="_blank"
          >
            {rowData.licence_doc.doc_expiration
              ? new Date(
                  rowData.licence_doc.doc_expiration
                ).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.licence_doc && rowData.licence_doc.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
  };

  const driverSrcField = (rowData) => {
    //return(rowData.insurance && rowData.insurance.doc_expiration)
    // let date = new Date(rowData.src_doc && rowData.src_doc.doc_expiration);
    // date.setDate(date.getDate() + 1);

    // let dd = date.getDate();
    // let mm = date.getMonth();
    // let yy = date.getFullYear();

    // let formated_date = dd + "/" + mm + "/" + yy;

    // return (rowData.src_doc && <><a href={"api.geovision/" + rowData.src_doc.doc_url} target="_blank">{rowData.src_doc.doc_expiration ? new Date(rowData.src_doc.doc_expiration).toLocaleDateString() : "Tarih yok"}</a>
    //   <i style={{ color: rowData.src_doc && rowData.src_doc.doc_url ? "green" : "red" }} class="large file alternate outline icon"></i></>);

    return (
      rowData.src_doc && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.src_doc.id}`}
            target="_blank"
          >
            {rowData.src_doc.doc_expiration
              ? new Date(rowData.src_doc.doc_expiration).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.src_doc && rowData.src_doc.doc_url ? "green" : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
  };

  const psychoSrcField = (rowData) => {
    //return(rowData.insurance && rowData.insurance.doc_expiration)

    return (
      rowData.psycho_doc && (
        <>
          <a
            href={`${BASE_URL}api/public/document/${rowData.psycho_doc.id}`}
            target="_blank"
          >
            {rowData.psycho_doc.doc_expiration
              ? new Date(rowData.psycho_doc.doc_expiration).toLocaleDateString()
              : "Tarih yok"}
          </a>
          <i
            style={{
              color:
                rowData.psycho_doc && rowData.psycho_doc.doc_url
                  ? "green"
                  : "red",
            }}
            class="large file alternate outline icon"
          ></i>
        </>
      )
    );
  };

  const driverVehicleField = (rowData) => {
    //return(rowData.insurance && rowData.insurance.doc_expiration)
    return (
      (rowData.vehicles &&
        Object.keys(rowData.vehicles).length > 0 &&
        rowData.vehicles[0].name) ||
      "-"
    );
  };

  const debitType = (rowData) => {
    let debitType;
    switch (rowData.debit_type) {
      case "ORDER_ACCEPTED":
        debitType = "Paket Kabul Yapıldı";
        break;
      case "BRANCH_ACCEPTED":
        debitType = "Şube Kabul Yapıldı";
        break;
      case "BRANCH_RETURN_ACCEPTED":
        debitType = "Şubeye İade Kabul Yapıldı";
        break;
      default:
        debitType = "Paket Kabul Bekliyor";
        break;
    }

    return debitType;
  };

  const vehicleUsageReportStatus = (rowData) => {
    //return(rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration)
    return <span> {rowData.status == "ACTIVE" ? "Aktif" : "Pasif"} </span>;
  };

  const vehicleUsageReportModelBrand = (rowData) => {
    //return(rowData.vehicle_licence && rowData.vehicle_licence.doc_expiration)
    return (
      <span>
        {" "}
        {rowData.vehicle_brand_name + " " + rowData.vehicle_model_name}{" "}
      </span>
    );
  };

  const vehicleUsageReportRefrigerate = (rowData) => {
    return <span> {rowData.refrigerate == true ? "Var" : "Yok"} </span>;
  };

  const vehicleUsageReportDriver = (rowData) => {
    return (
      <span>
        {" "}
        {(rowData.first_name && rowData.first_name + " " + rowData.last_name) ||
          "-"}{" "}
      </span>
    );
  };

  const vehicleUsageReportZone = (rowData) => {
    return (
      <Popup
        trigger={<p>{rowData.zone && rowData.zone.split(",")[0] + "..."}</p>}
        content={rowData.zone}
        basic
      />
    );
  };

  const vehicleUsageReportUse = (rowData) => {
    return (
      <span>
        {" "}
        {rowData.delivered > 0 ? (
          <span style={{ color: "green" }}> Evet </span>
        ) : (
          <span style={{ color: "red" }}> Hayır </span>
        )}{" "}
      </span>
    );
  };

  const vehicleUsageReportKm = (rowData) => {
    return <span> {(rowData.planned_distance / 1000).toFixed(1)} </span>;
  };

  const vehicleUsageReportCompletedKm = (rowData) => {
    return <span> {(rowData.completed_distance / 1000).toFixed(1)} </span>;
  };

  const totalPayment = (rowData) => {
    return (
      <span>
        {" "}
        {rowData.payment + rowData.payment_extra != 0
          ? rowData.payment + rowData.payment_extra
          : ""}{" "}
      </span>
    );
  };

  const vehicleUsageReportMaintanenceKm = (rowData) => {
    return <span> {(rowData.maintanence_km / 1000).toFixed(1)} </span>;
  };

  const arrivalTimeToUsAction = (rowData) => {
    return (
      <span>
        {" "}
        {rowData.created_at &&
          new Date(rowData.created_at.split("T")[0]).toLocaleDateString() +
            " " +
            convertDateToAnotherTimezone(rowData.created_at)[1]}{" "}
      </span>
    );
  };

  const orderNameLastNameAction = (rowData) => {
    return (
      <span>
        {" "}
        {rowData.customer.first_name +
          " " +
          ((rowData.customer.last_name && rowData.customer.last_name) ||
            "")}{" "}
      </span>
    );
  };

  const cityAndCountyJoin = (rowData) => {
    return (
      <span>
        {" "}
        {((rowData.address.city && rowData.address.city) || "-") +
          " / " +
          ((rowData.address.county && rowData.address.county) || "-")}{" "}
      </span>
    );
  };

  const headerStyle = {
    // 'position' : 'fixed',
    // 'overflow-y': 'hidden',
    // 'width': '130px',
    // 'height': '100px'
  };

  // ==================================  barcode_printed  ==========================================================

  const [selectedPrintOptions, setSelectedPrintOptions] = useState(null);

  //qrcode options
  const printOptions = [
    { name: "basıldı", value: "true" },
    { name: "basılmadı", value: "false" },
    { name: "Tümü", value: "" },
  ];

  const dropDownSelectedBarcode = (e) => {
    setSelectedPrintOptions(e.target.value);
    return dt.current.filter(e.target.value, "barcode_printed", "equals");
  };

  // ==================================  barcode_printed***  ==========================================================

  // ==================================  debit_type filter  ==========================================================

  const [selectedDebitTypeOptions, setSelectedDebitTypeOptions] =
    useState(null);

  //debit type options
  const debitTypeOptions = [
    { name: "Paket Kabul Yapıldı", value: "ORDER_ACCEPTED" },
    { name: "Şube Kabul Yapıldı", value: "BRANCH_ACCEPTED" },
    { name: "Şubeye İade Kabul Yapıldı", value: "BRANCH_RETURN_ACCEPTED" },
    { name: "Paket Kabul Bekliyor", value: "-" },
    { name: "Tümü", value: "" },
  ];

  const dropDownSelectedDebitType = (e) => {
    setSelectedDebitTypeOptions(e.target.value);
    return dt.current.filter(e.target.value, "debit_type", "equals");
    //return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
  };

  // ==================================  debit_type filter***  ==========================================================

  // ==================================  order-management-filter-geocode-skor  ==========================================================

  const [selectedGeocodeScoreOptions, setSelectedGeocodeScoreOptions] =
    useState(null);

  //geocode score options
  const geocodeScoreOptions = [
    { name: "102", value: "102" },
    { name: "101", value: "101" },
    { name: "100", value: "100" },
    { name: "75", value: "75" },
    { name: "50", value: "50" },
    { name: "25", value: "25" },
    { name: "Tümü", value: "" },
  ];

  const dropDownSelectedGeocodeScore = (e) => {
    setSelectedGeocodeScoreOptions(e.target.value);
    return dt.current.filter(e.target.value, "address.geocode_score", "equals");
    //return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
  };

  // ==================================  order-management-filter-geocode-skor ***  ==========================================================

  // ==================================  live-tracking-filter *** assigned_branch  ==========================================================

  const [liveTrackingFilterValue, setLiveTrackingFilterValue] = useState("");
  const [liveTrackingAssignedBranch, setTrackingAssignedBranch] = useState({
    branch: "",
    deci: 0,
    quantity: 0,
  });

  let assignedBranchOptions = [{ name: "Tümü", value: "" }];

  if (productsData) {
    let allAssignedBranches = [];
    productsData.forEach((p) => {
      if (!allAssignedBranches.includes(p.assigned_branch))
        allAssignedBranches.push(p.assigned_branch);
    });

    assignedBranchOptions = assignedBranchOptions.concat(
      allAssignedBranches.map((b) => ({ name: b, value: b }))
    );
  }

  // const assignedBranchOptions = productsData.map(p => p.assigned_branch);

  const assignedBranchFilterElement = (e) => {
    dt.current.filter(e, "assigned_branch", "equals");
    setLiveTrackingFilterValue(e);

    const filteredProducts = props.products?.filter(
      (p) => p.assigned_branch === e
    );
    let deciTotal = 0;
    let quantityTotal = 0;

    if (e && filteredProducts) {
      deciTotal = filteredProducts.reduce((p, f) => p + f.deci, 0);
      quantityTotal = filteredProducts.reduce((p2, f) => p2 + f.quantity, 0);
    }

    setTrackingAssignedBranch({
      branch: e,
      deci: deciTotal,
      quantity: quantityTotal,
    });

    // dispatch({
    //   type: TYPES.LIVE_ASSIGNED_BRANCH_FILTER,
    //   payload: {
    //     filter: e,
    //     total :{
    //       deci: deciTotal,
    //       quantity: quantityTotal
    //     }
    //   }
    // });
  };

  const liveTrackingRightPaginator = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={2}
          fontSize="18px"
          fontWeight={600}
          color="InfoText"
          fontStyle="italic"
          width="450px"
        >
          <Box width="140px">
            Toplam Desi: {liveTrackingAssignedBranch.deci}
          </Box>
          <Box width="140px">
            Toplam Paket: {liveTrackingAssignedBranch.quantity}
          </Box>
          <Box width="140px" paddingLeft="10px">
            {liveTrackingAssignedBranch.branch}
          </Box>
        </Stack>
      </>
    );
  };

  const liveTrackingLeftPaginator = () => {
    return (
      <>
        <Box></Box>
      </>
    );
  };

  // ==================================  admin-order-management-all-status-filter  ==========================================================

  const [selectedOrderAllStatusOptions, setSelectedOrderAllStatusOptions] =
    useState(null);

  //delivery status options
  const orderAllStatusOptions = [
    { name: "Depoda", value: "DELIVERY_PLANNED" },
    { name: "Dağıtımda", value: "CUSTOMER_INFORMED_1" },
    { name: "Teslim Edildi", value: "DELIVERED" },
    { name: "Teslim Edilmedi", value: "NOT_DELIVERED" },
    { name: "Teslim Ertelendi", value: "DELIVERY_POSTPONED" },
    { name: "Sipariş Müşteriden Alınamadı", value: "DELIVERY_CANCELLED" },
    { name: "İade Edilecek", value: "WILL_BE_RETURN" },
    { name: "Paket Kabul Yapıldı", value: "ORDER_ACCEPTED" },
    { name: "Kuryeye zimmetlendi", value: "COURIER_DEBIT" },
    { name: "Şube Kabul Yapıldı", value: "BRANCH_ACCEPTED" },
    { name: "Şubeye İade Kabul Yapıldı", value: "BRANCH_RETURN_ACCEPTED" },
    { name: "Paket Kabul Bekliyor", value: "ORDER_RECEIVED" },
    { name: "Tümü", value: "" },
  ];

  const dropDownSelectedOrderAllStatus = (e) => {
    setSelectedOrderAllStatusOptions(e.target.value);
    return dt.current.filter(e.target.value, "delivery_status", "equals");
    //return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
  };

  // ==================================  admin-order-management-all-status-filter***  ==========================================================

  let columns;
  switch (type) {
    case "LiveTracking":
      columns = [
        {
          selectionMode: "multiple",
          // style: { width: "40px", paddingBottom: "30px" },
        },
        {
          field: "order_code",
          header: "Sipariş Kodu",
          body: orderCodePopup,
          sortable: true,
          filter: true,
          //style: { padding: '1px' }
          headerStyle: {
            width: "110px",
            position: "fixed",
            "overflow-y": "hidden",
          },
        },
        {
          field: "order_date",
          header: "Sipariş Tarihi",
          body: liveTrackingDateFormat,
          sortable: true,
          filter: true,
          //style: { padding: '1px' } ,
          // headerStyle: {
          //    width: '110px',
          //    position: 'fixed',
          //   'overflow-y': 'hidden'
          // },
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "order_date", "contains")
              }
            />
          ),
        },
        {
          field: "name",
          header: "Sipariş Veren",
          sortable: true,
          filter: true,
          //style: { padding: '1px' }
          // headerStyle: {
          //    position: 'fixed',
          //   'overflow-y': 'hidden'
          // }
        },
        {
          field: "firm_name",
          header: "Firma Adı",
          sortable: true,
          filter: true,
          // headerStyle: {
          //   width: '110px',
          //   padding: '1px'
          //   // position: 'fixed',
          //   // 'overflow-y': 'hidden'
          // },
        },
        {
          field: "gsm_number",
          header: "Telefon",
          sortable: true,
          filter: true,
          //headerStyle: { width: '100px', padding: '1px' }
          // headerStyle: {
          //   width: '110px',
          //   position: 'fixed',
          //   'overflow-y': 'hidden'
          // },
        },
        // { field: "city", header: "il/ilçe" },
        {
          field: "delivery",
          header: productsData && "Depoda" + "(" + warehouseFilterValue + ")",
          body: deliveryStatus,

          sortable: false,
        },
        //{ field: "delivery", header: "Depoda", body: deliveryStatus, headerStyle: { transform: "rotate(270deg)", width: '80px', background: 'transparent' }, sortable: false, },
        {
          field: "delivery",
          header: productsData && "Yolda" + "(" + onTheWayFilterValue + ")",
          body: deliveryStatus,

          sortable: false,
        },
        //{ field: "delivery", header: "Yolda", body: deliveryStatus, headerStyle: { transform: "rotate(270deg)", width: '80px', background: 'transparent' }, sortable: false, },
        //{ field: "order_code", header: "Mesaj", headerStyle: { width: "3rem", writingMode: "vertical-lr", transform: "rotate(180deg)" } },
        //{ field: "delivery", header: "Teslim", body: deliveryStatus, headerStyle: { transform: "rotate(270deg)", width: '80px', background: 'transparent' }, sortable: false, },
        {
          field: "delivery",
          header: productsData && "Teslim" + "(" + deliveryFilterValue + ")",
          body: deliveryStatus,

          sortable: false,
        },
        //{ field: "delivery_status", header: "Teslim Durumu", body: deliveryStatusText, headerStyle: { width: '150px', padding: "0px", textAlign: "center" }, sortable: true, filter: true, filterElement: <DropDownFilter options={liveTrackingDelivery} onChange={e => dt.current.filter(e, "delivery_status", e == "CUSTOMER" ? "contains" : "equals")} /> }, // CUSTOMER(DAĞITIM), contains, will search through all records - style: headerStyle: { transform: "", padding: "1px", width: '80px' },
        {
          field: "filter",
          header: "Teslim Durumu",
          body: deliveryStatusText,
          sortable: true,
          filter: true,
          filterElement: (
            <DropDownFilter
              options={liveTrackingDelivery}
              onChange={(e) => dt.current.filter(e, "filter", "equals")}
            />
          ),
        }, // CUSTOMER(DAĞITIM), contains, will search through all records - style: headerStyle: { transform: "", padding: "1px", width: '80px' },
        {
          field: "delivery_date",
          header: "Teslimat Tarihi",
          body: liveTrackingDateFormat,
          sortable: true,
          filter: true,
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "delivery_date", "contains")
              }
            />
          ),
        }, // style: { padding: '1px' }
        {
          field: "arrival_time",
          header: "Teslim Saati",
          body: arrivalTime,
          sortable: true,
          filter: true,
        }, // style: { padding: '1px' }
        {
          field: "delivery_to",
          header: "Teslim Alan Kişi",
          sortable: true,
          filter: true,
        }, //style: { padding: '1px' }
        {
          field: "driver_name",
          header: "Kurye Adı Soyadı",
          sortable: true,
          filter: true,
        }, // style: { padding: '1px' }
        {
          field: "driver_phone",
          header: "Kurye Telefon",
          sortable: true,
          filter: true,
        }, // style: { padding: '1px' }
        {
          field: "customer_feedback_date",
          header: "Geri Bildirim Tarihi",
          sortable: true,
          filter: true,
          body: customerFeedbackDateFormat,
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "customer_feedback_date", "contains")
              }
            />
          ),
        }, //  style: { padding: '1px' }
        differentPage == "LiveTrackingAdmin" && {
          field: "delivery_description",
          header: "Teslim Edilememe Nedeni",
          sortable: true,
          filter: true,
        }, // headerStyle: { width: '140px', padding: '01x' }
        {
          field: "deci",
          header: "Desi",
          sortable: true,
          filter: true,
        },
        {
          field: "quantity",
          header: "Paket Sayısı",
          sortable: true,
          filter: true,
        }, //style: { padding: '1px' }
        {
          field: "assigned_branch",
          header: "Dağıtım Şubesi",
          sortable: true,
          filter: true,
          filterElement: (
            <DropDownFilter
              options={assignedBranchOptions}
              onChange={assignedBranchFilterElement}
            />
          ),
        }, //style: { padding: '1px' }
      ];
      break;
    case "Project":
      columns = [
        {
          key: "test",
          body: projectActionBodyTemplate,
          headerStyle: { width: "8rem" },
        },
        {
          body: detailProject,
        },
        {
          field: "code",
          header: "Proje No",
          sortable: true,
          filter: true,
          headerStyle: { width: "120px", textAlign: "center" },
        },
        {
          field: "customer.name",
          header: "Proje Sahibi",
          sortable: true,
          filter: true,
          headerStyle: { width: "140px", textAlign: "center" },
        },
        {
          header: "Son Başvuru Tarihi",
          body: dateConvert,
          sortable: true,
          filter: true,
          headerStyle: { width: "170px", textAlign: "center" },
        },
        {
          header: "Çalışma Periyodu",
          body: workingPeriodDates,
          headerStyle: { width: "15rem" },
          sortable: true,
          filter: true,
        },
        {
          field: "loading_points",
          header: "Hareket Noktaları",
          sortable: true,
          filter: true,
          headerStyle: { width: "170px", textAlign: "center" },
        },
        { field: "quota", header: "Alım Sayısı", sortable: true, filter: true },
        {
          field: "requirements",
          header: "Gereksinimler",
          sortable: true,
          filter: true,
        },
        {
          key: "action",
          body: actionBodyTemplate,
        },
      ];
      break;
    case "ProjectApplication":
      columns = [
        {
          body: detailProject,
          sortable: true,
        },
        { field: "test", header: "test", sortable: true, filter: true },
        { field: "test1", header: "test1", sortable: true, filter: true },
        { field: "test2", header: "test2", sortable: true, filter: true },
      ];
      break;
    case "ProjectDetail":
      columns = [
        {
          body: projectDetailPopupTemplate,
          headerStyle: { padding: "0px", width: "60px" },
        },
        {
          field: "advertisement.company_code",
          header: "Başvuru No",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "130px", textAlign: "center" },
        },
        {
          field: "created_at",
          header: "Başvuru Tarihi",
          body: created_atConvert,
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "150px", textAlign: "center" },
          filterElement: (
            <input
              type="text"
              placeholder="Başvuru Tarihi"
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              onMouseMove={(e) => {
                e.target.style.border = "1px solid #2196F3";
              }}
              onMouseLeave={(e) => {
                e.target.style.border = "1px solid #ced4da";
              }}
              style={{
                width: "100%",
                padding: "0.5rem",
                fontSize: "1rem",
                fontWeight: "400",
                border: "1px solid #ced4da",
                height: "36.39px",
                borderRadius: "4px",
                maxHeight: "36.39px",
              }}
              onChange={(e) =>
                dt.current.filter(e.target.value, "created_at", "contains")
              }
            />
          ),
        },
        {
          field: "company_name",
          header: "Şirket Adı",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "130px", textAlign: "center" },
        },
        {
          field: "user.first_name",
          header: "Adı",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "80px", textAlign: "center" },
        },
        {
          field: "user.last_name",
          header: "Soyadı",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "100px", textAlign: "center" },
        },
        {
          field: "user.identity_number",
          header: "TCKN",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "90px", textAlign: "center" },
        },
        {
          field: "user.birthday",
          header: "Doğum Tarihi",
          body: birthdayConvert,
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "145px", textAlign: "center" },
        },
        {
          field: "advertisement.loading_points",
          header: "İl/İlçe",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "100px", textAlign: "center" },
        },
        {
          field: "drivers.length",
          header: "Araç Sayısı",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "125px", textAlign: "center" },
        },
        {
          field: "vehicles.length",
          header: "Sürücü Sayısı",
          sortable: true,
          filter: true,
          headerStyle: { padding: "0px", width: "145px", textAlign: "center" },
        },
        // { field: "drivers", header: "Araç Sayısı", body: arrayDriverLength },
        // { field: "vehicles", header: "Sürücü Sayısı", body: arrayVehiclesLength },
        {
          key: "action",
          header: "Değerlendirme",
          headerStyle: { width: "17rem" },
          body: projectDetailTemplate,
        },
        {
          key: "",
          body: actionBodyTemplate,
          headerStyle: {
            // width: "120px"
          },
        },
      ];
      break;
    case "order-management":
      columns = [
        // { body:  },
        { selectionMode: "multiple", style: { width: "35px" } },
        {
          field: "customer.code",
          header: "Muşteri No",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "order_code",
          header: "Sipariş No",
          body: editOrder,
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "customer.first_name",
          header: "Alıcı Adı",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
          body: orderNameLastNameAction,
        },
        {
          field: "address.address",
          header: "Alıcı Adresi",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        // { field: "address.city" + " " + "address.county", header: "İl", sortable: true, filter: true, style: {width: '90px'} },
        {
          field: "city_county",
          header: "İl/İlçe",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "address.geocode_score",
          header: "Skor",
          sortable: true,
          filter: true,
          style: { width: "90px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedGeocodeScoreOptions}
              options={geocodeScoreOptions}
              onChange={(e) => dropDownSelectedGeocodeScore(e)}
              placeholder="-"
            />
          ),
        },
        {
          field: "debit_type",
          header: "Sipariş Durumu",
          body: debitType,
          sortable: true,
          filter: true,
          style: { width: "160px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedDebitTypeOptions}
              options={debitTypeOptions}
              onChange={(e) => dropDownSelectedDebitType(e)}
              placeholder="Seçiniz"
            />
          ),
        },
        {
          field: "address.latitude",
          header: "Enlem",
          body: coordinateReplaceOrderManagement,
          sortable: true,
          filter: true,
          style: { width: "95px", wordWrap: "break-word" },
        },
        {
          field: "address.longitude",
          header: "Boylam",
          body: coordinateReplaceOrderManagement,
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
        },
        {
          field: "created_at",
          header: "Siparişin Bize Gelme Saati",
          sortable: true,
          filter: true,
          style: { width: "230px", wordWrap: "break-word" },
          body: arrivalTimeToUsAction,
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "created_at", "contains")
              }
            />
          ),
        },
        {
          field: "customer.gsm_number",
          header: "Cep Telefonu",
          sortable: true,
          filter: true,
          style: { width: "140px", wordWrap: "break-word" },
        },
        {
          field: "deci",
          header: "Desi",
          sortable: true,
          filter: true,
          style: { width: "100px", wordWrap: "break-word" },
        },
        {
          field: "products.0.quantity",
          header: "Paket Sayısı",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        //{ field: "sms_notification", header: "SMS Bilgilendirme", sortable: true, filter: true, style: { width: '170px', wordWrap: "break-word" } },
        //{ field: "taken_by", header: "Gonderen", sortable: true, filter: true, style: { width: '120px', wordWrap: "break-word" } },
        {
          field: "barcode",
          header: "Barkod",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
        },
        //{ field: "customer.email", header: "Email", sortable: true, filter: true, style: { width: '140px', wordWrap: "break-word" } },
        //{ field: "customer.delivery_note", header: "Not", sortable: true, filter: true, style: { width: '110px', wordWrap: "break-word" } },
        {
          field: "assigned_branch.name",
          header: "Dağıtım Şubesi",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        //{ field: "debit_type", header: "Sipariş Durumu", body: debitType, sortable: true,filter: true, style: { width: '160px', wordWrap: "break-word" } , filterElement: <Dropdown optionLabel="name" value={selectedDebitTypeOptions} options={debitTypeOptions} onChange={(e) => dropDownSelectedDebitType(e)} placeholder="Seçiniz" />  },
        // { field: "barcode_printed", header: "Barkod", sortable: true, filter: true, body: barcodePrintedAction, style: { width: '150px' }, filterElement: <DropDownFilter value={selectedPrintOptions}  optionLabel="name" options={printOptions} onChange={e => aaaax(e)} /> },
        {
          field: "barcode_printed",
          header: "Barkod",
          sortable: true,
          filter: true,
          body: barcodePrintedAction,
          style: { width: "110px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedPrintOptions}
              options={printOptions}
              onChange={(e) => dropDownSelectedBarcode(e)}
              placeholder="Seçiniz"
            />
          ),
        },
        { body: geocodeEditAction, headerStyle: { width: "3em" } },
        { body: deleteUnplannedAction, style: { width: "120px" } },
      ];
      break;
    case "admin-order-management":
      columns = [
        // { body:  },
        { selectionMode: "multiple", style: { width: "35px" } },
        {
          field: "customer.code",
          header: "Muşteri No",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "order_code",
          header: "Sipariş No",
          body: editOrder,
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "customer.first_name",
          header: "Alıcı Adı",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
          body: orderNameLastNameAction,
        },
        {
          field: "address.address",
          header: "Alıcı Adresi",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        {
          field: "warehouse.name",
          header: "Depo",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        {
          field: "warehouse.firm_name",
          header: "Müşteri",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        // { field: "address.city" + " " + "address.county", header: "İl", sortable: true, filter: true, style: {width: '90px'} },
        {
          field: "city_county",
          header: "İl/İlçe",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "delivery_status",
          header: "Sipariş Durumu",
          body: adminOrderManagementAllDeliveryStatusText,
          sortable: true,
          filter: true,
          style: { width: "160px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedDebitTypeOptions}
              options={orderAllStatusOptions}
              onChange={(e) => dropDownSelectedOrderAllStatus(e)}
              placeholder="Seçiniz"
            />
          ),
        },
        //{ field: "debit_type", header: "Zimmet Durumu", body: debitType, sortable: true, filter: true, style: { width: '160px', wordWrap: "break-word" }, filterElement: <Dropdown optionLabel="name" value={selectedDebitTypeOptions} options={debitTypeOptions} onChange={(e) => dropDownSelectedDebitType(e)} placeholder="Seçiniz" /> },
        {
          field: "created_at",
          header: "Siparişin Gelme Saati",
          sortable: true,
          filter: true,
          style: { width: "190px", wordWrap: "break-word" },
          body: arrivalTimeToUsAction,
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "created_at", "contains")
              }
            />
          ),
        },
        {
          field: "address.latitude",
          header: "Enlem",
          body: coordinateReplace,
          sortable: true,
          filter: true,
          style: { width: "95px", wordWrap: "break-word" },
        },
        {
          field: "address.longitude",
          header: "Boylam",
          body: coordinateReplace,
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
        },
        {
          field: "customer.gsm_number",
          header: "Cep Telefonu",
          sortable: true,
          filter: true,
          style: { width: "140px", wordWrap: "break-word" },
        },
        {
          field: "deci",
          header: "Desi",
          sortable: true,
          filter: true,
          style: { width: "100px", wordWrap: "break-word" },
        },
        {
          field: "products.0.quantity",
          header: "Paket Sayısı",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        //{ field: "sms_notification", header: "SMS Bilgilendirme", sortable: true, filter: true, style: { width: '170px', wordWrap: "break-word" } },
        //{ field: "taken_by", header: "Gonderen", sortable: true, filter: true, style: { width: '120px', wordWrap: "break-word" } },
        {
          field: "barcode",
          header: "Barkod",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
        },
        //{ field: "customer.email", header: "Email", sortable: true, filter: true, style: { width: '140px', wordWrap: "break-word" } },
        //{ field: "customer.delivery_note", header: "Not", sortable: true, filter: true, style: { width: '110px', wordWrap: "break-word" } },
        {
          field: "assigned_branch.name",
          header: "Dağıtım Şubesi",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        //{ field: "debit_type", header: "Sipariş Durumu", body: debitType, sortable: true,filter: true, style: { width: '160px', wordWrap: "break-word" } , filterElement: <Dropdown optionLabel="name" value={selectedDebitTypeOptions} options={debitTypeOptions} onChange={(e) => dropDownSelectedDebitType(e)} placeholder="Seçiniz" />  },
        // { field: "barcode_printed", header: "Barkod", sortable: true, filter: true, body: barcodePrintedAction, style: { width: '150px' }, filterElement: <DropDownFilter value={selectedPrintOptions}  optionLabel="name" options={printOptions} onChange={e => aaaax(e)} /> },
        {
          field: "barcode_printed",
          header: "Barkod",
          sortable: true,
          filter: true,
          body: barcodePrintedAction,
          style: { width: "110px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedPrintOptions}
              options={printOptions}
              onChange={(e) => dropDownSelectedBarcode(e)}
              placeholder="Seçiniz"
            />
          ),
        },
        //{ body: geocodeEditAction, headerStyle: { width: "3em" } },
        { body: deleteUnplannedAction, style: { width: "120px" } },
      ];
      break;
    case "admin-order-geocode":
      columns = [
        // { body:  },
        {
          selectionMode: "multiple",
          style: { width: "35px", display: "none" },
        }, // selectionMode : multiple -> Prevents adding a class to the tr element
        {
          field: "warehouse.code",
          header: "Muşteri Adı",
          sortable: true,
          filter: true,
          style: { width: "130px" },
        },
        {
          field: "customer.code",
          header: "Muşteri No",
          sortable: true,
          filter: true,
          style: { width: "130px" },
        },
        //{ field: "order_code", header: "Sipariş No", body: editOrder, sortable: true, filter: true, style: { width: '130px', wordWrap: "break-word" } },
        {
          field: "order_code",
          header: "Sipariş No",
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "customer.first_name",
          header: "Alıcı Adı",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
          body: orderNameLastNameAction,
        },
        {
          field: "address.address",
          header: "Alıcı Adresi",
          sortable: true,
          filter: true,
          style: { width: "150px", wordWrap: "break-word" },
        },
        // { field: "address.city" + " " + "address.county", header: "İl", sortable: true, filter: true, style: {width: '90px'} },
        {
          header: "İl/İlçe",
          body: cityAndCountyJoin,
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
        },
        {
          field: "delivery_date",
          header: "Sipariş Tarihi",
          body: liveTrackingDateFormat,
          sortable: true,
          filter: true,
          style: { width: "130px", wordWrap: "break-word" },
          filterElement: (
            <DateFilter
              onChange={(date) =>
                dt.current.filter(date, "delivery_date", "contains")
              }
            />
          ),
        },
        {
          field: "address.latitude",
          header: "Enlem",
          body: coordinateReplace,
          sortable: true,
          filter: true,
          style: { width: "95px", wordWrap: "break-word" },
        },
        {
          field: "address.longitude",
          header: "Boylam",
          body: coordinateReplace,
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
        },
        {
          field: "assigned_branch.name",
          header: "Dağıtım Şubesi",
          sortable: true,
          filter: true,
          style: { width: "140px", wordWrap: "break-word" },
        },
        {
          field: "address.geocode_score",
          header: "Skor",
          sortable: true,
          filter: true,
          style: { width: "110px", wordWrap: "break-word" },
          filterElement: (
            <Dropdown
              optionLabel="name"
              value={selectedGeocodeScoreOptions}
              options={geocodeScoreOptions}
              onChange={(e) => dropDownSelectedGeocodeScore(e)}
              placeholder="-"
            />
          ),
        },
        { body: geocodeEditAction, headerStyle: { width: "3em" } },
        //{ body: deleteUnplannedAction, style: { width: '120px' } }
      ];
      break;
    case "vehicle-management":
      // columns = [
      //   { field: "active", header: "Aktif", sortable: true, filter: true, body: vehicleManagementBodyActive },
      //   { field: "vehicle_id", header: "Plaka", sortable: true, filter: true, style: {width: '90px'} },
      //   { field: "project_name", header: "Proje", sortable: true, filter: true },
      //   { field: "zone", header: "Zone", sortable: true, filter: true },
      //   { field: "vehicle_brand", header: "Marka", sortable: true, filter: true,style: {width: '40px'} },
      //   { field: "vehicle_model.name", header: "Model", sortable: true, filter: true },
      //   { field: "k_certificate", header: "K Belgesi", sortable: true, filter: true, style: {width: '100px'}},
      //   { field: "insurance", header: "Kasko", sortable: true, filter: true, style: {width: '100px'}},
      //   { field: "traffic_insurance", header: "Trafik Sigortası", sortable: true, filter: true, style: {width: '90px'} },
      //   { field: "vehicle_inspection", header: "Muayene", sortable: true, filter: true, style: {width: '100px'} },
      //   { field: "frigo", header: "Frigo/izalasyon var?", sortable: true, filter: true ,style: {width: '100px'}},
      //   { field: "name", header: "Araç Sürücüsü", sortable: true, filter: true ,style: {width: '120px'}},
      //   { field: "vehicle_licenc", header: "Ruhsat Resmi", sortable: true, filter: true },
      //   { field: "last_maintenance_date", header: "Son Bakım Tarihi", sortable: true, filter: true, style: {width: '90px'}  },
      //   { field: "last_maintenance_km", header: "Son Bakım KM", sortable: true, filter: true },
      //   {
      //     key: "action",
      //     body: actionBodyTemplate,
      //     style: {width: '110px'}
      //   }
      // ];

      // ===>
      columns = [
        {
          field: "status",
          header: "Aktif",
          body: vehicleManagementBodyActive,
          style: { width: "100px" },
        },
        {
          field: "company.name",
          header: "Firma",
          sortable: true,
          style: { width: "80px", padding: "0px", textAlign: "center" },
        },
        {
          field: "vehicle_id",
          header: "Plaka",
          sortable: true,
          style: { width: "80px", padding: "0px", textAlign: "center" },
        },
        {
          field: "projects",
          key: "vproject",
          header: "Proje",
          body: vehicleProjectField,
          style: { width: "80px", padding: "0px", textAlign: "center" },
        },
        {
          field: "zones",
          key: "zone",
          header: "Zone",
          body: vehicleZoneField,
          style: { width: "90px", padding: "0px", textAlign: "center" },
        },
        {
          field: "vehicle_model.name",
          header: "Model",
          sortable: true,
          style: { width: "100px", padding: "0px", textAlign: "center" },
        },
        {
          field: "k_certificate",
          key: "k_certificate",
          header: "K Belgesi",
          body: k_certificateField,
          style: { width: "120px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          field: "insurance",
          key: "insurance",
          header: "Kasko",
          body: insuranceField,
          style: { width: "120px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        //{ key: "inception_file", header: "Trafik Sigortası", body: inceptionField, style: { width: '110px' }, sortable: true },
        // { key: "inception_file", header: "Trafik Sigortası", body: inceptionField, style: { width: '110px' }, sortable: true },
        {
          field: "inception",
          key: "inception",
          header: "Muayene",
          body: inceptionField,
          style: { width: "120px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          field: "refrigerate",
          header: "Frigo / izolasyon - var?",
          body: refrigerateField,
          style: { width: "200px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          field: "driver_name",
          header: "Sürücü",
          // body: driversField,
          style: {
            width: "100px",
            padding: "0px",
            textAlign: "center",
          },
          sortable: true,
          // sortable: (a, b) => a > b ? 1 : -1
        },
        {
          field: "branches",
          header: "Şubeler",
          body: vehicleBranch,
          sortable: true,
          style: { width: "150px", padding: "0px", textAlign: "center" },
        },
        {
          field: "vehicle_licence",
          header: "Araç Ruhsat",
          body: vehicleLicenceField,
          style: { width: "130px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          field: "maintanence_date",
          header: "Son Bakım Tarihi",
          body: maintanenceDateField,
          style: { width: "160px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          field: "maintanence_km",
          header: "Son Bakım KM",
          body: maintanenceKmField,
          style: { width: "160px", padding: "0px", textAlign: "center" },
          sortable: true,
        },
        {
          key: "action",
          body: actionBodyTemplate,
          style: { width: "110px" },
        },
      ];
      break;
    case "driver-management":
      columns = [
        {
          field: "status",
          header: "Aktif",
          body: vehicleManagementBodyActive,
          style: { width: "100px" },
        },
        {
          field: "company.name",
          header: "Firma",
          sortable: true,
          style: { width: "100px", padding: "0px", textAlign: "center" },
        },
        //{ field: "username", header: "Kullanıcı Adı", sortable: true},
        {
          field: "first_name",
          header: "Adı",
          sortable: true,
          style: { width: "100px", padding: "0px", textAlign: "center" },
        },
        {
          field: "last_name",
          header: "Soyadı",
          sortable: true,
          style: { width: "100px", padding: "0px", textAlign: "center" },
        },
        {
          field: "birthday",
          header: "Doğum Tarihi",
          sortable: true,
          body: birthday,
          style: { width: "120px", padding: "0px", textAlign: "center" },
        },
        //{ field: "", header: "TC", sortable: true,},
        {
          field: "licence_number",
          header: "Ehliyet No",
          sortable: true,
          style: { width: "130px", padding: "0px", textAlign: "center" },
        },
        {
          field: "licence_doc",
          header: "Ehliyet Geçerlilik Tarihi",
          sortable: true,
          body: driverLicenceField,
          style: { width: "170px", padding: "0px", textAlign: "center" },
        },
        {
          field: "criminal_record",
          header: "Sabıka Kaydı Var mı?",
          sortable: true,
          body: criminalRecord,
          style: { width: "170px", padding: "0px", textAlign: "center" },
        },
        {
          field: "src_doc",
          header: "SRC Belgesi",
          sortable: true,
          style: { width: "110px" },
          body: driverSrcField,
          style: { width: "130px", padding: "0px", textAlign: "center" },
        },
        {
          field: "psycho_doc",
          header: "Psiko Teknik Belgesi",
          sortable: true,
          style: { width: "110px" },
          body: psychoSrcField,
          style: { width: "170px", padding: "0px", textAlign: "center" },
        },
        {
          field: "sgk",
          header: "SGK",
          sortable: true,
          style: { width: "130px", padding: "0px", textAlign: "center" },
        },
        {
          field: "phone",
          header: "Telefon",
          sortable: true,
          style: { width: "130px", padding: "0px", textAlign: "center" },
        },
        // { field: "email", header: "Email", sortable: true},
        {
          field: "address",
          header: "Adres",
          sortable: true,
          style: { width: "150px", padding: "0px", textAlign: "center" },
        },
        {
          field: "roles",
          header: "Yetkiler",
          body: driversRole,
          sortable: true,
          style: { width: "150px", padding: "0px", textAlign: "center" },
        },
        // { field: "birthday", header: "Başarılı Teslimat Oranı", sortable: true, body: birthday},
        // { field: "birthday", header: "Müşteri Şikayet Oranı", sortable: true, body: birthday},
        // { field: "birthday", header: "Müşteri Puan Ortalaması", sortable: true, body: birthday},
        {
          field: "vehicles",
          header: "Kullanıdığı Araçlar,",
          sortable: true,
          body: driverVehicleField,
          style: { width: "150px", padding: "0px", textAlign: "center" },
        },
        {
          key: "action",
          body: actionBodyTemplate,
          style: { width: "110px" },
        },
      ];
      break;
    case "vehicle-usage-report":
      columns = [
        {
          field: "date",
          header: "Tarih",
          sortable: true,
          style: { width: "120px" },
        },
        {
          field: "uStatus",
          header: "Kullanım Durumu",
          sortable: true,
          style: { width: "170px" },
          body: vehicleUsageReportUse,
        },
        {
          field: "vehicle_id",
          header: "Plaka",
          sortable: true,
          style: { width: "100px" },
        },
        {
          field: "company_name",
          header: "Firma",
          sortable: true,
          style: { width: "130px", "word-wrap": "break-word" },
        },
        {
          field: "project_names",
          header: "Çalıştığı Projeler",
          sortable: true,
          style: { width: "200px", "word-wrap": "break-word" },
        },
        {
          field: "status",
          header: "Aktif/Pasif",
          sortable: true,
          body: vehicleUsageReportStatus,
          style: { width: "120px" },
        },
        {
          field: "brandModal",
          header: "Marka/Model",
          sortable: true,
          body: vehicleUsageReportModelBrand,
          style: { width: "140px" },
        },
        {
          field: "refrigerate",
          header: "Frigo / izolasyon - var?",
          sortable: true,
          body: vehicleUsageReportRefrigerate,
          style: { width: "200px" },
        },
        {
          field: "driver",
          header: "Sürücü",
          sortable: true,
          body: vehicleUsageReportDriver,
          style: { width: "150px" },
        },
        {
          field: "phone",
          header: "Telefon",
          sortable: true,
          style: { width: "100px" },
        },
        {
          field: "maintanence_expiration",
          header: "Son Bakım Tarihi",
          sortable: true,
          style: { width: "160px" },
        },
        {
          field: "maintanence_km",
          header: "Son Bakım KM",
          sortable: true,
          style: { width: "150px" },
          body: vehicleUsageReportMaintanenceKm,
        },
        //{ field: "zone", header: "Bölge", sortable: true , style: { width: '200px' }, body: vehicleUsageReportZone },
        {
          field: "zone",
          header: "Bölge",
          sortable: true,
          style: { width: "200px", "word-wrap": "break-word" },
        },
        {
          field: "total",
          header: "Günlük Teslimat sayısı",
          sortable: true,
          style: { width: "200px" },
        },
        {
          field: "delivered",
          header: "Başarılı Teslimat Sayısı",
          sortable: true,
          style: { width: "200px" },
        },
        {
          field: "not_delivered",
          header: "Başarısız Teslimat sayısı",
          sortable: true,
          style: { width: "210px" },
        },
        {
          field: "delivery_postponed",
          header: "Ertelenen Teslimat sayısı",
          sortable: true,
          style: { width: "210px" },
        },
        {
          field: "planned_distance",
          header: "Günlük Planlanan  Km",
          sortable: true,
          style: { width: "200px" },
          body: vehicleUsageReportKm,
        },
        {
          field: "completed_distance",
          header: "Gerçekleşen Km",
          sortable: true,
          style: { width: "160px" },
          body: vehicleUsageReportCompletedKm,
        },
        {
          field: "reason",
          header: "Pasifse Nedeni",
          sortable: true,
          style: { width: "200px", "word-wrap": "break-word" },
        },
        {
          field: "payment",
          header: "Hakediş",
          sortable: true,
          style: { width: "110px" },
        },
        {
          field: "payment_extra",
          header: "Ek Hakediş",
          sortable: true,
          style: { width: "130px" },
        },
        {
          field: "total_payment",
          header: "Toplam Hakediş",
          sortable: true,
          style: { width: "170px" },
          body: totalPayment,
        },
      ];
      break;
    case "debit":
      columns = [
        { selectionMode: "multiple", style: { width: "10px" } },
        { field: "city", header: "İl", style: { width: "100px" } },
        { field: "county", header: "İlçe", style: { width: "100px" } },
        {
          field: "assigned_user",
          header: "Kullanıcı",
          style: { width: "100px" },
          body: dbtUserSelection,
        },
      ];
      break;
    case "dialog-table-customer-warehouse":
      columns = [
        { field: "code", header: "Kod", style: { width: "130px" } },
        { field: "name", header: "İsim", style: { width: "130px" } },
        { field: "city", header: "İl", style: { width: "130px" } },
        { field: "county", header: "İlçe", style: { width: "130px" } },
        {
          field: "latitude",
          header: "Enlem",
          body: coordinateReplace,
          style: { width: "130px" },
        },
        {
          field: "longitude",
          header: "Boylam",
          body: coordinateReplace,
          style: { width: "130px" },
        },
        { field: "address", header: "Adres", style: { width: "250px" } },
        {
          key: "action",
          body: customerWarehouseActionBodyTemplate,
        },
      ];
      break;
    case "admin-branch":
      columns = [
        //{ field: "firm_id.name", header: "Müşteri", style: { width: '150px' } },
        { field: "address.code", header: "Kod", style: {} },
        { field: "name", header: "İsim", style: {} },
        { field: "address.city", header: "İl", style: {} },
        { field: "address.county", header: "İlçe", style: {} },
        {
          field: "address.latitude",
          header: "Enlem",
          body: coordinateReplace,
          style: {},
        },
        {
          field: "address.longitude",
          header: "Boylam",
          body: coordinateReplace,
          style: {},
        },
        {
          field: "address.address",
          header: "Adres",
          style: {},
        },
        {
          field: "type",
          header: "Şube Tipi",
          style: {},
          body: branchType,
        },
        {
          field: "users",
          key: "users",
          body: usersAssignedToTheBranch,
          header: "Kullanıcı",
          style: {},
        },
        {
          field: "warehouses",
          key: "warehouses",
          body: warehouseAssignedToTheBranch,
          header: "Depo",
          style: {},
        },
        {
          key: "action",
          body: branchActionBodyTemplate,
        },
      ];
      break;
    default:
      columns = [
        { field: "code", header: "Müşteri Kodu", sortable: true, filter: true },
        { field: "name", header: "Müşteri Adı", sortable: true, filter: true },
        {
          field: "address.address",
          header: "Adres",
          sortable: true,
          filter: true,
        },
        {
          field: "address.county",
          header: "İlçe/il",
          sortable: true,
          filter: true,
        },
        {
          field: "address.first_name",
          header: "Şirket Yetkilisi",
          sortable: true,
          filter: true,
        },
        {
          field: "address.gsm_number",
          header: "Telefon-1",
          sortable: true,
          filter: true,
        },
        {
          field: "contact.username",
          header: "Bringo Sorumlusu",
          sortable: true,
          filter: true,
        },
        {
          key: "action",
          body: actionBodyTemplate,
          style: { width: "300px" },
        },
      ];
      break;
  }

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        body={col.body && col.body}
        sortable={col.sortable && col.sortable}
        headerStyle={col.headerStyle && col.headerStyle}
        style={col.style && col.style}
        selectionMode={col.selectionMode && col.selectionMode}
        key={col.field ? col.field : col.key}
        field={col.field && col.field}
        header={col.header && col.header}
        headerClassName={col.headerClassName && col.headerClassName}
        filter={col.filter && col.filter}
        filterPlaceholder={
          col.filterPlaceholder ? col.filterPlaceholder : col.header
        }
        filterMatchMode={col.filterMatchMode ? col.filterMatchMode : "contains"}
        filterElement={col.filterElement && col.filterElement}
        //filterFunction={col.filterFunction && col.filterFunction}
      />
    );
  });

  return (
    <DataTable
      //scrollable scrollHeight="800px" style={{ width: '100%' }}
      //scrollHeight="800px"
      // stickyHeader="true"
      // stickyTopAt=".layout-topbar"
      //style={{width: '2800px'}}
      tableStyle={tableStyle}
      className="dataTablecontainer"
      ref={dt}
      value={
        type == "order-management"
          ? ordersData
          : type == "vehicle-management"
          ? vehiclesData
          : productsData
      }
      selection={selectedProducts}
      onSelectionChange={(e) => setSelectedProducts(e.value)}
      // There was a problem with multiple selection on the live follow screen.
      //dataKey="id"
      paginatorRight={
        liveTrackingAssignedBranch.branch && liveTrackingRightPaginator
      }
      paginatorLeft={
        liveTrackingAssignedBranch.branch && liveTrackingLeftPaginator
      }
      paginator
      paginatorClassName="tbody-paginator"
      rows={50}
      autoLayout={true}
      rowsPerPageOptions={[20, 50, 100, 200, 400]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="" //Gösterilen {first} ile {last} arası toplam kayıt {totalRecords}
      selectionMode="checkbox"
      globalFilter={globalFilter}
      header={type !== "LiveTracking" && header}
      csvSeparator={";"}
      columns={dynamicColumns}
      onRowClick={type === "order-management" && editOrder}
      footer={
        type === "order-management"
          ? tableFooter
          : type === "LiveTracking"
          ? tableFooterLiveTracking
          : type === "debit"
          ? tableFooterDebit
          : type === "admin-order-management"
          ? tableFooterAdminOrderManagement
          : ""
      }
      scrollable // auto horizontal scroll
      onValueChange={(e) => dynamicFilter(e)}
      emptyMessage="Aradığınız kriterlere uygun kayıt bulunamadı."
    >
      {dynamicColumns}
    </DataTable>
  );
};

export default TableBody;
