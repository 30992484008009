export type OrderFilterOptionsItem = {
  count: number;
  value: string;
  key: string;
};

export type OrderFilterOptions = {
  slots: OrderFilterOptionsItem[];
  cities: OrderFilterOptionsItem[];
  order_dates: OrderFilterOptionsItem[];
  warehouses: OrderFilterOptionsItem[];
  statuses: OrderFilterOptionsItem[];
  customers: OrderFilterOptionsItem[];
  counties: OrderFilterOptionsItem[];
  branches: OrderFilterOptionsItem[];
  senders: OrderFilterOptionsItem[];
};

export const OrderFilterOptionsData = {
  slots: [],
  cities: [],
  order_dates: [],
  warehouses: [],
  statuses: [],
  customers: [],
  counties: [],
  branches: [],
  senders: [],
};

// REQUEST
export type OrderFilterOptionsRequest = {
  branches: string[];
  cities: string[];
  counties: string[];
  customers: string[];
  debit_end_date: Date | null;
  debit_start_date: Date | null;
  debit_types: string[] | null;
  deci_range: string;
  delivery_types: string[];
  driver_ids: string[];
  end_date: Date | null;
  fail_reasons: string[];
  in_route: boolean;
  order_codes: string[];
  order_ids: string[];
  package_codes: string[];
  package_debit_types: string[];
  route_id: string;
  senders: string[];
  slot_ranges: string[];
  start_date: Date | null;
  status: string[];
  vehicle_ids: string[];
  warehouse_codes: string[];
};

export const OrderFilterOptionsRequestData: OrderFilterOptionsRequest = {
  branches: [],
  cities: [],
  counties: [],
  customers: [],
  debit_end_date: null,
  debit_start_date: null,
  debit_types: null,
  deci_range: "",
  delivery_types: [],
  driver_ids: [],
  end_date: null,
  fail_reasons: [],
  in_route: false,
  order_codes: [],
  order_ids: [],
  package_codes: [],
  package_debit_types: [],
  route_id: "",
  senders: [],
  slot_ranges: [],
  start_date: null,
  status: [],
  vehicle_ids: [],
  warehouse_codes: [],
};
