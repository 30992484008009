import { getStatus } from "helper";
import { convertTimezone } from "utils/convertTimezone";

const filterHistoryData = (historyData) => {
  if (!historyData) return historyData;

  return (
    historyData &&
    historyData.map((data) => {
      const fullName = `${data.user_first_name} ${data.user_last_name}`;

      return {
        date: convertTimezone(data.date),
        status: getStatus(data.fail_code ?? data.status),
        username: data.username,
        updatedUsername: data.updated_username,
        fullName,
        description: data.description,
      };
    })
  );
};

export default filterHistoryData;
