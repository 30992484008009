import React from "react";
import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
//

export interface Props {
  count: number;
  openModal(): void;
}

export default function RoutesHeader(props: Props) {
  const { count, openModal } = props;

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ px: 2, height: 44, bgcolor: "background.neutral" }}
    >
      <Typography flex={1} variant="subtitle2">
        Rotalar ({count})
      </Typography>

      <Button
        size="small"
        color="secondary"
        variant="outlined"
        startIcon={<Add />}
        onClick={openModal}
      >
        Rota Oluştur
      </Button>
    </Stack>
  );
}
