import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { addLocale } from "primereact/api";

// import { Growl } from '@bit/primefaces.primereact.growl';
import "./InputDialogs.css";
import { Button } from "semantic-ui-react";

const InputDialogs = (props) => {
  const {
    onInputChange,
    submitted,
    customDialog,
    list,
    region,
    selectedContact,
    onContactChange,
    uploadExcelHandler,
    onFileLoad,
    toastCallback,
    InputControlled,
    displayInput,
    dropDownAdminWarehouseList,
  } = props;
  addLocale("tr", {
    firstDayOfWeek: 1,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["Pa", "Pz", "Sa", "Ça", "Pe", "Cu", "Ct"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const inputValue = (value) => {
    if (typeof value === "object" && value && value.field !== "undefined")
      return value.address;
    if (typeof value === "object" && value && value.county !== "undefined")
      return value.county;
    // if(typeof value === "object" && value.)
    return value;
  };

  const numberOnly = (value) => {
    if (value.keyCode >= 48 && value.keyCode <= 57) {
    } else {
      toastCallback("warn", "Not a number");
    }
  };

  const downloadFileSample = () => {
    const link = document.createElement("a");
    link.href =
      "https://api-dev.heybringo.com/api/public/download-sample-file?warehouseId=000000007a7cb805017a80f9011739a9";
    link.setAttribute("download", `FileName.xlsx`);
    link.click();
  };

  // const onUpload = event => {
  //   growl.show({
  // 		severity: 'info',
  // 		summary: 'Success',
  // 		detail: 'File Uploaded'
  // 	});
  // }

  const chooseOptions = {
    label: "Dosya seciniz",
    icon: "pi pi-file-excel",
  };

  const inputText = customDialog.map((col, i) => {
    if (col.type === "Input") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          {col.header == "İl" ||
          col.header == "İlçe" ||
          col.header == "Enlem" ||
          col.header == "Boylam" ? (
            <InputText
              id={col.field}
              placeholder={col.placeholder}
              value={inputValue(col.field)}
              onChange={(e) => onInputChange(e, col.name)}
              // onInput={e => onInputChange(e, col.name)}
              required={true}
              autoFocus
              readOnly={col.readOnly}
              className={classNames({
                "p-invalid": submitted && col.field == undefined,
              })}
              disabled={col.disabled}
            />
          ) : (
            <InputText
              id={col.field}
              placeholder={col.placeholder}
              defaultValue={inputValue(col.field)}
              onChange={(e) => onInputChange(e, col.name)}
              // onInput={e => onInputChange(e, col.name)}
              required={true}
              autoFocus
              readOnly={col.readOnly}
              className={classNames({
                "p-invalid": submitted && col.field == undefined,
              })}
              disabled={col.disabled}
            />
          )}

          {submitted && (col.field == "" || col.field == undefined) && (
            <small className="p-error">{col.header} is required.</small>
          )}
        </div>
      );
    } else if (col.type === "InputNumber") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          <InputNumber
            id={col.field}
            value={col.field}
            onChange={(e) => onInputChange(e, col.name)}
            required
            autoFocus
            onKeyDown={(e) => numberOnly(e)}
            className={classNames({ "p-invalid": submitted && !col.field })}
          />
          {submitted && !col.field && (
            <small className="p-error">{col.header} is required.</small>
          )}
        </div>
      );
    } else if (col.type === "Dropdown") {
      //console.log("AAAAAA: ", selectedContact);
      //selectedContact ? selectedContact :

      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          <Dropdown
            value={col.field}
            options={list.dropDownData}
            onChange={(e) =>
              onContactChange(e, col.contactType, col.contactType)
            }
            optionLabel={list.labelName}
            placeholder={list.placeholder}
          />

          {/*<Dropdown dataKey={col.name} value={ col.field } options={list.dropDownData} onChange={onContactChange} optionLabel={list.labelName} placeholder={list.placeholder} /> */}

          {submitted && (!col.field || col.field === "undefined") && (
            <small className="p-error">{col.header} is required.</small>
          )}
        </div>
      );
    } else if (col.type === "DropdownAdminWarehouse") {
      //console.log("AAAAAA: ", selectedContact);
      //selectedContact ? selectedContact :

      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          <Dropdown
            value={col.field}
            options={
              (dropDownAdminWarehouseList &&
                dropDownAdminWarehouseList.dropDownData) ||
              []
            }
            onChange={(e) =>
              onContactChange(e, col.contactType, col.contactType)
            }
            optionLabel={
              dropDownAdminWarehouseList && dropDownAdminWarehouseList.labelName
            }
            placeholder={
              dropDownAdminWarehouseList &&
              dropDownAdminWarehouseList.placeholder
            }
          />

          {/*<Dropdown dataKey={col.name} value={ col.field } options={list.dropDownData} onChange={onContactChange} optionLabel={list.labelName} placeholder={list.placeholder} /> */}

          {submitted && (!col.field || col.field === "undefined") && (
            <small className="p-error">{col.header} is required.</small>
          )}
        </div>
      );
    } else if (col.type === "dropdown-extra") {
      //console.log("AAAAAA: ", selectedContact);
      //selectedContact ? selectedContact :

      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          <Dropdown
            value={displayInput}
            options={region.dropDownData}
            onChange={(e) => InputControlled(e.target.value)}
            optionLabel={region.label}
            placeholder={region.placeholder}
          />

          {/*<Dropdown dataKey={col.name} value={ col.field } options={list.dropDownData} onChange={onContactChange} optionLabel={list.labelName} placeholder={list.placeholder} /> */}

          {submitted && (!col.field || col.field === "undefined") && (
            <small className="p-error">{col.header} is required.</small>
          )}
        </div>
      );
    } else if (col.type === "Calender") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          {/* {  col.select == "range" ? 
                   <Calendar id="range" value={new Date(col.field)} onChange={(e) => onInputChange(e, col.name)} selectionMode="range" placeholder={col.placeholder} readOnlyInput /> : */}
          <Calendar
            id="basic"
            dateFormat="dd.mm.yy"
            value={new Date(col.field)}
            onChange={(e) => onInputChange(e, col.name)}
            locale="tr"
          />
          {/* } */}
        </div>
      );
    } else if (col.type === "InputTextarea") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          <InputTextarea
            rows={5}
            cols={30}
            value={col.field}
            onChange={(e) => onInputChange(e, col.name)}
            placeholder={col.placeholder}
            disabled={col.disabled}
          />
        </div>
      );
    } else if (col.type === "FileInput") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label>
          {/* <FileUpload
            name='Order'
           // onUpload={uploadExcelHandler}
            customUpload={true}
		        multiple={true}
            accept='xlsx/*'
            chooseOptions={chooseOptions}
            // contentStyle={{width:"70px", height:"20px"}}
            uploadHandler={uploadExcelHandler}
            // onBeforeUpload
            // onBeforeSend
            onSelect={event => onFileLoad(event)}
          /> */}
          <div>
            <input
              enctype="multipart/form-data"
              type="file"
              onChange={(e) => onInputChange(e, col.name)}
            />
          </div>
          {/* <Growl
					ref={el => {
						growl = el;
					}}
				  /> */}
          <hr />
          <Button
            style={{ "margin-left": "24%", "margin-top": "10%" }}
            onClick={downloadFileSample}
            icon="file excel icon"
            label="Ornek Siparis Dosya Indir"
          />
        </div>
      );
    } else if (col.type === "Radio") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label> <br />
          Evet{" "}
          <input
            type="radio"
            id="radio"
            onChange={(e) => onInputChange(e, "sms_notification")}
            name="fav_language"
            value={col.field || 1}
          />{" "}
          &nbsp; Hayır{" "}
          <input
            type="radio"
            id="radio"
            onChange={(e) => onInputChange(e, "sms_notification")}
            name="fav_language"
            value={col.field || 0}
          />
        </div>
      );
    } else if (col.type === "Button") {
      return (
        <div className="p-field">
          <Button class="ui icon button" onClick={col.func}>
            {col.name}
          </Button>
        </div>
      );
    } else if (col.type == "Checkbox") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label> <br />
          <Checkbox
            checked={col.field}
            onChange={(e) => {
              let a = { value: e.checked };
              onInputChange(a, "barcode");
            }}
          ></Checkbox>
        </div>
      );
    } else if (col.type == "GeneralCheckbox") {
      return (
        <div className="p-field">
          <label htmlFor={col.field}>{col.header}</label> <br />
          <Checkbox
            checked={col.field}
            onChange={(e) => {
              let a = { value: e.checked };
              onInputChange(a, col.name);
            }}
          ></Checkbox>
        </div>
      );
    }
  });

  return inputText;
};

export default InputDialogs;
