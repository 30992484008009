import * as React from "react";
import moment from "moment";
import * as Icon from "iconsax-react";
import { Button, Chip, Stack } from "@mui/material";
//
import { getStatus } from "helper";
import { usePlanner } from "contexts/PlannerProvider";

interface Props {}

export default function OrdersFilterSelects(props: Props) {
  const { filters, setVisibleFilters } = usePlanner();

  const dates =
    filters.dates[0] && filters.dates[1]
      ? filters.dates.map((d) => moment(d).format("DD.MM.YYYY")).join(" / ")
      : null;

  const datesMalSube =
    filters.dates2[0] && filters.dates2[1] && filters.dates2Select
      ? `${filters.dates2Select.value} / ${filters.dates2
          .map((d) => moment(d).format("DD.MM.YYYY"))
          .join(" / ")}`
      : null;

  const projects =
    filters.selectProjects.length === 0
      ? null
      : filters.selectProjects.length === 1
      ? filters.selectProjects[0].value
      : filters.selectProjects.length;
  const branches =
    filters.selectBranches.length === 0
      ? null
      : filters.selectBranches.length === 1
      ? filters.selectBranches[0].value
      : filters.selectBranches.length;
  const cities =
    filters.selectCities.length === 0
      ? null
      : filters.selectCities.length === 1
      ? filters.selectCities[0].value
      : filters.selectCities.length;
  const counties =
    filters.selectCounties.length === 0
      ? null
      : filters.selectCounties.length === 1
      ? filters.selectCounties[0].value
      : filters.selectCounties.length;
  const staties =
    filters.selectStatus.length === 0
      ? null
      : filters.selectStatus.length === 1
      ? getStatus(filters.selectStatus[0].value)
      : filters.selectStatus.length;
  const deci = filters.selectDesiRange === "" ? null : filters.selectDesiRange;
  const slots =
    filters.selectSlot.length === 0
      ? null
      : filters.selectSlot.length === 1
      ? filters.selectSlot[0].value
      : filters.selectSlot.length;

  return (
    <Stack direction="row" flex={1} justifyContent="flex-end" spacing={1}>
      {dates && (
        <Chip
          label={dates}
          variant="soft"
          icon={<Icon.Calendar variant="Bulk" />}
        />
      )}
      {datesMalSube && (
        <Chip
          label={datesMalSube}
          variant="soft"
          icon={<Icon.Calendar variant="Bulk" />}
        />
      )}
      {projects && (
        <Chip
          label={projects}
          variant="soft"
          icon={<Icon.Document variant="Bulk" />}
        />
      )}
      {branches && (
        <Chip
          label={branches}
          variant="soft"
          icon={<Icon.Building variant="Bulk" />}
        />
      )}
      {cities && (
        <Chip
          label={cities}
          variant="soft"
          icon={<Icon.Map variant="Bulk" />}
        />
      )}
      {counties && (
        <Chip
          label={counties}
          variant="soft"
          icon={<Icon.Map variant="Bulk" />}
        />
      )}
      {staties && (
        <Chip
          label={staties}
          variant="soft"
          icon={<Icon.Status variant="Bulk" />}
        />
      )}
      {deci && (
        <Chip
          label={deci}
          variant="soft"
          icon={<Icon.WatchStatus variant="Bulk" />}
        />
      )}
      {slots && (
        <Chip
          label={slots}
          variant="soft"
          icon={<Icon.Aave variant="Bulk" />}
        />
      )}
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={() => setVisibleFilters(true)}
        startIcon={<Icon.FilterTick variant="Bulk" />}
      >
        Siparişleri Filtrele
      </Button>
    </Stack>
  );
}
