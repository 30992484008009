import React from "react";
import { useSnackbar } from "notistack";
import { Box, Stack, Typography } from "@mui/material";
import {
  AccessTimeOutlined,
  Delete,
  SnoozeOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

//
import { Services } from "api/Services";
import DataLocaleText from "../DataLocaleText";
import RouteFilterSelects from "./FilterSelects";
import Label from "components/CustomTable/TableLabel";
import { usePlanner } from "contexts/PlannerProvider";
import { RouteProps } from "contexts/PlannerProvider/types";
import { convertTimezone } from "utils/convertTimezone";

const services = new Services();

const renderStatusText = (status: string) => {
  if (status === "COMPLETED") {
    return "Tamamlandı";
  } else if (["ON_THE_WAY", "STARTED"].includes(status)) {
    return "Başladı";
  } else if (["PLANNED", "NOT_STARTED"].includes(status)) {
    return "Başlamadı";
  }
};

const renderStatus = (status: string) => {
  if (status === "COMPLETED") {
    return <TaskAltOutlined fontSize="small" color={"success"} />;
  } else if (["ON_THE_WAY", "STARTED"].includes(status)) {
    return <AccessTimeOutlined fontSize="small" color={"secondary"} />;
  } else if (["PLANNED", "NOT_STARTED"].includes(status)) {
    return <SnoozeOutlined fontSize="small" color="warning" />;
  }
};

export interface Props {}

export default function RoutesList(props: Props) {
  const {
    routes,
    deleteRoute,
    selectRoutes,
    handleSelectRoutes,
    routesLoading,
  } = usePlanner();
  const { enqueueSnackbar } = useSnackbar();
  const [checkLoading, setCheckLoading] = React.useState<boolean>(false);

  const handleDeleteRoute = React.useCallback(async (route: RouteProps) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Emin misiniz?")) {
      return;
    }
    try {
      await services.deletePlanningRoute(route.route_id);
      deleteRoute(route.route_id);
      enqueueSnackbar("Başarıyla silindi.", { variant: "success" });
    } catch (error: any) {
      if (error.code === "E0004") {
        enqueueSnackbar("İşlem yapılmış bir rotayı silemezsiniz.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
      }
    }
  }, []);

  const selectRoutesIds = selectRoutes.map((s) => s.route.route_id);

  return (
    <>
      <DataGrid
        checkboxSelection
        rowSelectionModel={selectRoutesIds}
        loading={checkLoading || routesLoading}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setCheckLoading(true);
          handleSelectRoutes(newRowSelectionModel as string[], () => {
            setCheckLoading(false);
          });
        }}
        //
        columns={[
          {
            flex: 1,
            field: "name",
            headerName: "Rota Adı",
            renderCell: ({ row }) => {
              return (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: "grey.400",
                      bgcolor: row.route_color,
                    }}
                  />
                  <Typography variant="body2">{row.name}</Typography>
                </Stack>
              );
            },
          },
          {
            flex: 1,
            field: "date",
            sortable: true,
            filterable: false,
            headerName: "Rota Tarihi",
            renderCell: ({ row }) => {
              return <Label>{convertTimezone(row.date)}</Label>;
            },
          },
          {
            flex: 1,
            field: "status",
            sortable: true,
            filterable: false,
            headerName: "Durum",
            renderCell: ({ row }) => {
              return (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  {renderStatus(row.status)}
                  <Typography variant="caption">
                    {renderStatusText(row.status)}
                  </Typography>
                </Stack>
              );
            },
          },
          {
            flex: 1,
            field: "driver__username",
            headerName: "Sürücü",
            valueGetter: ({ row: { driver } }) => {
              const isFullName = driver.first_name && driver.last_name;
              return isFullName
                ? `${driver.first_name} ${driver.last_name}`
                : driver.username;
            },
          },
          {
            flex: 1,
            field: "vehicle__name",
            valueGetter: ({ row }) => row.vehicle.name,
            headerName: "Araç",
            renderCell: ({ row }) => {
              return row.vehicle.name;
            },
          },
          {
            flex: 1,
            field: "distance",
            headerName: "Mesafe",
            renderCell: ({ row }) => {
              return `${(row.distance * 0.001 || 0).toFixed(1)} KM`;
            },
          },
          {
            flex: 1,
            field: "order_count",
            headerName: "Sipariş",
            renderCell: ({ row }) => {
              return <Label>{row.order_count}</Label>;
            },
          },
          {
            flex: 1,
            field: "delivered_order_count",
            headerName: "Teslim",
            renderCell: ({ row }) => {
              return (
                <Label
                  variant="ghost"
                  color="primary"
                >{`${row.delivered_order_count}`}</Label>
              );
            },
          },
          {
            flex: 1,
            field: "deliverednot_delivered_order_count_order_count",
            headerName: "Teslim Edilmeyen",
            renderCell: ({ row }) => {
              return (
                <Label
                  variant="ghost"
                  color="error"
                >{`${row.not_delivered_order_count}`}</Label>
              );
            },
          },
          {
            flex: 1,
            field: "package_count",
            headerName: "Paket",
            renderCell: ({ row }) => {
              return (
                <Label
                  variant="ghost"
                  color="secondary"
                >{`${row.package_count}`}</Label>
              );
            },
          },
          {
            flex: 1,
            field: "total_deci",
            headerName: "Desi",
            renderCell: ({ row }) => {
              return (
                <Label variant="ghost" color="warning">
                  {row.total_deci}
                </Label>
              );
            },
          },
          {
            flex: 1,
            field: "percent",
            headerName: "Tamamlanma",
            renderCell: ({ row }) => {
              const circleTotal =
                row.delivered_order_count + row.not_delivered_order_count;

              const percent = Math.round((circleTotal / row.order_count) * 100);

              return <Stack>{percent || 0}%</Stack>;
            },
          },
          {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            cellClassName: "actions",
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  color="error"
                  onClick={() => handleDeleteRoute(row)}
                />,
              ];
            },
          },
        ]}
        disableRowSelectionOnClick
        localeText={DataLocaleText}
        pageSizeOptions={[25, 50, 100]}
        getCellClassName={() => "theme--header"}
        sx={{ height: 1, bgcolor: "background.neutral" }}
        rows={routes.map((r) => ({ ...r, id: r.route_id }))}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        components={{
          Toolbar: () => {
            return (
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack pl={2} flex={1} direction="row" alignItems="center">
                  <GridToolbarQuickFilter sx={{ width: 1, maxWidth: 320 }} />
                  <RouteFilterSelects />
                </Stack>
                <GridToolbarContainer>
                  <GridToolbarFilterButton />
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                  />
                </GridToolbarContainer>
              </Stack>
            );
          },
        }}
      />
    </>
  );
}
