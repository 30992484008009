import * as React from "react";
import * as Icon from "iconsax-react";
import { Link } from "react-router-dom";
import { Stack, Box, Typography, Tooltip } from "@mui/material";
import { usePlanner } from "contexts/PlannerProvider";
import CustomSwitch from "components/CustomSwitch";

export interface DispatcherNewHeaderProps {}

export default function DispatcherNewHeader(props: DispatcherNewHeaderProps) {
  const { autoPlanning, onChangeAutoPlanning } = usePlanner();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ minHeight: 64, maxHeight: 64, px: 2, gap: 2 }}
    >
      <Link
        to="/"
        style={{
          width: 140,
          height: 60,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/hey-bringo-logo.png"
          sx={{
            display: "block",
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Link>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Tooltip title="Otomatik Planlama">
              <Icon.InfoCircle variant="Bulk" size={12} />
            </Tooltip>
            <Typography variant="caption" color="text.secondary">
              Planlama Seçeneği
            </Typography>
          </Stack>
          <Stack
            spacing={0.5}
            direction="row"
            component={Stack}
            alignItems="center"
          >
            <Typography
              variant="subtitle2"
              color={autoPlanning ? "initial" : "secondary"}
            >
              Manuel
            </Typography>
            <Box>-</Box>
            <Typography
              variant="subtitle2"
              color={autoPlanning ? "secondary" : "initial"}
            >
              Otomatik
            </Typography>
          </Stack>
        </Stack>
        <CustomSwitch checked={autoPlanning} onChange={onChangeAutoPlanning} />
      </Stack>
    </Stack>
  );
}
